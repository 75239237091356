import React from 'react';
import PropTypes from 'prop-types';

import { Click, FullEventClickTracker } from 'analytics';
import EventCell from 'components/Event/EventCell/EventCell';

const ScheduleList = ({ schedule, eventId, onClick, onClose }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(eventId);
    }
    if (onClose) {
      onClose();
    }
  };

  const performerSchedules = schedule.map((fullEvent) => {
    return (
      <EventCell
        key={`schedule-list-${fullEvent.id}`}
        fullEvent={fullEvent}
        onClick={handleClick}
        isActive={fullEvent.id === eventId}
        isSmall
        clickTracker={new FullEventClickTracker(fullEvent)
          .interaction(Click.INTERACTIONS.CELL())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.SCHEDULE())}
      />
    );
  });

  return <div>{performerSchedules}</div>;
};

ScheduleList.propTypes = {
  schedule: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default ScheduleList;
