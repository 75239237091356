import React from 'react';
import { connect } from 'react-redux';

import { ClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import MatchupImage from 'components/MatchupImage/MatchupImage';
import { selectIsMLBMatchupLogosExperiment } from 'experiments';
import { FullEvent } from 'models';
import { formatPriceWithComma } from 'utils/number';

import styles from './MetroPickerCollectionCard.module.scss';

interface MetroPickerCollectionCardProps {
  source: FullEvent;
  clickTracker: ClickTracker;
  lazyLoad: boolean;
  isMLBMatchupLogosExperiment: boolean;
}

const MetroPickerCollectionCard = ({
  source,
  clickTracker,
  lazyLoad,
  isMLBMatchupLogosExperiment,
}: MetroPickerCollectionCardProps) => {
  const link = source.getPath();
  const imageSrc = source.getPrimaryPerformer().heroImageUrl;
  const altTextForImage = source.getAltText();
  const price = source.getPrice();
  const title = source.getShortName('@');
  const subtitle = <BulletList listItems={source.dateTimeVenue} />;

  const showMLBMatchupLogos =
    isMLBMatchupLogosExperiment &&
    source instanceof FullEvent &&
    source.hasMatchupLogos;

  return (
    <Link
      className={styles['collection-card']}
      to={link}
      clickTracker={clickTracker}
      data-cy="collection-card-link"
    >
      <div className={styles['image-container']}>
        {showMLBMatchupLogos ? (
          <MatchupImage
            lazyLoad={lazyLoad}
            homePerformer={source.getPrimaryPerformer()}
            awayPerformer={source.getSecondaryPerformer()}
          />
        ) : (
          <Image src={imageSrc} alt={altTextForImage} lazyLoad={lazyLoad} />
        )}
        <span
          className={styles['card-price']}
        >{`From $${formatPriceWithComma(price)}`}</span>
      </div>
      <span className={styles['body']}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.subtitle}>{subtitle}</span>
      </span>
    </Link>
  );
};

const mapStateToProps = (state: unknown) => {
  return {
    isMLBMatchupLogosExperiment: selectIsMLBMatchupLogosExperiment(state),
  };
};

export default connect(mapStateToProps)(MetroPickerCollectionCard);
