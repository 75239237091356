import { urlCategorySelector } from 'store/modules/categories/category.helpers';
import { DEFAULT_SEAT_COUNT } from 'store/modules/resources/constants';
/*
  Experiment: FLASH_ZONE_HARMONY_WEB
  getDisplayGroupCarousel: control (not in use) - original function
  getDisplayGroupCarouselHarmony: control and harmony - new function
  Note: Based on experiment results, we can revert back to the original function
  or keep the new one.
*/
export const getDisplayGroupCarousel = (data) => {
  if (!data?.display_groups?.length) return {};

  const displayGroupsCarousel = data.display_groups.find(
    (list) => list.type === 'carousel'
  );

  return displayGroupsCarousel?.listings || {};
};

export const getDisplayGroupCarouselHarmony = (
  data,
  isExclusivesV1 = false
) => {
  const defaultState = {
    displayGroupCarousel: {},
    displayGroupCarouselHarmony: {},
  };
  if (!data?.display_groups?.length) return defaultState;
  const displayGroupsCarousel = data.display_groups.filter((list) => {
    if (isExclusivesV1) {
      return list.type === 'carousel' && list.deal !== 'best';
    }

    return list.type === 'carousel';
  });
  return {
    displayGroupCarousel: displayGroupsCarousel[0]?.listings || {},
    displayGroupCarouselHarmony: displayGroupsCarousel[1]?.listings || {},
  };
};

/*  getDisplayGroupList
    Sorted Listing ids (key = num tickets)
*/
export const getDisplayGroupList = (data) => {
  let regularList = {};

  if (data?.display_groups?.length > 0) {
    const displayGroupList = data.display_groups.find(
      (list) => list.type === 'list'
    );

    regularList = displayGroupList ? displayGroupList.listings : {};
  }

  return regularList;
};

// convert all listings object into an array - we show this when the preferred seat count doesn't exist
export const convertAllListingsToArray = (allListingsObj) => {
  const allListingsArray = [];

  for (const property in allListingsObj) {
    allListingsArray.push(allListingsObj[property]);
  }

  return allListingsArray;
};

/**
 * Get suggested listing seat count based on available lots and preferred seat
 * count. If no available lots, return 0. If preferred seat count is not
 * available, default to 2. If 2 is not available, default to the lowest seat
 * count.
 */
export function getSuggestedSeatCount(availableLots, preferredSeatCount) {
  if (!availableLots.length) {
    return 0;
  }

  if (preferredSeatCount && availableLots.includes(preferredSeatCount)) {
    return preferredSeatCount;
  } else if (availableLots.includes(DEFAULT_SEAT_COUNT)) {
    return DEFAULT_SEAT_COUNT;
  } else {
    return availableLots[0];
  }
}

export const getPricePercentChange = (oldPrice, newPrice) => {
  return Math.round(((newPrice - oldPrice) / oldPrice) * 100);
};

export const eventIsInCategoryList = (url = '', categoryList = []) => {
  const urlCategorySegment = url.split('/')[1] ?? '';
  const categoryName = urlCategorySelector(urlCategorySegment);
  return categoryList.includes(categoryName);
};
