export default function cmsMiddleware(cmsService) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { cms, types, ...rest } = action;

      if (!cms) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      return cms(cmsService).then(
        (result) => next({ ...rest, result: result.data, type: SUCCESS }),
        (error) => {
          console.error(
            `ButterCMS Error: ${error.message}`,
            `Url: ${getState().routing.locationBeforeTransitions.pathname}`
          );
          return next({ ...rest, error, type: FAILURE });
        }
      );
    };
}
