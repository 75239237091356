import { Click } from './events/Click';
import { TRACK } from './constants';

/**
 * ClickTracker class definition
 */
export class ClickTracker {
  constructor(targetType) {
    this.clickProperties = {
      [TRACK.TARGET_PAGE_TYPE]: targetType || Click.TARGET_PAGE_TYPES.NONE(),
    };
  }

  interaction(interaction, payload) {
    this.clickProperties[TRACK.INTERACTION] = interaction;
    this.payload(payload);
    return this;
  }

  payload(payload) {
    if (payload) {
      this.clickProperties[TRACK.PAYLOAD] = {
        ...this.clickProperties[TRACK.PAYLOAD],
        ...payload,
      };
    }
    return this;
  }

  sourcePageType(sourcePageType) {
    this.clickProperties[TRACK.SOURCE_PAGE_TYPE] = sourcePageType;
    return this;
  }

  targetPageType(targetPageType) {
    this.clickProperties[TRACK.TARGET_PAGE_TYPE] = targetPageType;
    return this;
  }

  dev(dev) {
    if (dev) {
      this.clickProperties[TRACK.DEV] = {
        ...this.clickProperties[TRACK.DEV],
        ...dev,
      };
    }
    return this;
  }

  json() {
    return this.clickProperties;
  }
}

/**
 * Classes that extend ClickTracker
 */
export class BlogPostClickTracker extends ClickTracker {
  constructor(slug) {
    super(Click.TARGET_PAGE_TYPES.BLOG_POST(slug));
  }
}

export class MetroClickTracker extends ClickTracker {
  constructor(metro) {
    super(Click.TARGET_PAGE_TYPES.METRO(metro.id));
  }
}

export class CategoryClickTracker extends ClickTracker {
  constructor(category) {
    super(
      Click.TARGET_PAGE_TYPES.CATEGORY(
        category.category || category.id || category
      )
    );
  }
}

export class PerformerClickTracker extends ClickTracker {
  constructor(performer) {
    super(Click.TARGET_PAGE_TYPES.PERFORMER(performer.id));
    this.dev({
      performer_slug: performer.team_id || performer.slug,
    });
  }
}

export class HomeClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.HOMEPAGE());
  }
}

export class CollectionClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.COLLECTION());
  }
}

export class FullEventClickTracker extends ClickTracker {
  constructor(event) {
    super(Click.TARGET_PAGE_TYPES.EVENT(event.id));
  }
}

export class VenueClickTracker extends ClickTracker {
  constructor(venue) {
    super(Click.TARGET_PAGE_TYPES.VENUE(venue.id));
  }
}

export class ListingClickTracker extends ClickTracker {
  constructor(listing, allInPricing, displayedPrice, isListingOverlay) {
    super(
      Click.TARGET_PAGE_TYPES.LISTING(
        listing.id,
        listing.eventId,
        allInPricing,
        displayedPrice,
        isListingOverlay
      )
    );
  }
}

export class StaticPageClickTracker extends ClickTracker {
  constructor(pageName) {
    super(Click.TARGET_PAGE_TYPES.STATIC_PAGE(pageName));
  }
}

export class AddCreditCardClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.ADD_CREDIT_CARD_MANUAL());
  }
}

export class AffirmPaymentClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.AFFIRM_PAYMENT_METHOD());
  }
}

export class PayPalPaymentClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.PAYPAL_PAYMENT_METHOD());
  }
}

export class ApplePayPaymentClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.APPLE_PAY_PAYMENT_METHOD());
  }
}

export class SearchPageClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.SEARCH());
  }
}

export class DeepLinkClickTracker extends ClickTracker {
  constructor(isMobile) {
    const targetPageType = isMobile
      ? Click.TARGET_PAGE_TYPES.INSTALL()
      : Click.TARGET_PAGE_TYPES.NONE();
    super(targetPageType);
  }
}

export class OrderConfirmationClickTracker extends ClickTracker {
  constructor(purchase) {
    super(Click.TARGET_PAGE_TYPES.ORDER_CONFIRMATION(purchase.id));
  }
}

export class AcceptTicketTransferClickTracker extends ClickTracker {
  constructor() {
    super(Click.TARGET_PAGE_TYPES.TRANSFER_TICKETS());
  }
}

export class TicketClickTracker extends ClickTracker {
  constructor(ticketId, transactionId, eventId) {
    super(Click.TARGET_PAGE_TYPES.TICKET(ticketId, transactionId, eventId));
  }
}

/**
 * Utilities
 */
export const getMetroClickTracker = (metro) => new MetroClickTracker(metro);
