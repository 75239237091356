import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _merge from 'lodash/merge';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  useAnalyticsContext,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import FilterDropdown from 'components/FilterControl/FilterDropdown';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import Spinner from 'components/Spinner';
import { device, useMediaQuery, useOnClickOutside } from 'hooks';
import { LocationFillIcon } from 'icons';
import { Collection as CollectionModel, FullEvent } from 'models';
import { updateCurrentLocation as updateCurrentLocationDispatch } from 'store/modules/app/app';
import { updateUserPreference as updateUserPreferenceDispatch } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import MetroPickerCollectionCard from './MetroPickerCollectionCard';

import styles from './MetroPickerCollection.module.scss';

interface MetroPickerCollectionProps {
  collection?: CollectionModel;
  collectionTitle?: string;
  currentMetro: Metro;
  closestMetro: Metro;
  metros: Metro[];
  isLoading: boolean;
  updateCurrentLocation: (metro: string) => void;
  updateUserPreference: (preferences: { lastVisitedMetro: string }) => void;
}

const MetroPickerCollection = ({
  collection,
  collectionTitle = 'Events',
  currentMetro,
  closestMetro,
  metros,
  isLoading,
  updateCurrentLocation,
  updateUserPreference,
}: MetroPickerCollectionProps) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [isMetroModalOpen, setIsMetroModalOpen] = useState(false);
  const isMobile = useMediaQuery(device.down.md);

  const toggleMetroModal = () => {
    setIsMetroModalOpen((prevIsMetroModalOpen) => {
      if (!prevIsMetroModalOpen) {
        const tracker = new ClickTracker().interaction(
          Click.INTERACTIONS.CHANGE_LOCATION()
        );
        analytics.track(
          new Click(_merge({}, clickContext, tracker.json()) as Click)
        );
      }
      return !prevIsMetroModalOpen;
    });
  };

  const handleMetroSelect = (selectedMetro: Metro) => {
    updateCurrentLocation(selectedMetro.id);
    updateUserPreference({ lastVisitedMetro: selectedMetro.id });
    toggleMetroModal();
  };

  const metroFilterOptions = [
    {
      name: 'Use My Location',
      onClick: () => handleMetroSelect(closestMetro),
      icon: <LocationFillIcon fill={colors.gametimeGreen} />,
    },
    ...metros.map((metro) => {
      return {
        name: metro.name,
        onClick: () => {
          handleMetroSelect(metro);
        },
      };
    }),
  ];

  const metroFilterModal = isMobile ? (
    <MetroSelectorModal
      show={isMetroModalOpen}
      onHide={() => {
        setIsMetroModalOpen(false);
      }}
    />
  ) : (
    <FilterDropdown show={isMetroModalOpen} options={metroFilterOptions} />
  );

  const handleMetroFilterClickOutside = () => {
    if (!isMobile) {
      setIsMetroModalOpen(false);
    }
  };

  const renderHeader = () => {
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, handleMetroFilterClickOutside);

    return (
      <div className={styles['picker-header']}>
        <span className={styles['metro-picker']}>
          {`${collectionTitle} Near `}
          <span className={styles['inline-metro-picker']} ref={ref}>
            <button
              className={styles['inline-metro']}
              onClick={toggleMetroModal}
            >
              {currentMetro.name}
            </button>
            {metroFilterModal}
          </span>
        </span>
      </div>
    );
  };

  const renderItem = (fullEvent: FullEvent, index: number) => {
    const clickTracker = new FullEventClickTracker(fullEvent).interaction(
      Click.INTERACTIONS.TILE(),
      {
        section_index: 0,
        item_index: index,
        collection: collectionTitle,
        get_in_price: fullEvent.getPrice(),
      }
    );

    return (
      <MetroPickerCollectionCard
        key={`slick-carousel-${fullEvent.id}`}
        source={fullEvent}
        clickTracker={clickTracker}
        lazyLoad={false}
      />
    );
  };

  return (
    <div className={styles['metro-picker-collection-container']}>
      <div className={styles['metro-picker-collection']}>
        <ReactSlickCarousel
          key="metro-picker-carousel"
          title={collectionTitle}
          analytics={analytics}
          clickContext={clickContext}
          items={collection?.eventsList}
          renderItem={renderItem}
          spacing={isMobile ? 16 : 24}
          slideWidth={isMobile ? 320 : 357}
          renderHeader={renderHeader}
          topArrows
        />
        {isLoading && (
          <div className={styles['metro-picker-spinner']}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateCurrentLocation: updateCurrentLocationDispatch,
  updateUserPreference: updateUserPreferenceDispatch,
};

export default connect<MetroPickerCollectionProps>(
  null,
  mapDispatchToProps
)(MetroPickerCollection);
