import React from 'react';
import PropTypes from 'prop-types';

import DividerIcon from 'icons/DividerIcon';
import MinusIcon from 'icons/MinusIcon';
import PlusIcon from 'icons/PlusIcon';
import ResetIcon from 'icons/ResetIcon';

import styles from './SeatMapControls.module.scss';

const SeatMapControls = ({
  onZoomIn,
  onZoomOut,
  reset,
  allowReset = false,
  isMobile = false,
}) => {
  return (
    <div className={styles.container}>
      {!isMobile && (
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.button}
            onClick={onZoomIn}
            aria-label="zoom in"
          >
            <PlusIcon />
          </button>
          <DividerIcon style={{ margin: 'auto', width: '80%' }} />
          <button
            type="button"
            className={styles.button}
            onClick={onZoomOut}
            aria-label="zoom out"
          >
            <MinusIcon />
          </button>
        </div>
      )}

      {allowReset && reset && (
        <button
          type="button"
          className={styles['reset-button']}
          onClick={reset}
          aria-label="reset zoom"
        >
          <ResetIcon />
        </button>
      )}
    </div>
  );
};

SeatMapControls.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  reset: PropTypes.func,
  allowReset: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default SeatMapControls;
