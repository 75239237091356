import React from 'react';
import { string, number } from 'prop-types';

import colors from 'styles/colors.constants';

const propTypes = {
  size: number,
  fill: string,
};

const defaultProps = {
  size: 16,
  fill: colors.gray243,
};

const CircleArrowIcon = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.5818 0 0 3.5818 0 8C0 12.4182 3.5818 16 8 16C12.4182 16 16 12.4182 16 8C16 3.5818 12.4182 0 8 0ZM5.8964 5.56066L8.33574 8L5.8964 10.4393C5.70114 10.6346 5.70114 10.9512 5.8964 11.1464L6.60351 11.8536C6.79877 12.0488 7.11535 12.0488 7.31061 11.8536L10.4571 8.70711C10.8476 8.31658 10.8476 7.68342 10.4571 7.29289L7.31061 4.14645C7.11535 3.95118 6.79877 3.95118 6.60351 4.14645L5.8964 4.85355C5.70114 5.04881 5.70114 5.3654 5.8964 5.56066Z"
      fill={fill}
    />
  </svg>
);

CircleArrowIcon.propTypes = propTypes;
CircleArrowIcon.defaultProps = defaultProps;

export default CircleArrowIcon;
