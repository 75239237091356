import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import NotFound from 'pages/NotFound/NotFound';
import { fetchStaticContentBySlug } from 'store/modules/content/static/actions';
import { selectStaticContentBySlug } from 'store/modules/content/static/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import {
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_TITLE,
  PAGE_DESCRIPTIONS,
  PAGE_TITLES,
} from './constants';

import styles from './ContentPage.module.scss';

@TrackPageView(({ slug }) => ({ pageType: View.PAGE_TYPES.STATIC_PAGE(slug) }))
@withClickContext(({ slug }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.STATIC_PAGE(slug),
}))
class ContentPage extends Component {
  static propTypes = {
    slug: PropTypes.string,
    content: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.content = null;
  }

  componentDidMount() {
    const { content } = this.props;
    this.content = content;
  }

  renderMeta() {
    const { slug } = this.props;
    const title = PAGE_TITLES[slug] || DEFAULT_PAGE_TITLE;
    const description = PAGE_DESCRIPTIONS[slug] || DEFAULT_PAGE_DESCRIPTION;

    return (
      <div>
        <HeadTitle title={title} />
        <HeadDescription description={description} />
      </div>
    );
  }

  render() {
    const { content } = this.props;
    // When navigating away from the page, this component is re-rendered for the spinner animation but with the new URL,
    // which likely will load no CMS content. In this case we use cached CMS content for the render.
    const cmsContent = content || this.content;

    if (!cmsContent) return <NotFound />;

    /* eslint-disable react/no-danger */
    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader search showCategories showAccount showHamburger />
        }
        footer={<GTFooter />}
        className={styles['content-container']}
      >
        {this.renderMeta()}
        <Container className={styles['body-container']}>
          <div dangerouslySetInnerHTML={{ __html: cmsContent.body }} />
        </Container>
      </ContainerTemplate>
    );
  }
}

const mapStateToProps = (state, { location: { pathname } }) => {
  const slug = pathname.replace('/', '');

  return {
    content: selectStaticContentBySlug(state, slug),
    slug,
  };
};

const promise = async ({ store: { dispatch }, location: { pathname } }) => {
  const slug = pathname.replace('/', '');

  return await Promise.all([
    dispatch(fetchMetros()),
    dispatch(fetchStaticContentBySlug(slug)),
  ]);
};

export default connect(mapStateToProps)(
  withDataLoader(ContentPage, {
    promise,
  })
);
