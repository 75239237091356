import { selectIsWebExclusivesV1Experiment } from 'experiments';
import {
  isListingPage,
  lastRouteSelector,
} from 'store/modules/history/history';
import { locationSelector } from 'store/modules/location';
import { zListingsSelector } from 'store/modules/user/user.selectors';
import {
  getPreferenceSortOrder,
  userPreferenceShowAllInPriceSelector,
} from 'store/modules/userPreference/user.preference.selectors';

import { LISTING } from './listing.actionTypes';
import { priceQuartileSelector } from './selectors';

export const setPriceQuartile = (priceQuartile = -1) => {
  return {
    type: LISTING.SET_PRICE_QUARTILE,
    priceQuartile,
  };
};

export const fetchListings =
  ({
    eventId,
    zoomLevel,
    sortOrderParam = null,
    priceBucket = -1,
    isMobile,
    /**
     * quantity should be included as pricing may potentially differ for the
     * same listing depending on the lot size
     */
    quantity,
  }) =>
  (dispatch, getState) => {
    if (!eventId) {
      return Promise.resolve();
    }

    const state = getState();

    if (!sortOrderParam) {
      sortOrderParam = getPreferenceSortOrder(state);
    }

    /**
     * this isExclusivesV1 value is used to filter out the "best" deals
     * carousel display group.
     */
    const isExclusivesV1 = selectIsWebExclusivesV1Experiment(state);

    // Fetch all current, live zListing only experiments and add to the params
    const zListingExperiments = zListingsSelector(state);
    const allInPrice = userPreferenceShowAllInPriceSelector(state);

    const searchParams = {
      ...zListingExperiments,
      sort_order: sortOrderParam,
      all_in_pricing: allInPrice,
      quantity,
    };

    if (isMobile && searchParams.zListings31) {
      delete searchParams.zListings31;
    }

    const storePriceQuartile = priceQuartileSelector(state);

    if (typeof zoomLevel === 'number' && zoomLevel >= 0) {
      searchParams.zoom = zoomLevel;
    }

    const location = locationSelector(state);
    const isListingDetails = isListingPage(location.pathname);
    const mobileQuery = location?.query?.view;

    if (isListingDetails && storePriceQuartile > 0) {
      searchParams.quartile = storePriceQuartile;
    } else if (priceBucket > 0) {
      searchParams.quartile = priceBucket;
    } else if (isMobile && mobileQuery) {
      const lastRoute = lastRouteSelector(state);
      const sameEvent = lastRoute && lastRoute.includes(eventId);

      if (sameEvent && storePriceQuartile > 0) {
        searchParams.quartile = storePriceQuartile;
      }
    }

    // Default listings api request
    return dispatch({
      types: [
        LISTING.GET_LISTINGS_REQUEST,
        LISTING.GET_LISTINGS_REQUEST_SUCCESS,
        LISTING.GET_LISTINGS_REQUEST_FAIL,
      ],
      params: searchParams,
      isExclusivesV1,
      promise: (httpClient) =>
        httpClient.request({
          path: `/v2/listings/${eventId}`,
          searchParams,
        }),
    });
  };

export const setLockedCarouselHover = (isHovered) => {
  return {
    type: LISTING.SET_LOCKED_CAROUSEL_HOVER,
    isHovered,
  };
};

export const updateMapHarmony = (updating = true) => {
  return {
    type: LISTING.UPDATE_MAP_HARMONY,
    updating,
  };
};

export const updateListMapHarmonyToggle = (toggleIsOn) => {
  return {
    type: LISTING.UPDATE_LIST_MAP_HARMONY_TOGGLE,
    toggleIsOn,
  };
};

export const updateUnlockedSponsoredDeals = (eventId) => {
  return {
    type: LISTING.UPDATE_UNLOCKED_ZONE_DEAL_EVENTS,
    eventId,
  };
};

export const setHoveredListingId = (hoveredListingId = '') => {
  return {
    type: LISTING.SET_HOVERED_LISTING_ID,
    hoveredListingId,
  };
};
