import React, { useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { device, useMediaQuery } from 'hooks/useMediaQuery';

import { useMapDimensions } from '../hooks';
import { SeatMapImage, SeatMapPinSingle } from '../SeatMap';
import SeatMapControls from '../SeatMapControls';
import {
  SeatMapInteraction,
  SeatMapInteractionMain,
} from '../SeatMapInteraction';
import SeatMapLoadIndicator from '../SeatMapLoadIndicator';

import styles from '../ListingsMapView.module.scss';

const ListingsMapViewLight = ({ mapUrl, singlePinData }) => {
  const seatMapRef = useRef(null);
  const isMobile = useMediaQuery(device.down.md);

  const [isMapLoaded, setMapLoaded] = useState(false);
  const [isMapError, setMapError] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [measureRef, { width: rectWidth = 0, height: rectHeight = 0 }] =
    useMeasure();

  /*
    a) On map image load sets the map image proportions.
    b) On window resize, calculates pin's coordinates based on map size.
  */
  const mapImage = useMapDimensions(rectWidth, rectHeight, isMapLoaded);

  return (
    <div
      data-testid="listings-map-view-light"
      ref={measureRef}
      className={classNames(styles['map-container'])}
    >
      <section
        ref={setMapRef}
        style={{
          width: mapImage.width,
        }}
      >
        <SeatMapLoadIndicator
          loaded={isMapLoaded}
          error={isMapError}
          isOrderSeatMap
        />
        {mapRef && (
          <SeatMapInteraction
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            isMobile={isMobile}
            onPanInteractionEnd={() => {}}
            onPan={() => {}}
            zoomInteractionEnd={() => {}}
          >
            {({ zoomIn, zoomOut, state, resetTransform }) => {
              const { scale } = state;

              return (
                <>
                  <SeatMapControls
                    isMobile={isMobile}
                    onZoomIn={() => {
                      if (scale === 5) return;
                      zoomIn();
                    }}
                    onZoomOut={() => {
                      if (scale === 1) return;
                      zoomOut();
                    }}
                    reset={() => {
                      resetTransform();
                    }}
                    allowReset={scale > 1}
                  />

                  <SeatMapInteractionMain isLoaded={isMapLoaded}>
                    <SeatMapImage
                      ref={seatMapRef}
                      mapUrl={mapUrl}
                      scale={scale}
                      onLoad={() => {
                        setMapLoaded(true);
                      }}
                      onError={() => setMapError(true)}
                    />

                    <SeatMapPinSingle
                      width={mapImage.width}
                      height={mapImage.height}
                      scale={scale}
                      data={singlePinData}
                    />
                  </SeatMapInteractionMain>
                </>
              );
            }}
          </SeatMapInteraction>
        )}
      </section>
    </div>
  );
};

ListingsMapViewLight.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  singlePinData: PropTypes.object,
};

export default ListingsMapViewLight;
