import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import UserAddressForm from 'components/UserAddressForm/UserAddressForm';
import { Listing } from 'models';
import { PURCHASE_CTA_TEXTS } from 'pages/Listing/constants';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import { defaultCardTokenSelector } from 'store/modules/userPurchases/userPurchases.selectors';

@connect((state) => ({
  creditCard: userPurchaseCreditCard(state),
  defaultCardToken: defaultCardTokenSelector(state),
}))
@withClickContext(({ delivery, listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: delivery
    ? Click.SOURCE_PAGE_TYPES.VERIFY_SHIPPING_ADDRESS()
    : Click.SOURCE_PAGE_TYPES.VERIFY_BILLING_ADDRESS(),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
@TrackPageView(({ delivery, listing }) => ({
  [TRACK.PAGE_TYPE]: delivery
    ? View.PAGE_TYPES.VERIFY_SHIPPING_ADDRESS()
    : View.PAGE_TYPES.VERIFY_BILLING_ADDRESS(),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
export default class PurchaseUserCollectAddress extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    delivery: PropTypes.bool,
    creditCard: PropTypes.object.isRequired,
    defaultCardToken: PropTypes.string,
    purchaseType: PropTypes.oneOf(Object.values(PURCHASE_TYPE)).isRequired,
    listing: PropTypes.instanceOf(Listing).isRequired,
  };

  static defaultProps = {
    delivery: false,
  };

  verifyText() {
    const {
      delivery,
      creditCard: { cards },
      defaultCardToken,
      purchaseType,
    } = this.props;

    if (delivery) {
      return {
        title: 'Shipping Address',
        desc: 'These are physical tickets that will be mailed to you.',
      };
    }

    const isAlternatePaymentMethodSelected = [
      PURCHASE_TYPE.APPLE_PAY,
      PURCHASE_TYPE.GOOGLE_PAY,
      PURCHASE_TYPE.AFFIRM_PAY,
      PURCHASE_TYPE.PAYPAL_PAY,
    ].includes(purchaseType);
    if (isAlternatePaymentMethodSelected) {
      return {
        title: 'Purchased! Verify Billing Address',
        desc: 'Before delivering your tickets, we need to verify your billing address.',
      };
    }

    const defaultCard = cards[defaultCardToken] || {};
    const lastFourString = defaultCard.last_four
      ? ` (${defaultCard.last_four})`
      : '';
    return {
      title: 'Purchased! Verify Billing Address',
      desc: `Before delivering your tickets, we need to verify your card${lastFourString}`,
    };
  }

  render() {
    const { delivery, onSubmit } = this.props;

    const addressPlaceholder = delivery
      ? 'Shipping Address'
      : 'Billing Address';
    const clickTracker = new ClickTracker().interaction(
      Click.INTERACTIONS.CONTINUE_BUTTON()
    );

    return (
      <div>
        <InfoHeader headerContent="Purchased!" isDarkTheme bold />
        <ThemedCard {...this.verifyText()}>
          <UserAddressForm
            handleUserAddressFormSubmit={onSubmit}
            ctaText={PURCHASE_CTA_TEXTS.DEFAULT}
            fieldProps={{
              name: {
                placeholder: 'Full Name',
              },
              address: {
                placeholder: addressPlaceholder,
              },
              address2: {
                placeholder: 'Unit Number (i.e. Unit 799)',
              },
            }}
            clickTracker={clickTracker}
          />
        </ThemedCard>
      </div>
    );
  }
}
