import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RouterContext, RouterState } from 'react-router';
import { usePrevious } from 'react-use';
import { AppContextState } from 'contexts/AppContext';
import { Store } from 'redux';

import Spinner from 'components/Spinner';

import { getRouteLoaders, loadRouteData } from './LoaderContext.utils';
import {
  DataLoader,
  JsonData,
  PreloadedState,
  RouteComponentWithLoader,
} from './types';

const LoaderContext = createContext<PreloadedState | undefined>(undefined);

interface LoaderContextProviderProps {
  routerProps: RouterState;
  preloadedState: PreloadedState;
  store: Store;
  asyncRedirect: (url: string, status?: number) => void;
  appContext: AppContextState;
}

/**
 * Context provider for pre-loading route-specific data in a test environment.
 * Not meant for use in runtime code.
 *
 * @deprecated Use `LoaderContextProvider` instead.
 */
export function TestRendererLoaderContextProvider({
  children,
  preloadedState,
}: {
  children: React.ReactNode;
  preloadedState: JsonData;
}) {
  return (
    <LoaderContext.Provider value={preloadedState}>
      {children}
    </LoaderContext.Provider>
  );
}

// hook to track loading across routes and decide to show/hide loader
function useHideLoader(currentPathname: string) {
  const previousPathname = usePrevious(currentPathname);

  return (
    !!previousPathname &&
    currentPathname.includes('/events/') &&
    !currentPathname.includes('/listings/') &&
    previousPathname.includes('/events/') &&
    previousPathname.includes('/listings/')
  );
}

/**
 * Context provider for pre-loading route-specific data.
 * Utilized for both server-side and client-side rendering, but data fetching
 * within this context is only performed on the client-side. Server-side data
 * fetching needs to be handled outside of the scope of React, then passed in
 * as `preloadedState`.
 */
export function LoaderContextProvider({
  routerProps,
  preloadedState = {},
  store,
  asyncRedirect,
  appContext,
}: LoaderContextProviderProps) {
  const [currentRouterProps, setCurrentRouterProps] = useState(routerProps);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<PreloadedState>(preloadedState);

  const isInitialLoadRef = useRef(true);
  const appContextRef = useRef(appContext);
  const abortControllerRef = useRef<AbortController | undefined>();

  appContextRef.current = appContext;
  const hideLoaderBasedOnRouteChange = useHideLoader(
    routerProps.location.pathname
  );

  useEffect(() => {
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;
      return;
    }

    const loaders = getRouteLoaders(routerProps.components);

    if (loaders.length > 0) {
      const hideLoader =
        loaders.some((dataLoader) => dataLoader.hideLoadingSpinner) ||
        hideLoaderBasedOnRouteChange;
      setIsLoading(!hideLoader);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort(
          new DOMException('New request initiated', 'LoaderContext')
        );
      }

      abortControllerRef.current = new AbortController();

      loadRouteData(loaders, {
        store,
        asyncRedirect,
        appContext: appContextRef.current,
        abortController: abortControllerRef.current,
        ...routerProps,
      })
        .then((data) => {
          setState(data);
          setCurrentRouterProps(routerProps);
          setIsLoading(false);
        })
        .finally(() => {
          if (abortControllerRef.current) {
            abortControllerRef.current = undefined;
          }
        });
    } else {
      setCurrentRouterProps(routerProps);
    }
  }, [
    // while the properties themselves are stable, the object reference changes
    // on every render
    // see: https://github.com/remix-run/react-router/blob/v3.2.6/modules/Router.js#L144-L152
    routerProps.components,
    routerProps.location,
    routerProps.params,
    routerProps.routes,
    store,
    asyncRedirect,
    hideLoaderBasedOnRouteChange,
  ]);

  return (
    <LoaderContext.Provider value={state}>
      {isLoading && <Spinner isFullscreen />}
      <RouterContext {...currentRouterProps} />
    </LoaderContext.Provider>
  );
}

/**
 * Hook to access the LoaderContextProvider state
 */
export function useLoaderData<D extends JsonData>(loaderKey: string) {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error(
      'useLoaderData must be used within a LoaderContextProvider'
    );
  }
  return context[loaderKey] as D | undefined;
}

/**
 * HOC to pass a component data returned from the loader. This is not required
 * for components that only preload data to the Redux store. The keys from the
 * component data loader return value will be omitted from the props on the
 * returned component.
 *
 * This can only be used with class components, functional component should use
 * the `useLoaderData` hook.
 */
export function withDataLoader<
  P extends object,
  D extends JsonData | void,
  R extends object = Omit<P, keyof D>,
>(Component: React.ComponentClass<P>, dataLoader: DataLoader<D>) {
  const WithDataLoaderComponent: RouteComponentWithLoader<R, D> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const data = useLoaderData(dataLoader.key || '') || {};
    return <Component {...(props as unknown as P)} {...data} />;
  };

  // hoist the dataLoader to the wrapper component to enable data loading
  WithDataLoaderComponent.dataLoader = dataLoader;

  const displayName = Component.displayName || Component.name || 'Component';
  WithDataLoaderComponent.displayName = `withDataLoader(${displayName})`;

  return WithDataLoaderComponent;
}
