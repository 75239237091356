import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import BlogPost from 'models/Blog/Post';

import styles from './PostCard.module.scss';

const PostCard = ({ post, clickTracker }) => {
  const {
    postCard,
    cardLink,
    imageContainer,
    infoContainer,
    category,
    title,
    publishDate,
  } = styles;

  return (
    <div className={postCard}>
      <Link className={cardLink} to={post.getUrl()} clickTracker={clickTracker}>
        <div className={imageContainer}>
          <Image src={post.featuredImage} />
        </div>
        <div className={infoContainer}>
          <div className={category}>{post.getTopCategory()}</div>
          <div className={title}>{post.title}</div>
          <div className={publishDate}>
            {`Posted ${post.getPublishedDate()}`}
          </div>
        </div>
      </Link>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.instanceOf(BlogPost),
  clickTracker: PropTypes.object,
};

PostCard.defaultProps = {};

export default PostCard;
