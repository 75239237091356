import React from 'react';

export default ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.17084 4.61807C4.10034 4.50058 4.06509 4.44183 4.08407 4.39546C4.08728 4.38764 4.09146 4.38025 4.09652 4.37347C4.12652 4.33334 4.19503 4.33334 4.33204 4.33334H7.66796C7.80497 4.33334 7.87348 4.33334 7.90348 4.37347C7.90854 4.38025 7.91272 4.38764 7.91593 4.39546C7.93491 4.44183 7.89966 4.50058 7.82916 4.61807L6.16121 7.398C6.09419 7.50969 6.06068 7.56554 6.01233 7.57155C6.00414 7.57257 5.99586 7.57257 5.98767 7.57155C5.93932 7.56554 5.90581 7.50969 5.83879 7.398L4.17084 4.61807Z"
      fill="white"
    />
    <g filter="url(#filter0_d_2_2102)">
      <path
        d="M2 13C2 11.3431 3.34315 10 5 10H9V16H5C3.34315 16 2 14.6569 2 13Z"
        fill="#65FFB5"
      />
    </g>
    <rect x="10" y="11" width="7" height="4" fill="#F2C94C" />
    <path
      d="M18 11H23C24.1046 11 25 11.8954 25 13C25 14.1046 24.1046 15 23 15H18V11Z"
      fill="#EB5757"
    />
    <defs>
      <filter
        id="filter0_d_2_2102"
        x="0"
        y="8"
        width="11"
        height="10"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.396078 0 0 0 0 1 0 0 0 0 0.709804 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2_2102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2_2102"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
