export const LISTING = {
  GET_LISTINGS_REQUEST: 'GET_LISTINGS_REQUEST',
  GET_LISTINGS_REQUEST_SUCCESS: 'GET_LISTINGS_REQUEST_SUCCESS',
  GET_LISTINGS_REQUEST_FAIL: 'GET_LISTINGS_REQUEST_FAIL',
  SET_LOCKED_CAROUSEL_HOVER: 'SET_LOCKED_CAROUSEL_HOVER',
  SET_PRICE_QUARTILE: 'SET_PRICE_QUARTILE',
  UPDATE_MAP_HARMONY: 'UPDATE_MAP_HARMONY',
  UPDATE_LIST_MAP_HARMONY_TOGGLE: 'UPDATE_LIST_MAP_HARMONY_TOGGLE',
  UPDATE_UNLOCKED_ZONE_DEAL_EVENTS: 'UPDATE_UNLOCKED_ZONE_DEAL_EVENTS',
  SET_HOVERED_LISTING_ID: 'SET_HOVERED_LISTING_ID',
};
