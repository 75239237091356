import React from 'react';
import HorizontalScroll from 'ui/HorizontalScroll/HorizontalScroll';

import PicksCard from '../PicksCard/PicksCard';

import styles from './PicksFAQ.module.scss';

const PICK_CARDS = [
  {
    emoji: '🔎',
    number: '01',
    message:
      'Gametime analyzes thousands of tickets for all your favorite events.',
    image: 'https://assets.gametime.co/static_pages/picks/1.jpg',
  },
  {
    emoji: '✨',
    number: '02',
    message: 'We find the best of the best tickets, so you don’t have to.',
    image: 'https://assets.gametime.co/static_pages/picks/2.jpg',
  },
  {
    emoji: '🤌',
    number: '03',
    message: 'You choose the deal that‘s right for you.',
    image: 'https://assets.gametime.co/static_pages/picks/3.jpg',
  },
];

const PicksFAQ = () => {
  return (
    <div className={styles['faq-section']}>
      <span className={styles['faq-info']}>
        <h2 className={styles['faq-title']}>What are Gametime Picks?</h2>
        <p className={styles['faq-description']}>More saving, more fun.</p>
      </span>
      <div className={styles['faq-cards-container']}>
        <HorizontalScroll isMobile>
          <div className={styles['faq-cards']}>
            {PICK_CARDS.map((card) => (
              <HorizontalScroll.Card
                className={styles['faq-card']}
                key={card.number}
                itemId={card.number}
              >
                <PicksCard {...card} />
              </HorizontalScroll.Card>
            ))}
          </div>
        </HorizontalScroll>
      </div>
    </div>
  );
};

export default PicksFAQ;
