import { createSelector } from 'reselect';

import config from 'config/config';
import {
  getAssociatedListingPathname,
  locationSelector,
  selectAlgoliaSearchParams,
} from 'store/modules/location';
import { seatCountSelector } from 'store/modules/purchase/purchase.selectors';

export const selectCheckoutMagicLinkParams = createSelector(
  locationSelector,
  seatCountSelector,
  selectAlgoliaSearchParams,
  (location, seatCount, searchParams) => {
    searchParams.set(
      [config.cookiesConfig.USER_EXTRAS.parameters.SEAT_COUNT.query],
      seatCount
    );

    if (location.query.zoom) {
      searchParams.set('zoom', location.query.zoom);
    }

    const listingPath = getAssociatedListingPathname(location.pathname);
    const params = {
      pathname: `${listingPath}/checkout`,
      search: Object.fromEntries(searchParams),
    };

    return params;
  }
);
