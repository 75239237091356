import { Component, createElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { redirect } from 'helpers/RedirectHelper';

const EnsurePath = (path) => (wrappedComponent) => {
  class EnhancedComponent extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      dispatch: PropTypes.func.isRequired,
    };

    componentDidMount() {
      const ensurePath = typeof path === 'function' ? path(this.props) : path;

      const { pathname, search } = this.props.location;
      if (ensurePath && ensurePath !== pathname) {
        const { dispatch } = this.props;
        dispatch(redirect(ensurePath, null, search));
      }
    }

    render() {
      return createElement(wrappedComponent, this.props);
    }
  }

  return connect()(EnhancedComponent);
};

export default EnsurePath;
