import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './EventTypeTabItem.module.scss';

const EventTypeTabItem = ({ id, title, isActive, setActiveItem }) => {
  const handleClick = () => {
    if (!isActive) setActiveItem(id);
  };

  return (
    <button
      type="button"
      className={classNames(styles['event-type-tab-item'], {
        [styles.active]: isActive,
      })}
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

EventTypeTabItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  setActiveItem: PropTypes.func,
};

export default EventTypeTabItem;
