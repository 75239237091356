import { selectCurrentMetro } from '../resources/resource.selectors';

import { RESTRICTED_METROS } from './constants';

export const getLocationTitle = ({ city, region, state, name }) =>
  name || `${city}${state || region ? `, ${state || region}` : ''}`;

export const isRestrictedMetro = (state) => {
  const loc = selectCurrentMetro(state);

  if (!loc) return false;

  return loc && RESTRICTED_METROS.has(loc.id);
};
