import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import config from 'config/config';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import { getListingById } from 'store/modules/data/Listings/selectors';
import {
  getAssociatedListingPathname,
  locationSelector,
} from 'store/modules/location';
import { defaultSeatCountSelector } from 'store/modules/purchase/purchase.selectors';
import {
  selectUserDetails,
  userAuthCookieValue,
} from 'store/modules/user/user.selectors';
import {
  mParticleFireIdentityRequest,
  mParticleIdentityCallback,
} from 'utils/mParticle';

import { USER_ACTION_TYPES } from './user.actionTypes';

export const setAuthCookie = (user, res) => {
  const { name, attributes } = config.cookiesConfig.AUTH;
  attributes.expires = attributes.maxAge
    ? new Date(Date.now() + attributes.maxAge)
    : undefined;
  if (__CLIENT__) {
    // numeric maxAge attribute not supported by js-cookie, must convert
    // to string and set on attribute name "Max-Age"
    const { maxAge, ...cookieAttributes } = attributes;
    if (Number.isFinite(maxAge)) {
      cookieAttributes['Max-Age'] = maxAge.toString();
    }
    Cookies.set(name, userAuthCookieValue(user), cookieAttributes);
  } else if (res) {
    res.cookie(name, userAuthCookieValue(user), attributes);
  }
};

export const setBranchIdentity = (user) => {
  if (
    __CLIENT__ &&
    typeof window !== 'undefined' &&
    typeof window.branch?.setIdentity === 'function'
  ) {
    try {
      window.branch.setIdentity(user.id, (err, data) => {
        if (err) {
          console.error('error setting branch identity', data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
};

// setting user identity fields that can then be passed to third party data providers like iterable
export const setmParticleIdentity = (user) => {
  if (__CLIENT__ && typeof window !== 'undefined' && window.mParticle) {
    mParticleFireIdentityRequest(user);
  }
};

/* ********************* ACTION CREATORS *********************** */

export const fetchUser = (id, session_token) => ({
  types: [
    USER_ACTION_TYPES.FETCH_USER,
    USER_ACTION_TYPES.FETCH_USER_SUCCESS,
    USER_ACTION_TYPES.FETCH_USER_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/user/${id}`,
      headers: { session_token },
    }),
});

export const logout = () => async (dispatch, getState) => {
  const user = selectUserDetails(getState());

  dispatch({
    types: [
      USER_ACTION_TYPES.LOGOUT,
      USER_ACTION_TYPES.LOGOUT_SUCCESS,
      USER_ACTION_TYPES.LOGOUT_FAIL,
    ],
    promise: (httpClient) =>
      httpClient
        .request({
          path: `/v1/user/${user.id}/logout`,
          method: 'POST',
          headers: { session_token: user.session_token },
        })
        .then(() => {
          if (__CLIENT__ && typeof window !== 'undefined') {
            window.branch.logout((err) => {
              if (err) {
                console.error('error logging out');
              }
            });

            if (window.mParticle?.Identity?.logout) {
              let retryCount = 0;
              const identityCallback = (result) => {
                if (retryCount < 3) {
                  retryCount += 1;
                  mParticleIdentityCallback(result, () => {
                    window.mParticle.Identity.logout({}, identityCallback);
                  });
                } else {
                  retryCount = 0;
                }
              };

              window.mParticle.Identity.logout({}, identityCallback);
            }
          }

          const authCookie = config.cookiesConfig.AUTH;
          Cookies.remove(authCookie.name, { path: authCookie.attributes.path });
        }),
  });
};

export const purchaseFlowLogout = () => (dispatch, getState) => {
  dispatch(logout());
  dispatch(
    push(getAssociatedListingPathname(locationSelector(getState()).pathname))
  );
};

export const createUser = (userData) => {
  return (dispatch, getState) => {
    const { google_advertising_id, webUserId } = appConfigSelector(getState());

    return dispatch({
      types: [
        USER_ACTION_TYPES.CREATE_USER,
        USER_ACTION_TYPES.CREATE_USER_SUCCESS,
        USER_ACTION_TYPES.CREATE_USER_FAIL,
      ],
      promise: (httpClient) =>
        httpClient.request({
          path: '/v1/user',
          method: 'POST',
          body: {
            google_advertising_id,
            signup_type: 'manual',
            web_id: webUserId,
            ...userData,
          },
        }),
    });
  };
};

export const updateUser = (user) => ({
  types: [
    USER_ACTION_TYPES.UPDATE_USER,
    USER_ACTION_TYPES.UPDATE_USER_SUCCESS,
    USER_ACTION_TYPES.UPDATE_USER_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/user/${user.id}`,
      method: 'PUT',
      body: user,
      authUser: user,
    }),
});

export const sendAppDownloadSMS = (phone, branchLink) => ({
  types: [
    USER_ACTION_TYPES.SEND_APP_DOWNLOAD_SMS,
    USER_ACTION_TYPES.SEND_APP_DOWNLOAD_SMS_SUCCESS,
    USER_ACTION_TYPES.SEND_APP_DOWNLOAD_SMS_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/notifications/sms`,
      method: 'POST',
      body: {
        branch_link: branchLink,
        message: 'download_gt',
        phone_number: phone,
      },
    }),
});

export const tokenGenerate = (body) => ({
  types: [
    USER_ACTION_TYPES.TOKEN_GENERATE,
    USER_ACTION_TYPES.TOKEN_GENERATE_SUCCESS,
    USER_ACTION_TYPES.TOKEN_GENERATE_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/user/token-generate`,
      method: 'POST',
      body,
    }),
});

export const tokenLogin = (body) => ({
  types: [
    USER_ACTION_TYPES.TOKEN_LOGIN,
    USER_ACTION_TYPES.TOKEN_LOGIN_SUCCESS,
    USER_ACTION_TYPES.TOKEN_LOGIN_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/user/token-login`,
      method: 'POST',
      body,
    }),
});

export const addUserPromoCode =
  (promoCode = '') =>
  (dispatch, getState) => {
    const user = selectUserDetails(getState());
    const code = promoCode.toUpperCase();

    return dispatch({
      types: [
        USER_ACTION_TYPES.ADD_PROMO_CODE,
        USER_ACTION_TYPES.ADD_PROMO_CODE_SUCCESS,
        USER_ACTION_TYPES.ADD_PROMO_CODE_FAIL,
      ],
      promise: (httpClient) =>
        httpClient.request({
          path: `/v1/user/${user.id}/code`,
          method: 'POST',
          headers: { session_token: user.session_token },
          body: { code },
        }),
    });
  };

/**
 * Get an array of promo codes that the user has redeemed to their account.
 */
export const fetchUserPromoCodes = () => (dispatch, getState) => {
  const user = selectUserDetails(getState());

  return dispatch({
    types: [
      USER_ACTION_TYPES.FETCH_PROMO_CODES,
      USER_ACTION_TYPES.FETCH_PROMO_CODES_SUCCESS,
      USER_ACTION_TYPES.FETCH_PROMO_CODES_FAIL,
    ],
    promise: (httpClient) =>
      httpClient.request({
        path: `/v1/user/${user.id}/promos`,
        headers: { session_token: user.session_token },
      }),
  });
};

/**
 * Fetch the promos for the specified event and total. When the event ID and
 * the total price for the purchase (total ticket price * seat fee), the
 * response will contain an array of promos ordered from best to worst.
 */
export const fetchUserPromoCodesForListing =
  ({ eventId, listingId, abortController }) =>
  (dispatch, getState) => {
    const state = getState();
    const user = selectUserDetails(state);

    // Promo codes are attached to the user, so non-logged in users will not have promos
    if (!user) {
      return Promise.reject(
        new Error('Unable to retrieve promo codes for unauthenticated users.')
      );
    }

    const listing = getListingById(state, listingId);

    if (!listing) {
      return Promise.reject(
        new Error(`Unable to find listing with ID of ${listingId}`)
      );
    }

    const seatCount = defaultSeatCountSelector(state, { listing });
    const totalPrice = listing?.price?.total * seatCount;

    if (Number.isNaN(totalPrice)) {
      return Promise.reject(
        new Error(`Unable to parse total price for listing ${listingId}`)
      );
    }

    const newParams = { event_id: eventId, total_price: totalPrice };

    return dispatch({
      types: [
        USER_ACTION_TYPES.FETCH_PROMOS_FOR_LISTING,
        USER_ACTION_TYPES.FETCH_PROMOS_FOR_LISTING_SUCCESS,
        USER_ACTION_TYPES.FETCH_PROMOS_FOR_LISTING_FAIL,
      ],
      params: newParams,
      promise: (httpClient) =>
        httpClient.request({
          path: `/v1/user/${user.id}/promos`,
          headers: { session_token: user.session_token },
          searchParams: newParams,
          abortController,
        }),
    });
  };

export const fetchUserLoginOptions = (username) => ({
  types: [
    USER_ACTION_TYPES.FETCH_USER_LOGIN_OPTIONS,
    USER_ACTION_TYPES.FETCH_USER_LOGIN_OPTIONS_SUCCESS,
    USER_ACTION_TYPES.FETCH_USER_LOGIN_OPTIONS_FAIL,
  ],
  payload: { username },
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/user/login-options`,
      method: 'POST',
      body: {
        username,
      },
    }),
});

export const requestAccountDeletion = () => (dispatch, getState) => {
  const user = selectUserDetails(getState());

  return dispatch({
    type: USER_ACTION_TYPES.REQUEST_ACCOUNT_DELETION,
    promise: (httpClient) =>
      httpClient.request({
        path: `/v1/user/login-options`,
        authUser: user,
      }),
  });
};

export const createUserExternalAccount =
  (accountType, userInfo) => (dispatch, getState) => {
    const user = selectUserDetails(getState());

    return dispatch({
      types: [
        USER_ACTION_TYPES.CREATE_USER_EXTERNAL_ACCOUNT,
        USER_ACTION_TYPES.CREATE_USER_EXTERNAL_ACCOUNT_SUCCESS,
        USER_ACTION_TYPES.CREATE_USER_EXTERNAL_ACCOUNT_FAIL,
      ],
      promise: (httpClient) =>
        httpClient.request({
          path: `/v1/external_account/${accountType}`,
          method: 'POST',
          authUser: user,
          body: {
            info: userInfo,
          },
        }),
    });
  };

export const fetchUserExternalAccount =
  (accountType) => (dispatch, getState) => {
    const user = selectUserDetails(getState());

    return dispatch({
      types: [
        USER_ACTION_TYPES.FETCH_USER_EXTERNAL_ACCOUNT,
        USER_ACTION_TYPES.FETCH_USER_EXTERNAL_ACCOUNT_SUCCESS,
        USER_ACTION_TYPES.FETCH_USER_EXTERNAL_ACCOUNT_FAIL,
      ],
      promise: (httpClient) =>
        httpClient.request({
          path: `/v1/external_account/${accountType}`,
          authUser: user,
        }),
    });
  };

export const loadAbExperimentAssignments = (assignments) => ({
  type: USER_ACTION_TYPES.LOAD_AB_EXPERIMENT_ASSIGNMENTS,
  assignments,
});

export const loadZListingsAssignments = (zListings) => ({
  type: USER_ACTION_TYPES.LOAD_ZLISTINGS_ASSIGNMENTS,
  zListings,
});

export const loadFeatureFlagAssignments = (featureFlags) => ({
  type: USER_ACTION_TYPES.LOAD_FEATURE_FLAG_ASSIGNMENTS,
  featureFlags,
});

export const loadZSearchAssignments = (zSearch) => ({
  type: USER_ACTION_TYPES.LOAD_ZSEARCH_ASSIGNMENTS,
  zSearch,
});

export const setIsBot = (isBot) => ({
  type: USER_ACTION_TYPES.SET_IS_BOT,
  isBot,
});

export const setForterToken = (forterToken) => ({
  type: USER_ACTION_TYPES.SET_FORTER_TOKEN,
  forterToken,
});
