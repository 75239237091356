import Cookies from 'js-cookie';

import { REDUCERS } from 'store/modules/reducer.constants';
import { isObjectEmpty } from 'utils/objects';

const _selectUser = (state) => state[REDUCERS.USER];

export const selectUserDetails = (state) => _selectUser(state).details;

export const selectUserPromos = (state) => _selectUser(state).promos;

export const selectUserIsBot = (state) => _selectUser(state).isBot;

export const selectIsNewUser = (state) => _selectUser(state).isNewUser;

export const selectUserExternalAccount = (state) =>
  _selectUser(state).externalAccount;

// Forter sets a token from their script, but if that fails, the cookie can be used
export const selectUserForterToken = (state) =>
  _selectUser(state).forterToken || Cookies.get('forterToken');

export const isUserLoggedIn = (state) => selectUserDetails(state) !== null;

export const userAuthCookieValue = ({ id, session_token }) => {
  return JSON.stringify({ id, session_token });
};

/**
 * Selects the best active promo for the listing or undefined. If a user has
 * active promos for the listing, these promos are ordered from best to worst.
 * If the user has no active listings the return value is undefined.
 */
export const selectBestUserPromoForListing = (state) => {
  const { promosForListing = [] } = _selectUser(state);

  return promosForListing[0];
};

/**
 * If the user has at least one promo active in their account, calculate the
 * price to show on the listing details page here. If a user has active promos
 * for the listing, these promos are ordered from best to worst so we take
 * the discounted price from the first promo to use as the total listing price.
 *
 * @returns {number} the final price for the listing with the applied promo code, formatted in dollars
 */
export const userPromosForListingPriceSelector = (state) => {
  const bestPromo = selectBestUserPromoForListing(state);
  const promoPrice = bestPromo?.promo_discount?.final_price || 0;

  return promoPrice / 100;
};

/**
 * If the user has at least one promo active in their account, return the savings
 * for the active listing. If a user has active promos for the listing, these promos
 * are ordered from best to worst so we take the savings from the first promo to use
 * as the total listing savings.
 *
 * @returns {number} the savings for the listing from the promo code, formatted in dollars
 */
export const userPromosForListingSavingsSelector = (state) => {
  const bestPromo = selectBestUserPromoForListing(state);
  const savings = bestPromo?.promo_discount?.savings || 0;

  return savings / 100;
};

/**
 * If the user has at least one promo active in their account, return the promo code
 * for the promo used on the active listing. If a user has active promos for the listing,
 * these promos are ordered from best to worst so we take the savings from the first
 * promo to use as the total listing savings.
 *
 * @returns {String} the code of the best promo code to apply to the listing
 */
export const userPromosForListingPromoCodeSelector = (state) => {
  const bestPromo = selectBestUserPromoForListing(state);
  const promoCode = bestPromo?.promo_details?.code || '';

  return promoCode;
};

/*
  Experiments
*/
export const experimentsSelector = (state) => {
  return _selectUser(state).abExperimentAssignments;
};

export const zListingsSelector = (state) => {
  return _selectUser(state).zListingsAssignments;
};

export const zSearchSelector = (state) => {
  return _selectUser(state).zSearchAssignments;
};

export const featureFlagsSelector = (state) => {
  return _selectUser(state).featureFlagAssignments;
};

export const ABAssignmentSelector = (state, experiment) => {
  return experimentsSelector(state)[experiment];
};

export const featureFlagAssignmentSelector = (state, featureFlag) => {
  return featureFlagsSelector(state)[featureFlag];
};

export const userActiveExperimentsSelector = (experimentsInStore = {}) => {
  const experimentList = {};

  if (!isObjectEmpty(experimentsInStore)) {
    Object.entries(experimentsInStore).forEach((experiment) => {
      const [key, value] = experiment;
      const name = `ab_test_${key.toLowerCase()}`;
      experimentList[name] = value;
    });
  }

  return experimentList;
};

export const userActiveZListingsSelector = (zListingsInStore = {}) => {
  const zListingList = {};

  if (!isObjectEmpty(zListingsInStore)) {
    Object.entries(zListingsInStore).forEach((zListing) => {
      const [key, value] = zListing;
      const name = `ab_test_${key.toLowerCase()}`;
      zListingList[name] = value;
    });
  }

  return zListingList;
};

export const userActiveZSearchSelector = (zSearchInStore = {}) => {
  const zSearchList = {};

  if (!isObjectEmpty(zSearchInStore)) {
    Object.entries(zSearchInStore).forEach((zSearch) => {
      const [key, value] = zSearch;
      const name = `ab_test_${key.toLowerCase()}`;
      zSearchList[name] = value;
    });
  }

  return zSearchList;
};
