const SMALL_WORDS =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
const WHITESPACE = /\s/;
const IS_MANUAL_CASE = /.(?=[A-Z]|\..)/;
const ALPHANUMERIC_PATTERN = /[A-Za-z0-9\u00C0-\u00FF]/;

const acronyms = [
  'ny',
  'sf',
  'al',
  'nl',
  'afc',
  'nfc',
  'nba',
  'nfl',
  'mlb',
  'ncaa',
  'rnb',
];

export function titleCase(input) {
  if (!input) {
    return '';
  }
  const result = [];

  input.split(/ |-/).forEach((token, index) => {
    // custom space to upcase acronyms. place any desired acronyms in the above acronyms array
    if (acronyms.includes(token)) {
      result.push(token.toUpperCase());
    } else if (
      // Ignore already capitalized words.
      !IS_MANUAL_CASE.test(token) &&
      // Ignore small words except at beginning or end.
      (!SMALL_WORDS.test(token) ||
        index === 0 ||
        index + token.length === input.length) &&
      // Ignore URLs.
      (input.charAt(index + token.length) !== ':' ||
        WHITESPACE.test(input.charAt(index + token.length + 1)))
    ) {
      // Find and uppercase first word character, skips over *modifiers*.
      result.push(token.replace(ALPHANUMERIC_PATTERN, (s) => s.toUpperCase()));
    } else {
      result.push(token);
    }
  });

  return result.join(' ');
}
