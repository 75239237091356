import React from 'react';

import colors from 'styles/colors.constants';

interface FlashIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

const FlashIcon = ({
  width = '16',
  height = '16',
  fill = colors.black,
}: FlashIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9077 1.18814C15.046 1.37391 15.027 1.63295 14.8633 1.79668L9.86666 6.6L13.93 7.67002C14.0343 7.77433 14.0831 7.92181 14.0617 8.06777C14.0403 8.21372 13.9511 8.34095 13.8212 8.41088L1.68792 14.9442C1.48404 15.054 1.23049 14.9976 1.09227 14.8119C0.954044 14.6261 0.972952 14.3671 1.13669 14.2033L6.13333 9.39998L2.07002 8.32998C1.96571 8.22567 1.91686 8.07819 1.93828 7.93223C1.95971 7.78628 2.04887 7.65905 2.17876 7.58912L14.3121 1.05582C14.516 0.946037 14.7695 1.00236 14.9077 1.18814Z"
        fill={fill}
      />
    </svg>
  );
};

export default FlashIcon;
