import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import JobPosting from '../JobPosting/JobPosting';

import styles from './TeamJobPostings.module.scss';

const TeamJobPostings = (props) => (
  <div
    className={classNames(styles['job-posting'], {
      [styles['multiple']]: !props.fullWidth,
    })}
  >
    <div className={styles.header}>
      <div className={styles.title}>{props.title}</div>
      {props.description && (
        <div className={styles.description}>{props.description}</div>
      )}
    </div>

    {props.postings.map((posting) => (
      <div className={styles['job-posting-container']} key={posting.id}>
        <JobPosting
          title={posting.text}
          location={posting.categories.location}
          url={posting.hostedUrl}
        />
      </div>
    ))}
  </div>
);

TeamJobPostings.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  postings: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
};

export default TeamJobPostings;
