import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import Image from 'components/Image/Image';
import ImagePan from 'components/ImagePan/ImagePan';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { getListingById } from 'store/modules/data/Listings/selectors';

import styles from './ListingHero.module.scss';

const ListingHero = ({ fullEvent, showZoneTag, originListing }) => {
  const { isMobile, isTablet } = useAppContext().state;

  const imagePropsForOriginListing = originListing.getImageOptions(
    fullEvent.venue
  );

  return (
    <div className={styles['listing-hero']} data-cy="listing-hero-container">
      <div className={styles.image}>
        {isMobile || isTablet ? (
          <Image {...imagePropsForOriginListing} />
        ) : (
          <ImagePan imageProps={imagePropsForOriginListing} />
        )}
      </div>
      {showZoneTag && (
        <div className={styles['zone-tag-container']}>
          <ZoneTag />
        </div>
      )}
    </div>
  );
};

ListingHero.propTypes = {
  fullEvent: PropTypes.object,
  showZoneTag: PropTypes.bool,
  shortSeatView: PropTypes.bool,
  originListing: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const {
    params: { listingId },
  } = props;

  const originListing = listingId
    ? getListingById(state, listingId)
    : props.listing;

  return {
    originListing,
  };
};

export default withRouter(connect(mapStateToProps)(ListingHero));
