import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';

import styles from './SelectorModalOptions.module.scss';

export default class SelectorModalOptions extends Component {
  static propTypes = {
    currentSelection: PropTypes.object,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    getClickTracker: PropTypes.func,
  };

  renderOptions() {
    const { currentSelection, options, onSelect, getClickTracker } = this.props;

    return options.map((option) => {
      const { id, name, path } = option;
      const isActive = currentSelection && currentSelection.id === option.id;

      return (
        <Link
          className={classNames(
            styles.optionLink,
            isActive ? styles.active : ''
          )}
          key={id}
          to={path}
          data-cy={`metro-selector-option-${option.id}`}
          onClick={(event) => onSelect(option, event)}
          clickTracker={getClickTracker(option)}
        >
          <span className={styles.name}>{name}</span>
        </Link>
      );
    });
  }

  render() {
    return <>{this.renderOptions()}</>;
  }
}
