export const REDUCERS = {
  APP: 'app',
  APP_UI: 'uiReducer',
  EVENTS_PAGE_DATA: 'eventsPageData',
  FEATURES: 'features',
  PURCHASE: 'userPurchases',
  USER: 'user',
  MODALS: 'modals',
  SEATMAP: 'seatmap',
  HISTORY: 'history',
  LISTINGS: 'listings',
  UPGRADE_LISTING: 'upgradeListing',
  RESOURCES: 'resources',
  GENRE_PERFORMERS: 'genrePerformers',
} as const;
