import React from 'react';

import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';

import { EMPLOYEE_REVIEWS } from '../../careers.constants';

import Review from './Review';

import styles from './EmployeeReviews.module.scss';

const EmployeeReviews = () => (
  <div className={styles['employee-reviews-container']}>
    <ReactSlickCarousel
      spacing={24}
      slideWidth={334}
      items={EMPLOYEE_REVIEWS}
      renderItem={(review, index, goTo) => (
        <Review review={review} onClick={() => goTo(index)} />
      )}
      getItemKey={(review) => review.name}
      arrows={false}
      dots
      centerMode
    />
  </div>
);

export default EmployeeReviews;
