import React from 'react';
import PropTypes from 'prop-types';

import { EVENT_TYPE } from './constants';
import EventTypeTabItem from './EventTypeTabItem';

import styles from './EventTypeTabBar.module.scss';

const EventTypeTabBar = ({ activeItem, setActiveItem, showParkingTab }) => {
  return (
    <div className={styles['event-type-tab-bar']}>
      <EventTypeTabItem
        id={EVENT_TYPE.EVENTS.id}
        title={EVENT_TYPE.EVENTS.title}
        isActive={activeItem === EVENT_TYPE.EVENTS.id}
        setActiveItem={setActiveItem}
      />
      {showParkingTab && (
        <EventTypeTabItem
          id={EVENT_TYPE.PARKING.id}
          title={EVENT_TYPE.PARKING.title}
          isActive={activeItem === EVENT_TYPE.PARKING.id}
          setActiveItem={setActiveItem}
        />
      )}
    </div>
  );
};

EventTypeTabBar.propTypes = {
  activeItem: PropTypes.string.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  showParkingTab: PropTypes.bool.isRequired,
};

export default EventTypeTabBar;
