import { DEFAULT_SEAT_COUNT } from 'store/modules/resources/constants';
import { formatUrl } from 'utils/format';
import { isInteger } from 'utils/number';

import Position from './Position';
import Price from './Price';
import Spot from './Spot';

export const ZONE_TICKET_DISCLOSURE = 'zone_ticket';

export default class Listing {
  constructor(listing = {}) {
    this.deliveryType = listing.delivery_type;
    this.transferType = listing.transfer_type;
    this.ticketType = listing.ticket_type;
    this.position = new Position(listing.spot.position);
    this.price = new Price(listing.price);
    this.lotsList = listing.lots;
    this.seats = listing.seats;
    this.eventId = listing.event_id;
    this.row = listing.row;
    this.section = this.#fixSectionName(listing.section);
    this.rawSectionName = listing.section;
    this.sectionGroup = listing.section_group;
    this.source = listing.source;
    this.viewUrl = listing.view_url;
    this.id = listing.id;
    this.zoomLevel = null;
    this.spot = new Spot(listing.spot);
    this.ticketQuantities = this.lotsList.sort((a, b) => a - b);
    this.defaultSeatCount =
      this.ticketQuantities.indexOf(DEFAULT_SEAT_COUNT) > -1
        ? DEFAULT_SEAT_COUNT
        : this.ticketQuantities[0];
    this.seatMarker = {
      top: `${75 * (this.position.y / 2400) + 30}%`,
      left: `${90 * (this.position.x / 3200) + 30}%`,
    };
    this.disclosures = listing.spot.disclosures;
    this.signature = listing.signature;
    this.lotAttributes = listing.lot_attributes;
    this.displaySavings = listing.display_savings;
    this.discount = this._getDiscount();
    this._isVisibleInMap = true;
    this._listingTrackingData = {};
    this._currentSeatCount = null;
    this._isDealLockDisabled = false;
  }

  getPath(fullEvent, listingId) {
    const eventPath = fullEvent ? fullEvent.getPath() : '';
    if (listingId) {
      return `${eventPath}/listings/${listingId}`;
    }
    return `${eventPath}/listings/${this.id}`;
  }

  getImageOptions(venue) {
    const alt = `Seat view from ${this.sectionGroup}${
      venue ? ` at ${venue.name}` : ''
    }`;
    return {
      seoPrefix: venue ? formatUrl(venue.name) : '',
      seoPostfix: formatUrl(this.sectionGroup),
      alt,
      src: this.viewUrl,
    };
  }

  getPrice(inCents = false) {
    return inCents ? this.price.prefee : this.price.prefee / 100;
  }

  getTotalPrice(inCents = false) {
    return inCents ? this.price.total : this.price.total / 100;
  }

  getSalesTax() {
    return this.price.salesTax / 100;
  }

  getFaceValue() {
    let faceValue = this.price.faceValue;

    if (!faceValue) return 0;

    faceValue = faceValue / 100;

    // If not a whole number, format as XX.XX to prevent $12.3 showing.
    if (!isInteger(faceValue)) {
      faceValue = faceValue.toFixed(2);
    }

    return faceValue;
  }

  getSeatFee(inCents = false) {
    const seatFee = this.price.total - this.price.prefee - this.price.salesTax;

    return inCents ? seatFee : seatFee / 100;
  }

  /**
   * Returns the maximum lot of tickets you can buy by looking at the last item in the array
   * since it is already sorted in the constructor.
   */
  getMaxLotSize() {
    let maxLotSize = 0;

    if (this.ticketQuantities.length) {
      const quantities = this.ticketQuantities;
      maxLotSize = quantities[quantities.length - 1];
    }

    return maxLotSize;
  }

  isDiscounted() {
    return !!this.displaySavings?.amount;
  }

  getDiscountPercent() {
    return this.displaySavings?.percent ?? 0;
  }

  getSavingsAmount() {
    return (this.displaySavings?.amount ?? 0) / 100;
  }

  // TODO(dbangerter): Remove this hack once the backend always returns sensible section names.
  #fixSectionName(originalSectionName) {
    if (!originalSectionName || !/-|_/.test(originalSectionName)) {
      return originalSectionName;
    }

    return originalSectionName
      .split(/-|_| /)
      .map((word) => {
        word = word.toLowerCase();
        if (word === 'sro') {
          return word.toUpperCase();
        }
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }

  _getDiscount() {
    const available = this.isDiscounted();
    const prefeeDiscount = this.getSavingsAmount();
    const percentDiscountPercentage = this.getDiscountPercent();

    return {
      available,
      prefeeDiscount,
      percentDiscountPercentage,
      showLabel: false,
    };
  }

  getLotDealForQuantity(qty) {
    if (!qty) return undefined;
    return this.lotAttributes && this.lotAttributes[qty.toString()]
      ? this.lotAttributes[qty.toString()].deal
      : undefined;
  }

  _isDealOfType(dealType) {
    const deal = this.getLotDealForQuantity(this._currentSeatCount);
    return deal ? deal === dealType : false;
  }

  get isZoneDeal() {
    return this._isDealOfType('zone');
  }

  get isFlashDeal() {
    return this._isDealOfType('flash');
  }

  get isBestDeal() {
    return this._isDealOfType('best');
  }

  get isCheapestDeal() {
    return this._isDealOfType('cheapest');
  }

  get isRareDeal() {
    return this._isDealOfType('rare');
  }

  get isFeaturedDeal() {
    return this.isRare || this.isBestDeal || this.isCheapestDeal;
  }

  get isSponsoredDeal() {
    return this.isZoneDeal || this.isFlashDeal;
  }

  get dealType() {
    return this.getLotDealForQuantity(this._currentSeatCount) || '';
  }

  get isExclusivesDeal() {
    const exclusivesDealTypes = ['super', 'flash', 'best', 'cheapest', 'zone'];

    return exclusivesDealTypes.includes(this.dealType);
  }

  get hasZoneTicketDisclosure() {
    return this.disclosures.includes(ZONE_TICKET_DISCLOSURE);
  }

  setListingZoomLevel(zoomLevel) {
    this.zoomLevel = zoomLevel;
  }

  set isVisibleInMap(isVisible = true) {
    this._isVisibleInMap = isVisible;
  }

  get isVisibleInMap() {
    return this._isVisibleInMap;
  }

  listingDecoration(seatCount) {
    const lotDealType = this.getLotDealForQuantity(seatCount);

    let dealType = '';
    let featuredType = '';

    const isPromo = this.isDiscounted();
    let isSponsored = false;

    if (lotDealType === 'zone' || lotDealType === 'flash') {
      dealType = lotDealType;
      isSponsored = true;
    } else if (lotDealType) {
      dealType = 'featured';
      featuredType = lotDealType;
    }

    return {
      dealType,
      featuredType,
      isPromo,
      isSponsored,
    };
  }

  get listingTrackingData() {
    return this._listingTrackingData;
  }

  set currentSeatCount(seatCount) {
    this._currentSeatCount = seatCount;

    // add more listing tracking data here in the future
    this._listingTrackingData = { ...this.listingDecoration(seatCount) };
  }

  get currentSeatCount() {
    return this._currentSeatCount;
  }

  get isDealLockDisabled() {
    return this._isDealLockDisabled;
  }

  set isDealLockDisabled(value) {
    this._isDealLockDisabled = value;
  }
}
