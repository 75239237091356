import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import WarningCircleFillIcon from 'icons/WarningCircleFillIcon';
import colors from 'styles/colors.constants';

import styles from './AffirmTextInput.module.scss';

const AffirmTextInput = ({
  id, // id used for attributing label to input
  label, // the label text
  required, // (optional) whether or not the field is required and needs a red asterisk
  type, // (optional) the input type (text, number, etc.)
  autoComplete, // (optional) whether autocomplete should be on or off
  placeholder, // placeholder text
  maxLength, // (optional) maximum length the input can support
  onChange, // event for when the change event is detected, usually used to reset error states
  onBlur, // event for when the blur event is detected, usually used for inline validation
  name, // (optional) name of the input for the form to grab and associate the input with
  error, // (optional) object that contains the state of the error and the message
  info, // (optional) object that contains the state of the info message
  disabled, // (optional) boolean for if the field should be disabled
  value, // (optional) value for the input if needs to be programmatically set
}) => {
  const hintId = `${id}-hint`; // used for a11y to associate input with label
  const errorId = `${id}-error`; // used for ally to associate input with error message
  const infoId = `${id}-info`;
  const { hasError, message } = error || {};
  const { hasInfo, infoMessage } = info || {};
  const inputProps = {
    id,
    className: classNames(styles.input),
    placeholder,
    type,
    name,
    autoComplete,
    'aria-describedby': `${errorId} ${hintId}`,
    'aria-required': required,
    maxLength,
    disabled,
    size: 1, // CSS flexbox will handle sizing
  };

  return (
    <div className={styles['text-input']}>
      <div className={styles['label-container']}>
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      </div>
      <div
        className={classNames(styles['input-container'], {
          [styles.error]: hasError,
          [styles.disabled]: disabled,
        })}
      >
        <input
          {...inputProps}
          onChange={(e) => onChange && onChange(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          value={value}
        />
      </div>
      <div
        className={classNames(styles['error-container'], {
          [styles.hidden]: !hasError,
        })}
      >
        <WarningCircleFillIcon fill={colors.softRed} width="16" height="16" />
        <span
          id={errorId}
          className={styles.error}
          aria-live="polite"
          data-cy="error-message"
        >
          {message}
        </span>
      </div>
      <div
        className={classNames(styles['info-container'], {
          [styles.hidden]: !hasInfo,
        })}
      >
        <span id={infoId} className={styles.info} aria-live="polite">
          {infoMessage}
        </span>
      </div>
    </div>
  );
};

AffirmTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  hint: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  info: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default AffirmTextInput;
