import React from 'react';
import { connect } from 'react-redux';
import { push as pushDispatch } from 'react-router-redux';
import PropTypes from 'prop-types';

import DownloadIcon from 'icons/DownloadIcon';
import LogOutIcon from 'icons/LogOutIcon';
import ProfileFillIcon from 'icons/ProfileFillIcon';
import TicketSetIcon from 'icons/TicketSetIcon';
import { setActiveMyTicketsView as setActiveMyTicketsViewDispatch } from 'store/modules/app/app';
import {
  MODALS,
  showModal as showModalDispatch,
} from 'store/modules/modals/modals';
import { logout as logoutDispatch } from 'store/modules/user/actions';

import MenuDropdownItem from './MenuDropdownItem';

import styles from './MenuDropdown.module.scss';

const MenuDropdown = ({
  logout,
  push,
  showModal,
  onClose,
  setActiveMyTicketsView,
}) => {
  const handleLogout = async () => {
    await logout();
    onClose();
    push('/');
  };

  const handleMyTicketsClick = () => {
    setActiveMyTicketsView('upcoming');
    onClose();
    push('/my-tickets');
  };

  const handleMyAccountClick = () => {
    onClose();
    push('/my-account');
  };

  const handleGetAppClick = () => {
    showModal(MODALS.GET_APP);
    onClose();
  };

  return (
    <div className={styles['menu-dropdown']}>
      <MenuDropdownItem
        icon={<TicketSetIcon />}
        label="My Tickets"
        onClick={handleMyTicketsClick}
      />
      <MenuDropdownItem
        icon={<ProfileFillIcon />}
        label="My Account"
        onClick={handleMyAccountClick}
      />
      <MenuDropdownItem
        icon={<DownloadIcon />}
        label="Get the App"
        onClick={handleGetAppClick}
      />
      <hr className={styles.divider} />
      <MenuDropdownItem
        icon={<LogOutIcon />}
        label="Log Out"
        onClick={handleLogout}
      />
    </div>
  );
};

MenuDropdown.propTypes = {
  push: PropTypes.func,
  logout: PropTypes.func,
  showModal: PropTypes.func,
  onClose: PropTypes.func,
  setActiveMyTicketsView: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutDispatch()),
  push: (path) => dispatch(pushDispatch(path)),
  showModal: (modal) => dispatch(showModalDispatch(modal)),
  setActiveMyTicketsView: (view) =>
    dispatch(setActiveMyTicketsViewDispatch(view)),
});

export default connect(null, mapDispatchToProps)(MenuDropdown);
