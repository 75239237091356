import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import { AnimatePresence, motion } from 'framer-motion';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import SponsorDealBadge from 'ui/SponsorDealBadge';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  PurchaseListing,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import AffirmPriceBanner from 'components/Affirm/PriceBanner/PriceBanner';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import {
  generateBreadcrumbSchema,
  getCategoryBreadcrumbConfig,
  getEventBreadcrumbConfig,
  getListingBreadcrumbConfig,
} from 'components/Breadcrumbs/breadcrumb.helpers';
import {
  isListingFlashDeal,
  isListingZoneDeal,
} from 'components/Deals/helpers';
import DeliveryBadge from 'components/DeliveryFormat/DeliveryBadge/DeliveryBadge';
import EventDateTime from 'components/EventDateTime/EventDateTime';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import JsonLD from 'components/JsonLD/JsonLD';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import PricingSummary from 'components/PricingSummary/PricingSummary';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import {
  ACTIONS as T_ACTIONS,
  getTrackingProps,
} from 'components/Trackable/TrackingHelper';
import UrgencyMessage from 'components/UrgencyMessage/UrgencyMessage';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import {
  experiments,
  selectIsWebExclusivesV1Experiment,
  selectTicketCoverageExperiment,
} from 'experiments';
import { redirect } from 'helpers/RedirectHelper';
import { VerifiedIcon } from 'icons';
import { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import { getListingPageTitle } from 'modules/pageTitles';
import {
  getCurrencyPrefix,
  isDefaultAllInState,
  isFaceValueState,
} from 'pages/Event/helpers';
import {
  URGENCY_MESSAGING_THRESHOLD,
  WIDTH_MOBILE_SEAT_MAP,
} from 'pages/Listing/constants';
import { deliveryFormatForListing } from 'store/datatypes/DELIVERY_FORMATS';
import {
  deliveryTypeFor,
  isThirdPartyDelivery,
} from 'store/datatypes/DELIVERY_TYPES';
import {
  isAllInPriceSelector,
  lastZoomLevelSelector,
  updateLastVisitedListingId,
} from 'store/modules/app/app.ui';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { fetchListings } from 'store/modules/data/Listings/actions';
import {
  getListingById,
  selectIsVenueAllInPrice,
} from 'store/modules/data/Listings/selectors';
import { isListingDetailsPage } from 'store/modules/history/history';
import {
  getAssociatedEventPathname,
  querySelector,
} from 'store/modules/location';
import { MODALS, showModal } from 'store/modules/modals/modals';
import {
  defaultSeatCountSelector,
  extendedDefaultSeatCountPurchaseSelector,
} from 'store/modules/purchase/purchase.selectors';
import { fetchDisclosures } from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  isPurchasableListingSelector,
  selectAllDeals,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { fetchUserPromoCodesForListing } from 'store/modules/user/actions';
import {
  selectUserDetails,
  userPromosForListingPriceSelector,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import { selectIsInitialUserPurchase } from 'store/modules/userPurchases/userPurchases.selectors';
import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';
import { isSuperBowl } from 'utils/superBowl';

import GTCoverage from './components/GTCoverage';
import IncludesFees from './components/IncludesFees';
import SeatSelector from './components/SeatSelector';
import Tag from './components/Tag';
import { getSeatRange } from './utils';

import styles from './Listing.module.scss';

@TrackPageView(
  ({
    listing,
    fullEvent,
    extendedPurchase,
    seatCount,
    showAllInPricing,
    isPromoEligible,
  }) => ({
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.LISTING({
      fullEvent,
      listing,
      displayed_price:
        _get(extendedPurchase, 'totalPrice') /
        (seatCount && seatCount > 0 ? seatCount : 1),
    }),
    payload: {
      all_in_filter: showAllInPricing,
      [PAYLOAD.PROMO_ELIGIBLE]: isPromoEligible,
      [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
      [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
      [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
      [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
    },
  })
)
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.LISTING(),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
@withAnalyticsContext
class Listing extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    listing: PropTypes.object,
    updateUserPreference: PropTypes.func,
    params: PropTypes.shape({
      listingId: PropTypes.string.isRequired,
      eventId: PropTypes.string.isRequired,
    }).isRequired,
    extendedPurchase: PropTypes.object,
    seatCount: PropTypes.number,
    updateLastVisitedListingId: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isPurchasableListing: PropTypes.bool.isRequired,
    fullEvent: PropTypes.object,
    isListingFlow: PropTypes.bool.isRequired,
    performer: PropTypes.object, // eslint-disable-line react/no-unused-prop-types,
    redirect: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    fetchDisclosures: PropTypes.func.isRequired,
    allDeals: PropTypes.object,
    allDisclosures: PropTypes.object,
    priceWithPromoApplied: PropTypes.number,
    fetchUserPromoCodesForListing: PropTypes.func,
    event: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    eventId: PropTypes.string, // Used in mapStateToProps
    selectedMetro: PropTypes.string,
    promoAmount: PropTypes.number,
    showAllInPricing: PropTypes.bool.isRequired,
    showFaceValuePricing: PropTypes.bool.isRequired,
    ticketQuantities: PropTypes.array,
    // eslint-disable-next-line react/no-unused-prop-types
    isPromoEligible: PropTypes.bool, // Used in tracking
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    ticketCoverageVariant: PropTypes.oneOf(
      Object.values(experiments.GT_TICKET_COVERAGE)
    ).isRequired,
    seatRange: PropTypes.string,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
    isExclusivesV1: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSeatsChange = this.handleSeatsChange.bind(this);
    this.handleSuperbowlModal = this.handleSuperbowlModal.bind(this);
    this.handleAffirmBannerTracker = this.handleAffirmBannerTracker.bind(this);
    this.handlePriceBreakdownInfoClick =
      this.handlePriceBreakdownInfoClick.bind(this);
    this.handleGTCoverageClick = this.handleGTCoverageClick.bind(this);

    this.state = {
      loading: false,
      isUpdatingPricing: false,
    };
  }

  componentDidMount() {
    const { listing, isPurchasableListing } = this.props;
    if (!isPurchasableListing) {
      this.validateRoute(this.props);
      return;
    }

    // listing has disclosures. let's fetch the list of disclosures so we know how to render things
    if (listing.disclosures.length > 0) {
      this.props.fetchDisclosures();
    }

    this.props.updateLastVisitedListingId(listing.id);
  }

  componentDidUpdate() {
    const { isListingFlow, fullEvent, listing } = this.props;

    if (!listing) {
      this.props.redirect(fullEvent.getPath());
    }

    if (isListingFlow) {
      this.validateRoute(this.props);
    }
  }

  validateRoute(props) {
    const {
      fullEvent,
      isPurchasableListing,
      performer,
      location: { pathname },
    } = props;
    const performerPath = getPerformerPath(performer);

    if (!isPurchasableListing) {
      if (fullEvent.isValid()) {
        const eventPath = getAssociatedEventPathname(pathname);
        this.props.redirect(eventPath);
      } else if (performerPath) {
        this.props.redirect(performerPath);
      } else {
        this.props.redirect('/');
      }
    }
  }

  handleAffirmBannerTracker() {
    const { clickContext, analyticsContext } = this.props;
    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.AFFIRM_BANNER();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }
  }

  handleGTCoverageClick() {
    const { clickContext, analyticsContext } = this.props;
    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.GT_TICKET_COVERAGE();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }
  }

  handlePriceBreakdownInfoClick() {
    const {
      extendedPurchase: {
        seatFee,
        prefeeSeatPrice,
        discount,
        salesTax,
        totalPrice,
      },
      seatCount,
      fullEvent,
      selectedMetro,
      promoAmount,
      analyticsContext,
      clickContext,
    } = this.props;

    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.PRICE_BREAKDOWN();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }

    this.props.showModal(MODALS.PRICE_BREAKDOWN, {
      prefeeSeatPrice,
      currencyPrefix: getCurrencyPrefix(fullEvent, selectedMetro),
      seatCount,
      discount,
      seatFee,
      salesTax,
      totalPrice,
      promoAmount,
    });
  }

  updateUserPreferenceSeatCount(newSeatCount) {
    // in addition to when a user updates their seat count manually, we call this method
    // whenever a purchase is initiated, because there are cases where a user is making a purchase
    // with a new seatCount quantity (ex: landing on a listing that doesn't actually have
    // the user's original preferred seatCount quantity available)
    this.props.updateUserPreference({
      seatCount: newSeatCount,
    });
  }

  async handleSeatsChange(e) {
    const { fullEvent, listing } = this.props;
    const seatCount = parseInt(e.target.value, 10);
    const eventId = fullEvent.id;
    const listingId = listing.id;

    if (this.props.updateUserPreference) {
      this.updateUserPreferenceSeatCount(seatCount);
    }

    try {
      this.setState({ isUpdatingPricing: true });
      await this.props.fetchUserPromoCodesForListing({ eventId, listingId });
      this.setState({ isUpdatingPricing: false });
    } catch {
      this.setState({ isUpdatingPricing: false });
    }
  }

  initiateCheckoutFlow() {
    this.setState({ loading: true }, () => {
      const { location, seatCount } = this.props;
      let { pathname } = location;
      if (pathname.endsWith('/')) {
        pathname = pathname.slice(0, -1);
      }

      this.updateUserPreferenceSeatCount(seatCount);

      setTimeout(() => {
        this.setState({ loading: false });
        this.navigateToNextPath(`${pathname}/checkout`);
      }, 200);
    });
  }

  navigateToNextPath(nextPath) {
    const { location } = this.props;

    this.props.push({
      pathname: nextPath,
      search: location.search,
    });
  }

  renderSeatMap() {
    const { fullEvent, listing } = this.props;
    const mapStyle = {
      backgroundImage: `url(${fullEvent.event.mapUrl}?width=${WIDTH_MOBILE_SEAT_MAP})`,
    };
    const markerStyle = {
      background: colors.gametimeGreenLight,
      top: `calc(${listing.seatMarker.top} - 18px)`,
      left: `calc(${listing.seatMarker.left} - 18px)`,
    };

    return (
      <div className={styles['seat-map-container']}>
        <div className={styles['seat-map']} style={mapStyle} />
        <motion.div
          layout
          className={styles['seat-map-marker']}
          style={markerStyle}
        />
      </div>
    );
  }

  renderTicketPrice() {
    const {
      extendedPurchase: { seatFee, salesTax },
      listing,
      seatCount,
      priceWithPromoApplied,
      fullEvent,
      selectedMetro,
      showAllInPricing,
      showFaceValuePricing,
    } = this.props;

    const { isUpdatingPricing } = this.state;

    // The user has changed their seat count, so we need to update pricing
    if (isUpdatingPricing) return null;

    const isDiscounted = listing.isDiscounted();
    const savings = listing.getSavingsAmount() || 0;

    let isPromoApplied = false;
    let totalSavings = 0;
    let primaryPrice = 0;
    let price = showAllInPricing ? listing.getTotalPrice() : listing.getPrice();

    if (isDiscounted) {
      totalSavings = savings * seatCount;
      primaryPrice = showAllInPricing
        ? listing.getTotalPrice() + savings
        : listing.getPrice() + savings;
    }

    /**
     * If the user has at least one promo active in their account, calculate the
     * price to show on the listing details page here. The math for calculating
     * this price is as follows:
     *
     * Final Promo Price (from BE) - (Seat Fees * Seat Count) - (Sales Tax * Seat Count)
     */
    if (priceWithPromoApplied > 0) {
      const totalSeatFee = seatFee * seatCount;
      const totalSalesTax = salesTax * seatCount;
      const totalTaxAndFees = totalSeatFee + totalSalesTax;
      let adjustedTotal = priceWithPromoApplied;

      if (!showAllInPricing) {
        adjustedTotal -= totalTaxAndFees;
      }

      if (adjustedTotal > 0 && adjustedTotal !== price) {
        price = Math.ceil(adjustedTotal / seatCount);
        isPromoApplied = true;
      }
    }

    const showMarkdownBanner = isDiscounted && !isPromoApplied;

    return (
      <div
        className={styles['ticket-price-container']}
        data-cy="ticket-price-container"
      >
        {showMarkdownBanner && (
          <div className={styles['discount-banner']}>
            You save ${totalSavings}
          </div>
        )}

        {isPromoApplied && (
          <div
            className={classNames(
              styles['promo-banner'],
              styles['discount-banner']
            )}
          >
            Promo Applied
          </div>
        )}

        <div className={styles['ticket-price']}>
          <AnimatePresence>
            <motion.div
              key={`ticket-price-${listing.id}`}
              className={styles['price-container']}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, position: 'relative' }}
              exit={{ opacity: 0, position: 'absolute' }}
              style={{ display: 'flex' }}
            >
              <span className={styles['price']}>
                {isDiscounted && (
                  <span className={styles['primary']}>
                    <TicketPrice price={primaryPrice} />
                  </span>
                )}
                <span
                  className={classNames(styles['per-ticket'], {
                    [styles['promo']]: isPromoApplied,
                  })}
                >
                  <TicketPrice
                    price={price}
                    currencyPrefix={getCurrencyPrefix(fullEvent, selectedMetro)}
                  />{' '}
                  each
                </span>
              </span>
              {(showFaceValuePricing || showAllInPricing) && (
                <IncludesFees onClick={this.handlePriceBreakdownInfoClick} />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    );
  }

  renderPrice() {
    const {
      extendedPurchase,
      priceWithPromoApplied,
      seatCount,
      ticketQuantities,
      seatRange,
    } = this.props;
    const { isUpdatingPricing } = this.state;
    let { totalPrice = 0 } = extendedPurchase;

    if (priceWithPromoApplied > 0) {
      totalPrice = priceWithPromoApplied;
    }

    return (
      <>
        <div className={styles['price-details']}>
          <div>
            <SeatSelector
              seatCount={seatCount}
              ticketQuantities={ticketQuantities}
              handleSeatsChange={this.handleSeatsChange}
            />
            {seatRange && <p className={styles['seats-range']}>{seatRange}</p>}
          </div>

          {this.renderTicketPrice()}
        </div>
        {!isUpdatingPricing && totalPrice >= 50 && totalPrice < 20000 && (
          <AffirmPriceBanner
            price={totalPrice}
            clickTracker={this.handleAffirmBannerTracker}
          />
        )}
      </>
    );
  }

  renderContinueButton() {
    const { loading, isUpdatingPricing } = this.state;
    const { listing } = this.props;

    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        mpActions={
          new PurchaseListing(
            getTrackingProps(
              T_ACTIONS.PURCHASE_STARTED,
              this.props,
              this.state,
              {
                payload: {
                  [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
                  [PAYLOAD.FEATURED_TYPE]:
                    listing.listingTrackingData.featuredType,
                  [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
                  [PAYLOAD.IS_SPONSORED]:
                    listing.listingTrackingData.isSponsored,
                },
              }
            )
          )
        }
        loading={loading}
        disabled={loading || isUpdatingPricing}
        onClick={() => {
          this.initiateCheckoutFlow();
        }}
        clickTracker={new ClickTracker()
          .interaction(Click.INTERACTIONS.CONTINUE_BUTTON())
          .payload({
            [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
            [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
            [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
            [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
          })}
      >
        <span>CONTINUE</span>
      </ThemedButtonLoader>
    );
  }

  renderMeta() {
    const { fullEvent, listing } = this.props;
    const { section, sectionGroup, row, viewUrl } = listing;

    const title = getListingPageTitle({
      headline: fullEvent.getMediumName('vs', false),
      alias: `${sectionGroup} ${section}`,
      row,
      formatted: fullEvent.datetimeFriendly,
    });

    const breadcrumbs = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      getCategoryBreadcrumbConfig(fullEvent.event.category),
      getEventBreadcrumbConfig(fullEvent),
      getListingBreadcrumbConfig(listing, fullEvent, title),
    ];

    return (
      <div>
        <HeadTitle title={title} />
        <HeadImage src={viewUrl} />
        <JsonLD json={generateBreadcrumbSchema(breadcrumbs)} />
      </div>
    );
  }

  renderVerifiedTrustSignal() {
    return (
      <div className={styles['trust-signal-container']}>
        <div className={styles['verified-icon']}>
          <VerifiedIcon stroke={colors.black} />
        </div>
        <div className={styles['trust-signal-text-container']}>
          <div className={styles['trust-signal-primary-text']}>
            Verified Tickets
          </div>
          <div className={styles['trust-signal-secondary-text']}>
            100% Gametime Guaranteed
          </div>
        </div>
      </div>
    );
  }

  handleSuperbowlModal() {
    this.props.showModal(MODALS.SUPER_BOWL, { showZoneTicketInfo: true });
  }

  renderUrgencyMessaging(quantity) {
    return <UrgencyMessage type="listing" quantity={quantity} />;
  }

  render() {
    const {
      fullEvent,
      listing,
      seatCount,
      allDisclosures,
      appContext: {
        state: { isMobile },
      },
      allDeals,
      ticketCoverageVariant,
      isExclusivesV1,
    } = this.props;

    const showSponsorDealBadge = isExclusivesV1 && listing.isExclusivesDeal;

    if (isObjectEmpty(listing)) {
      return null;
    }
    const hideSeats = fullEvent.venueState === 'MD'; // temp: hide seats for Maryland events
    const maxLotSize = listing.getMaxLotSize();

    // Only show urgency messaging if the maximum available lot size is less than or equal to threshold
    const isUrgencyMessagingEligible =
      maxLotSize > 0 && maxLotSize <= URGENCY_MESSAGING_THRESHOLD;

    const isZoneDeal = isListingZoneDeal(listing, seatCount);
    const isFlashDeal = isListingFlashDeal(listing, seatCount);
    const dealType = listing.getLotDealForQuantity(seatCount);
    const dealInfo = allDeals[dealType] || null;
    return (
      <div className={styles['ticket-card']}>
        {this.renderMeta()}
        <div className={styles.card}>
          <motion.div
            className={styles['card-headers']}
            transition={{ duration: 0.5 }}
            variants={{
              on: { x: 'calc(-100% - 7px)' },
              off: { x: 0 },
            }}
            initial={false}
          >
            <div className={styles['card-header']}>
              <DeliveryBadge
                deliveryFormat={deliveryFormatForListing(listing)}
                isMobile={isMobile}
              />
              {isExclusivesV1
                ? showSponsorDealBadge && (
                    <div className={styles['sponsor-deal-badge']}>
                      <SponsorDealBadge
                        isExclusivesV1={isExclusivesV1}
                        variant={listing.dealType}
                      />
                    </div>
                  )
                : (isZoneDeal || isFlashDeal || dealInfo) && (
                    <Tag
                      listing={listing}
                      fullEvent={fullEvent}
                      isZoneDeal={isZoneDeal}
                      isFlashDeal={isFlashDeal}
                      dealInfo={dealInfo}
                    />
                  )}
            </div>
          </motion.div>
          <div className={styles['card-sub-header']}>
            {this.renderSeatMap()}
          </div>
          <div className={styles['card-body']}>
            {isUrgencyMessagingEligible &&
              this.renderUrgencyMessaging(maxLotSize)}
            <div className={styles['listing-details']}>
              <h3 className={styles.datetime} data-cy="ticket-date-container">
                <EventDateTime fullEvent={fullEvent} type="DOTTED" />
              </h3>
              <AnimatePresence>
                <motion.div
                  key={`pricing-summary-${listing.id}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, position: 'relative' }}
                  exit={{ opacity: 0, position: 'absolute' }}
                  style={{ display: 'flex' }}
                >
                  <PricingSummary
                    seatCount={seatCount}
                    section={listing.section}
                    rowDesc={listing.row}
                    seats={listing.seats}
                    hideSeats={hideSeats}
                  />
                </motion.div>
              </AnimatePresence>
              {isSuperBowl(fullEvent.id) ? (
                listing.disclosures.includes(ZONE_TICKET_DISCLOSURE) && (
                  <ZoneTag
                    variant="text-info-icon"
                    onTouchEnd={this.handleSuperbowlModal}
                    onClick={this.handleSuperbowlModal}
                  />
                )
              ) : (
                <Disclosures
                  allDisclosures={allDisclosures}
                  disclosures={listing.disclosures}
                />
              )}
            </div>
            {this.renderPrice()}
            <div data-cy="ticket-guarantee-container">
              {isSuperBowl(fullEvent.id) ? (
                <div className={styles['detail-row']}>
                  {this.renderVerifiedTrustSignal()}
                </div>
              ) : (
                <div className={styles['detail-row']}>
                  <GTCoverage
                    variant={ticketCoverageVariant}
                    onClick={this.handleGTCoverageClick}
                  />
                </div>
              )}
              <div
                className={styles['action-button']}
                data-cy="continue-button-container"
              >
                {this.renderContinueButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { listing, appContext, location } = props;
  const eventId = props.eventId || props.params.eventId;
  const fullEvent = selectFullEventById(state, eventId);

  const seatCount = defaultSeatCountSelector(state, props);

  // Active A/B Experiments
  const ticketCoverageVariant = selectTicketCoverageExperiment(state);

  const isAllInPricingWithFlag = selectIsVenueAllInPrice(
    state,
    fullEvent.venueState
  );

  const showRegulatoryAllInPricing = isDefaultAllInState(fullEvent?.venueState);
  const showFaceValuePricing = isFaceValueState(fullEvent?.venueState);
  const isAllInPriceActive = isAllInPriceSelector(state);
  const showAllInPricing =
    showRegulatoryAllInPricing || isAllInPriceActive || isAllInPricingWithFlag;
  let ticketQuantities = listing.ticketQuantities;

  const isInitialUserPurchase = selectIsInitialUserPurchase(state);

  return {
    extendedPurchase:
      props.listing && extendedDefaultSeatCountPurchaseSelector(state, props),
    seatCount,
    creditCard: userPurchaseCreditCard(state),
    isPurchasableListing: isPurchasableListingSelector(state, props),
    isListingFlow: isListingDetailsPage(location.pathname),
    fullEvent,
    performer: fullEvent && fullEvent.getPrimaryPerformer(),
    prePurchaseStepsNeeded:
      props.listing && isThirdPartyDelivery(deliveryTypeFor(props.listing)),
    allDeals: selectAllDeals(state),
    allDisclosures: allDisclosuresSelector(state),
    priceWithPromoApplied: userPromosForListingPriceSelector(state),
    listing,
    selectedMetro: (
      selectUserMetro(state) ||
      selectClosestMetro(state, appContext.state.ipGeoLocation)
    )?.name,
    promoAmount: userPromosForListingSavingsSelector(state),
    showAllInPricing,
    showFaceValuePricing,
    ticketQuantities,
    isPromoEligible: isInitialUserPurchase,
    ticketCoverageVariant,
    seatRange: getSeatRange(listing.seats),
    isExclusivesV1: selectIsWebExclusivesV1Experiment(state),
  };
};

const mapDispatchToProps = {
  updateUserPreference,
  updateLastVisitedListingId,
  push,
  redirect,
  showModal,
  fetchDisclosures,
  fetchUserPromoCodesForListing,
};

const dataLoaderPromise = async ({
  store: { getState, dispatch },
  params: { eventId, listingId },
  appContext: { isMobile },
  abortController,
}) => {
  const state = getState();
  const user = selectUserDetails(state);

  if (!user) {
    console.info('User details not found');
    return;
  }

  const { zoom: zoomQueryParam } = querySelector(state);
  let zoomLevel = lastZoomLevelSelector(state);

  if (zoomQueryParam && !Number.isNaN(zoomQueryParam)) {
    zoomLevel = parseInt(zoomQueryParam, 10);
  }
  const listing = getListingById(state, listingId);
  if (!listing) {
    return dispatch(
      fetchListings({
        eventId,
        zoomLevel,
        isMobile,
      })
    ).then(() =>
      dispatch(
        fetchUserPromoCodesForListing({ eventId, listingId, abortController })
      )
    );
  } else {
    return dispatch(
      fetchUserPromoCodesForListing({ eventId, listingId, abortController })
    );
  }
};

export default withDataLoader(
  withAppContext(connect(mapStateToProps, mapDispatchToProps)(Listing)),
  {
    promise: dataLoaderPromise,
    hideLoadingSpinner: true,
  }
);
