import React from 'react';

import styles from './PicksReviews.module.scss';

const REVIEW_CARDS = [
  {
    rating: 5,
    title: 'Awesome!!',
    review:
      '"I get awesome deals. Better than any other ticket app on prices."',
    date: '7/26/2024',
    key: '1',
  },
  {
    rating: 5,
    title: 'Love This App',
    review:
      '"Love this app for sports tickets! So cheap and easy to get tix to watch your fav team play!!"',
    date: '7/25/2024',
    key: '2',
  },
  {
    rating: 5,
    title: 'Great App!',
    review: '"Great App! The only one I trust for tickets!!!"',
    date: '7/23/2024',
    key: '3',
  },
  {
    rating: 5,
    title: 'Super convenient',
    review:
      '"I\'ve been using this app for sports tickets for years. Super convenient, great prices, great deals and seats for the price. Love it."',
    date: '7/20/2024',
    key: '4',
  },
  {
    rating: 5,
    title:
      'Best app for tickets. Even checking other sites, this is the easiest!!!',
    review:
      '"Absolutely love this ticket app. So easy to find good seats. Best prices and the purchase is easy also. Always seem to come back to Gametime"',
    date: '7/26/2024',
    key: '5',
  },
];

const PickReviews = () => (
  <div className={styles['review-section']}>
    <span className={styles['review-title-container']}>
      <h2 className={styles['review-title']}>
        See what fans like you are saying.
      </h2>
      <p className={styles['review-subtitle']}>
        1,000,000+ tickets sold. 500,000+ happy customers.
      </p>
    </span>
    <div className={styles['review-cards-container']}>
      {REVIEW_CARDS.map((card) => {
        const stars = '⭐'.repeat(card.rating);
        return (
          <div key={card.key} className={styles['review-card']}>
            <span className={styles['review-card-rating']}>{stars}</span>
            <div className={styles['review-card-body']}>
              <span className={styles['review-card-title']}>{card.title}</span>
              <span className={styles['review-card-description']}>
                {card.review}
              </span>
            </div>
            <span className={styles['review-card-date']}>{card.date}</span>
          </div>
        );
      })}
    </div>
  </div>
);

export default PickReviews;
