export const experiments = {
  LOCALIZE: {
    name: 'LOCALIZE',
    default_variant: 'CONTROL',
    variant: 'LOCALIZE',
  },
  INSURANCE_WEB_PILOT: {
    name: 'INSURANCE_WEB_PILOT',
    default_variant: 'CONTROL',
    variant: 'INSURANCE_ENABLED',
  },
  GT_TICKET_COVERAGE: {
    name: 'GT_TICKET_COVERAGE',
    default_variant: 'CONTROL',
    variant1: 'FOR_EVERY_FAN',
    variant2: 'VERIFIED_TICKETS',
  },
  PERCENTAGE_VS_DOLLARS: {
    name: 'PERCENTAGE_VS_DOLLARS',
    default_variant: 'CONTROL',
    variant: 'DOLLARS_OFF',
  },
  FLASH_ZONE_HARMONY_WEB: {
    name: 'FLASH_ZONE_HARMONY_WEB',
    default_variant: 'CONTROL',
    variant: 'FLASH_ZONE',
  },
  INCLUDES_FEES_COPY: {
    name: 'INCLUDES_FEES_COPY',
    default_variant: 'CONTROL',
    variant: 'INCLUDES_FEES',
  },
  GALLERY_VIEW_V2: {
    name: 'GALLERY_VIEW_V2',
    default_variant: 'CONTROL',
    variant: 'GALLERY_VIEW_V2',
  },
  PERFORMER_INLINE_PRICING_MWEB: {
    name: 'PERFORMER_INLINE_PRICING_MWEB',
    default_variant: 'CONTROL',
    variant: 'PERFORMER_INLINE_PRICING_MWEB',
  },
  NEW_MLB_INFO_COLLECTION: {
    name: 'NEW_MLB_INFO_COLLECTION',
    default_variant: 'CONTROL',
    variant: 'NEW_MLB_INFO',
  },
  WEB_EXCLUSIVES_V1: {
    name: 'WEB_EXCLUSIVES_V1',
    default_variant: 'CONTROL',
    variant: 'WEB_EXCLUSIVES_V1',
  },
  MLB_MATCHUP_LOGOS: {
    name: 'MLB_MATCHUP_LOGOS',
    default_variant: 'CONTROL',
    variant: 'SHOW_MLB_MATCHUP_LOGOS',
  },
  NO_NFL_AUTOZOOM_DESKTOP: {
    name: 'NO_NFL_AUTOZOOM_DESKTOP',
    default_variant: 'CONTROL',
    variant: 'REMOVE_NFL_AUTOZOOM',
  },
  MARKET_SHARE_COLLECTION_V2: {
    name: 'MARKET_SHARE_COLLECTION_V2',
    default_variant: 'CONTROL',
    variant: 'MARKET_SHARE_COLLECTION_V2',
  },
} as const;
