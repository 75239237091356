import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CloseLineIcon from 'icons/CloseLineIcon';
import colors from 'styles/colors.constants';

import styles from './ModalHeader.module.scss';

const ModalHeader = ({
  title,
  onClose,
  children,
  isLightTheme = false,
  isDarkTheme = false,
  closeIconColor = colors.gray400,
}) => {
  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.light]: isLightTheme,
          [styles.dark]: isDarkTheme,
        })}
      >
        <h2 className={styles.title}>{title}</h2>
        {onClose && (
          <button type="button" className={styles.close} onClick={onClose}>
            <CloseLineIcon fill={closeIconColor} />
          </button>
        )}
      </div>
      {children}
    </>
  );
};

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  isLightTheme: PropTypes.bool,
  closeIconColor: PropTypes.string,
  isDarkTheme: PropTypes.bool,
};

export default ModalHeader;
