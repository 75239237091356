import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

import { withAnalyticsContext } from 'analytics';
import { getTrackingProps } from 'components/Trackable/TrackingHelper';

/**
 * @param action: action name to be sent to tracking lib
 * @constructor: Sends a tracking event on a wrapped component's load.
 */
const OnLoadTracker =
  ({ action }) =>
  (wrappedComponent) => {
    @withAnalyticsContext
    class OnLoadTrackerComponent extends Component {
      static propTypes = {
        analyticsContext: PropTypes.shape({
          track: PropTypes.func.isRequired,
        }),
      };

      componentDidMount() {
        if (this.props.analyticsContext.track) {
          this.props.analyticsContext.track(
            getTrackingProps(action, this.props)
          );
        }
      }

      render() {
        return createElement(wrappedComponent, this.props);
      }
    }
    return OnLoadTrackerComponent;
  };

export default OnLoadTracker;
