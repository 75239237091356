import { IpGeoLocation } from 'types/http';

import { UPDATE_IP_EXAMPLE } from './constants';
import { AppContextAction, AppContextState } from './types';

export const defaultIpGeoLocation: IpGeoLocation = {
  isDefault: true,
  country: 'US',
  region: 'CA',
  city: 'San Francisco',
  zip: '94103',
  ll: [37.7758, -122.4128],
};

export const initialState: AppContextState = {
  isMobile: false,
  isTablet: false,
  browser: null,
  browserVersion: null,
  os: null,
  phone: null,
  tablet: null,
  isIOS: null,
  isAndroid: null,
  ipExample: '1234-example',
  ipGeoLocation: defaultIpGeoLocation,
  isBot: false,
  datadogSession: {
    recordRate: 0,
    sampleRate: 0,
  },
};

export function appContextReducer(
  state: AppContextState,
  action: AppContextAction
) {
  switch (action.type) {
    case UPDATE_IP_EXAMPLE:
      return {
        ...state,
        ipExample: action.payload,
      };
    default:
      return state;
  }
}
