import React from 'react';

import styles from './JobPosting.module.scss';

interface JobPostingProps {
  title: string;
  location: string;
  url: string;
}

function JobPosting({ url, location, title }: JobPostingProps) {
  return (
    <div className={styles['job-posting']}>
      <a
        className={styles['job-title']}
        rel="noopener noreferrer"
        href={url}
        target="_blank"
      >
        {title}
      </a>
      <span className={styles['job-location']}>{location}</span>
    </div>
  );
}

export default JobPosting;
