import { createSelector, createStructuredSelector } from 'reselect';

import { getCarouselDealInfo } from 'components/Deals/helpers';
import {
  selectIsCaliforniaVenueAllInPriceEnabled,
  selectIsColoradoVenueAllInPriceEnabled,
  selectIsMarylandVenueAllInPriceEnabled,
} from 'featureFlags';
import { Listing } from 'models';

export const getFilteredZoomLevels = (
  listingsDataZoomLevels,
  currentZoomLevel,
  getNextZoomLevels = true
) => {
  const filteredZoomlevels = getNextZoomLevels
    ? listingsDataZoomLevels.filter((zoomLevel) => zoomLevel > currentZoomLevel)
    : listingsDataZoomLevels.filter(
        (zoomLevel) => zoomLevel < currentZoomLevel
      );
  return filteredZoomlevels.sort(function (a, b) {
    return a - b;
  });
};

export const getListingsBySeatCount = createSelector(
  (listingsData) => listingsData.allListings,
  (listingsData, seatCount) => {
    return listingsData.displayGroupList?.[seatCount]
      ? listingsData.displayGroupList[seatCount]
      : [];
  },
  (_, seatCount) => seatCount,
  (allListings, displayGroupBySeatCount, seatCount) => {
    const deals = {
      flash: false,
      zone: false,
      featured: false,
      zoneDealListingIds: [],
    };
    const listings = displayGroupBySeatCount.map((listingId) => {
      const listing = new Listing(allListings[listingId]);
      listing.currentSeatCount = seatCount;
      if (listing.isFlashDeal) deals.flash = true;
      if (listing.isZoneDeal) {
        deals.zone = true;
        deals.zoneDealListingIds.push(listing.id);
      }
      if (listing.isFeaturedDeal) deals.featured = true;
      return listing;
    });
    return {
      listings,
      hasFlashDeals: deals.flash,
      hasZoneDeals: deals.zone,
      hasFeaturedDeals: deals.featured,
      zoneDealListingIds: deals.zoneDealListingIds,
    };
  }
);

export const getListingsDataSelector = (state) => {
  return state?.data?.listings;
};

export const getListingById = (state, listingsId) => {
  if (!state || !listingsId) {
    return null;
  }

  if (state?.data?.listings?.allListings[listingsId]) {
    const listing = state.data.listings.allListings[listingsId];
    return new Listing(listing);
  }

  return null;
};

export const getCarouselListings = ({
  listings,
  seatCount,
  isDealsHarmony,
}) => {
  const allListings = listings?.allListings || {};
  if (listings?.displayGroupCarousel[seatCount]?.length) {
    const displayListings = listings.displayGroupCarousel[seatCount]
      .filter((listItem) => allListings[listItem])
      .map((listItem) => {
        const listing = new Listing(allListings[listItem]);
        listing.currentSeatCount = seatCount;
        if (isDealsHarmony) {
          listing.isDealLockDisabled = true;
        }
        return listing;
      });
    return {
      listings: displayListings,
      ...getCarouselDealInfo(displayListings),
    };
  }

  return {
    listings: [],
    ...getCarouselDealInfo([]),
  };
};

export const getCarouselHarmony = ({
  listings = {},
  seatCount,
  isDealsHarmony,
}) => {
  const defaultState = {
    listings: [],
    isDealsHarmony: false,
    ...getCarouselDealInfo([]),
  };

  if (!isDealsHarmony) {
    return defaultState;
  }

  const allListings = listings.allListings || {};
  if (listings?.displayGroupCarouselHarmony[seatCount]?.length) {
    const displayListings = listings.displayGroupCarouselHarmony[seatCount]
      .filter((listItem) => allListings[listItem])
      .map((listItem) => {
        const listing = new Listing(allListings[listItem]);
        listing.currentSeatCount = seatCount;

        if (isDealsHarmony) {
          listing.isDealLockDisabled = true;
        }
        return listing;
      });
    return {
      listings: displayListings,
      isDealsHarmony,
      ...getCarouselDealInfo(displayListings),
    };
  }

  return defaultState;
};

export const getDisplayGroupCarouselFromStore = (state) => {
  return state?.data?.listings?.displayGroupCarousel || {};
};

export const priceQuartileSelector = (state) => {
  return state?.data?.listings?.priceQuartile;
};

export const fetchListingsLoadingSelector = (state) => {
  return state?.data?.listings?.loading;
};

export const isLockedCarouselHoveredSelector = (state) => {
  return state.data.listings.isLockedCarouselHovered;
};

export const listMapHarmonyToggleSelector = (state) => {
  return state.data.listings.listMapHarmonyToggleIsOn;
};

export const updatingMapHarmonySelector = (state) => {
  return state.data?.listings.isUpdatingMapHarmony;
};
export const isZoneUnlockedSelector = (state, eventId) => {
  const unlockedZoneDealEvents =
    state.data.listings.unlockedZoneDealEvents || [];
  return unlockedZoneDealEvents.includes(eventId);
};

export const hoveredListingIdSelector = (state) => {
  return state.data.listings.hoveredListingId;
};

export const previousQuerySearchSelector = (state) => {
  return state.routing.locationBeforeTransitions?.search || '';
};

const selectAllInPriceByState = createStructuredSelector({
  CA: selectIsCaliforniaVenueAllInPriceEnabled,
  MD: selectIsMarylandVenueAllInPriceEnabled,
  CO: selectIsColoradoVenueAllInPriceEnabled,
});

export const selectIsVenueAllInPrice = createSelector(
  selectAllInPriceByState,
  (_, venueState) => venueState,
  (allInPriceByState, venueState) => {
    return allInPriceByState[venueState] ?? false;
  }
);
