const LOCALIZE_CLIENT_KEY = 'Dzi7LadO8MQI3';
const LOCALIZE_SRC = 'https://global.localizecdn.com/localize.js';
const LOCALIZE_SCRIPT_ID = 'localize-script';

export function injectLocalizeScript() {
  if (typeof window === 'undefined') {
    throw new Error(
      'LocalizeJS cannot be configured outside a browser environment'
    );
  }

  const script = document.createElement('script');
  script.id = LOCALIZE_SCRIPT_ID;
  script.src = LOCALIZE_SRC;

  return new Promise((resolve, reject) => {
    script.onload = () => {
      const s = document.createElement('script');
      s.id = 'localize-fn';
      s.innerHTML = `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget","getSourceLanguage"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);`;
      document.head.append(s);

      /*global Localize*/
      /*eslint no-undef: "error"*/
      const loc = Localize.initialize({
        key: LOCALIZE_CLIENT_KEY,
        rememberLanguage: true,
        autoApprove: true,
      });

      resolve(loc);
    };

    script.onerror = () => {
      reject(new Error('Error loading LocalizeJS script'));
    };

    document.head.append(script);
  });
}

export function cleanupLocalizeScript() {
  if (typeof document !== 'undefined') {
    const script = document.querySelector(`#${LOCALIZE_SCRIPT_ID}`);
    if (script) {
      script.remove();
    }
  }
}
