import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import ContentSection from 'components/ContentSection/ContentSection';
import HeadDescription from 'components/Head/Description';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import HeroSection from 'components/HeroSection/HeroSection';
import Section from 'components/Section/Section';
import { BaseContent } from 'models';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import { fetchPromoCodesContent } from 'store/modules/content/promoCodes/actions';
import { selectPromoCodes } from 'store/modules/content/promoCodes/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import Coupon from './Coupon/';

import styles from './PromoCodes.module.scss';

const PAGE_TITLE = 'Official Gametime Promo Codes';
const HERO_IMAGE_URL =
  'https://cdn.kustomerhostedcontent.com/media/5ff34d4f2e2de5e58056fa86/c396ee10d2d43ff95a5dfbfb879ba139.png';

@connect((state) => ({ promoCodes: selectPromoCodes(state) }))
@TrackPageView(() => ({
  pageType: View.PAGE_TYPES.STATIC_PAGE('promo_codes'),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.STATIC_PAGE('promo_codes'),
}))
class PromoCodes extends Component {
  static propTypes = {
    promoCodes: PropTypes.object,
  };

  renderMeta() {
    return (
      <div>
        <HeadImage src={HERO_IMAGE_URL} />
        <HeadTitle title={PAGE_TITLE} />
        <HeadDescription description="Get the latest deals and promotions with Gametime promo codes for live concert, event and sports tickets." />
      </div>
    );
  }

  renderCoupons() {
    const { promoCodes } = this.props;
    return promoCodes?.promo_codes.map((coupon) => {
      if (!coupon.active) return null;
      return <Coupon key={coupon.name} coupon={coupon} />;
    });
  }

  renderContent() {
    const { promoCodes } = this.props;

    return (
      <div className={styles.content}>
        <Section className={styles.section} headline="Promo Codes" grayHeadline>
          {this.renderCoupons()}
        </Section>
        <ContentSection
          headline="Frequently Asked Questions"
          content={new BaseContent({ body: promoCodes?.faq, active: true })}
        />
      </div>
    );
  }

  render() {
    const imgProps = {
      lazyLoad: false,
      isPreloaded: true,
      src: HERO_IMAGE_URL,
    };

    return (
      <GTContainer
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
        className={styles['promo-codes-container']}
      >
        {this.renderMeta()}
        <HeroSection title="Gametime Promo Codes" imageProps={imgProps} />
        {this.renderContent()}
      </GTContainer>
    );
  }
}

export default withDataLoader(PromoCodes, {
  promise: async ({ store: { dispatch } }) =>
    await Promise.all([
      dispatch(fetchMetros()),
      dispatch(fetchPromoCodesContent()),
    ]),
});
