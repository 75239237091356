import React from 'react';
import { connect } from 'react-redux';

import CheckoutLogin from 'components/Login/CheckoutLogin';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { isUserLoggedIn } from 'store/modules/user/user.selectors';

import PurchaseFlowProvider from './PurchaseFlowContext';

interface Props {
  children: React.ReactNode;
  isLoggedIn: boolean;
  listing: Listing;
  event: FullEvent;
}

interface ChildProps {
  listing: Listing;
  event: FullEvent;
}

function ProtectedCheckoutRoute({
  children,
  isLoggedIn,
  listing,
  event,
}: Props) {
  if (!React.isValidElement<ChildProps>(children)) {
    // props.children can be any ReactNode, including undefined, null, boolean,
    // string, and number, which are not valid as a ReactElement
    throw new Error(
      'Children of ProtectedCheckoutRoute must be a valid React element'
    );
  }

  if (isLoggedIn) {
    // TODO: we should resolve this legacy pattern and use route params
    // within the child routes to select the data needed for the components

    return (
      <PurchaseFlowProvider listing={listing} event={event}>
        {React.cloneElement(children, { listing, event })}
      </PurchaseFlowProvider>
    );
  }

  return <CheckoutLogin listing={listing} />;
}

function mapStateToProps(state: unknown) {
  return {
    isLoggedIn: isUserLoggedIn(state),
  };
}

export default connect(mapStateToProps)(ProtectedCheckoutRoute);
