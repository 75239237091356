import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';

interface UpdatePasswordRequest {
  userId: string;
  token: string;
  password: string;
}

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const updatePassword = ({
  userId,
  token,
  password,
}: UpdatePasswordRequest) => {
  return httpClient.request({
    path: `/v1/user/${userId}/update-password`,
    method: 'PATCH',
    body: {
      token,
      password,
    },
  });
};
