import React from 'react';

import { ClickTracker } from 'analytics';
import ButtonLoader from 'components/ButtonLoader/ButtonLoader';
import Link from 'components/Link/Link';
import colors from 'styles/colors.constants';

import styles from './ThemedButtonLoader.module.scss';

interface ThemedButtonLoaderProps {
  children?: React.ReactNode;
  backgroundColor: string;
  color?: string;
  margin?: number;
  href?: string;
  to?: string;
  rounded?: boolean;
  clickTracker?: ClickTracker | object;
  type?: 'button' | 'submit';
  loading?: boolean;
}

const ThemedButtonLoader = ({
  children,
  backgroundColor,
  color = colors.baseLight,
  margin,
  href,
  to,
  rounded,
  clickTracker,
  ...props
}: ThemedButtonLoaderProps) => {
  const buttonStyle: React.CSSProperties = {
    background: backgroundColor,
    color: color,
  };

  if (margin) {
    buttonStyle.marginLeft = `${margin}px`;
    buttonStyle.marginRight = `${margin}px`;
    buttonStyle.width = `calc(100% - ${2 * margin}px)`;
  }

  if (rounded) {
    buttonStyle.borderRadius = 50;
  }

  const Button = href || to ? Link : ButtonLoader;

  return (
    <Button
      className={styles['themed-button']}
      style={buttonStyle}
      clickTracker={clickTracker}
      to={to}
      href={href}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ThemedButtonLoader;
