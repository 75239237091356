import React from 'react';

import {
  Click,
  CollectionClickTracker,
  FullEventClickTracker,
  useAnalyticsContext,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import { EventJsonLD } from 'components/JsonLD/helpers/event/types';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import { HEADER_TYPES } from 'components/SectionHeader/constants';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { HeaderType } from 'components/SectionHeader/types';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { Collection as CollectionModel, FullEvent } from 'models';
import { CategoryGroup } from 'modules/CategoryGroups/CategoryGroups.helpers';
import {
  COLLECTION_HEADINGS,
  COLLECTION_SLUG_TO_URL,
  COLLECTION_SPORT_SLUG,
  COLLECTION_TITLES,
  COLLECTIONS_MUSIC_THEATER_SLUG,
  VISIBLE_COLLECTION_ITEMS,
} from 'pages/Collection/constants';
import { Metro } from 'types';

interface CollectionHeaderProps {
  collection: CollectionModel;
  collectionTitle: string;
  currentMetro: Metro;
  sectionIndex: number;
  headerType?: HeaderType;
}

interface SlugToUrl {
  [key: string]: string;
}

function CollectionHeader({
  collection,
  collectionTitle,
  currentMetro,
  sectionIndex,
  headerType,
}: CollectionHeaderProps) {
  const lowerTitle = collectionTitle.toLowerCase();
  const slugToUrl: SlugToUrl = COLLECTION_SLUG_TO_URL;

  const isHomeGameTitle = lowerTitle.includes(COLLECTION_TITLES.HOME_GAMES);
  const isMusicOrTheaterSlug = COLLECTIONS_MUSIC_THEATER_SLUG.includes(
    collection.slug
  );

  let seeAllLinkPath = collection.pathByMetro(currentMetro.id);
  if (isHomeGameTitle) {
    seeAllLinkPath = collection.getPrimaryPerformerPath();
  }
  if (isMusicOrTheaterSlug) {
    seeAllLinkPath = slugToUrl[collection.slug];
  }

  const isPopularCollection = lowerTitle === COLLECTION_TITLES.POPULAR;
  const isSuperBowlCollection =
    lowerTitle === COLLECTION_TITLES.SUPERBOWL_LVIII;

  let sectionTitle = '';
  if (isPopularCollection) {
    sectionTitle = COLLECTION_HEADINGS.POPULAR_EVENTS_NEAR_YOU;
  } else if (isSuperBowlCollection) {
    sectionTitle = COLLECTION_HEADINGS.SUPERBOWL_LVIII;
  } else {
    sectionTitle = lowerTitle;
  }

  const clickTracker = new CollectionClickTracker().interaction(
    Click.INTERACTIONS.SEE_MORE(),
    {
      section_index: sectionIndex,
      collection: lowerTitle,
    }
  );

  const showSeeAll = collection.slug !== COLLECTION_SPORT_SLUG;

  const getHeaderType = (): HeaderType => {
    if (headerType) {
      return headerType;
    }

    if (isPopularCollection) {
      return HEADER_TYPES.SUBTITLE;
    }

    return HEADER_TYPES.BODY;
  };

  return (
    <SectionHeader
      title={sectionTitle}
      showSeeAll={showSeeAll}
      seeAllLinkPath={seeAllLinkPath}
      clickTracker={clickTracker}
      headerType={getHeaderType()}
    />
  );
}

export interface CollectionProps {
  collection: CollectionModel;
  collectionTitle: string;
  currentMetro: Metro;
  sectionIndex: number;
  lazyLoad: boolean;
  heroCarouselEvents: FullEvent[];
  headerType?: HeaderType;
  categoryGroup?: CategoryGroup;
  isGTPicksCollection?: boolean;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

export default function Collection({
  collection,
  collectionTitle,
  currentMetro,
  sectionIndex,
  lazyLoad,
  heroCarouselEvents = [],
  headerType,
  categoryGroup,
  isGTPicksCollection,
}: CollectionProps) {
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const isPopularCollection =
    collection.title.toLowerCase() === COLLECTION_TITLES.POPULAR;
  const isMobile = useMediaQuery(device.down.md);

  const items = React.useMemo(() => {
    // Hack to not display the same first element in Home/Metro/EventHeroCarousel
    // The new carousel contains the first sports/music/theater event from the popular collection
    // so we need to account for that here; in the future we might have another end point or data for hero carousel
    const isHomeGameTitle = !!collection.title
      ?.toLowerCase()
      .includes(COLLECTION_TITLES.HOME_GAMES);

    const venueName = collection.eventsList?.[0]?.venue?.name;
    const isVenueCollection =
      venueName &&
      collection.title.toLowerCase() === `at ${venueName}`.toLowerCase();

    const isHomeVenue = isHomeGameTitle || isVenueCollection;

    const eventsCount = isHomeVenue
      ? collection.eventsCount()
      : collection.uniqueEventsCount();

    const itemCount = Math.min(VISIBLE_COLLECTION_ITEMS, eventsCount);
    const listKey = isHomeVenue ? 'eventsList' : 'uniqueEventsList';

    let collectionEventsList = collection[listKey];

    if (isPopularCollection && !!heroCarouselEvents.length) {
      collectionEventsList = Array.from(
        new Set([...heroCarouselEvents, ...collectionEventsList])
      );
    }

    return collectionEventsList.slice(0, itemCount);
  }, [collection, heroCarouselEvents, isPopularCollection]);

  function renderFullEvent(fullEvent: FullEvent, index: number) {
    const clickTracker = new FullEventClickTracker(fullEvent).interaction(
      Click.INTERACTIONS.TILE(),
      {
        section_index: sectionIndex,
        item_index: index,
        collection: collectionTitle,
        get_in_price: fullEvent.getPrice(),
      }
    );

    const isTopPick: boolean =
      isPopularCollection && !!heroCarouselEvents.length
        ? heroCarouselEvents.some((e: FullEvent) => e.id === fullEvent.id)
        : false;

    const jsonLD: EventJsonLD | null =
      categoryGroup === fullEvent.getCategoryGroup()
        ? dataToJsonLD(fullEvent)
        : null;

    return (
      <CollectionCard
        key={`slick-carousel-${fullEvent.id}`}
        source={fullEvent}
        clickTracker={clickTracker}
        lazyLoad={lazyLoad}
        showPromoBanner
        isTopPick={isTopPick}
        toListingPage={false}
        jsonLD={jsonLD}
        isGTPicksCollection={isGTPicksCollection}
      />
    );
  }

  return (
    <ReactSlickCarousel
      title={collectionTitle}
      analytics={analytics}
      clickContext={clickContext}
      items={items}
      renderItem={renderFullEvent}
      spacing={16}
      slideWidth={isMobile ? 304 : 344}
      renderHeader={() => (
        <CollectionHeader
          collection={collection}
          collectionTitle={collectionTitle}
          currentMetro={currentMetro}
          sectionIndex={sectionIndex}
          headerType={headerType}
        />
      )}
    />
  );
}
