import { createSelector } from 'reselect';

import { FullEvent } from 'models';

import { getFilterKey } from './filterReducer';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _selectFullEvents = (state) => state.data.fullEvents.events;
const _selectFullParkingEvents = (state) => state.data.fullEvents.parkingEvents;
const _selectFullEventById = (state, id) => _selectFullEvents(state)[id];
const _selectFullEventsFilters = (state) => state.dataFilters.fullEvents;
const _selectFullEventsIdsByFilters = (state, filters) => {
  const filterKey = getFilterKey(filters);
  return _selectFullEventsFilters(state)[filterKey];
};
const _selectEventsData = (state) => state.data.fullEvents.eventsData;

/* Returns Models */
export const selectFullEvents = createSelector(
  _selectFullEvents,
  (fullEventsState) => {
    const events = fullEventsState || [];
    return events.map((e) => new FullEvent(e));
  }
);

export const selectParkingFullEvents = createSelector(
  _selectFullParkingEvents,
  (fullParkingEventsState) => {
    const events = fullParkingEventsState || [];
    return events.map((e) => new FullEvent(e));
  }
);
export const selectFullEventById = createSelector(
  _selectFullEventById,
  (fullEventObject) => (fullEventObject ? new FullEvent(fullEventObject) : null)
);

export const selectFullEventsByFilters = createSelector(
  _selectFullEvents,
  _selectFullEventsIdsByFilters,
  (fullEventObjects, eventIds) => {
    if (!eventIds) {
      return undefined;
    }
    return eventIds.map((eventId) => new FullEvent(fullEventObjects[eventId]));
  }
);

export const selectEventsData = createSelector(
  _selectEventsData,
  (eventsData) => {
    if (!eventsData) {
      return undefined;
    }

    return {
      ...eventsData,
      events: eventsData.events?.map((event) => new FullEvent(event)) || [],
    };
  }
);

/* Helpers: Doesnt use reselect */
export const selectFullEventsByVenueSlug = (state, venueSlug) =>
  selectFullEventsByFilters(state, { venueSlug });

export const selectFullEventsByPerformerSlug = (state, performerSlug) =>
  selectFullEventsByFilters(state, { performerSlug });

export const selectFullEventsByPrimaryPerformerId = (
  state,
  primaryPerformerId
) => selectFullEventsByFilters(state, { primaryPerformerId });
