import { ValueOf } from 'type-fest';

export const ENV = {
  DEVELOPMENT: 'development',
  TESTING: 'testing',
  STAGING: 'staging',
  QA: 'qa',
  PRE_PRODUCTION: 'pre-production',
  PRODUCTION: 'production',
} as const;

export type Environment = ValueOf<typeof ENV>;

export function envIsLikeDev(env: Environment) {
  const devLikeEnvs: Environment[] = [
    ENV.DEVELOPMENT,
    ENV.TESTING,
    ENV.STAGING,
  ];
  return devLikeEnvs.includes(env);
}

export function envIsSentryEnv(env: Environment) {
  const sentryEnvs: Environment[] = [
    ENV.QA,
    ENV.PRE_PRODUCTION,
    ENV.PRODUCTION,
  ];
  return sentryEnvs.includes(env);
}

export function isValidEnv(env: string): env is Environment {
  return Object.values(ENV).includes(env as Environment);
}
