import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  endOfDay,
  endOfYear,
  format,
  getHours,
  isFriday,
  isSameWeek,
  isSunday,
  isThisWeek,
  isToday as is_today,
  isTomorrow,
  isWeekend,
  nextFriday,
  nextSunday,
  parseISO,
  setHours,
  startOfDay,
  subHours,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import _capitalize from 'lodash/capitalize';

export const DATE_FORMAT_DEFAULT = "iiii, MMMM d 'at' h:mm a";
export const DATE_FORMAT_TBD = "iiii, MMMM d 'at' 'TBD'";
export const DAY_DATE_FORMAT = 'iii M/d';
export const DAY_DATE_YEAR_FORMAT = 'iii M/d/yy';
export const DAY_FORMAT = 'iii';
export const DAY_FORMAT_DDDD = 'iiii';
export const TIME_FORMAT = 'h:mm a';
export const DATE_FORMAT_M_D = 'M/d';
export const DATE_FORMAT_M_DD = 'M/dd';
export const DATE_FORMAT_MM_DD = 'MM/dd';
export const DATE_FORMAT_MMM_D = 'MMM d';
export const DATE_FORMAT_M_D_YY = 'M/d/yy';
export const DATE_FORMAT_YYYY = 'yyyy';
export const DAY_TIME_FORMAT = 'iii h:mm a';
export const DATE_FORMAT_MMM_DD_YYYY = 'MMM. dd, yyyy';
export const DATE_FORMAT_EEEE_MMMM_D_YYYY = 'iiii, MMMM d, yyyy';

// date-fns v2 accepts number or Date only so need to change string type into Date type
const standardizeDateTime = (datetime) =>
  typeof datetime === 'string' ? parseISO(datetime) : datetime;

const isNextWeek = (datetime) =>
  isSameWeek(addDays(Date.now(), 7), standardizeDateTime(datetime));

// If the date is in the past, the difference will be positive when comparing dates, 0 means date is today
export const isPastDate = (eventDate) =>
  differenceInDays(Date.now(), eventDate) > 0;

export const getDefaultFormat = (datetime) =>
  format(standardizeDateTime(datetime), DATE_FORMAT_DEFAULT);
export const getSlashedDate = (datetime) =>
  format(standardizeDateTime(datetime), DATE_FORMAT_M_D_YY);
export const getTBDFormat = (datetime) =>
  format(standardizeDateTime(datetime), DATE_FORMAT_TBD);

export const formatDate = (date, dateFormat = DATE_FORMAT_MM_DD) => {
  try {
    return format(standardizeDateTime(date), dateFormat);
  } catch (err) {
    return 'Invalid Date';
  }
};

export const dateDifference = (firstDate, secondDate) =>
  Math.abs(
    differenceInDays(
      standardizeDateTime(firstDate),
      standardizeDateTime(secondDate)
    )
  );

const todayLabel = 'today';

const DATE_LABELS = {
  TOMORROW: {
    label: 'tomorrow',
    labelShort: 'tmrw',
  },
  TONIGHT: {
    label: 'tonight',
    labelShort: todayLabel,
  },
  TODAY: {
    label: todayLabel,
    labelShort: todayLabel,
  },
  WEEK: {
    label: 'week',
    labelShort: 'week',
  },
  WEEKEND: {
    label: 'weekend',
    labelShort: 'weekend',
  },
  BEYOND: {
    label: 'beyond',
    labelShort: 'beyond',
  },
};

const getDatetimeWithUtcOffset = () => {
  const now = new Date();
  return new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );
};

export const getOverrideDayLabelConfig = (
  eventDatetimeMetro,
  eventDatetimeUtc
) => {
  /*
   * Show the labels based on the current metro time.
   */
  const timezoneOffsetInHours = differenceInHours(
    standardizeDateTime(eventDatetimeUtc),
    standardizeDateTime(eventDatetimeMetro)
  );
  const datetimeWithUtcOffset = getDatetimeWithUtcOffset();
  const datetimeMetro = subHours(datetimeWithUtcOffset, timezoneOffsetInHours);

  const diffInDays = differenceInCalendarDays(
    standardizeDateTime(datetimeMetro),
    standardizeDateTime(eventDatetimeMetro)
  );
  switch (diffInDays) {
    case 0:
      if (getHours(standardizeDateTime(eventDatetimeMetro)) < 17) {
        return DATE_LABELS.TODAY;
      }
      return DATE_LABELS.TONIGHT;
    case -1:
      return DATE_LABELS.TOMORROW;
    default:
      return null;
  }
};

export const isToday = (eventDatetimeUtc, timezone) => {
  const eventDatetime = new Date(`${eventDatetimeUtc}Z`);
  const formattedDatetime = formatInTimeZone(
    eventDatetime,
    timezone,
    "yyyy-MM-dd'T'HH:mm:ss"
  );
  const dateLabel = getOverrideDayLabelConfig(
    formattedDatetime,
    eventDatetimeUtc
  );

  return dateLabel === DATE_LABELS.TODAY || dateLabel === DATE_LABELS.TONIGHT;
};

export const isLastCall = (eventDatetimeUtc, timezone, hours) => {
  const currentTime = Date.now();
  const eventTime = new Date(`${eventDatetimeUtc}Z`).getTime();

  const diffMs = eventTime - currentTime;
  // Convert hours to ms: 60 mins / hour, 60 secs / min, 1000 ms per sec.
  const limitMs = hours * 60 * 60 * 1000;

  // Allow 'last call' badge for events that started earlier today.
  if (diffMs < 0) {
    return isToday(eventDatetimeUtc, timezone);
  }

  return diffMs < limitMs;
};

export const formatUpcomingDate = (
  eventDatetimeMetro,
  eventDatetimeUtc,
  dateFormat = DAY_DATE_FORMAT
) => {
  const overrideDayLabel = getOverrideDayLabelConfig(
    eventDatetimeMetro,
    eventDatetimeUtc
  );

  if (overrideDayLabel) {
    return _capitalize(overrideDayLabel.label);
  }
  return format(standardizeDateTime(eventDatetimeMetro), dateFormat);
};

export const isGTWeekend = (datetime) =>
  isWeekend(standardizeDateTime(datetime)) ||
  isFriday(standardizeDateTime(datetime));

export const formatUpcomingEventDays = (upcomingFullEvents) => {
  if (!upcomingFullEvents || !Array.isArray(upcomingFullEvents)) {
    return '';
  }
  const MAX_APPLICABLE_EVENTS_DAYS = 10;
  const applicableEvents = upcomingFullEvents.filter(
    (fullEvent) =>
      differenceInDays(
        standardizeDateTime(fullEvent.event.datetimeLocal),
        Date.now()
      ) < MAX_APPLICABLE_EVENTS_DAYS
  );

  const usedNames = {};
  const formattedNames = [];
  const dayNames = [];

  const setName = (name, repo = formattedNames) => {
    if (!usedNames[name]) {
      usedNames[name] = true;
      repo.push(name);
    }
  };

  const getLabelPrefix = (datetime) => {
    if (isThisWeek(standardizeDateTime(datetime))) {
      return 'this';
    }
    if (isNextWeek(standardizeDateTime(datetime))) {
      return 'next';
    }
    return '';
  };

  applicableEvents.forEach((fullEvent) => {
    let potentialName;
    if (
      !usedNames[DATE_LABELS.TONIGHT.label] &&
      is_today(standardizeDateTime(fullEvent.event.datetimeLocal))
    ) {
      setName(DATE_LABELS.TONIGHT.label);
    } else if (
      !usedNames[DATE_LABELS.TOMORROW.label] &&
      isTomorrow(standardizeDateTime(fullEvent.event.datetimeLocal))
    ) {
      setName(DATE_LABELS.TOMORROW.label);
    } else {
      const prefix = getLabelPrefix(fullEvent.event.datetimeLocal);
      if (prefix) {
        if (isGTWeekend(fullEvent.event.datetimeLocal)) {
          potentialName = `${prefix} ${DATE_LABELS.WEEKEND.label}`;
        } else {
          potentialName = `${prefix} ${DATE_LABELS.WEEK.label}`;
        }
        setName(potentialName);
        setName(
          `${prefix} ${format(
            standardizeDateTime(fullEvent.event.datetimeLocal),
            'iiii'
          )}`,
          dayNames
        );
      }
    }
  });

  const toReturn = [...formattedNames, ...dayNames].slice(0, 5);

  if (
    toReturn.length > 1 &&
    differenceInDays(
      standardizeDateTime(
        upcomingFullEvents[upcomingFullEvents.length - 1].event.datetimeLocal
      ),
      Date.now()
    ) > MAX_APPLICABLE_EVENTS_DAYS
  ) {
    toReturn.push(DATE_LABELS.BEYOND.label);
  }

  return [toReturn.slice(0, -1).join(', '), toReturn.slice(-1)[0]].join(
    toReturn.length < 2 ? '' : ' and '
  );
};

export const urlDate = (datetime) => {
  if (!datetime) {
    return '';
  }
  return format(standardizeDateTime(datetime), 'M-d-yyyy');
};

export const getSingleDayRange = (datetime) => {
  const start = startOfDay(standardizeDateTime(datetime));
  const end = endOfDay(standardizeDateTime(datetime));
  const timezoneOffset = datetime.getTimezoneOffset();

  return {
    start: format(
      addMinutes(start, timezoneOffset),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    ),
    end: format(addMinutes(end, timezoneOffset), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  };
};

export const getThisWeekendDates = (today) => {
  const isTodayWeekend = isGTWeekend(today);
  const isTodaySunday = isSunday(today);
  const start = isTodayWeekend
    ? new Date(today)
    : setHours(startOfDay(nextFriday(today)), 4);
  const end = isTodaySunday ? endOfDay(today) : endOfDay(nextSunday(today));
  const timezoneOffset = today.getTimezoneOffset();

  return {
    start: format(
      addMinutes(start, timezoneOffset),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    ),
    end: format(addMinutes(end, timezoneOffset), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  };
};

export const getNextWeekendDates = (today) => {
  const timezoneOffset = today.getTimezoneOffset();
  const isTodayWeekend = isGTWeekend(today);
  const isTodaySunday = isSunday(today);
  const start = isTodayWeekend
    ? startOfDay(nextFriday(today))
    : startOfDay(addDays(nextFriday(today), 7));
  const end = isTodaySunday
    ? endOfDay(nextSunday(today))
    : endOfDay(addDays(nextSunday(today), 7));

  return {
    start: format(
      addMinutes(start, timezoneOffset),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    ),
    end: format(addMinutes(end, timezoneOffset), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  };
};

export const getEndOfYearRange = (today) => {
  const timezoneOffset = today.getTimezoneOffset();
  const start = startOfDay(today);
  const end = endOfYear(today);

  return {
    start: format(
      addMinutes(start, timezoneOffset),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    ),
    end: format(addMinutes(end, timezoneOffset), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  };
};

export const getDefaultRange = (performer) => {
  const eligibleCategories = ['mlb'];
  if (!eligibleCategories.includes(performer.category)) {
    return {};
  }
  const oneYearDates = getEndOfYearRange(new Date());
  return {
    sales_cutoff_after: oneYearDates.start,
    sales_cutoff_before: oneYearDates.end,
  };
};

export const getCurrentDateInISO = () => {
  return new Date().toISOString().split('T')[0];
};
