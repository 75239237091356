import React from 'react';

import colors from 'styles/colors.constants';

interface CirlceInfoIconProps {
  /** Width of SVG element, defaults to '16' */
  width?: React.SVGProps<SVGSVGElement>['width'];
  /** Height of SVG element, defaults to '16' */
  height?: React.SVGProps<SVGSVGElement>['height'];
  /** Fill of Path element, defaults to colors.white */
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

export default function CircleInfoIcon({
  width = '16',
  height = '16',
  fill = colors.white,
}: CirlceInfoIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="circle-info-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5C4.9625 2.5 2.5 4.9625 2.5 8C2.5 11.0375 4.9625 13.5 8 13.5C11.0375 13.5 13.5 11.0375 13.5 8C13.5 4.9625 11.0375 2.5 8 2.5ZM8 1C4.13407 1 1 4.13408 1 8C1 11.8659 4.13407 15 8 15C11.8659 15 15 11.8659 15 8C15 4.13408 11.8659 1 8 1ZM8.35355 3.85355L9.41421 4.91421C9.60948 5.10948 9.60948 5.42606 9.41421 5.62132L8.35355 6.68198C8.15829 6.87724 7.84171 6.87724 7.64645 6.68198L6.58579 5.62132C6.39052 5.42606 6.39052 5.10948 6.58579 4.91421L7.64645 3.85355C7.84171 3.65829 8.15829 3.65829 8.35355 3.85355ZM8.5 12C8.77614 12 9 11.7761 9 11.5V8.5C9 8.22386 8.77614 8 8.5 8H7.5C7.22386 8 7 8.22386 7 8.5V11.5C7 11.7761 7.22386 12 7.5 12H8.5Z"
        fill={fill}
      />
    </svg>
  );
}
