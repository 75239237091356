import React from 'react';

import { BENEFITS_AND_PERKS } from '../../careers.constants';

import SingleBenefit from './SingleBenefit';

import styles from './BenefitsAndPerks.module.scss';

const BenefitsAndPerks = React.forwardRef((_props, ref) => {
  return (
    <div className={styles['benefits-and-perks-container']} ref={ref}>
      <h1 className={styles['gametime-h1']}>Benefits & Perks</h1>
      <p>
        Gametime offers competitive benefits and perks, so you have the support
        and tools you need to bring your best self to work.
      </p>
      <div className={styles['benefits-and-perks-grid']}>
        {BENEFITS_AND_PERKS.map((benefitAndPerk) => {
          const { name, description, src } = benefitAndPerk;
          return (
            <SingleBenefit
              key={name}
              name={name}
              description={description}
              src={src}
            />
          );
        })}
      </div>
    </div>
  );
});

export default BenefitsAndPerks;
