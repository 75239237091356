import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  DeepLinkClickTracker,
  PAYLOAD,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import AnalyticsContext from 'components/Trackable/AnalyticsContext';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { extendDefaultBranchState, generateBranchLink } from 'utils/branchLink';
import { flatten } from 'utils/flatten';

import { DEEPLINK_ACTIONS } from './DeepLink.constants';

@withClickContext()
@withAppContext
@connect(
  (state, props) => {
    const { campaign } = props;
    const branchData = extendDefaultBranchState(
      state,
      campaign,
      props.branchData
    );
    return {
      branchData,
    };
  },
  {
    showModal,
  }
)
@AnalyticsContext({
  event: 'deep_link',
})
@withAnalyticsContext
class DeepLink extends Component {
  static propTypes = {
    branchData: PropTypes.object,
    branchCallback: PropTypes.func,
    children: PropTypes.node,
    onClick: PropTypes.func,
    showModal: PropTypes.func,
    href: PropTypes.string,
    className: PropTypes.string,
    preventLinkFetch: PropTypes.bool,
    clickTracker: PropTypes.object,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    fireFacebookEvent: PropTypes.bool,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
  };

  static defaultProps = {
    branchData: {},
    branchCallback: () => {},
    onClick: () => {},
    preventLinkFetch: false,
    fireFacebookEvent: false,
  };

  constructor(props) {
    super(props);

    const { href } = props;
    this.state = { href };

    this.handleClick = this.handleClick.bind(this);
    this.branchCallback = this.branchCallback.bind(this);
  }

  componentDidMount() {
    const { branchData, preventLinkFetch } = this.props;

    // prevent link generation if an href is passed as a prop
    if (preventLinkFetch) {
      return;
    }
    generateBranchLink(branchData, this.branchCallback);
  }

  componentDidUpdate() {
    const { branchData, href } = this.props;
    if (href !== this.state.href) {
      if (href) {
        this.setState({ href });
      }
    } else if (!href) {
      generateBranchLink(branchData, this.branchCallback);
    }
  }

  branchCallback(error, href) {
    if (error) {
      console.error(error);
      this.props.analyticsContext.track({
        action: 'branch_error',
        error: JSON.stringify(error),
      });
      return;
    }
    this.props.branchCallback(error, href);
    this.setState({ href });
  }

  handleClick(event) {
    const {
      appContext: {
        state: { isMobile },
      },
      onClick,
      branchData,
      clickTracker,
      fireFacebookEvent,
      analyticsContext,
      clickContext,
    } = this.props;
    analyticsContext.track(flatten(branchData));

    const deeplinkTracker = clickTracker || new DeepLinkClickTracker(isMobile);
    let deeplinkProperties = deeplinkTracker;

    if (
      fireFacebookEvent &&
      __CLIENT__ &&
      typeof window !== 'undefined' &&
      typeof window.fbq === 'function'
    ) {
      window.fbq('track', 'Lead');
    }

    // if desktop
    if (!isMobile) {
      if (deeplinkTracker instanceof ClickTracker) {
        deeplinkTracker.payload({
          [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.OPEN_SMS,
        });
        deeplinkProperties = deeplinkTracker.json();
      }

      analyticsContext.track(
        new Click(_merge({}, clickContext, deeplinkProperties))
      );
      this.props.showModal(MODALS.GET_APP, {
        clickTracker: deeplinkTracker,
        branchData,
      });
      event.preventDefault();
    } else {
      const { href } = this.state;
      if (deeplinkTracker instanceof ClickTracker) {
        deeplinkTracker.payload({
          [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.DOWNLOAD,
          [PAYLOAD.BRANCH_URL]: href,
        });
        deeplinkProperties = deeplinkTracker.json();
      }

      analyticsContext.track(
        new Click(_merge({}, clickContext, deeplinkProperties))
      );
    }
    onClick(event);
  }

  render() {
    const { children, className } = this.props;
    const { href } = this.state;
    const linkProps = {
      className,
      href,
      onClick: this.handleClick,
    };
    return <a {...linkProps}>{children}</a>;
  }
}

export default DeepLink;
