import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CaretDownIcon from 'icons/CaretDownIcon';
import colors from 'styles/colors.constants';

import styles from './Chip.module.scss';

const Chip = ({
  size = 'md',
  color = 'primary',
  label,
  disabled = false,
  isMenu = false,
  icon = null,
  alignCenter = false,
  onClick = null,
  testId = '',
}) => {
  const { gametimeGreenLight: gtGreen, gray900 } = colors;
  const rootStyles = classNames(
    styles.chip,
    styles[`size-${size}`],
    styles[`color-${color}`],
    {
      [styles.disabled]: disabled,
      [styles['is-menu']]: isMenu,
      [styles['icon-chip']]: icon !== null,
      [styles['align-center']]: alignCenter,
      [styles.clickable]: onClick !== null,
    }
  );

  const labelStyles = classNames(styles.label, {
    [styles['icon-chip']]: icon !== null,
  });

  return (
    <div
      data-testid={testId}
      data-cy={testId}
      className={rootStyles}
      onClick={onClick && onClick}
    >
      {icon && icon}
      <div className={labelStyles}>{label}</div>
      {isMenu && (
        <CaretDownIcon fill={color === 'secondary' ? gray900 : gtGreen} />
      )}
    </div>
  );
};

Chip.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  isMenu: PropTypes.bool,
  icon: PropTypes.element,
  alignCenter: PropTypes.bool,
  testId: PropTypes.string,
};

export default Chip;
