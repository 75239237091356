import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';

import { NAVIGATION_SECTIONS } from '../../careers.constants';

import styles from './SubNavigation.module.scss';

const SubNavigation = ({ onClick }) => {
  return (
    <div className={styles['sub-navigation-container']}>
      <div className={styles['sub-navigation']} data-cy="sub-navigation">
        <button
          type="button"
          className={classNames(styles.btn, styles['desktop-only'])}
          onClick={() => onClick(NAVIGATION_SECTIONS.ourCompany)}
          data-cy="our-company-sub-navigation"
        >
          Our Company
        </button>
        <button
          type="button"
          className={classNames(styles.btn, styles['desktop-only'])}
          onClick={() => onClick(NAVIGATION_SECTIONS.benefitsAndPerks)}
          data-cy="benefits-and-perks-sub-navigation"
        >
          Benefits & Perks
        </button>
        <Link
          className={classNames(styles.btn, styles['about-us'])}
          href="/about"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="about-us-sub-navigation"
        >
          About Us
        </Link>
        <button
          type="button"
          className={classNames(styles.btn, styles.active)}
          onClick={() => onClick(NAVIGATION_SECTIONS.openPositions)}
          data-cy="open-positions-sub-navigation"
        >
          Open Positions
        </button>
      </div>
    </div>
  );
};

SubNavigation.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SubNavigation;
