// 2024 Super Bowl

export const SUPER_BOWL_SLUG = 'nflsuperbowl';
export const SUPER_BOWL_LVIII_PATHNAME =
  '/nfl-football-tickets/super-bowl-lviii';
export const SUPER_BOWL_EVENT_ID = '642eeb4b2ca89f00016aff53';
export const SUPER_BOWL_PERFORMERS_IDS = [];

export const isSuperBowl = (eventId) => eventId === SUPER_BOWL_EVENT_ID;

export const SUPER_BOWL_FAQ_RELATIVE_URL =
  '/blog/super-bowl-lviii-frequently-asked-questions';

export const isSuperBowlPerformer = (performerId) =>
  SUPER_BOWL_PERFORMERS_IDS.includes(performerId);
