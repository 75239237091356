import React from 'react';
import PropTypes from 'prop-types';

import { PerformerClickTracker } from 'analytics';
import Image from 'components/Image/Image';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import JsonLD from 'components/JsonLD/JsonLD';
import Link from 'components/Link/Link';
import Performer from 'models/Performer';
import PerformerInContext from 'models/PerformerInContext';

import styles from './SimpleCollectionCard.module.scss';

const SimpleCollectionCard = ({ source, clickTracker }) => {
  let performer;
  let altTextForImage;

  if (source instanceof Performer) {
    performer = source;
    altTextForImage = source.getImageOptions().alt;
  } else if (source instanceof PerformerInContext) {
    ({ performer } = source);
    altTextForImage = source.getAltText();
  } else {
    return null;
  }

  const imageSrc = performer.heroImageUrl;
  const imageLink = performer.getPath();
  const title = performer.name;
  const jsonLD = dataToJsonLD(source);
  const performerClickTracker =
    clickTracker || new PerformerClickTracker(performer);

  return (
    <Link
      to={imageLink}
      clickTracker={performerClickTracker}
      data-cy="simple-collection-card-container"
    >
      <div className={styles['collection-container']}>
        <Image className={styles.image} src={imageSrc} alt={altTextForImage} />
        <div className={styles.title}>{title}</div>
        {jsonLD && <JsonLD json={jsonLD} />}
      </div>
    </Link>
  );
};

SimpleCollectionCard.propTypes = {
  source: PropTypes.oneOfType([
    PropTypes.instanceOf(Performer),
    PropTypes.instanceOf(PerformerInContext),
  ]).isRequired,
  clickTracker: PropTypes.object,
};

export default SimpleCollectionCard;
