import React, { Component } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalHeader from './ModalHeader/ModalHeader';

import styles from './GTAnimationModal.module.scss';

export default class GTAnimationModal extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string,
    headerContent: PropTypes.node,
    isLightTheme: PropTypes.bool,
    closeIconColor: PropTypes.string,
    isDarkTheme: PropTypes.bool,
  };

  render() {
    const {
      children,
      className,
      onHide,
      show,
      headerTitle,
      headerContent,
      isLightTheme,
      closeIconColor,
      isDarkTheme,
    } = this.props;

    return (
      <Modal
        className={classNames([styles['gt-modal'], styles[className]])}
        overlayClassName={styles['gt-modal-overlay']}
        bodyOpenClassName={styles['gt-modal-body-open']}
        isOpen={show}
        onRequestClose={onHide}
        onHide={onHide}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        {headerTitle && (
          <ModalHeader
            title={headerTitle}
            onClose={onHide}
            isLightTheme={isLightTheme}
            isDarkTheme={isDarkTheme}
            closeIconColor={closeIconColor}
          >
            {headerContent}
          </ModalHeader>
        )}
        {children}
      </Modal>
    );
  }
}
