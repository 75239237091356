import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  GroupedArrows,
  LeftButton as LeftArrow,
  RightButton as RightArrow,
} from './HorizontalScrollButton';
import HorizontalScrollCard from './HorizontalScrollCard';

import 'react-horizontal-scrolling-menu/dist/styles.css';
import styles from './HorizontalScrollView.module.scss';

/*
  https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
*/

const DELTA = 16;
const onWheel = (apiObj, ev) => {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < DELTA;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
};

const HorizontalScroll = ({
  children,
  isMobile,
  groupedArrows = false,
  className = null,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        !('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
      ) {
        setDisabled(true);
      }
    }
    setLoading(false);
  }, [isMobile]);

  if (loading) {
    return null;
  }

  if (isMobile || disabled) {
    return <div className={styles['horizontal-scroll-native']}>{children}</div>;
  }

  const props = groupedArrows
    ? { Footer: GroupedArrows }
    : { LeftArrow, RightArrow };

  return (
    <ScrollMenu
      {...props}
      onWheel={onWheel}
      scrollContainerClassName={styles['horizontal-scroll-container']}
      wrapperClassName={classNames({
        [styles['wrapper-side-arrows']]: !groupedArrows,
        [styles['wrapper-grouped-arrows']]: groupedArrows,
        [`${className}`]: className !== null,
      })}
    >
      {children}
    </ScrollMenu>
  );
};

HorizontalScroll.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool.isRequired,
  groupedArrows: PropTypes.bool,
  className: PropTypes.string,
};

HorizontalScroll.Card = HorizontalScrollCard;

export default HorizontalScroll;
