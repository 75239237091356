/*eslint-disable */
export const CONTEXT_PROPS = {
  lastVisitedMetro: '$lastVisitedMetro',
  closestMetro: 'closestMetro',
  $metro: '$metro',
};

export const EVENTS = {
  VIEWED_METROS: 'Opened metro selector',
  TAPPED_CURRENT_LOCATION_BUTTON: 'Tapped location button in metro selector',
  SELECT_METRO: 'Tapped metro in metro selector',
  RETURN_TO_METRO: 'Tapped return to metro in metro selector',
};
/* eslint-enable */
