// Note: this is the (server) sdk
import Apptimize from '@apptimize/apptimize-server-sdk';

import config from 'config/config';
import { ENV } from 'config/environments';

import { featureFlags } from './featureFlags';
import { FeatureFlag } from './types';

/**
 * By default, development environments will have all feature
 * flags enabled. To bucket according to Apptimize's
 * configuration in the development environment, set the
 * below variable to false.
 */
const isDevelopment = config.env === ENV.DEVELOPMENT;

/* Apptimize feature flags */
export const getFeatureFlagsList = (userId = 'GTid', isbotTraffic = false) => {
  const featureFlagsList = Object.values(featureFlags).reduce(
    (accumulator: Record<string, boolean>, flag: FeatureFlag) => {
      if (isbotTraffic) {
        accumulator[flag.name] = false;
      } else {
        accumulator[flag.name] = isDevelopment
          ? true
          : Apptimize.isFeatureFlagEnabled(flag.name, userId);
      }

      return accumulator;
    },
    {}
  );

  return featureFlagsList;
};
