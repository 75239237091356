import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

const APP_UI_ACTION_TYPES = generateActionTypes(
  [
    'UPDATE_UI_STATE',
    'UPDATE_ZOOM_LEVEL',
    'TOGGLE_ALL_IN_PRICE',
    'SET_ALL_IN_PRICE',
    'MOBILE_HERO_SEARCHBOX',
    'SET_FORM_SUBMITTING',
    'SET_FORM_SUBMITTED',
  ],
  REDUCERS.APP_UI
);

const initialState = {
  allInPrice: false,
  showToast: true,
  mobileHeroSearchBox: false,
  isFormSubmitting: false,
  isFormSubmitted: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APP_UI_ACTION_TYPES.UPDATE_UI_STATE:
      return {
        ...state,
        ...action.result,
      };
    case APP_UI_ACTION_TYPES.TOGGLE_ALL_IN_PRICE:
      return {
        ...state,
        allInPrice: !state.allInPrice,
      };
    case APP_UI_ACTION_TYPES.SET_ALL_IN_PRICE:
      return {
        ...state,
        allInPrice: action.value,
      };
    case APP_UI_ACTION_TYPES.MOBILE_HERO_SEARCHBOX:
      return {
        ...state,
        mobileHeroSearchBox: !state.mobileHeroSearchBox,
      };
    case APP_UI_ACTION_TYPES.SET_FORM_SUBMITTING:
      return {
        ...state,
        isFormSubmitting: action.result,
      };
    case APP_UI_ACTION_TYPES.SET_FORM_SUBMITTED:
      return {
        ...state,
        isFormSubmitted: action.result,
      };
    default:
      return state;
  }
}

export const updateLastVisitedListingId = (lastVisitedListingId) => ({
  type: APP_UI_ACTION_TYPES.UPDATE_UI_STATE,
  result: { lastVisitedListingId },
});

export const updateLastZoomLevel = (lastZoomLevel) => ({
  type: APP_UI_ACTION_TYPES.UPDATE_UI_STATE,
  result: { lastZoomLevel },
});

export const toggleAllInPrice = () => {
  return {
    type: APP_UI_ACTION_TYPES.TOGGLE_ALL_IN_PRICE,
  };
};

export const setAllInPrice = (value = true) => {
  return {
    type: APP_UI_ACTION_TYPES.SET_ALL_IN_PRICE,
    value,
  };
};

export const showMobileHeroSearchBox = () => {
  return {
    type: APP_UI_ACTION_TYPES.MOBILE_HERO_SEARCHBOX,
  };
};

// Global form submission indicators
export const setFormSubmitting = (isFormSubmitting) => ({
  type: APP_UI_ACTION_TYPES.SET_FORM_SUBMITTING,
  result: isFormSubmitting,
});

export const setFormSubmitted = (isFormSubmitted) => ({
  type: APP_UI_ACTION_TYPES.SET_FORM_SUBMITTED,
  result: isFormSubmitted,
});

export const lastZoomLevelSelector = (state) =>
  state[REDUCERS.APP_UI].lastZoomLevel;

export const isAllInPriceSelector = (state) =>
  state[REDUCERS.APP_UI].allInPrice;

export const mobileHeroSearchBoxSelector = (state) =>
  state[REDUCERS.APP_UI].mobileHeroSearchBox;

export const isFormSubmittingSelector = (state) =>
  state[REDUCERS.APP_UI].isFormSubmitting;

export const isFormSubmittedSelector = (state) =>
  state[REDUCERS.APP_UI].isFormSubmitted;
