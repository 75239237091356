import React from 'react';

const PhoneFillIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Phone Icon</title>
      <g fillRule="evenodd">
        <path d="M.75 3.86c0-.94.654-1.755 1.572-1.96 1.042-.23 2.408-.534 3.475-.772a2.009 2.009 0 0 1 2.316 1.256l1.078 2.874c.327.874.01 1.858-.767 2.376L5.25 9.75c2.241 3.745 5.245 6.76 9 9l2.116-3.174a2.008 2.008 0 0 1 2.376-.767l2.874 1.078a2.008 2.008 0 0 1 1.256 2.316l-.773 3.475a2.008 2.008 0 0 1-1.96 1.572h-4.661c-.32 0-.636-.076-.92-.223C8.894 20.073 3.938 15.122.975 9.441a2.015 2.015 0 0 1-.223-.92C.75 7.567.75 5.382.75 3.862z" />
        <path
          d="M.75 3.86c0-.94.654-1.755 1.572-1.96 1.042-.23 2.408-.534 3.475-.772a2.009 2.009 0 0 1 2.316 1.256l1.078 2.874c.327.874.01 1.858-.767 2.376L5.25 9.75c2.241 3.745 5.245 6.76 9 9l2.116-3.174a2.008 2.008 0 0 1 2.376-.767l2.874 1.078a2.008 2.008 0 0 1 1.256 2.316l-.773 3.475a2.008 2.008 0 0 1-1.96 1.572h-4.661c-.32 0-.636-.076-.92-.223C8.894 20.073 3.938 15.122.975 9.441a2.015 2.015 0 0 1-.223-.92C.75 7.567.75 5.382.75 3.862z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

PhoneFillIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#969696',
};

export default PhoneFillIcon;
