import React from 'react';

import { CaretDownIcon } from 'icons';
import colors from 'styles/colors.constants';
import { formatTickets } from 'utils/format';

import styles from './SeatSelector.module.scss';

interface SeatSelectorProps {
  seatCount: number;
  ticketQuantities: number[];
  handleSeatsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SeatSelector = ({
  seatCount,
  ticketQuantities = [],
  handleSeatsChange,
}: SeatSelectorProps) => {
  return (
    <div className={styles['seat-selector']} data-cy="seat-selector-container">
      <CaretDownIcon fill={colors.white} />
      <select value={seatCount} onChange={handleSeatsChange}>
        {ticketQuantities.length > 0 &&
          ticketQuantities.map((seat) => (
            <option value={seat} key={seat}>
              {formatTickets(seat)}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SeatSelector;
