import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { OG_TYPES } from 'components/Head/constants';
import HeadDescription from 'components/Head/Description';
import HeadOGType from 'components/Head/HeadOGType';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import Image from 'components/Image/Image';
import { postDataToJSONLD } from 'components/JsonLD/helpers';
import JsonLD from 'components/JsonLD/JsonLD';
import { redirect } from 'helpers/RedirectHelper';
import BlogPost from 'models/Blog/Post';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import {
  getModifiedPostSlug,
  isMigratedPost,
} from 'store/modules/blog/constants';
import { fetchPostBySlug } from 'store/modules/blog/posts/actions';
import { postBySlugSelector } from 'store/modules/blog/posts/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import styles from './Post.module.scss';

@TrackPageView(({ slug }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.BLOG_POST(slug),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.BLOG_POST(),
}))
class Post extends Component {
  componentDidMount() {
    const { post, slug } = this.props;

    if (isMigratedPost(slug)) {
      return this.props.redirect(`/blog/${getModifiedPostSlug(slug)}`);
    }

    if (!post.isValid()) {
      this.props.redirect('/blog');
    }
  }

  renderMeta() {
    const { metaDescription, seoTitle, featuredImage } = this.props.post;
    return (
      <div>
        {!!featuredImage && <HeadImage src={featuredImage} />}
        <HeadTitle title={seoTitle} />
        <HeadDescription description={metaDescription} />
      </div>
    );
  }

  renderPostMeta() {
    const { post } = this.props;
    const {
      author: {
        first_name: firstName,
        last_name: lastName,
        profile_image: profileImage,
      },
    } = post;

    return (
      <div className={styles['post-meta']}>
        <HeadOGType type={OG_TYPES.ARTICLE} />
        <div className={styles['profile-image']}>
          <Image src={profileImage} responsive />
        </div>
        <div className={styles['post-info']}>
          <div
            className={styles['author-info']}
          >{`${firstName} ${lastName}`}</div>
          <div className={styles['publish-date']}>
            {`Published: ${post.getPublishedDate()}`}
          </div>
        </div>
      </div>
    );
  }

  renderFeaturedImage() {
    const { featuredImage } = this.props.post;

    if (!featuredImage) {
      return null;
    }

    return (
      <div className={styles['featured-image']}>
        <div className={styles['bottom-container']}>
          {this.renderPostMeta()}
        </div>
        <Image src={featuredImage} responsive />
      </div>
    );
  }

  renderTitleAndSummary() {
    const { title } = this.props.post;

    return (
      <div className={styles['title-container']}>
        <h1>{title}</h1>
      </div>
    );
  }

  render() {
    const { body } = this.props.post;

    /* eslint-disable react/no-danger */
    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          className: styles.header,
          showHamburger: true,
        }}
        className={styles['blog-container']}
      >
        {this.renderMeta()}
        <div className={styles['post-body']}>
          {this.renderFeaturedImage()}
          <div className={styles['post-content']}>
            {this.renderTitleAndSummary()}
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
          <JsonLD json={postDataToJSONLD(this.props.post)} />
        </div>
      </GTContainer>
    );
  }
}

Post.propTypes = {
  post: PropTypes.instanceOf(BlogPost),
  slug: PropTypes.string,
  redirect: PropTypes.func,
};

const mapStateToProps = (state, { params: { slug } }) => ({
  post: postBySlugSelector(state, slug),
  slug,
});

const ConnectedPost = connect(mapStateToProps, { redirect })(Post);

export default withDataLoader(ConnectedPost, {
  promise: async ({ store, params }) => {
    await store.dispatch(fetchMetros());

    if (params.slug) {
      await store.dispatch(fetchPostBySlug(params.slug));
    }
  },
});
