export const FETCH_PERFORMER_CONTENT = 'FETCH_PERFORMER_CONTENT';
export const FETCH_PERFORMER_CONTENT_FAIL = 'FETCH_PERFORMER_CONTENT_FAIL';
export const FETCH_PERFORMER_CONTENT_SUCCESS =
  'FETCH_PERFORMER_CONTENT_SUCCESS';

export const fetchPerformerContentBySlug = (slug) => (dispatch) =>
  dispatch({
    types: [
      FETCH_PERFORMER_CONTENT,
      FETCH_PERFORMER_CONTENT_SUCCESS,
      FETCH_PERFORMER_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('performers', slug),
  });
