import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CloseLineIcon from 'icons/CloseLineIcon';
import { fetchGlobalBannerContent } from 'store/modules/content/global/actions';
import { globalBannerContentSelector } from 'store/modules/content/global/selectors';
import { userPreferenceHideAnnouncementBarSelector } from 'store/modules/userPreference/user.preference.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';

import styles from './CustomNotification.module.scss';

/**
 * Template for a custom notification on the top of the site where the content is populated
 * via ButterCMS. If there is no content from the CMS, this component will not render anything.
 * If there is content from the CMS (global page), it will render the HTML from that page's
 * WYSIWYG editor. This component also has an X to dismiss the banner which stores the preference
 * in the user's preferences where things like the user's selected metro and preferred sort type
 * are stored.
 *
 * https://buttercms.com/pages/page/292359/
 */
const CustomNotification = ({
  globalBannerContent,
  isHidden,
  fetchBannerContent,
  updateUserPreferences,
  isEventPage,
}) => {
  // Don't show the banner if the user's preference has it hidden or if it's an event page.
  const [isBannerHidden, setIsBannerHidden] = useState(isHidden || isEventPage);

  /**
   * fetchBannerContent() is mapped to props from the mapDispatchToProps call at the
   * end of this file. Since this is an asynchronous call, we call useEffect() here with
   * fetchBannerContent as a dependency and globalBannerContent as the trigger for calling
   * the effect. By following this structure, we will cause a re-render when the props
   * for globalBannerContent after the dispatch goes through where we also update the global
   * state. This lets us have an asynchronous dispatch map to props like the asyncConnect
   * calls do, but without a decorator pattern and not having to rely on that library.
   */
  useEffect(() => {
    const fetchData = async () => {
      await fetchBannerContent();
    };

    if (!isBannerHidden && !globalBannerContent) {
      fetchData();
    }
  }, [fetchBannerContent, globalBannerContent, isBannerHidden]);

  const onClick = () => {
    updateUserPreferences({ hideAnnouncementBar: true });
    setIsBannerHidden(true);
  };

  // Check to see if there is any content to display and that the banner is not already hidden.
  if (isBannerHidden || !globalBannerContent) return null;

  return (
    <div
      className={classNames(styles['custom-container'], 'custom-notification')}
    >
      <div
        className={styles['custom-message']}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: globalBannerContent }}
      />
      <button
        className={styles['custom-close']}
        type="button"
        onClick={onClick}
      >
        <CloseLineIcon />
      </button>
    </div>
  );
};

CustomNotification.propTypes = {
  globalBannerContent: PropTypes.string,
  isHidden: PropTypes.bool,
  fetchBannerContent: PropTypes.func.isRequired,
  updateUserPreferences: PropTypes.func.isRequired,
  isEventPage: PropTypes.bool.isRequired,
};

// Works like @connect to map selectors to props for the component.
const mapStateToProps = (state) => ({
  isHidden: userPreferenceHideAnnouncementBarSelector(state),
  globalBannerContent: globalBannerContentSelector(state),
});

/**
 * Works like asyncConnect in combination with the useEffect to add a
 * dispatch to the props. Note that the mapped function name is different
 * from the dispatch function to prevent collision with the imported function.
 */
const mapDispatchToProps = (dispatch) => ({
  fetchBannerContent: () => dispatch(fetchGlobalBannerContent()),
  updateUserPreferences: (pref) => dispatch(updateUserPreference(pref)),
});

// Same thing as @connect((state) => {}, {}), but just separated into 2 arguments.
export default connect(mapStateToProps, mapDispatchToProps)(CustomNotification);
