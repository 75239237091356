import React, { createContext, useContext } from 'react';

import { FullEvent, Listing } from 'models';

interface EventContextType {
  fullEvent: FullEvent;
  listings?: Listing[];
  activeListing?: Listing;
  carouselListings?: Listing[];
}

export interface EventProviderProps extends EventContextType {
  children: React.ReactNode;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

function EventProvider({
  children,
  fullEvent,
  listings = [],
  activeListing,
  carouselListings = [],
}: EventProviderProps) {
  const contextValue = {
    fullEvent,
    listings,
    activeListing,
    carouselListings,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
}

function useEvent() {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error('useEvent must be used within an EventProvider');
  }
  return context;
}

export { EventProvider, useEvent };
