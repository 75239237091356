import { CATEGORY_GROUP_CONFIG } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { PERFORMERS_URLS } from 'store/modules/performers/performers.helpers';
import { getContrastColor } from 'utils/colors';
import { formatUrl } from 'utils/format';
import { titleCase } from 'utils/strings/title-case';

export default class Performer {
  constructor(performer = {}, searchSessionId) {
    this.abbrev = performer.abbrev;
    this.category = performer.category;
    this.categoryGroup = performer.category_group;
    this.contrastColor = performer.contrast_color;
    this.displayType = performer.display_type;
    this.id = performer.id;
    this.genres = performer.genres;
    this.heroImageUrl = performer.hero_image_url;
    this.mediumName = performer.medium_name;
    this.name = performer.name;
    this.primaryColor = performer.primary_color;
    this.shortName = performer.short_name;
    this.slug = performer.slug;
    this.algoliaFields = {
      queryId: performer.meta?.query_id,
      resultPosition: performer.meta?.result_position,
      searchIndex: performer.meta?.search_index,
      searchSessionId: searchSessionId,
    };
    this.logoImageUrl = performer.logo_image_url;
    this.logoBgColor = performer.logo_bg_color;

    if (this.logoBgColor) {
      this.logoBgColor = `#${this.logoBgColor.slice(0, 6)}`;
    }

    /* Custom Properties */
    if (this.primaryColor) {
      this.primaryColor = `#${this.primaryColor.slice(0, 6)}`;
      this.contrastColor = getContrastColor(this.primaryColor);
    }
    this.isSportsCategoryGroup =
      this.categoryGroup === CATEGORY_GROUP_CONFIG.SPORT.id;
    this.type = this.isSportsCategoryGroup ? 'team' : 'performer';
  }

  getPath() {
    return `/${formatUrl(`${this.name} tickets`)}/performers/${this.slug}`;
  }

  getMatchupPath(matchupPerformer) {
    return `/${formatUrl(
      `${this.shortName} ${matchupPerformer.shortName} tickets`
    )}/performers/${this.slug}/${matchupPerformer.slug}`;
  }

  getParkingPath(matchupPerformer) {
    return `${matchupPerformer ? this.getMatchupPath(matchupPerformer) : this.getPath()}/parking`;
  }

  getBreadcrumbConfig() {
    return {
      url: this.getPath(),
      name: this.name,
    };
  }

  getPerformerGenre() {
    const genre = this.genres.find((genre) => genre.primary === true);
    return genre?.slug;
  }

  getImageOptions() {
    const alt = `${this.name} Tickets`;
    let seoPrefix = this.categoryGroup ? formatUrl(this.categoryGroup) : '';
    if (seoPrefix) {
      if (this.category) {
        seoPrefix = `${seoPrefix}/${formatUrl(this.category)}`;
      }
    } else {
      seoPrefix = this.category ? formatUrl(this.category) : '';
    }
    return {
      seoPrefix,
      seoPostfix: formatUrl(this.name),
      alt,
      src: this.heroImageUrl,
    };
  }

  getHeadTitle() {
    const matchingUrl = PERFORMERS_URLS[this.slug];
    const title = matchingUrl?.title || this.name;
    const emoji = matchingUrl?.emoji ? ` ${matchingUrl?.emoji}` : '';
    return `Cheap ${titleCase(title)} Tickets${emoji}`;
  }

  getMatchupHeadTitle(matchupPerformer) {
    return `${this.name} vs. ${matchupPerformer.name} Tickets`;
  }

  getHeadDescription() {
    let scheduleString = 'schedules';

    if (this.categoryGroup === CATEGORY_GROUP_CONFIG.CONCERT.id) {
      scheduleString = 'tour dates';
    }

    const matchingUrl = PERFORMERS_URLS[this.slug];
    const title = matchingUrl?.shortTitle || matchingUrl?.title || this.name;
    const desc = matchingUrl?.desc || this.shortName || this.name;

    return `See ${title} ticket prices and ${scheduleString}, and score cheap last-minute ${desc} tickets. Best Price Guarantee! 100% Authentic Tickets.`;
  }

  getMatchupHeadDescription(matchupPerformer) {
    return (
      `Score yourself the cheapest ${this.shortName} vs. ${matchupPerformer.shortName} tickets available` +
      ` with a 100% safe online purchase! Here you can find the full ${this.category.toUpperCase()} rivalry dates & schedules.`
    );
  }
}
