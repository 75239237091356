import React from 'react';
import PropTypes from 'prop-types';

import 'external-svg-loader';

import styles from './FeaturedListingsTag.module.scss';

const FeaturedListingsTag = ({ title = '', color = '', icon = '' }) => {
  return (
    <div
      className={styles['featured-listings-tag']}
      style={{ borderColor: color }}
      data-cy={`featured-listing-tag-${title.replace(/\s+/g, '')}`}
    >
      <svg data-src={icon} fill={color} width="16" height="16" />
      <div className={styles['featured-listings-text']}>{title}</div>
    </div>
  );
};

FeaturedListingsTag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
};

export default FeaturedListingsTag;
