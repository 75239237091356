import React from 'react';

import {
  DataLoader,
  JsonData,
  LoaderUtils,
  RouteComponentWithLoader,
} from './types';

/**
 * Get data loaders from a list of route components. The route component are
 * provided by the react-router `routerProps.components` property.
 */
export function getRouteLoaders<P extends object, D extends JsonData | void>(
  components: (
    | React.ComponentType<P>
    | RouteComponentWithLoader<P, D>
    | undefined
  )[]
) {
  return components.reduce((loaders, component) => {
    if (component && 'dataLoader' in component && component.dataLoader) {
      loaders.push(component.dataLoader);
    }
    return loaders;
  }, [] as DataLoader<D>[]);
}

/**
 * Load data for a route using the provided data loaders.
 */
export async function loadRouteData(
  dataLoaders: DataLoader<JsonData | void>[],
  loaderUtils: LoaderUtils
) {
  if (!dataLoaders.length) {
    return {};
  }

  const loaderResults = await Promise.allSettled(
    dataLoaders.map(async (dataLoader) => ({
      key: dataLoader.key,
      data: await dataLoader.promise(loaderUtils),
    }))
  );

  return loaderResults.reduce<Record<string, JsonData>>((routeData, result) => {
    if (result.status === 'fulfilled') {
      const { key, data } = result.value || {};

      if (key && data) {
        routeData[key] = data;
      }
    } else if (result.status === 'rejected') {
      console.error('Failed to load data', result.reason);
    }

    return routeData;
  }, {});
}
