import React, { Component } from 'react';
import { withDataLoader } from 'contexts/LoaderContext';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import HeadDescription from 'components/Head/Description';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import BenefitsAndPerks from './components/BenefitsAndPerks';
import CareersHero from './components/CareersHero';
import EmployeeReviews from './components/EmployeeReviews';
import OpenRoles from './components/OpenRoles';
import OurCompany from './components/OurCompany';
import SubNavigation from './components/SubNavigation';
import { NAVIGATION_SECTIONS } from './careers.constants';

import styles from './Careers.module.scss';

@TrackPageView(() => ({
  pageType: View.PAGE_TYPES.STATIC_PAGE('careers'),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.STATIC_PAGE('careers'),
}))
class Careers extends Component {
  constructor(props) {
    super(props);

    this.ourCompanyRef = React.createRef();
    this.benefitsAndPerksRef = React.createRef();
    this.openRolesRef = React.createRef();
  }

  renderMeta() {
    return (
      <div>
        <HeadTitle title="Careers" />
        <HeadDescription description="We're hiring! Do the best work of your career at Gametime. Browse open roles in engineering, product, operations, marketing, and more." />
        <HeadImage src="https://assets.gametime.co/static_pages/careers/sizzle-md.jpg" />
      </div>
    );
  }

  scrollToSection(ref) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  /**
   * Scrolls the user to the specified ref of the page when clicking on a button from the
   * navigation buttons underneath the hero on the page.
   *
   * @param {String} section the section that the page should scroll to
   */
  handleNavigationClick = (section) => {
    switch (section) {
      case NAVIGATION_SECTIONS.ourCompany:
        this.scrollToSection(this.ourCompanyRef);
        break;
      case NAVIGATION_SECTIONS.benefitsAndPerks:
        this.scrollToSection(this.benefitsAndPerksRef);
        break;
      case NAVIGATION_SECTIONS.openPositions:
        this.scrollToSection(this.openRolesRef);
        break;
      default:
        break;
    }
  };

  renderContent() {
    return (
      <div className={styles['careers-content']}>
        <CareersHero onClick={this.handleNavigationClick} />
        <SubNavigation onClick={this.handleNavigationClick} />
        <OurCompany ref={this.ourCompanyRef} />
        <BenefitsAndPerks ref={this.benefitsAndPerksRef} />
        <EmployeeReviews />
        <OpenRoles ref={this.openRolesRef} />
      </div>
    );
  }

  render() {
    return (
      <GTContainer
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
        className={styles['careers-container']}
      >
        {this.renderMeta()}
        {this.renderContent()}
      </GTContainer>
    );
  }
}

export default withDataLoader(Careers, {
  promise: async ({ store }) => {
    await store.dispatch(fetchMetros());
  },
});
