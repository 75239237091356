import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import GTAnimationModal from 'components/GTAnimationModal/GTAnimationModal';
import UserPromoCodeForm from 'components/UserPromoCodeForm/UserPromoCodeForm';

import styles from './PromoCodeModal.module.scss';

const PromoCodeModal = ({
  isOpen = false,
  onLoading,
  onSuccess,
  onClose,
  errorMessage = '',
}) => {
  return (
    <GTAnimationModal
      className="promo-code-modal"
      show={isOpen}
      onHide={onClose}
      headerTitle="Add Promo Code"
      isDarkTheme
    >
      <div className={classNames(styles['modal-body'])}>
        <UserPromoCodeForm
          onLoading={onLoading}
          onSuccess={onSuccess}
          errorMessage={errorMessage}
        />
      </div>
    </GTAnimationModal>
  );
};

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default PromoCodeModal;
