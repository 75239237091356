import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import {
  AcceptTicketTransferClickTracker,
  Click,
  ClickTracker,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import AppBadgesRow from 'components/AppBadgesRow/AppBadgesRow';
import SimpleButton, {
  TYPES as BUTTON_TYPES,
} from 'components/Buttons/SimpleButton';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_CAMPAIGNS } from 'components/DeepLink/DeepLink.constants';
import {
  getDeliveryDisplayProps,
  IN_HAND_DATE_REPLACEMENT,
  TRANSFER_EMAIL_REPLACEMENT,
  TRANSFER_TYPE_REPLACEMENT,
  USER_EMAIL_REPLACEMENT,
} from 'components/DeliveryFormat/delivery.format.constants';
import GetAppPromo from 'components/GetAppPromo/GetAppPromo';
import GTSurveyModal from 'components/GTSurveyModal/GTSurveyModal';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import Link from 'components/Link/Link';
import {
  DetailBetMGMOffer,
  DetailConfirmationMessageCard,
  DetailDeliveryCard,
  DetailEventCard,
  DetailInsuranceCard,
  DetailListingCard,
  DetailParkingUpsell,
  DetailPriceSummaryCard,
  DetailTicketsCard,
  DetailVenueCard,
} from 'components/OrderDetail';
import DetailSubHead from 'components/OrderDetail/DetailSubHead';
import TransferLink from 'components/TransferLink/TransferLink';
import { selectHowDidYouHearAboutUsSurveyEnabled } from 'featureFlags';
import { radiusSaleConfirmation } from 'helpers/Radius/helpers';
import {
  getInsuranceTotalPrice,
  getIsInsuranceOptInStatus,
} from 'pages/Checkout/components/InsuranceOptions/InsuranceOptions.utils';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import ListingsMapViewLight from 'pages/Event/components/ListingsMapView/OrderPage/ListingsMapViewLight';
import { getCurrencyPrefix, isDefaultAllInState } from 'pages/Event/helpers';
import {
  DELIVERY_FORMATS,
  deliveryFormatForPurchase,
} from 'store/datatypes/DELIVERY_FORMATS';
import { DELIVERY_TYPES } from 'store/datatypes/DELIVERY_TYPES';
import { TRANSFER_TYPES } from 'store/datatypes/TRANSFER_TYPES';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import { isAllInPriceSelector } from 'store/modules/app/app.ui';
import { fetchFullEventById } from 'store/modules/data/FullEvents/actions';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { fetchUpsellEvents } from 'store/modules/data/UpsellEvents/actions';
import { selectUpsellEventsByEventId } from 'store/modules/data/UpsellEvents/selectors';
import { locationSelector } from 'store/modules/location';
import {
  fetchDisclosures,
  fetchMetros,
} from 'store/modules/resources/resource.actions';
import {
  allDisclosuresSelector,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { fetchUserExternalAccount } from 'store/modules/user/actions';
import { getExternalAccountType } from 'store/modules/user/constants';
import {
  selectUserDetails,
  selectUserExternalAccount,
} from 'store/modules/user/user.selectors';
import { userPreferencePostPurchaseSurveySelector } from 'store/modules/userPreference/user.preference.selectors';
import { fetchCompleteUserPurchases } from 'store/modules/userPurchases/actions';
import {
  isFirstPurchaseSelector,
  userCompletePurchaseSelector,
} from 'store/modules/userPurchases/userPurchases.selectors';
import {
  extendDefaultBranchState,
  generateBranchLink,
  getBranchUrl,
} from 'utils/branchLink';
import { isSuperBowl } from 'utils/superBowl';

import ConfirmationHeader from './components/ConfirmationHeader/ConfirmationHeader';
import UpsellSection from './components/UpsellSection/UpsellSection';
import { ORDER_STEP_STATUS } from './constants';
import {
  areNonTransferTicketsReady,
  getBetMGMEligibility,
  getOrderStatus,
  getPerformerMessage,
} from './helpers';

import styles from './Order.module.scss';

const mapStateToProps = (
  state,
  {
    params: { transactionId },
    location: {
      query: { confirm },
    },
    appContext,
  }
) => {
  const user = selectUserDetails(state);
  if (!user) {
    return {};
  }

  const completePurchase = userCompletePurchaseSelector(state, transactionId);
  if (!completePurchase) {
    return { user };
  }

  // allDisclosures is a dictionary of every disclosure we support so that we can enrich the disclosures coming with listings
  const allDisclosures = allDisclosuresSelector(state);
  const externalAccount = selectUserExternalAccount(state);

  const completePurchaseSteps = completePurchase.steps;

  const showTransferTicketView =
    completePurchase.delivery_info?.delivery_type === DELIVERY_TYPES.mobile;

  const isHardDeliveryType =
    completePurchase.delivery_info?.delivery_type === DELIVERY_TYPES.hard;

  const isLocalPickup =
    completePurchase.delivery_info?.delivery_type ===
    DELIVERY_TYPES.local_pickup;

  const acceptanceLinks =
    showTransferTicketView && completePurchase.delivery_info.transfer_urls
      ? completePurchase.delivery_info.transfer_urls
      : undefined;

  const fullEvent = selectFullEventById(state, completePurchase.event_id);
  const deliveryFormat = deliveryFormatForPurchase(completePurchase);

  const upsellEvents = selectUpsellEventsByEventId(
    state,
    completePurchase.event_id
  );

  const relatedEvents = fullEvent.getRelatedEvents();
  const { parking } = relatedEvents;
  let parkingPassEventUrl = '';

  if (parking && parking.length) {
    const [relatedParkingEventId] = parking;
    const parkingEvent = selectFullEventById(state, relatedParkingEventId);

    parkingPassEventUrl = parkingEvent.getPath();
  }

  const orderStatus = getOrderStatus(completePurchaseSteps);
  const isPending = orderStatus === ORDER_STEP_STATUS.PENDING;

  const { newUserCookie } = appConfigSelector(state);

  const isHowDidYouHearAboutUsSurveyEnabled =
    selectHowDidYouHearAboutUsSurveyEnabled(state);
  const isFirstPurchase = isFirstPurchaseSelector(state);
  const isSurveyResolved = userPreferencePostPurchaseSurveySelector(state);

  const showPostPurchaseSurvey =
    isHowDidYouHearAboutUsSurveyEnabled && isFirstPurchase && !isSurveyResolved;

  return {
    user,
    completePurchase,
    fullEvent,
    deliveryDisplayProps: getDeliveryDisplayProps(deliveryFormat),
    deliveryFormat,
    upsellEvents,
    isPending,
    orderStatus,
    showConfirmation: Boolean(confirm),
    showTransferTicketView,
    isHardDeliveryType,
    acceptanceLinks,
    newUserCookie,
    allDisclosures,
    parkingPassEventUrl,
    isAllInPriceActive: isAllInPriceSelector(state),
    showPostPurchaseSurvey,
    selectedMetro: (
      selectUserMetro(state) ||
      selectClosestMetro(state, appContext.state.ipGeoLocation)
    )?.name,
    isLocalPickup,
    externalAccount,
    branchData: extendDefaultBranchState(
      state,
      DEEPLINK_CAMPAIGNS.ORDER_CONFIRMATION
    ),
  };
};

@TrackPageView(({ completePurchase, parkingPassEventUrl }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.ORDER_CONFIRMATION(
    _get(completePurchase, 'id'),
    parkingPassEventUrl !== '',
    _get(completePurchase, 'charge.promo_code')
  ),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.ORDER_CONFIRMATION(),
}))
class OrderPage extends Component {
  static propTypes = {
    user: PropTypes.object,
    completePurchase: PropTypes.object,
    fullEvent: PropTypes.object,
    deliveryDisplayProps: PropTypes.object,
    deliveryFormat: PropTypes.oneOf(Object.values(DELIVERY_FORMATS)),
    upsellEvents: PropTypes.array,
    isPending: PropTypes.bool,
    orderStatus: PropTypes.string,
    showConfirmation: PropTypes.bool,
    acceptanceLinks: PropTypes.array,
    showTransferTicketView: PropTypes.bool,
    isHardDeliveryType: PropTypes.bool,
    newUserCookie: PropTypes.bool,
    allDisclosures: PropTypes.object,
    parkingPassEventUrl: PropTypes.string,
    isAllInPriceActive: PropTypes.bool,
    showPostPurchaseSurvey: PropTypes.bool,
    selectedMetro: PropTypes.string,
    isLocalPickup: PropTypes.bool,
    externalAccount: PropTypes.shape({
      email: PropTypes.string,
    }),
    branchData: PropTypes.object,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      surveyModalOpen: false,
      openModalTimeout: null,
      branchLink: null,
    };

    this.closeSurveyModal = this.closeSurveyModal.bind(this);
    this.openSurveyModal = this.openSurveyModal.bind(this);
    this.onHeaderInteraction = this.onHeaderInteraction.bind(this);
    this.showRegulatoryPricing = this.showRegulatoryPricing.bind(this);
  }

  closeSurveyModal() {
    this.setState({
      surveyModalOpen: false,
    });
  }

  openSurveyModal() {
    this.setState({
      surveyModalOpen: true,
    });
  }

  componentDidMount() {
    const {
      fullEvent,
      completePurchase,
      user,
      newUserCookie,
      showPostPurchaseSurvey,
      showConfirmation,
      branchData,
    } = this.props;

    const { surveyModalOpen } = this.state;

    if (typeof window !== 'undefined') {
      // Hides open survey modal if user navigates
      window.onpopstate = () => {
        if (surveyModalOpen) this.closeSurveyModal();
      };

      radiusSaleConfirmation({
        category: fullEvent.event.category,
        orderId: completePurchase.id,
        charge: completePurchase.charge,
        tickets: completePurchase.tickets,
        isNewUser: newUserCookie,
        user,
      });
    }

    if (showPostPurchaseSurvey && showConfirmation) {
      this.setState({
        openModalTimeout: setTimeout(() => {
          this.openSurveyModal();
        }, 3000),
      });
    }

    const newBranchData = {
      ...branchData,
      data: {
        ...branchData.data,
        $canonical_url: getBranchUrl(
          `/profile/my-tickets/${completePurchase.id}`
        ),
      },
    };

    generateBranchLink(newBranchData, (error, link) => {
      if (error) {
        console.error(error);
        return;
      }
      this.setState({
        branchLink: link,
      });
    });
  }

  onHeaderInteraction() {
    const { openModalTimeout } = this.state;
    if (openModalTimeout) {
      clearTimeout(openModalTimeout);
    }
  }

  shouldComponentUpdate(nextProps) {
    /**
     * Prevents the order confirmation from re-rendering if the user logs out
     * on the page. If the user logs out, they will not have a completePurchase
     * available in the props and thus rendering this page without that would be
     * useless. If the user refreshes on this page, then the routing redirects
     * would take place so this function will only run if the user logs out from
     * this page since that's the only scenario where the completePurchase is
     * undefined.
     */
    return nextProps.completePurchase !== undefined;
  }

  getDeliveryDisplayText = () => {
    const {
      deliveryDisplayProps,
      completePurchase,
      acceptanceLinks,
      deliveryFormat,
      externalAccount,
      user,
    } = this.props;

    if (
      completePurchase.delivery_info.delivery_type === DELIVERY_TYPES.mobile
    ) {
      let transferType = completePurchase.delivery_info.transfer_type;
      transferType = !transferType
        ? TRANSFER_TYPES.TM
        : TRANSFER_TYPES[transferType.toUpperCase()];
      const transferEmail = completePurchase.delivery_info.transfer_email;

      let message;
      if (acceptanceLinks && acceptanceLinks.length > 0) {
        message = deliveryDisplayProps.acceptanceDeliveryText;
      } else {
        const transferComplete = completePurchase.steps.some(
          (step) => step.status === 'completed' && step.done
        );
        message = transferComplete
          ? deliveryDisplayProps.postDeliveryText
          : deliveryDisplayProps.preDeliveryText;
      }

      message = message.replace(
        new RegExp(TRANSFER_TYPE_REPLACEMENT, 'g'),
        transferType
      );
      message = message.replace(
        new RegExp(TRANSFER_EMAIL_REPLACEMENT, 'g'),
        transferEmail
      );
      message = message.replace(
        new RegExp(IN_HAND_DATE_REPLACEMENT, 'g'),
        completePurchase.delivery_info?.in_hand_date
      );

      return message;
    }

    if (deliveryFormat === DELIVERY_FORMATS.MLB_ORDER) {
      return deliveryDisplayProps.text
        .replace(
          new RegExp(TRANSFER_EMAIL_REPLACEMENT, 'g'),
          externalAccount?.email
        )
        .replace(new RegExp(USER_EMAIL_REPLACEMENT, 'g'), user.email);
    }
    return deliveryDisplayProps.text;
  };

  getOrderedListItems = () => {
    const { deliveryDisplayProps, deliveryFormat } = this.props;
    if (deliveryFormat !== DELIVERY_FORMATS.MLB_ORDER) return [];

    return deliveryDisplayProps.instructions.map((item) => {
      if (item.dataKey) {
        const email = this.props[item.dataKey]?.email || '';
        Object.assign(item, { email });
      }
      return item;
    });
  };

  getDeliveryDisplayLinkText = () => {
    const { completePurchase, acceptanceLinks, deliveryFormat } = this.props;
    if (deliveryFormat === DELIVERY_FORMATS.MLB_ORDER) {
      return (
        <DeepLink
          campaign={DEEPLINK_CAMPAIGNS.ORDER_CONFIRMATION}
          href={this.state.branchLink}
          preventLinkFetch
        >
          <SimpleButton
            fullWidth
            className={styles['mlb-tickets-button']}
            type={BUTTON_TYPES.GREEN_SOLID}
            text="get the app"
            clickTracker={
              new Click(
                new ClickTracker()
                  .interaction(Click.INTERACTIONS.ACCESS_TICKETS())
                  .sourcePageType(Click.SOURCE_PAGE_TYPES.ORDER_CONFIRMATION())
                  .json()
              )
            }
          />
        </DeepLink>
      );
    }
    if (
      completePurchase.delivery_info.delivery_type === DELIVERY_TYPES.mobile
    ) {
      if (acceptanceLinks?.length === 1) {
        return (
          <div className={styles['acceptance-button-section']}>
            <SimpleLinkButton
              className={styles['acceptance-button']}
              type={BUTTON_TYPES.GREEN_SOLID}
              text="ACCEPT TICKETS"
              target="_blank"
              rel="noopener noreferrer"
              href={acceptanceLinks[0]}
              clickTracker={new AcceptTicketTransferClickTracker().interaction(
                Click.INTERACTIONS.ACCEPT_TICKETS()
              )}
            />
          </div>
        );
      }

      if (acceptanceLinks?.length > 1) {
        return (
          <div className={styles['transfer-links']}>
            {acceptanceLinks.slice(0, 20).map((link, index) => (
              <TransferLink key={link} number={index + 1} link={link} />
            ))}
          </div>
        );
      }

      const transferComplete = completePurchase.steps.some(
        (step) => step.status === 'completed' && step.done
      );

      if (!transferComplete) {
        return (
          <div className={styles['extended-link-text']}>
            {'For more info, visit our '}
            <Link
              href="https://support.gametime.co/en_us/categories/transfer-ticket-questions-HyQpK83nq"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </Link>
            .
          </div>
        );
      }
    }

    return null;
  };

  renderMeta() {
    const { completePurchase } = this.props;
    const orderNumber = completePurchase?.confirmation_number || 'Pending';

    return (
      <div>
        <HeadTitle title={`Order #${orderNumber}`} />
      </div>
    );
  }

  renderSidebar() {
    const {
      completePurchase,
      fullEvent,
      deliveryDisplayProps: {
        confirmationCardTitle,
        orderDetailTitle,
        getAppTitle,
        getAppText,
        postDeliveryTitle,
      },
      deliveryFormat,
      upsellEvents,
      showConfirmation,
      showTransferTicketView,
      isHardDeliveryType,
      user,
      allDisclosures,
      parkingPassEventUrl,
      showPostPurchaseSurvey,
      selectedMetro,
      isPending,
      isLocalPickup,
      orderStatus,
    } = this.props;

    const isSuperBowlPurchase = isSuperBowl(fullEvent.id);
    const { surveyModalOpen } = this.state;

    const allNonTransferTicketsReady = areNonTransferTicketsReady(
      completePurchase.tickets
    );

    const transferComplete = completePurchase.steps.some(
      (step) => step.status === 'completed' && step.done
    );
    const isMlbOrder = deliveryFormat === DELIVERY_FORMATS.MLB_ORDER;
    const ticketCount = completePurchase.tickets.length;
    const seatFee = completePurchase.charge.fees / 100 / ticketCount;
    const salesTax = completePurchase.charge.sales_tax / 100 / ticketCount;
    const prefeePrice =
      (completePurchase.charge.total_amount -
        completePurchase.charge.fees -
        completePurchase.charge.sales_tax) /
      100 /
      ticketCount;
    const promoAmount =
      completePurchase.charge.total_amount -
      completePurchase.charge.discounted_amount;
    const orderNumber =
      completePurchase.confirmation_number || '* please call *';
    let adjustedTotal = completePurchase.charge.total_amount;
    let deliveryDetailsTitle = showConfirmation
      ? confirmationCardTitle
      : orderDetailTitle;
    if (transferComplete && postDeliveryTitle) {
      deliveryDetailsTitle = postDeliveryTitle;
    }
    let confirmationMessage = '';

    if (!isMlbOrder) {
      confirmationMessage = getPerformerMessage(
        fullEvent,
        isPending,
        showConfirmation,
        transferComplete
      );
    }

    const showSuperBowlConfirmation = isSuperBowlPurchase && showConfirmation;
    const showConfirmationHeader = showConfirmation && orderStatus !== null;

    if (promoAmount > 0) {
      adjustedTotal = completePurchase.charge.adjusted_amount;
    }

    const isBetMGMEligible = getBetMGMEligibility(
      fullEvent.venue.state,
      fullEvent.event.category
    );

    const currencyPrefix = getCurrencyPrefix(fullEvent, selectedMetro);
    return (
      <div>
        {showPostPurchaseSurvey && surveyModalOpen && (
          <GTSurveyModal
            transactionId={completePurchase.id}
            onHide={this.closeSurveyModal}
            show
          />
        )}
        {showConfirmationHeader && (
          <ConfirmationHeader orderStatus={orderStatus} />
        )}
        <DetailSubHead text={`Order #${orderNumber}`} />
        <DetailConfirmationMessageCard
          user={user}
          message={confirmationMessage}
          isSuperBowl={showSuperBowlConfirmation}
          isPending={isPending}
          isLocalPickup={isLocalPickup}
        />
        <DetailDeliveryCard
          deliveryFormat={deliveryFormat}
          title={deliveryDetailsTitle}
          text={this.getDeliveryDisplayText()}
          inlineLink={this.getDeliveryDisplayLinkText()}
          orderedList={this.getOrderedListItems()}
          displayExtended={DELIVERY_FORMATS.MOBILE !== deliveryFormat}
        />
        <DetailEventCard
          fullEvent={fullEvent}
          viewUrl={completePurchase.spot.view_url}
        />
        <DetailVenueCard fullEvent={fullEvent} />
        {allNonTransferTicketsReady ? (
          <DetailTicketsCard
            tickets={completePurchase.tickets}
            sectionGroup={completePurchase.spot.section_group}
            section={completePurchase.spot.section}
            row={completePurchase.spot.row}
            primaryPerformerSlug={fullEvent.getPrimaryPerformer().slug}
            eventId={completePurchase.event_id}
            transactionId={completePurchase.id}
            deliveryFormat={deliveryFormat}
            allDisclosures={allDisclosures}
            disclosures={completePurchase.spot.disclosures}
            isHardDeliveryType={isHardDeliveryType}
          />
        ) : (
          <DetailListingCard
            sectionGroup={completePurchase.spot.section_group}
            section={completePurchase.spot.section}
            row={completePurchase.spot.row}
            seatCount={ticketCount}
            allDisclosures={allDisclosures}
            disclosures={completePurchase.spot.disclosures}
          />
        )}
        {!isPending && (
          <DetailPriceSummaryCard
            prefeeSeatPrice={prefeePrice}
            seatFee={seatFee}
            salesTax={salesTax}
            seatCount={ticketCount}
            totalPrice={adjustedTotal / 100}
            promoAmount={promoAmount / 100}
            currencyPrefix={currencyPrefix}
            insurancePrice={
              getIsInsuranceOptInStatus(
                completePurchase.insurance_info?.status
              ) && getInsuranceTotalPrice(completePurchase.insurance_info)
            }
            insuranceBookingStatus={completePurchase.insurance_info?.status}
          />
        )}
        {completePurchase.insurance_info && (
          <DetailInsuranceCard
            insuranceInfo={completePurchase.insurance_info}
          />
        )}
        {isBetMGMEligible && (
          <DetailBetMGMOffer
            eventId={completePurchase.event_id}
            transactionId={completePurchase.id}
          />
        )}
        {parkingPassEventUrl && fullEvent.isValid() && (
          <DetailParkingUpsell
            eventId={completePurchase.event_id}
            transactionId={completePurchase.id}
            eventUrl={parkingPassEventUrl}
          />
        )}
        {!isSuperBowlPurchase && !showTransferTicketView && !isMlbOrder && (
          <GetAppPromo title={getAppTitle} text={getAppText} />
        )}
        {showTransferTicketView && (
          <AppBadgesRow
            campaignType={DEEPLINK_CAMPAIGNS.ORDER_CONFIRMATION}
            messaging="Do more fun things. Get the Gametime app."
          />
        )}
        {!!upsellEvents?.length && (
          <UpsellSection upsellEvents={upsellEvents} />
        )}
      </div>
    );
  }

  showRegulatoryPricing() {
    const { fullEvent } = this.props;
    return isDefaultAllInState(fullEvent.venueState);
  }

  render() {
    const {
      fullEvent,
      appContext: {
        state: { isMobile },
      },
      completePurchase,
      isAllInPriceActive,
      showPostPurchaseSurvey,
    } = this.props;
    let singlePinData = null;

    if (completePurchase !== undefined) {
      const ticketCount = completePurchase.tickets.length;
      const promoAmount =
        completePurchase.charge.total_amount -
        completePurchase.charge.discounted_amount;
      const eachTixTotal = completePurchase.charge.total_amount / ticketCount;
      const eachTixPrefee =
        (completePurchase.charge.total_amount -
          completePurchase.charge.fees -
          completePurchase.charge.sales_tax) /
        ticketCount;
      const eachTixPromo = promoAmount / ticketCount;

      const showRegulatoryPricing = this.showRegulatoryPricing();

      let prefeePrice =
        isAllInPriceActive || showRegulatoryPricing
          ? eachTixTotal
          : eachTixPrefee;

      if (eachTixPromo > 0 && prefeePrice - eachTixPromo >= 0) {
        prefeePrice -= eachTixPromo;
      }

      if (completePurchase.spot && completePurchase.spot.position) {
        singlePinData = {
          mapX: completePurchase.spot.position.x,
          mapY: completePurchase.spot.position.y,
          viewUrl: completePurchase.spot.view_url,
        };
      }
    }

    return (
      <ContainerTemplate
        header={
          <MinimalHeader
            search
            showAccount
            showCategories
            showHamburger
            onHeaderInteraction={
              showPostPurchaseSurvey ? this.onHeaderInteraction : undefined
            }
          />
        }
      >
        {this.renderMeta()}
        <div className={styles['order-page']}>
          <div className={styles.main}>
            <div className={styles.sidebar}>
              <div className={styles['sidebar-content']}>
                {this.renderSidebar()}
              </div>
            </div>
            {!isMobile && (
              <div className={styles['map-section']}>
                <div className={styles['map-view']}>
                  <ListingsMapViewLight
                    singlePinData={singlePinData}
                    mapUrl={fullEvent.event.mapUrl}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

export default withDataLoader(
  withAppContext(connect(mapStateToProps)(OrderPage)),
  {
    promise: async ({
      store: { dispatch, getState },
      params: { transactionId },
      asyncRedirect,
    }) =>
      new Promise((resolve, reject) => {
        let state = getState();
        const user = selectUserDetails(state);
        if (!user) {
          // update for changes to logout method, now on logout this componenent will re-render
          // and if the last action was logout, it will redirect home, but if the user intentionally
          // visits the url: /order/id, it will redirect to /login
          const { pathname } = locationSelector(state);
          const path = pathname === '/' ? '/' : `/login?redirect=${pathname}`;
          asyncRedirect(path);
          return reject(new Error('No user details found'));
        }

        dispatch(
          fetchCompleteUserPurchases({
            user_id: user.id,
            session_token: user.session_token,
          })
        )
          .then(() => {
            state = getState();
            const completePurchase = userCompletePurchaseSelector(
              state,
              transactionId
            );

            if (
              !completePurchase ||
              completePurchase.steps[0]?.status === 'cancelled'
            ) {
              asyncRedirect('/my-tickets');
              return reject(new Error('Incomplete or cancelled purchase'));
            }

            const eventId = completePurchase.event_id;
            const promises = [
              dispatch(fetchMetros()),
              dispatch(fetchDisclosures()),
              dispatch(fetchUpsellEvents({ event_id: eventId })),
              dispatch(fetchFullEventById(eventId)),
            ];

            /**
             * we only try fetching an external account if there isn't
             * currently an externalAccount stored and it is an MLB order
             */
            const ticketType = completePurchase.tickets[0]?.type;
            const externalAccountType =
              ticketType && getExternalAccountType(ticketType);
            const externalAccount = selectUserExternalAccount(state);
            if (externalAccountType && !externalAccount) {
              promises.push(
                dispatch(fetchUserExternalAccount(externalAccountType))
              );
            }

            Promise.all(promises)
              .then(() => {
                // state has changed from previous promises, so reassign
                state = getState();

                /**
                 * Parking passes are a related event so we need to
                 * fetch the full event based off the ID in order
                 * to get the URL for the event.
                 */
                const fullEvent = selectFullEventById(state, eventId);

                if (!fullEvent) return resolve();

                const relatedEvents = fullEvent.getRelatedEvents();

                if (!relatedEvents?.parking?.length) return resolve();

                const [relatedParkingEventId] = relatedEvents.parking;

                dispatch(fetchFullEventById(relatedParkingEventId))
                  .then(resolve)
                  .catch(reject);
              })
              .catch(reject);
          })
          .catch((err) => {
            asyncRedirect('/my-tickets');
            return reject(err);
          });
      }),
  }
);
