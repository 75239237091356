import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import { LoginEvent, SessionStart, withAnalyticsContext } from 'analytics';
import ConnectedAppSpinner from 'components/ConnectedAppSpinner/ConnectedAppSpinner';
import ConnectedAppSubmittingOverlay from 'components/ConnectedAppSubmittingOverlay/ConnectedAppSubmittingOverlay';
import { DEEPLINK_CAMPAIGNS } from 'components/DeepLink/DeepLink.constants';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import { OG_TYPES } from 'components/Head/constants';
import HeadOGType from 'components/Head/HeadOGType';
import HeadRobots from 'components/Head/Robots';
import ScrollToTop from 'components/ScrollToTop';
import config from 'config/config';
import { domain as productionDomain } from 'config/production.config';
import { isAllowedAccess } from 'helpers/IpHelper';
import { redirect } from 'helpers/RedirectHelper';
import { generateBranchLinkByCampaign } from 'store/modules/app/app';
import { appConfigDomainSelector } from 'store/modules/app/app.selectors';
import { locationSelector } from 'store/modules/location';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { incrementSiteVisitCount } from 'store/modules/userPreference/userPreference';

import 'rc-tooltip/assets/bootstrap.css';
import styles from './App.module.scss';

/**
 * Sets user identity for Sentry error reports if user exists, otherwise
 * sets user identity to null.
 */
function setSentryUser(user) {
  if (user && Object.keys(user).length > 0) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  } else {
    Sentry.setUser(null);
  }
}
@withAppContext
@connect(
  (state, props) => {
    const { pathname } = locationSelector(state);

    return {
      pathname,
      isProductionDomain: productionDomain === appConfigDomainSelector(state),
      isAllowedAccess: isAllowedAccess(props.appContext.state.ipGeoLocation),
      user: selectUserDetails(state),
    };
  },
  {
    incrementSiteVisitCount,
    generateBranchLinkByCampaign,
    redirect,
  }
)
@withAnalyticsContext
export default class App extends Component {
  static propTypes = {
    children: PropTypes.node,
    incrementSiteVisitCount: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    generateBranchLinkByCampaign: PropTypes.func.isRequired,
    pathname: PropTypes.string,
    isProductionDomain: PropTypes.bool,
    isAllowedAccess: PropTypes.bool,
    user: PropTypes.object,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
        isTablet: PropTypes.bool.isRequired,
        isIOS: PropTypes.bool,
        isAndroid: PropTypes.bool,
        ipGeoLocation: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
  };

  componentDidMount() {
    if (__CLIENT__) {
      this.props.analyticsContext.track(new SessionStart({}));

      // If user logged in using magic link, track login event
      const { name, attributes } = config.cookiesConfig.MAGIC_LINK_LOGIN;
      const magicLinkLoginResult = Cookies.get(name);
      if (magicLinkLoginResult) {
        this.props.analyticsContext.track(
          new LoginEvent({
            status: magicLinkLoginResult,
            provider: 'magic_link',
          })
        );
        Cookies.remove(name, { path: attributes.path });
      }
    }

    if (!this.props.isAllowedAccess) {
      this.props.redirect('/unavailable');
      this.redirected = true;
      return;
    }

    setSentryUser(this.props.user);

    this.props.incrementSiteVisitCount();
    this.props.generateBranchLinkByCampaign(DEEPLINK_CAMPAIGNS.HEADER);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      setSentryUser(this.props.user);
    }
  }

  render() {
    const {
      pathname,
      isProductionDomain,
      children,
      appContext: {
        state: { isMobile, isIOS, isAndroid },
      },
    } = this.props;

    const dataType = classNames({
      mobile: isMobile,
      ios: isIOS,
      android: isAndroid,
    });

    if (this.redirected) {
      return null;
    }

    return (
      <div className={styles.app}>
        <ScrollToTop />
        <HeadOGType type={OG_TYPES.WEBSITE} />
        <Helmet
          {...config.app.head}
          htmlAttributes={{ 'data-type': dataType }}
        />
        {isProductionDomain && <HeadCanonicalPath path={pathname} />}
        <HeadRobots
          noindex={!isProductionDomain}
          nofollow={!isProductionDomain}
          noarchive={!isProductionDomain}
        />
        {children}
        <ConnectedAppSpinner />
        <ConnectedAppSubmittingOverlay />
      </div>
    );
  }
}
