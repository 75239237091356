import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ClockIcon from 'icons/ClockIcon';
import colors from 'styles/colors.constants';

import styles from './UrgencyMessage.module.scss';

const UrgencyMessage = ({ type, quantity }) => {
  const isListing = type === 'listing';
  let message = '';

  if (quantity === 1) {
    message = isListing
      ? `Only 1 ticket left at this price!`
      : `1 ticket left at your price`;
  } else {
    message = isListing
      ? `Only ${quantity} tickets left at this price!`
      : `${quantity} tickets left at your price`;
  }

  return (
    <div className={classNames(styles['urgency-message'], styles[type])}>
      {isListing && <ClockIcon fill={colors.teal} width={16} height={16} />}
      <h3 className={classNames(styles.content)}>{message}</h3>
    </div>
  );
};

UrgencyMessage.propTypes = {
  type: PropTypes.oneOf(['listing', 'checkout']).isRequired,
  quantity: PropTypes.number,
};

export default UrgencyMessage;
