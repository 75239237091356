import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useBraintreeHostedFields } from 'helpers/Braintree';
import CreditCardBackFillIcon from 'icons/CreditCardBackFillIcon';
import LockIcon from 'icons/LockIcon';
import { PAYMENT_TYPES_CONFIG } from 'store/datatypes/PAYMENT_TYPES';
import colors from 'styles/colors.constants';
import typography from 'styles/typography.constants';

import { BraintreeHostedFieldGroup } from './BraintreeHostedFieldGroup';

import styles from './BraintreeHostedFieldCVVForm.module.scss';

const cvvFieldId = 'cvv-verification';

const hostedFieldsOptions = {
  styles: {
    input: {
      'font-size': '16px',
      'font-family': typography.primaryFontFamily,
      color: colors.white,
    },
  },
  fields: {
    cvv: {
      container: `#${cvvFieldId}`,
      placeholder: 'CVV',
    },
  },
};

function BraintreeHostedFieldCVVForm({ card, dispatch, formId, onSubmit }) {
  const cardConfig = PAYMENT_TYPES_CONFIG[card.card_type];

  const {
    createHostedFields,
    hostedFields,
    fieldErrors,
    setFieldError,
    fieldFocus,
  } = useBraintreeHostedFields(dispatch);

  useEffect(() => {
    createHostedFields(hostedFieldsOptions);
  }, [createHostedFields]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!hostedFields) {
      return;
    }

    if (hostedFields.validate()) {
      onSubmit(hostedFields.tokenize, setFieldError);
    }
  };

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <BraintreeHostedFieldGroup
        label="CVV"
        hint={
          <>
            <LockIcon fill={colors.gray400} size={16} />
            Secured by PayPal
          </>
        }
        fieldId={cvvFieldId}
        isFocused={fieldFocus.cvv}
        error={fieldErrors.cvv}
        startIcon={<CreditCardBackFillIcon />}
        endIcon={
          <>
            {cardConfig.icon}
            <span className={styles['last-four']}>{card.last_four}</span>
          </>
        }
      />
    </form>
  );
}
BraintreeHostedFieldCVVForm.propTypes = {
  card: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect()(BraintreeHostedFieldCVVForm);
