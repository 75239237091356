import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import Link from 'components/Link/Link';
import Spinner, { VARIANTS } from 'components/Spinner/Spinner';

import { ALL_LOCATIONS, ALL_TEAMS } from '../../careers.constants';

import TeamJobPostings from './TeamJobPostings/TeamJobPostings';

import styles from './OpenRoles.module.scss';

const ALL_TEAMS_OPTION = { value: 'all', label: 'All Teams', description: '' };
const ALL_LOCATIONS_OPTION = { value: 'all', label: 'All Offices' };

const OpenRoles = React.forwardRef((_props, ref) => {
  const [currentTeam, setCurrentTeam] = useState(ALL_TEAMS_OPTION);
  const [currentLocation, setCurrentLocation] = useState(ALL_LOCATIONS_OPTION);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [teamJobPostings, setTeamJobPostings] = useState([]);
  const [showResumeLink, setShowResumeLink] = useState(false);

  const processSingleTeam = (data, teamTitle, teamDescription) => {
    return [
      <TeamJobPostings
        key={teamTitle}
        title={teamTitle}
        description={teamDescription}
        postings={data}
        fullWidth
      />,
    ];
  };

  const processTeams = (data) => {
    return data.map((team) => (
      <TeamJobPostings
        key={team.title}
        title={team.title}
        postings={team.postings}
      />
    ));
  };

  const getLeverApiUrl = (teamName, location) => {
    const url = new URL('https://api.lever.co/v0/postings/gametime');

    if (teamName) {
      url.searchParams.append('team', teamName);
    } else {
      url.searchParams.append('group', 'team');
    }

    if (location) {
      url.searchParams.append('location', location);
    }

    url.searchParams.append('mode', 'json');

    return url;
  };

  useEffect(() => {
    const teamName =
      currentTeam.value === ALL_TEAMS_OPTION.value ? null : currentTeam.label;

    const teamDescription = currentTeam.description;

    const location =
      currentLocation.value === ALL_LOCATIONS_OPTION.value
        ? null
        : currentLocation.value;

    const leverApiUrl = getLeverApiUrl(teamName, location);

    setIsLoadingJobs(true);
    fetch(leverApiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newTeamJobPostings = teamName
          ? processSingleTeam(data, teamName, teamDescription)
          : processTeams(data);

        setShowResumeLink(data.length === 0);
        setTeamJobPostings(newTeamJobPostings);
        setIsLoadingJobs(false);
      });
  }, [currentTeam, currentLocation]);

  const handleTeamFilterChange = (selectedTeamOption) => {
    setCurrentTeam(selectedTeamOption);
  };

  const handleLocationFilterChange = (selectedLocationOption) => {
    setCurrentLocation(selectedLocationOption);
  };

  const getTeamOptions = () => {
    const teamOptions = ALL_TEAMS.map((team) => ({
      value: team.name,
      label: team.name,
      description: team.description,
    }));

    teamOptions.unshift(ALL_TEAMS_OPTION);
    return teamOptions;
  };

  const getLocationOptions = () => {
    const locationOptions = ALL_LOCATIONS.map((location) => ({
      value: location,
      label: location,
    }));

    locationOptions.unshift(ALL_LOCATIONS_OPTION);
    return locationOptions;
  };

  return (
    <div className={styles['open-roles-container']} ref={ref}>
      <div className={styles['open-roles']}>
        <h1 className={styles['gametime-h1']}>Open Positions</h1>
        <p>
          We're growing fast and looking for curious, motivated people to join
          us. Learn more about our teams and apply below!
        </p>
        <div className={styles['job-filters']}>
          <Select
            className={styles['job-filters-select']}
            classNamePrefix="job-filter"
            components={{
              IndicatorSeparator: () => null,
            }}
            instanceId="team-filter"
            isSearchable={false}
            onChange={handleTeamFilterChange}
            options={getTeamOptions()}
            value={currentTeam}
          />
          <Select
            className={styles['job-filters-select']}
            classNamePrefix="job-filter"
            components={{
              IndicatorSeparator: () => null,
            }}
            instanceId="location-filter"
            isSearchable={false}
            onChange={handleLocationFilterChange}
            options={getLocationOptions()}
            value={currentLocation}
          />
        </div>
        {isLoadingJobs ? (
          <Spinner
            format={VARIANTS.GAMETIME_GREEN_LIGHT}
            style={{ height: 160, marginTop: 32 }}
          />
        ) : (
          <>
            <section className={styles['container-job-postings']}>
              {teamJobPostings}
            </section>

            {showResumeLink && (
              <Link
                className={styles['resume-link']}
                href={`mailto:talent@gametime.co?subject=${encodeURI(
                  'Interested in working at Gametime'
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Send us your resume!
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default OpenRoles;
