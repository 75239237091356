import React, { useState } from 'react';
import { RadioCard, RadioGroup } from 'ui/Radio';

import GTAnimationModal from 'components/GTAnimationModal/GTAnimationModal';
import Spinner from 'components/Spinner/Spinner';
import { CheckShieldIcon, GroupIcon } from 'icons';
import { UserModel } from 'types';

import CheckoutCard from '../CheckoutCard';

import InsuranceDataCollectionForm from './InsuranceDataCollectionForm';
import { Insurance, UpdateQuoteParams } from './InsuranceOptions.utils';

import styles from './InsuranceOptions.module.scss';

interface InsuranceOptionsProps {
  insurance: Insurance;
  user: UserModel;
}

export default function InsuranceOptions({
  insurance,
  user,
}: InsuranceOptionsProps) {
  const [showDataCollectionModal, setShowDataCollectionModal] = useState(false);

  const handleOptInChange = (isSelected: boolean) => {
    insurance.trackOptInChange(isSelected);

    // show the data collection modal before opting in unless already updated
    if (isSelected && !insurance.quote?.isUpdated) {
      return setShowDataCollectionModal(isSelected);
    }

    insurance.setIsSelected(isSelected);
  };

  const handleSubmit = async (params: UpdateQuoteParams) => {
    return insurance.handleDataCollectionFormSubmit(params).then(() => {
      insurance.setIsSelected(true);
      setShowDataCollectionModal(false);
    });
    // don't catch here, handle errors in the form
  };

  const coverageList = (
    <div className={styles['coverage-list']}>
      <span>You can be covered for:</span>
      <ul>
        <li>
          Illness or injury to you, another ticket holder, or close relative
        </li>
        <li>Public transit delays caused by severe weather</li>
        <li>Involuntary unemployment, jury duty, and more</li>
      </ul>
    </div>
  );

  if (insurance.isLoadingEligibility) {
    return <Spinner />;
  }

  if (!insurance.eligibility?.eligible || !insurance.quote) {
    return null;
  }

  return (
    <>
      <CheckoutCard
        title={
          <span className={styles['title']}>
            <span>Make My Purchase Reimbursable</span>
            <CheckShieldIcon className={styles['icon']} />
          </span>
        }
        description="Reimbursement up to 100% if you can't attend due to illness, injury, involuntary unemployment, and more. See terms for details."
        body={
          <div className={styles['insurance-options']}>
            <RadioGroup<boolean>
              name="insurance-options"
              onChange={handleOptInChange}
              value={insurance.isSelected}
            >
              <div>
                <div className={styles['recommended-tab']}>
                  <span className={styles['recommended-text']}>
                    Recommended
                  </span>
                </div>
                <RadioCard
                  value
                  title={
                    <span className={styles['radio-card-title']}>
                      Yes, make my purchase reimbursable
                    </span>
                  }
                  subtitle={
                    <div className={styles['quote']}>
                      Insure for{' '}
                      {insurance.isLoadingQuote ? (
                        <div className={styles['placeholder']} />
                      ) : (
                        <>
                          <span className={styles['price']}>
                            ${insurance.quote.unitPrice.toFixed(2)}
                          </span>
                          /ticket
                        </>
                      )}
                    </div>
                  }
                  expandedContent={coverageList}
                />
              </div>
              <RadioCard
                value={false}
                title={
                  <span className={styles['radio-card-title']}>
                    No, I don't want insurance
                  </span>
                }
              />
            </RadioGroup>
            <span className={styles['social-proof']}>
              <GroupIcon className={styles['group-icon']} />
              29,840 people chose XCover protection this month
            </span>
            <span className={styles['terms-and-conditions']}>
              {insurance.quote.details.disclaimer}{' '}
              <a
                className={styles['policy-wording-link']}
                href={insurance.quote.details.pds_url}
              >
                View Wording
              </a>
            </span>
          </div>
        }
      />
      <GTAnimationModal
        headerTitle="Make My Purchase Reimbursable"
        isDarkTheme
        show={showDataCollectionModal}
        onHide={() => setShowDataCollectionModal(false)}
        className="insurance-data-modal"
      >
        <div className={styles['modal-body']}>
          <h2 className={styles['modal-body-heading']}>
            Confirm Your Information
          </h2>
          {coverageList}
          <InsuranceDataCollectionForm
            zipCode={insurance.quote.details.postal_code}
            user={user}
            onSubmit={handleSubmit}
          />
        </div>
      </GTAnimationModal>
    </>
  );
}
