import React from 'react';
import { RadioCard, RadioGroup } from 'ui/Radio';

import AffirmPromoMessage from 'components/Affirm/AffirmPromoMessage';
import AffirmLogoBlueCircle from 'components/Affirm/assets/affirm_blue_solid_circle-white_bg.svg';
import Spinner from 'components/Spinner/Spinner';
import CreditCardFrontFillIcon from 'icons/CreditCardFrontFillIcon';
import PayPalCardIcon from 'icons/PayPalCardIcon';
import {
  CARD_TYPES,
  PAYMENT_TYPES_CONFIG,
} from 'store/datatypes/PAYMENT_TYPES';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import colors from 'styles/colors.constants';
import { CreditCard } from 'types';

import styles from './PaymentMethods.module.scss';

interface Props {
  isLoading: boolean;
  creditCards: CreditCard[];
  isApplePayAvailable: boolean;
  isGooglePayAvailable: boolean;
  selectedPaymentMethod?: string;
  onSelectPaymentMethod: (paymentMethod: string) => void;
  adjustedTotal: number;
  addCardForm: React.ReactNode;
}

const applePayConfig = PAYMENT_TYPES_CONFIG[CARD_TYPES.APPLEPAY];
const googlePayConfig = PAYMENT_TYPES_CONFIG[CARD_TYPES.GOOGLEPAY];

function PaymentMethods({
  isLoading,
  creditCards,
  isApplePayAvailable,
  isGooglePayAvailable,
  selectedPaymentMethod,
  onSelectPaymentMethod,
  adjustedTotal,
  addCardForm,
}: Props) {
  if (isLoading) {
    return <Spinner style={{ height: 120 }} />;
  }

  return (
    <RadioGroup
      name="payment-method"
      onChange={onSelectPaymentMethod}
      value={selectedPaymentMethod}
    >
      {isApplePayAvailable && (
        <RadioCard
          value={`${PURCHASE_TYPE.APPLE_PAY}:${CARD_TYPES.APPLEPAY}`}
          title={applePayConfig.displayName}
          icon={applePayConfig.icon}
        />
      )}
      {isGooglePayAvailable && (
        <RadioCard
          value={`${PURCHASE_TYPE.GOOGLE_PAY}:${CARD_TYPES.GOOGLEPAY}`}
          title={googlePayConfig.displayName}
          icon={googlePayConfig.icon}
        />
      )}
      {creditCards.map((card) => {
        if (!card.card_type) {
          return null;
        }

        const cardConfig = PAYMENT_TYPES_CONFIG[card.card_type];
        return (
          <RadioCard
            key={card.token}
            value={`${PURCHASE_TYPE.CREDIT_CARD_ON_FILE}:${card.token}`}
            title={
              <span className={styles['card-desc']}>
                {cardConfig.displayName}
                <span className={styles.star} />
                <span className={styles.lastfour}>{card.last_four}</span>
              </span>
            }
            subtitle={
              <span className={styles['card-exp']}>
                Expires {card.expiration_month}/{card.expiration_year}
              </span>
            }
            icon={cardConfig.icon}
          />
        );
      })}
      <RadioCard
        value={PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY}
        icon={<CreditCardFrontFillIcon fill={colors.white} />}
        title="Add New Card"
        expandedContent={addCardForm}
      />
      {adjustedTotal < 50 || adjustedTotal >= 20000 ? null : (
        <RadioCard
          value={PURCHASE_TYPE.AFFIRM_PAY}
          icon={
            <span className={styles['affirm-icon']}>
              <img src={AffirmLogoBlueCircle} alt="Affirm Logo" />
            </span>
          }
          title="Pay Over Time"
          subtitle={
            <span className={styles['affirm-promo-message']}>
              <AffirmPromoMessage total={adjustedTotal} />
            </span>
          }
        />
      )}
      <RadioCard
        value={PURCHASE_TYPE.PAYPAL_PAY}
        icon={<PayPalCardIcon />}
        title="Pay with PayPal"
      />
    </RadioGroup>
  );
}

export default PaymentMethods;
