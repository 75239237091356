import React from 'react';
import PropTypes from 'prop-types';

import {
  GENERAL_ADMISSIONS_EXACT_MATCHES,
  GENERAL_ADMISSIONS_SUBSTRINGS,
  PENDING_SEAT_VALUES,
} from './seat.constants';

const SeatDescription = ({ seatCount, seats, section }) => {
  const lowerCaseSection = section.toLowerCase();
  const matchesGASubstring = () => {
    let match = false;
    let i = 0;

    do {
      if (lowerCaseSection.includes(GENERAL_ADMISSIONS_SUBSTRINGS[i])) {
        match = true;
      }
      i += 1;
    } while (i < GENERAL_ADMISSIONS_SUBSTRINGS.length && match === false);
    return match;
  };

  const isPendingSeat = (seat) => PENDING_SEAT_VALUES.includes(seat);

  const getSeatNumbers = () => {
    if (seatCount === 1) {
      return `Seat ${seats[seats.length - seatCount]}`;
    } else if (seatCount <= 4) {
      return `Seats ${seats.slice(seats.length - seatCount).join(', ')}`;
    } else {
      return `Seats ${seats[seats.length - seatCount]} - ${
        seats[seats.length - 1]
      }`;
    }
  };

  const getDescriptionText = () => {
    if (
      GENERAL_ADMISSIONS_EXACT_MATCHES.includes(lowerCaseSection) ||
      matchesGASubstring()
    ) {
      return seatCount > 1 ? `${seatCount} Tickets` : `${seatCount} Ticket`;
    }

    if (seats.length < seatCount || seats.some(isPendingSeat)) {
      return seatCount > 1
        ? `${seatCount} Seats Together`
        : `${seatCount} Ticket`;
    }

    return getSeatNumbers();
  };

  return <li>{getDescriptionText()}</li>;
};

SeatDescription.defaultProps = {};

SeatDescription.propTypes = {
  seatCount: PropTypes.number.isRequired,
  seats: PropTypes.array,
  section: PropTypes.string,
};

export default SeatDescription;
