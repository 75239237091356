import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Location } from 'history';

function ScrollToTop({ location }: { location: Location }) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
}

export default withRouter(ScrollToTop);
