import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { SearchResponse } from 'types';

import { paramsToString, SearchRequest } from './search.service.utils';

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const getSearchResults = ({
  searchParams,
  headers,
}: SearchRequest): Promise<SearchResponse | null> => {
  const urlSearchParams = paramsToString(searchParams);

  return httpClient.request({
    path: '/v1/search',
    searchParams: urlSearchParams,
    headers,
  });
};
