import React, { useContext } from 'react';
import {
  publicApiType,
  VisibilityContext,
} from 'react-horizontal-scrolling-menu';
import classNames from 'classnames';

import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import 'react-horizontal-scrolling-menu/dist/styles.css';
import styles from './HorizontalScrollView.module.scss';

type ButtonBaseProps = {
  onClick: () => void;
  disabled: boolean;
  isRightButton?: boolean;
};

function ButtonBase({
  onClick,
  disabled,
  isRightButton = false,
}: ButtonBaseProps) {
  return (
    <>
      <button
        disabled={disabled}
        type="button"
        aria-label="Show more"
        onClick={onClick}
        className={classNames(styles['view-more-button'], {
          [styles.disabled]: disabled,
        })}
      >
        <ChevronIcon
          direction={isRightButton ? 'right' : 'left'}
          width={16}
          height={16}
          color={colors.white}
        />
      </button>
      <span
        className={classNames({
          [styles['view-more-button--right']]: isRightButton,
          [styles['view-more-button--left']]: !isRightButton,
          [styles.disabled]: disabled,
        })}
      ></span>
    </>
  );
}

export function LeftButton() {
  const { scrollPrev, items, useIsVisible }: publicApiType =
    useContext(VisibilityContext);
  const isFirstItemVisible = useIsVisible(items.first()?.key || '', true);

  return (
    <ButtonBase disabled={isFirstItemVisible} onClick={() => scrollPrev()} />
  );
}

export function RightButton() {
  const { scrollNext, items, useIsVisible } = useContext(VisibilityContext);
  const isLastItemVisible =
    useIsVisible(items.last()?.key || '') || items.last()?.visible;

  return (
    <ButtonBase
      disabled={isLastItemVisible || false}
      onClick={() => scrollNext()}
      isRightButton
    />
  );
}

export function GroupedArrows() {
  return (
    <div className={styles['grouped-arrows-container']}>
      <LeftButton /> <RightButton />
    </div>
  );
}
