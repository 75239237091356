/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import HomeFillIcon from 'icons/HomeFillIcon';
import colors from 'styles/colors.constants';

const UserZipFormValidationSchema = Yup.object().shape({
  zipcode: Yup.string()
    .matches(
      /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[ -]*\d{1}[A-Z]{1}\d{1}$)/i,
      {
        message: 'Please enter a valid ZIP code.',
      }
    )
    .trim()
    .required('Please enter a valid ZIP code'),
});

const UserZipForm = ({
  clickTracker,
  ctaText = 'CONFIRM',
  handleUserZipFormSubmit,
}) => {
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      await handleUserZipFormSubmit(values);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ zipcode: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserZipFormValidationSchema}
    >
      {(props) => {
        const {
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <TextInput
              id="user-zip"
              name="zipcode"
              label="ZIP Code"
              placeholder="ZIP Code"
              startIcon={<HomeFillIcon fill={colors.gray200} />}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              inputMode="numeric"
              autoComplete="postal-code"
              error={{
                hasError: errors.zipcode && touched.zipcode,
                message: errors.zipcode,
              }}
            ></TextInput>
            <DarkThemedFormButtonLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              clickTracker={clickTracker}
              showBackground
            />
          </Form>
        );
      }}
    </Formik>
  );
};

UserZipForm.propTypes = {
  handleUserZipFormSubmit: PropTypes.func.isRequired,
  clickTracker: PropTypes.object,
  ctaText: PropTypes.string,
};

export default UserZipForm;
