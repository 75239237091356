import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';

import {
  Click,
  FullEventClickTracker,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import GTGrid from 'components/GTGrid/GTGrid';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import Image from 'components/Image/Image';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import { redirect } from 'helpers/RedirectHelper';
import CollectionModel from 'models/Collection';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { selectCollectionsByFilters } from 'store/modules/data/Collections/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectMetro } from 'store/modules/resources/resource.selectors';
import { addQuery } from 'utils/url';

import {
  COLLECTION_HEADINGS,
  COLLECTION_SLUGS,
  COLLECTION_VIEWS,
} from './constants';

import styles from './Collection.module.scss';

@connect(
  (state, props) => {
    const {
      params: { metro, slug, view },
    } = props;
    const collection = selectCollectionsByFilters(state, {
      metro,
      slug,
      view,
    })[0];

    return {
      collection,
      metro,
      currentMetro: selectMetro(state, metro),
    };
  },
  {
    redirect,
    fetchCollections,
  }
)
@TrackPageView(({ routeParams: { splat } }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.COLLECTION(splat),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.COLLECTION(),
}))
class Collection extends Component {
  static propTypes = {
    collection: PropTypes.instanceOf(CollectionModel),
    currentMetro: PropTypes.object,
    metro: PropTypes.string.isRequired,
    fetchCollections: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object,
      search: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = props.collection
      ? {
          currentPage:
            'currentPage' in props.collection
              ? props.collection.currentPage
              : 1,
          eventCollection:
            'eventsList' in props.collection ? props.collection.eventsList : [],
        }
      : {};
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.collection) {
      return null;
    }

    const { currentPage, eventsList } = props.collection;
    const { eventCollection } = state;

    if (currentPage === state.currentPage) {
      return null;
    }

    return {
      currentPage,
      eventCollection: eventCollection.concat(eventsList),
    };
  }

  handleClick = () => {
    const { collection, metro } = this.props;

    this.props.fetchCollections({
      metro,
      slug: collection.slug,
      view: collection.view,
      results_per_page: 15,
      results_page: collection.currentPage + 1,
    });
  };

  renderHeroSection() {
    const {
      collection: { title, imageUrl, slug },
      currentMetro,
    } = this.props;
    const { name: metroName = '' } = currentMetro;

    let cleanTitle;

    switch (slug) {
      case COLLECTION_SLUGS.PROMOTED:
        // Prevent "At Prudential Center Events"
        const heroTitle = title.replace('At ', '');
        // ex. Bell Centre Events
        cleanTitle = `${heroTitle} ${COLLECTION_HEADINGS.EVENTS}`;
        break;
      case COLLECTION_SLUGS.POPULAR:
        // ex. Popular Events in Boston
        cleanTitle = `${COLLECTION_HEADINGS.POPULAR_EVENTS} in ${metroName}`;
        break;
      case COLLECTION_SLUGS.UNDER_50:
        // ex. Events Under $50 in San Francisco
        cleanTitle = `${COLLECTION_HEADINGS.UNDER_50} in ${metroName}`;
        break;
      case COLLECTION_SLUGS.THIS_WEEKEND:
        cleanTitle = `${COLLECTION_HEADINGS.WEEKEND} in ${metroName}`;
        break;
      default:
        cleanTitle = title;
    }

    return (
      <div className={styles['hero-container']}>
        <div className={styles.overlay} />
        <div className={styles.hero}>
          <Image src={imageUrl} />
        </div>
        <div className={styles['info-container']}>
          <div className={styles['title-container']}>
            <h1 className={styles.title}>{cleanTitle}</h1>
          </div>
        </div>
      </div>
    );
  }

  renderEvents() {
    const {
      collection: { title },
    } = this.props;
    const { eventCollection } = this.state;

    return eventCollection.map((fullEvent, index) => {
      const clickTracker = new FullEventClickTracker(fullEvent).interaction(
        Click.INTERACTIONS.TILE(),
        {
          collection: title,
          get_in_price: fullEvent.getPrice(),
        }
      );
      return (
        <div key={`${fullEvent.id}-${index}`} className={styles['event-card']}>
          <CollectionCard
            source={fullEvent}
            clickTracker={clickTracker}
            jsonLD={dataToJsonLD(fullEvent)}
          />
        </div>
      );
    });
  }

  renderMeta() {
    const {
      collection,
      currentMetro,
      location: { pathname, query },
    } = this.props;
    const { slug = '', title: collectionTitle = '' } = collection;
    const { name: metroName = '' } = currentMetro;

    // default meta tags
    let pageTitle = 'Gametime';
    let pageDescription =
      'Get the cheapest tickets to the most popular events in sports, music, and theater in 60+ cities, with seat view photos and the best prices – guaranteed!';

    if (slug === COLLECTION_SLUGS.UNDER_50) {
      pageTitle = 'Events Under $50 Near You';
      pageDescription = `Get tickets under $50 to events in sports, music, and theater in ${metroName}, with seat view photos and the best prices – guaranteed! 100% Authentic Tickets.`;
    } else if (slug === COLLECTION_SLUGS.THIS_WEEKEND) {
      pageTitle = 'Events This Weekend Near You';
      pageDescription = `This weekend! Get the cheapest tickets to events in sports, music, and theater in ${metroName}, with seat view photos and the best prices – guaranteed!`;
    } else if (slug === COLLECTION_SLUGS.JUST_ANNOUNCED) {
      pageTitle = 'Just Announced Events ';
      pageDescription = `Get the cheapest tickets to just announced events in sports, music, and theater in ${metroName}, with seat view photos and the best prices – guaranteed!`;
    } else if (slug === COLLECTION_SLUGS.POPULAR) {
      pageTitle = 'Cheap Tickets for Popular Events Near You';
      pageDescription = `Get the cheapest tickets to the most popular events in sports, music, and theater in ${metroName}, with seat view photos and the best prices – guaranteed!`;
    } else if (slug === COLLECTION_SLUGS.PROMOTED) {
      pageTitle = `Events ${collectionTitle} Near You`;
      pageDescription = `Get the cheapest tickets to the most popular events in sports, music, and theater ${collectionTitle} in ${metroName}, with the best prices – guaranteed!`;
    }

    return (
      <>
        <HeadTitle title={pageTitle} />
        <HeadDescription description={pageDescription} />
        <HeadCanonicalPath path={pathname} query={query} />
      </>
    );
  }

  componentDidMount() {
    if (!this.props.collection || !this.props.collection.id) {
      this.props.redirect('/');
    }
  }

  render() {
    const {
      collection,
      location: { pathname, search },
    } = this.props;
    if (!collection || !collection.id) {
      return null;
    }

    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          className: styles.header,
          showHamburger: true,
        }}
        className={styles['collection-container']}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        <GTGrid evenSpacing>
          <div className={styles['events-container']}>
            <div className={styles.banner}>Top Events Nearby</div>
            <div className={styles.events}>
              {this.renderEvents()}
              {collection.currentPage > 1 && (
                <a
                  className={styles['pagination-link']}
                  href={addQuery(pathname, search, { page: 1 })}
                />
              )}
              {collection.morePages && (
                <>
                  <div className={styles['pagination-button-container']}>
                    <PaginationButton onClick={this.handleClick} />
                  </div>
                  <a
                    className={styles['pagination-link']}
                    href={addQuery(pathname, search, {
                      page: collection.currentPage + 1,
                    })}
                  />
                </>
              )}
            </div>
          </div>
        </GTGrid>
      </GTContainer>
    );
  }
}

export default withDataLoader(Collection, {
  promise: async ({ store: { dispatch }, params: { metro, slug, view } }) => {
    return await Promise.all([
      dispatch(fetchMetros()),
      dispatch(
        fetchCollections({
          metro,
          with_results: true,
          view: COLLECTION_VIEWS.WEB_DISCOVER,
        })
      ),
    ]).then(() =>
      dispatch(
        fetchCollections({
          slug,
          metro,
          view,
          results_page: 1,
          results_per_page: 15,
        })
      )
    );
  },
});
