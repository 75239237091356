/*
Disclosures are special attributes to events/listings that describe restrictions or special
characteristics of those tickets. 

For example: "aisle", "limited_view", "no_alcohool", "zone_ticket", "print_only" are disclosures found in listings
*/
export default class Disclosure {
  constructor(disc = {}) {
    this.slug = disc.slug;
    this.title = disc.title;
    this.description = disc.description;
    this.resourceType = disc.resource_type;
    this.iconURL = disc.icon_url;

    /* display_types is an array sorted by all display_types that could render that disclosure.
    it's there mostly for the apps so that old client don't break if a display_type changes. 
    For us, we just care about the first one */
    if (disc.display_types?.length) {
      const [best] = disc.display_types;
      this.bestDisplayType = best;
    }
  }
}
