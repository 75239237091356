import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button, { TYPES } from 'components/Buttons/SimpleButton';
import GTAnimationModal from 'components/GTAnimationModal/GTAnimationModal';
import AffirmTextInput from 'components/Inputs/AffirmTextInput';
import Spinner, { VARIANTS } from 'components/Spinner/Spinner';
import colors from 'styles/colors.constants';

import AffirmBlackLogo from '../../Affirm/assets/affirm_black_logo.svg';

import styles from './AffirmFormModal.module.scss';

const AffirmFormModal = ({ isOpen = false, onClose, onSubmit }) => {
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState({ value: '', error: false });
  const [lastName, setLastName] = useState({ value: '', error: false });
  const [isFormPristine, setFormPristine] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      return () => {
        setLoading(false);
        setFirstName({ value: '', error: false });
        setLastName({ value: '', error: false });
        setFormPristine(true);
      };
    }
  }, [isOpen]);

  // Remove errors when the user enters anything in the fields and update state
  const handleNameChange = (e) => {
    const inputName = e?.target?.name || '';
    const inputValue = e?.target?.value || '';

    if (isFormPristine) setFormPristine(false);

    if (inputName === 'firstName') {
      setFirstName({ value: inputValue, error: false });
    } else if (inputName === 'lastName') {
      setLastName({ value: inputValue, error: false });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // If the user has not added any data and tries to submit
    if (isFormPristine) {
      setFormPristine(false);
      setFirstName({ ...firstName, error: true });
      setLastName({ ...lastName, error: true });
      setLoading(false);
      return false;
    }

    // If the user has typed in the fields, check for completion
    if (!isFormPristine) {
      if (firstName.value === '') {
        setFirstName({ ...firstName, error: true });
      }

      if (lastName.value === '') {
        setLastName({ ...lastName, error: true });
      }
    }

    // The first and last name errors are set above so return to prevent submit
    if (firstName.error || lastName.error) {
      setLoading(false);
      return false;
    }

    onSubmit({
      firstName: firstName.value,
      lastName: lastName.value,
    });
  };

  // Reset all the fields if the user closes the modal
  const handleClose = () => {
    setLoading(false);
    setFirstName({ value: '', error: false });
    setLastName({ value: '', error: false });
    setFormPristine(true);
    onClose();
  };

  return (
    <GTAnimationModal
      className="affirm-form-modal"
      headerTitle="Get Started"
      show={isOpen}
      onHide={handleClose}
      isLightTheme
      closeIconColor={colors.affirmPurple}
    >
      <div className={styles['form-container']}>
        <div className={styles['affirm-logo']}>
          <img src={AffirmBlackLogo} alt="Affirm Logo" />
        </div>
        <p className={styles['form-heading']}>
          Please enter your name to continue.
        </p>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
          <AffirmTextInput
            id="user-first-name"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            onChange={(e) => handleNameChange(e)}
            disabled={isLoading}
            error={{
              hasError: !isFormPristine && firstName.error,
              message: 'Please enter a first name.',
            }}
            required
          />
          <AffirmTextInput
            id="user-last-name"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            onChange={(e) => handleNameChange(e)}
            disabled={isLoading}
            error={{
              hasError: !isFormPristine && lastName.error,
              message: 'Please enter a last name.',
            }}
            required
          />
          <Button
            className={styles.submit}
            fullWidth
            disabled={isLoading}
            text="continue"
            type={TYPES.AFFIRM}
          />
        </form>
        {isLoading && <Spinner format={VARIANTS.DARK} />}
      </div>
    </GTAnimationModal>
  );
};

AffirmFormModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AffirmFormModal;
