import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DealsSlider from 'components/Deals/DealsSlider/DealsSlider';
import {
  dealTypes,
  getUnlockedZoneDealInfo,
  saveUnlockZoneDealInfo,
} from 'components/Deals/helpers';
import LockedDeal from 'components/Deals/LockedDeal';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import { setLockedCarouselHover as setLockedCarouselHoverDispatch } from 'store/modules/data/Listings/actions';
import {
  fetchListingsLoadingSelector,
  isLockedCarouselHoveredSelector,
  isZoneUnlockedSelector,
} from 'store/modules/data/Listings/selectors';

import styles from './CarouselListing.module.scss';

const CarouselListing = ({
  fullEvent,
  listings = [],
  dealType,
  handleDealUnlock,
  isZoneDealsUnlocked,
  zoomLevel,
  allDisclosures,
  onListingHover,
  seatCount,
  allDeals,
  eventId,
  setLockedCarouselHover,
  isLockedCarouselHovered = false,
  isLoadingListings,
  isExclusivesV1,
}) => {
  const [activeZoneDeal, setZoneDeal] = useState(
    getUnlockedZoneDealInfo(eventId)
  );

  const onZoneDealUnlock = useCallback(() => {
    const savedZoneDeal = saveUnlockZoneDealInfo(eventId);
    setZoneDeal(savedZoneDeal);
    handleDealUnlock(dealTypes.zoneDeal);
  }, [eventId, handleDealUnlock]);

  useEffect(() => {
    const zoneDealInfo = getUnlockedZoneDealInfo(eventId);

    if (zoneDealInfo && !activeZoneDeal) {
      setZoneDeal(zoneDealInfo);
    }

    if (zoneDealInfo && !isZoneDealsUnlocked) {
      // update state on pg reload for map
      handleDealUnlock(dealTypes.zoneDeal);
    }
  }, [activeZoneDeal, isZoneDealsUnlocked, handleDealUnlock, eventId]);

  const isMobile = useMediaQuery(device.down.md);

  const handleLockedCarouselHover = () => {
    if (isMobile || isLockedCarouselHovered) return;
    setLockedCarouselHover(true);
  };

  const ListingCards = listings.map((carouselItem, index) => (
    <div
      key={carouselItem.id}
      className={classNames({ [styles.disabled]: isLoadingListings })}
    >
      <ListingCard
        fullEvent={fullEvent}
        onHover={onListingHover}
        listingIndex={index}
        zoomLevel={zoomLevel}
        allDisclosures={allDisclosures}
        listing={carouselItem}
        allDeals={allDeals}
        seatCount={seatCount}
        isCarouselItem
        isZoneDeal={dealType === dealTypes.zoneDeal}
        isFlashDeal={dealType === dealTypes.flashDeal}
        isExclusivesV1={isExclusivesV1}
      />
    </div>
  ));

  if (listings.length === 0) {
    return null;
  }

  if (dealType === dealTypes.zoneDeal) {
    if (!activeZoneDeal && !isZoneDealsUnlocked) {
      return (
        <div
          className={classNames(styles.container, {
            [styles.disabled]: isLoadingListings,
          })}
          onMouseOver={handleLockedCarouselHover}
          onFocus={handleLockedCarouselHover}
        >
          <LockedDeal
            isCarousel
            isExclusivesV1={isExclusivesV1}
            dealType={dealTypes.zoneDeal}
            fullEvent={fullEvent}
            listings={listings}
            onUnlockDeal={onZoneDealUnlock}
          />
        </div>
      );
    }

    return (
      <DealsSlider
        header="Zone Deals"
        listingCards={ListingCards}
        listingModels={listings} // clean up later MWEB-4009, don't need this for cell timer
        fullEvent={fullEvent}
        dealType={dealTypes.zoneDeal}
      />
    );
  }

  if (isExclusivesV1) {
    return (
      <DealsSlider
        header="Top Deals"
        listingCards={ListingCards}
        listingModels={listings}
        fullEvent={fullEvent}
      />
    );
  }

  if (dealType === dealTypes.flashDeal) {
    return (
      <DealsSlider
        header="Flash Deals"
        listingCards={ListingCards}
        listingModels={listings} // clean up later MWEB-4009, don't need this for cell timer
        fullEvent={fullEvent}
        dealType={dealTypes.flashDeal}
      />
    );
  }

  if (dealType === dealTypes.bestDeal) {
    return (
      <DealsSlider
        header="Best Deals"
        listingCards={ListingCards}
        listingModels={listings}
        fullEvent={fullEvent}
      />
    );
  }

  return null;
};

CarouselListing.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent),
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  dealType: PropTypes.string,
  handleDealUnlock: PropTypes.func,
  isZoneDealsUnlocked: PropTypes.bool,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  seatCount: PropTypes.number,
  zoomLevel: PropTypes.number,
  onListingHover: PropTypes.func,
  eventId: PropTypes.string,
  setLockedCarouselHover: PropTypes.func,
  isLockedCarouselHovered: PropTypes.bool,
  isLoadingListings: PropTypes.bool.isRequired,
  isExclusivesV1: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { eventId } = props;

  return {
    isLoadingListings: fetchListingsLoadingSelector(state),
    isLockedCarouselHovered: isLockedCarouselHoveredSelector(state),
    isZoneDealsUnlocked: isZoneUnlockedSelector(state, eventId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLockedCarouselHover: (isHovered) =>
    dispatch(setLockedCarouselHoverDispatch(isHovered)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselListing);
