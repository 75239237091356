import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SeatDescription from 'components/SeatDescription/SeatDescription';

import styles from './PricingSummary.module.scss';

const PricingSummary = ({ seatCount, seats, section, rowDesc, hideSeats }) => (
  <ul
    className={classNames(styles['seat-details'])}
    data-cy="pricing-summary-container"
  >
    <li className={styles['list-item']}>
      {'Sec '}
      {section}
    </li>
    <li className={styles['list-item']}>
      {'Row '}
      {rowDesc}
    </li>
    {!hideSeats && (
      <SeatDescription seatCount={seatCount} seats={seats} section={section} />
    )}
  </ul>
);

PricingSummary.defaultProps = {};

PricingSummary.propTypes = {
  seatCount: PropTypes.number.isRequired,
  seats: PropTypes.arrayOf(PropTypes.string),
  section: PropTypes.string,
  rowDesc: PropTypes.string,
  hideSeats: PropTypes.bool.isRequired,
};

export default PricingSummary;
