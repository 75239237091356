export default class Base {
  constructor({
    body,
    active,
    expanded = false,
    sm_height = null,
    md_height = null,
    lg_height = null,
    version = '1',
    meta_title = '',
    meta_description = '',
    meta_parking_title = '',
    meta_parking_description = '',
    related_performers_title = '',
    parking_body = '',
  }) {
    this.body = body;
    this.isActive = active;
    this.isInitiallyExpanded = expanded;
    this.version = version;
    this.smHeight = sm_height;
    this.mdHeight = md_height;
    this.lgHeight = lg_height;
    this.metaTitle = meta_title;
    this.metaDescription = meta_description;
    this.relatedPerformersTitle = related_performers_title;
    this.metaParkingTitle = meta_parking_title;
    this.metaParkingDescription = meta_parking_description;
    this.parkingBody = parking_body;
  }
}
