import { Click, MetroClickTracker, StaticPageClickTracker } from 'analytics';
import {
  CATEGORIES,
  generateCategoryLinks,
} from 'store/modules/categories/category.helpers';
import { generatePerformerLinks } from 'store/modules/performers/performers.helpers';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import { APP_STORE_LINKS } from 'utils/appStoreLinks';

const LOS_ANGELES = 'losangeles';
const SAN_FRANCISCO = 'sanfrancisco';
const NEW_YORK = 'newyork';
const SEATTLE = 'seattle';
const CHICAGO = 'chicago';
const BOSTON = 'boston';
const HOUSTON = 'houston';

const METROS_DATA = {
  [LOS_ANGELES]: {
    id: LOS_ANGELES,
    name: 'Los Angeles',
  },
  [SAN_FRANCISCO]: {
    id: SAN_FRANCISCO,
    name: 'SF Bay Area',
  },
  [NEW_YORK]: {
    id: NEW_YORK,
    name: 'New York City',
  },
  [SEATTLE]: {
    id: SEATTLE,
    name: 'Seattle',
  },
  [CHICAGO]: {
    id: CHICAGO,
    name: 'Chicago',
  },
  [BOSTON]: {
    id: BOSTON,
    name: 'Boston',
  },
  [HOUSTON]: {
    id: HOUSTON,
    name: 'Houston',
  },
};

const NBA_PERFORMERS = {
  nbagsw: 'nbagsw',
  nbalal: 'nbalal',
  nbalac: 'nbalac',
};

const NBA_DATA = {
  [NBA_PERFORMERS.nbagsw]: {
    name: 'Golden State Warriors',
    slug: NBA_PERFORMERS.nbagsw,
    id: '5270135d3bf376613a000003',
    test_id: 'footer-warriors',
  },
  [NBA_PERFORMERS.nbalal]: {
    name: 'Los Angeles Lakers',
    slug: NBA_PERFORMERS.nbalal,
    id: '52af58b43bf3763ea7000005',
    test_id: 'footer-lakers',
  },
  [NBA_PERFORMERS.nbalac]: {
    name: 'LA Clippers',
    slug: NBA_PERFORMERS.nbalac,
    id: '52af58b43bf3763ea7000004',
    test_id: 'footer-clippers',
  },
};

const NFL_PERFORMERS = {
  nflsf: 'nflsf',
  nfloak: 'nfloak',
};

const NFL_DATA = {
  [NFL_PERFORMERS.nfloak]: {
    name: 'Las Vegas Raiders',
    slug: NFL_PERFORMERS.nfloak,
    id: '53dd34733bf37618a700000a',
    test_id: 'footer-raiders',
  },
  [NFL_PERFORMERS.nflsf]: {
    name: 'San Francisco 49ers',
    slug: NFL_PERFORMERS.nflsf,
    id: '53d186373bf37669a7000003',
    test_id: 'footer-49ers',
  },
};

const NHL_PERFORMERS = {
  nhlsjs: 'nhlsjs',
  nhllak: 'nhllak',
};

const NHL_DATA = {
  [NHL_PERFORMERS.nhlsjs]: {
    name: 'San Jose Sharks',
    slug: NHL_PERFORMERS.nhlsjs,
    id: '5270135d3bf376613a000002',
    test_id: 'footer-sharks',
  },
  [NHL_PERFORMERS.nhllak]: {
    name: 'Los Angeles Kings',
    slug: NHL_PERFORMERS.nhllak,
    id: '52af58b43bf3763ea7000003',
    test_id: 'footer-kings',
  },
};

export const FOOTER_TITLES = {
  HOT_EVENTS: 'Hot Events',
  HOT_CATEGORIES: 'Hot Categories',
  HOT_METROS: 'Hot Metros',
};

const TRENDING_PERFORMERS = [
  NBA_DATA[NBA_PERFORMERS.nbagsw],
  NHL_DATA[NHL_PERFORMERS.nhlsjs],
  NBA_DATA[NBA_PERFORMERS.nbalal],
  NBA_DATA[NBA_PERFORMERS.nbalac],
  NHL_DATA[NHL_PERFORMERS.nhllak],
  NFL_DATA[NFL_PERFORMERS.nfloak],
  NFL_DATA[NFL_PERFORMERS.nflsf],
];

const TRENDING_CATEGORIES = [
  CATEGORIES.MLB,
  CATEGORIES.NBA,
  CATEGORIES.NFL,
  CATEGORIES.NHL,
  CATEGORIES.CBB,
  CATEGORIES.MUSIC,
  CATEGORIES.THEATER,
];

const TRENDING_METROS = [
  METROS_DATA[LOS_ANGELES],
  METROS_DATA[SAN_FRANCISCO],
  METROS_DATA[SEATTLE],
  METROS_DATA[HOUSTON],
  METROS_DATA[NEW_YORK],
  METROS_DATA[CHICAGO],
  METROS_DATA[BOSTON],
];

export const LEGAL_ROUTES = {
  GT_GUARANTEE: {
    path: 'gametime-guarantee',
  },
  TOS: {
    path: 'terms-of-service',
  },
  PRIVACY: {
    path: 'privacy-policy',
  },
  SHARE_CODES: {
    path: 'share-codes',
  },
  PRICE_GUARANTEE: {
    path: 'price-guarantee',
  },
};

export const COMPANY_LINKS = {
  ABOUT: {
    title: 'About',
    path: '/about',
    testId: 'footer-about',
  },
  CAREERS: {
    title: 'Careers',
    path: '/careers',
    testId: 'footer-careers',
  },
  PRESS: {
    title: 'Press',
    path: '/press',
    testId: 'footer-press-inquiries',
  },
  PROMO_CODES: {
    title: 'Gametime Promo Codes',
    path: '/promo-codes',
    testId: 'footer-promo-codes',
  },
  SUPPORT: {
    title: 'Support',
    path: 'https://support.gametime.co',
    testId: 'footer-support',
  },
  WHY: {
    title: 'Why Gametime',
    path: '/why-gametime',
    testId: 'footer-why-gametime',
  },
  SITEMAP: {
    title: 'Sitemap',
    path: '/sitemap',
    testId: 'footer-sitemap',
  },
};

const generateMetroLinks = (metros) =>
  metros.map((metro) => ({
    title: metro.name,
    to: getMetroPerformersPathByMetro(metro),
    clickTracker: new MetroClickTracker(metro),
    id: metro.id,
    testId: `footer-${metro.id}`,
  }));

export const generateFooterLinks = () => [
  {
    title: FOOTER_TITLES.HOT_EVENTS,
    links: generatePerformerLinks(TRENDING_PERFORMERS),
  },
  {
    title: FOOTER_TITLES.HOT_CATEGORIES,
    links: generateCategoryLinks(TRENDING_CATEGORIES),
  },
  {
    title: FOOTER_TITLES.HOT_METROS,
    links: generateMetroLinks(TRENDING_METROS),
  },
  [
    {
      title: 'Download',
      links: [
        {
          title: 'Gametime iOS App',
          testId: 'footer-gametimeios',
          href: APP_STORE_LINKS.iOS.url,
          clickTracker: new StaticPageClickTracker(
            APP_STORE_LINKS.iOS.pageName
          ).interaction(Click.INTERACTIONS.FOOTER()),
        },
        {
          title: 'Gametime Android App',
          testId: 'footer-gametimeandroid',
          href: APP_STORE_LINKS.android.url,
          clickTracker: new StaticPageClickTracker(
            APP_STORE_LINKS.android.pageName
          ).interaction(Click.INTERACTIONS.FOOTER()),
        },
      ],
    },
    {
      title: 'Social',
      links: [
        {
          title: 'TikTok',
          testId: 'footer-tiktok',
          href: 'https://www.tiktok.com/@gametime',
          clickTracker: new StaticPageClickTracker('tiktok').interaction(
            Click.INTERACTIONS.FOOTER()
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Instagram',
          testId: 'footer-instagram',
          href: 'https://www.instagram.com/gametimeapp',
          clickTracker: new StaticPageClickTracker('instagram').interaction(
            Click.INTERACTIONS.FOOTER()
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'X',
          testId: 'footer-x',
          href: 'https://x.com/gametime',
          clickTracker: new StaticPageClickTracker('X').interaction(
            Click.INTERACTIONS.FOOTER()
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Facebook',
          testId: 'footer-facebook',
          href: 'https://facebook.com/Gametime',
          clickTracker: new StaticPageClickTracker('facebook').interaction(
            Click.INTERACTIONS.FOOTER()
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      ],
    },
  ],
  {
    title: 'Company',
    links: [
      {
        title: COMPANY_LINKS.ABOUT.title,
        testId: COMPANY_LINKS.ABOUT.testId,
        to: COMPANY_LINKS.ABOUT.path,
        clickTracker: new StaticPageClickTracker('about').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.CAREERS.title,
        testId: COMPANY_LINKS.CAREERS.testId,
        to: COMPANY_LINKS.CAREERS.path,
        clickTracker: new StaticPageClickTracker('careers').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.PRESS.title,
        testId: COMPANY_LINKS.PRESS.testId,
        href: COMPANY_LINKS.PRESS.path,
        clickTracker: new StaticPageClickTracker('press').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.PROMO_CODES.title,
        testId: COMPANY_LINKS.PROMO_CODES.testId,
        to: COMPANY_LINKS.PROMO_CODES.path,
        clickTracker: new StaticPageClickTracker('promo-codes').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.SUPPORT.title,
        testId: COMPANY_LINKS.SUPPORT.testId,
        href: COMPANY_LINKS.SUPPORT.path,
        target: '_blank',
        rel: 'noopener noreferrer',
        clickTracker: new StaticPageClickTracker('support').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.WHY.title,
        testId: COMPANY_LINKS.WHY.testId,
        to: COMPANY_LINKS.WHY.path,
        clickTracker: new StaticPageClickTracker('why-gametime').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
      {
        title: COMPANY_LINKS.SITEMAP.title,
        testId: COMPANY_LINKS.SITEMAP.testId,
        to: COMPANY_LINKS.SITEMAP.path,
        clickTracker: new StaticPageClickTracker('sitemap').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
      },
    ],
  },
  {
    title: 'Policies',
    links: [
      {
        title: 'Help Center',
        testId: 'footer-faq',
        href: 'https://support.gametime.co',
        clickTracker: new StaticPageClickTracker('faq').interaction(
          Click.INTERACTIONS.FOOTER()
        ),
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        title: 'Gametime Guarantee',
        testId: 'footer-gtguarantee',
        to: `/${LEGAL_ROUTES.GT_GUARANTEE.path}`,
        clickTracker: new StaticPageClickTracker(
          LEGAL_ROUTES.GT_GUARANTEE.path
        ).interaction(Click.INTERACTIONS.FOOTER()),
      },
      {
        title: 'Price Guarantee',
        testId: 'footer-priceguarantee',
        to: `/${LEGAL_ROUTES.PRICE_GUARANTEE.path}`,
        clickTracker: new StaticPageClickTracker(
          LEGAL_ROUTES.PRICE_GUARANTEE.path
        ).interaction(Click.INTERACTIONS.FOOTER()),
      },
      {
        title: 'Share Codes',
        testId: 'footer-sharecodes',
        to: `/${LEGAL_ROUTES.SHARE_CODES.path}`,
        clickTracker: new StaticPageClickTracker(
          LEGAL_ROUTES.SHARE_CODES.path
        ).interaction(Click.INTERACTIONS.FOOTER()),
      },
      {
        title: 'NY Registered Brokers',
        testId: 'footer-nybrokers',
        to: '/blog/ny-registered-brokers',
        clickTracker: new StaticPageClickTracker(
          'ny-registered-brokers'
        ).interaction(Click.INTERACTIONS.FOOTER()),
      },
    ],
  },
];
