import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner/Spinner';
import { showAppSpinnerSelector } from 'store/modules/app/app.selectors';
import {
  historySelector,
  isEventPagePreListings,
  isListingDetailsPage,
  lastRouteSelector,
} from 'store/modules/history/history';

const ConnectedAppSpinner = ({ showAppSpinner, skipAppSpinner }) => {
  if (skipAppSpinner || !showAppSpinner) return null;
  return <Spinner isFullscreen />;
};

ConnectedAppSpinner.propTypes = {
  showAppSpinner: PropTypes.bool.isRequired,
  skipAppSpinner: PropTypes.bool,
};

// NOTE: this should be reworked when the event preloading mechanism is fixed.
const mapStateToProps = (state, props) => {
  let skipAppSpinner = false;
  if (
    isListingDetailsPage(props.router.location.pathname) &&
    historySelector(state).length > 1
  ) {
    skipAppSpinner = true;
  }

  if (
    isEventPagePreListings(props.router.location.pathname) &&
    isListingDetailsPage(lastRouteSelector(state))
  ) {
    skipAppSpinner = true;
  }

  return {
    showAppSpinner: showAppSpinnerSelector(state),
    skipAppSpinner,
  };
};

export default withRouter(connect(mapStateToProps)(ConnectedAppSpinner));
