import React from 'react';
import PropTypes from 'prop-types';

import styles from './Review.module.scss';

const Review = ({ review, onClick }) => {
  const { name, subline, copy, src } = review;

  return (
    <div key={name} className={styles['review-block']} onClick={onClick}>
      <div className={styles['review-container']}>
        <img
          className={styles['review-profile']}
          src={src}
          alt="Employee Review"
        />
        <span className={styles['review-copy']}>&quot;{copy}&quot;</span>
        <span className={styles['review-name-role']}>
          {name}
          <br />
          {subline}
        </span>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subline: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
};

export default Review;
