import React from 'react';
import classNames from 'classnames';

import colors from 'styles/colors.constants';

import styles from '../../SeatMap.module.scss';

interface SeatMapPinStickProps {
  color?: string;
  isLargePin?: boolean;
  coord: { top: number; left: number };
  inverseScale: number;
  scale: number;
}

const SeatMapPinStick = ({
  color = colors.white,
  isLargePin = false,
  coord,
  inverseScale,
  scale,
}: SeatMapPinStickProps) => {
  const stickHeight = isLargePin ? 16 : 11;
  const adjustedHeight = stickHeight * inverseScale;

  return (
    <div
      className={classNames([
        styles['pin-stick-container'],
        { [styles.large]: isLargePin },
      ])}
      style={{
        top: coord.top,
        left: coord.left,
        marginTop: -adjustedHeight,
        transform: `scale(${1 / scale})`,
      }}
    >
      <span
        className={styles['pin-stick']}
        style={{ background: color }}
      ></span>
    </div>
  );
};

export default SeatMapPinStick;
