import { LISTING } from './listing.actionTypes';
import { getDisplayGroupCarouselHarmony, getDisplayGroupList } from './utils';

const initialState = {
  availableLots: [],
  allListings: {},
  displayGroupCarousel: {},
  displayGroupCarouselHarmony: {},
  displayGroupList: {},
  loading: false,
  isLockedCarouselHovered: false,
  priceQuartile: -1, // selected quartile values will be 1, 2, 3, 4; -1 is default and for non selected
  isUpdatingMapHarmony: false,
  listMapHarmonyToggleIsOn: false,
  unlockedZoneDealEvents: [],
  hoveredListingId: '',
};

export default function reducer(state = initialState, action = {}) {
  const { result, params } = action;
  switch (action.type) {
    case LISTING.GET_LISTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LISTING.GET_LISTINGS_REQUEST_SUCCESS:
      const { displayGroupCarouselHarmony, displayGroupCarousel } =
        getDisplayGroupCarouselHarmony(result, action.isExclusivesV1);
      return {
        ...state,
        loading: false,
        allInPricing: params.all_in_pricing,
        availableLots: result.available_lots,
        sortOrder: params.sort_order,
        allListings: result.listings,
        displayGroupCarousel,
        displayGroupCarouselHarmony,
        displayGroupList: getDisplayGroupList(result),
        priceQuartile: params?.quartile ? params?.quartile : -1,
      };
    case LISTING.GET_LISTINGS_REQUEST_FAIL:
      return initialState;
    case LISTING.SET_LOCKED_CAROUSEL_HOVER:
      return {
        ...state,
        isLockedCarouselHovered: action.isHovered,
      };
    case LISTING.SET_PRICE_QUARTILE:
      return {
        ...state,
        priceQuartile: action.priceQuartile,
      };
    case LISTING.UPDATE_MAP_HARMONY:
      return {
        ...state,
        isUpdatingMapHarmony: action.updating,
      };
    case LISTING.UPDATE_LIST_MAP_HARMONY_TOGGLE:
      return {
        ...state,
        listMapHarmonyToggleIsOn: action.toggleIsOn,
        isUpdatingMapHarmony: true,
      };
    case LISTING.UPDATE_UNLOCKED_ZONE_DEAL_EVENTS:
      if (state.unlockedZoneDealEvents.includes(action.eventId)) return state;
      return {
        ...state,
        unlockedZoneDealEvents: [
          ...state.unlockedZoneDealEvents,
          action.eventId,
        ],
      };
    case LISTING.SET_HOVERED_LISTING_ID:
      return {
        ...state,
        hoveredListingId: action.hoveredListingId,
      };
    default:
      return state;
  }
}
