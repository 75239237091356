import React from 'react';
import PropTypes from 'prop-types';

import ApplePayButtonImage from 'assets/ApplePayButton.png';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import { APPLE_PAY_ERRORS } from 'store/modules/userPurchases/applePay/applePay';
import colors from 'styles/colors.constants';

/* https://developers.braintreepayments.com/guides/apple-pay/client-side/javascript/v3 */
const APPLE_PAY_API_VERSION = 1;
const APPLE_PAY_MERCHANT_LABEL = 'GAMETIME';

export default function ApplePayButton({ onClick }) {
  const handleClick = () => {
    onClick(
      ({
        amount,
        applePayInstance,
        onCancel,
        onError,
        onPaymentAuthorized,
      }) => {
        const session = new window.ApplePaySession(
          APPLE_PAY_API_VERSION,
          applePayInstance.createPaymentRequest({
            requiredShippingContactFields: ['phone', 'email'],
            total: {
              label: APPLE_PAY_MERCHANT_LABEL,
              amount,
            },
          })
        );

        if (!session) {
          onError(APPLE_PAY_ERRORS.SESSION_CREATION_FAILED);
        }

        Object.assign(session, {
          oncancel() {
            onCancel();
          },
          onvalidatemerchant(validateMerchantEvent) {
            applePayInstance.performValidation(
              {
                validationURL: validateMerchantEvent.validationURL,
                displayName: APPLE_PAY_MERCHANT_LABEL,
              },
              (validationError, merchantSession) => {
                if (validationError) {
                  console.error('Error validating merchant: ', validationError);
                  session.abort();
                  onError(APPLE_PAY_ERRORS.MERCHANT_VALIDATION_FAILED);
                  return;
                }

                session.completeMerchantValidation(merchantSession);
              }
            );
          },
          onpaymentauthorized(paymentAuthorizedEvent) {
            applePayInstance.tokenize(
              {
                token: paymentAuthorizedEvent.payment.token,
              },
              (tokenizeError, payload) => {
                if (tokenizeError) {
                  console.error('Error tokenizing Apple Pay: ', tokenizeError);
                  session.completePayment(
                    window.ApplePaySession.STATUS_FAILURE
                  );
                  onError(APPLE_PAY_ERRORS.BRAINTREE_TOKENIZE_FAIL);
                  return;
                }

                session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

                onPaymentAuthorized(payload.nonce);
              }
            );
          },
        });

        return session;
      }
    );
  };

  return (
    <ThemedButtonLoader backgroundColor={colors.white} onClick={handleClick}>
      <img
        src={ApplePayButtonImage}
        style={{ height: '46px' }}
        alt="Buy with Apple Pay"
      />
    </ThemedButtonLoader>
  );
}

ApplePayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
