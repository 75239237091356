import React from 'react';
import { connect } from 'react-redux';

import { selectIsPercentageVsDollarsExperiment } from 'experiments';
import DiscountIcon from 'icons/DiscountIcon';
import { Listing } from 'models';

import styles from './PriceMarkdownTag.module.scss';

// only show savings in dollars if the savings amount is greater than or equal
// to this value in whole dollars, otherwise continue showing percentage
const SHOW_DISCOUNT_IN_DOLLARS_MINIMUM = 10;

interface PriceMarkdownTagProps {
  listing: Listing;
  showDiscountIcon?: boolean;
  showDiscountInDollars: boolean;
}

function PriceMarkdownTag({
  listing,
  showDiscountIcon = true,
  showDiscountInDollars,
}: PriceMarkdownTagProps) {
  const savingsAmount = listing.getSavingsAmount();

  const priceMarkdown =
    showDiscountInDollars &&
    savingsAmount >= SHOW_DISCOUNT_IN_DOLLARS_MINIMUM ? (
      <>
        <span className={styles['dollar-sign']}>$</span>
        {savingsAmount}
      </>
    ) : (
      <>{listing.getDiscountPercent()}%</>
    );

  return (
    <div className={styles['price-markdown-tag']}>
      {showDiscountIcon && <DiscountIcon height={16} width={16} />}
      <span
        className={styles['price-markdown-text']}
        data-testid="price-markdown-text"
      >
        {priceMarkdown} OFF
      </span>
    </div>
  );
}

function mapStateToProps(state: unknown) {
  return {
    showDiscountInDollars: selectIsPercentageVsDollarsExperiment(state),
  };
}

export default connect(mapStateToProps)(PriceMarkdownTag);
