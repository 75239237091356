import React, { createContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectIsLocalizeExperiment } from 'experiments';
import { cleanupLocalizeScript, injectLocalizeScript } from 'helpers/Localize';

const LocalizeContext = createContext();

function LocalizeProvider({ children, isEnabled }) {
  const [localize, setLocalize] = useState(null);

  if (!isEnabled) {
    return <>{children}</>;
  }

  useEffect(() => {
    injectLocalizeScript()
      .then((localize) => {
        setLocalize(localize);
      })
      .catch(console.error);

    return () => {
      cleanupLocalizeScript();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      localize,
    }),
    [localize]
  );

  return (
    <LocalizeContext.Provider value={contextValue}>
      {children}
    </LocalizeContext.Provider>
  );
}

LocalizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isEnabled: selectIsLocalizeExperiment(state),
  };
}
export default connect(mapStateToProps)(LocalizeProvider);
