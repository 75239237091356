import url from 'url';

import _has from 'lodash/has';

import config from 'config/config';
import { domain as productionDomain } from 'config/production.config';
import { cleanUrl } from 'utils/format';

export const locationSelector = (globalState) =>
  globalState?.routing?.locationBeforeTransitions;

export const querySelector = (globalState) =>
  locationSelector(globalState)?.query;

export const selectAlgoliaSearchParams = (globalState) => {
  const query = querySelector(globalState);
  const algoliaParams = new URLSearchParams();

  if (query.queryId) {
    algoliaParams.set('queryId', query.queryId);
  }
  if (query.resultPosition) {
    algoliaParams.set('resultPosition', query.resultPosition);
  }
  if (query.searchIndex) {
    algoliaParams.set('searchIndex', query.searchIndex);
  }
  if (query.searchSessionId) {
    algoliaParams.set('searchSessionId', query.searchSessionId);
  }

  return algoliaParams;
};

// Whether the query contains 'gclid' (Google Click ID) - paid click
export const isFromAdwords = (globalState) =>
  _has(querySelector(globalState), 'gclid');

/**
 * convert pathname to eventpathname
 * @param location
 */
export const getAssociatedEventPathname = (location) =>
  location.split('/listings')[0];

/**
 * get listing pathname
 * @param location
 */
export const getAssociatedListingPathname = (location) =>
  location.split('/buy')[0].split('/checkout')[0];

export const getUrlFromRelativePathname = (
  relativePathname,
  providedHostname = '',
  query = {}
) => {
  const forceProduction = providedHostname === productionDomain;
  const urlParts = {
    hostname: providedHostname || config.domain,
    pathname: cleanUrl(relativePathname),
    query,
  };

  if (__DEVELOPMENT__ && !forceProduction) {
    urlParts.protocol = 'http';
    urlParts.port = config.port;
  } else {
    urlParts.protocol = 'https';
  }

  return url.format(urlParts);
};
