export const getSeatRange = (seats: string[] = []) => {
  if (seats.length === 0 || seats.includes('*')) {
    return null;
  }

  if (seats.length === 1) {
    return `Selected from seat: ${seats[0]}`;
  }

  return `Selected from seats: ${seats[0]} - ${seats[seats.length - 1]}`;
};
