import type { Listing } from 'types';

// https://gametime.atlassian.net/browse/MWEB-1660
export const RESTRICTED_METROS = new Set(['newyork', 'buffalo'] as const);

// acceptable values for account_type in /v1/external_account/{account_type}
export const EXTERNAL_ACCOUNT_TYPES = {
  MLB_TDC: 'MLB_TDC',
  MLB_TM: 'MLB_TM',
} as const;

/**
 * Returns external account type based on ticket type, if no external account
 * type exist for the ticket type, return undefined.
 */
export function getExternalAccountType(ticketType: Listing['ticket_type']) {
  switch (ticketType) {
    case 'tdc': {
      return EXTERNAL_ACCOUNT_TYPES.MLB_TDC;
    }
    case 'tm-official': {
      return EXTERNAL_ACCOUNT_TYPES.MLB_TM;
    }
    default: {
      return;
    }
  }
}
