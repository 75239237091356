import React from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';

import DisclosureDetail from './DisclosureDetail';

import styles from './Disclosures.module.scss';

function Disclosures({
  allDisclosures = {},
  disclosures = [],
  onlyIcon = false,
}) {
  if (disclosures.length === 0) {
    // we are here because listing has no disclosures, good bye
    return null;
  }

  const listItems = disclosures
    .map((d) => {
      const disclosure = allDisclosures[d];

      // In certain screens, we want to only display disclosure icons instead of the clickable "i" icon
      if (onlyIcon) {
        if (disclosure?.iconURL) {
          return (
            <img
              key={d}
              className={styles['white-icon']}
              src={disclosure.iconURL}
              alt={disclosure.title || 'disclosure'}
              width={20}
              height={14}
            />
          );
        }

        return null;
      }

      // This component only cares about "default" disclosures and not the special_treatment cases such as zone_deals or print_only since these are not
      // shown to our users in the same way (and likely have a completely different component for them)
      if (!disclosure || disclosure?.bestDisplayType !== 'default') {
        return null;
      }

      // disclosures with title and description, should pop a modal
      if (disclosure?.title && disclosure?.description) {
        return (
          <DisclosureDetail
            key={d}
            disclosure={disclosure}
            displayText
            color={colors.softBlue}
          />
        );
      }

      let title = '';
      if (isObjectEmpty(disclosure) || !disclosure?.title.length) {
        title = d.replace('_', ' ').toUpperCase();
      } else {
        title = disclosure.title;
      }
      return (
        <span key={d} className={styles['disclosures-text']}>
          {title}
        </span>
      );
    })
    .filter((el) => {
      return el !== null;
    });

  return (
    <div className={styles['disclosures-container']}>
      <div>
        {listItems.map((item, i) => {
          return (
            item && (
              <React.Fragment key={`${item.key}_disclosure_${i}`}>
                {item}
                {!onlyIcon && i !== listItems.length - 1 && (
                  <span className={styles.bullet} />
                )}
              </React.Fragment>
            )
          );
        })}
      </div>
    </div>
  );
}

Disclosures.propTypes = {
  disclosures: PropTypes.array,
  allDisclosures: PropTypes.object,
  onlyIcon: PropTypes.bool,
};

export default Disclosures;
