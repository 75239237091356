import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PAYLOAD, TRACK, TrackPageView, View } from 'analytics';
import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import { useCountdownUntil } from 'hooks/useCountdownTimer';
import { Listing } from 'models';

import styles from './SMSVerificationForm.module.scss';

function formatRemainingTime(remaining) {
  return `${remaining.minutes}:${remaining.seconds.toFixed().padStart(2, '0')}`;
}

function SMSVerificationForm({
  email,
  hasInvalidCodeError,
  isSubmitting,
  clearErrors,
  handleGenerateSMSCode,
  handleSMSCodeLogin,
  handleGenerateMagicLink,
  nextAllowedTokenRequestTime,
}) {
  const [code, setCode] = useState('');
  const [isCodeResent, setIsCodeResent] = useState(false);

  const handleSubmit = (value, isCodePasted = false) => {
    handleSMSCodeLogin(email, value, isCodePasted, isCodeResent);
  };

  const handleChange = (e) => {
    clearErrors(false);
    const { value } = e.target;
    setCode(value);
    if (value.length === 6) {
      const isPasted = e.nativeEvent?.inputType?.startsWith('insertFromPaste');
      handleSubmit(value, isPasted);
    }
  };

  const countdown = useCountdownUntil(nextAllowedTokenRequestTime);

  const handleResendCode = () => {
    setIsCodeResent(true);
    handleGenerateSMSCode(email);
  };

  const buttonLabel = () => {
    if (countdown.isActive) {
      return `Didn't get a code? Retry in ${formatRemainingTime(
        countdown.remaining
      )}`;
    }
    return 'Resend Code';
  };

  return (
    <div className={styles.container}>
      <div className={styles['input-container']}>
        <TextInput
          id="sms-code"
          label="6-Digit Code"
          pattern="[0-9]*"
          placeholder="000000"
          maxLength={6}
          autoComplete="one-time-code"
          disabled={isSubmitting}
          value={code}
          onChange={handleChange}
          error={{
            hasError: hasInvalidCodeError,
            message: 'Invalid Code',
          }}
        ></TextInput>
      </div>
      <DarkThemedFormButtonLoader
        type="submit"
        text="Verify"
        disabled={code.length < 6 || isSubmitting}
        loading={isSubmitting}
        showBackground
        onClick={() => handleSubmit(code)}
      />

      <button
        type="button"
        className={styles['resend-code-button']}
        disabled={countdown.isActive}
        onClick={handleResendCode}
      >
        {buttonLabel()}
      </button>

      <hr className={styles['divider']} />

      <button
        type="button"
        className={styles['resend-code-button']}
        onClick={() => handleGenerateMagicLink(email)}
      >
        Log In Via Email
      </button>
    </div>
  );
}
SMSVerificationForm.propTypes = {
  email: PropTypes.string.isRequired,
  hasInvalidCodeError: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired,
  handleGenerateSMSCode: PropTypes.func.isRequired,
  handleSMSCodeLogin: PropTypes.func.isRequired,
  handleGenerateMagicLink: PropTypes.func.isRequired,
  nextAllowedTokenRequestTime: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  listing: PropTypes.instanceOf(Listing), // Used in tracking
};

const getEventState = (props) => {
  const listingTrackingData = props.listing?.listingTrackingData || {};

  return {
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.SMS_VERIFICATION(),
    payload: {
      [PAYLOAD.DEAL_TYPE]: listingTrackingData.dealType,
      [PAYLOAD.FEATURED_TYPE]: listingTrackingData.featuredType,
      [PAYLOAD.IS_PROMO]: listingTrackingData.isPromo,
      [PAYLOAD.IS_SPONSORED]: listingTrackingData.isSponsored,
    },
  };
};

export default TrackPageView(getEventState)(SMSVerificationForm);
