import React from 'react';
import { connect } from 'react-redux';
import { ValueOf } from 'type-fest';

import { ClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Emoji from 'components/Emoji';
import Link from 'components/Link/Link';
import { PriceButtonColors } from 'components/PriceButton/PriceButton';
import Responsive from 'components/Responsive/Responsive';
import TableViewCell from 'components/TableViewCell/TableViewCell';
import UrgencyBadge, { Badge } from 'components/UrgencyBadge/UrgencyBadge';
import { FullEvent } from 'models';
import { locationSelector } from 'store/modules/location';
import { formatPriceWithComma } from 'utils/number';
import { addQuery } from 'utils/url';

import styles from './EventCell.module.scss';

export const EVENT_CELL_TYPES = {
  SHORT: 'short',
  DEFAULT: 'default',
  MEDIUM: 'medium',
};

type LocationQuery = {
  queryId: string;
  resultPosition: string;
  searchIndex: string;
  searchSessionId: string;
};

interface EventCellProps {
  fullEvent: FullEvent;
  type?: ValueOf<typeof EVENT_CELL_TYPES>;
  onClick?: VoidFunction;
  clickTracker?: ClickTracker | object;
  hideLocation?: boolean;
  isActive?: boolean;
  isSmall?: boolean;
  isSearchBar?: boolean;
  location: {
    search: string;
    query: LocationQuery;
    pathname: string;
  };
  badgeProps?: Badge | null;
  priceButtonColors?: PriceButtonColors;
  showInlinePricing?: boolean;
  showTooltip?: boolean;
}

function EventCell({
  fullEvent,
  type = EVENT_CELL_TYPES.DEFAULT,
  onClick,
  clickTracker,
  hideLocation,
  isSmall,
  isSearchBar,
  location,
  badgeProps,
  isActive = false,
  priceButtonColors,
  showInlinePricing,
  showTooltip = false,
}: EventCellProps) {
  let title = '';
  if (type === EVENT_CELL_TYPES.SHORT) {
    title = fullEvent.getShortName();
  } else if (type === EVENT_CELL_TYPES.MEDIUM) {
    title = fullEvent.getMediumName();
  } else {
    title = fullEvent.getName();
  }

  const { dateLabel, dayLabel } = fullEvent.getDateLabels();
  const price = fullEvent.getPrice();
  const formattedPrice = price ? `$${formatPriceWithComma(price)}+` : '';

  const getEventUrl = () => {
    const queryAlgoliaFields = {
      queryId: fullEvent.algoliaFields.queryId || location.query.queryId,
      resultPosition:
        fullEvent.algoliaFields.resultPosition || location.query.resultPosition,
      searchIndex:
        fullEvent.algoliaFields.searchIndex || location.query.searchIndex,
      searchSessionId:
        fullEvent.algoliaFields.searchSessionId ||
        location.query.searchSessionId,
    };

    const eventUrl = addQuery(
      fullEvent.getPath(),
      location.search,
      queryAlgoliaFields
    );

    return eventUrl;
  };

  const getBulletListItems = () => {
    const bulletListItems: React.ReactNode[] = [];
    const bannerEmoji = fullEvent.event.banner.emoji;

    if (showInlinePricing && formattedPrice !== '') {
      bulletListItems.push(
        <span className={styles['inline-pricing']}>{formattedPrice}</span>
      );
    }
    bulletListItems.push(fullEvent.getTime());

    if (bannerEmoji) {
      bulletListItems.push(
        <Responsive
          xs={<Emoji emoji={bannerEmoji} />}
          md={
            <>
              <Emoji emoji={bannerEmoji} />
              {!isSearchBar && (
                <span className={styles['banner-headline']}>
                  {fullEvent.event.banner.headline}
                </span>
              )}
            </>
          }
        />
      );
    }

    if (!hideLocation) {
      const venueName = fullEvent.venue.name;
      if (venueName) {
        bulletListItems.push(venueName);
      }

      const venueLocation = !fullEvent.isSportsCategoryGroup()
        ? fullEvent.venue.getLocation()
        : null;
      if (venueLocation) {
        bulletListItems.push(venueLocation);
      }
    }

    return bulletListItems;
  };

  return (
    <Link
      to={getEventUrl()}
      onClick={onClick}
      clickTracker={clickTracker}
      title={showTooltip ? title : undefined}
    >
      <TableViewCell
        leftTitle={dateLabel}
        leftSubtitle={dayLabel}
        title={title}
        subtitle={<BulletList listItems={getBulletListItems()} />}
        price={!showInlinePricing ? formattedPrice : undefined}
        rightColors={priceButtonColors}
        isActive={isActive}
        isSearchBar={isSearchBar}
        isSmall={isSmall}
        badge={badgeProps && <UrgencyBadge badge={badgeProps} />}
      />
    </Link>
  );
}

function mapStateToProps(state: unknown) {
  return {
    location: locationSelector(state),
  };
}

export default connect(mapStateToProps)(EventCell);
