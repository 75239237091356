import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import { fetchGlobalHeaderTab } from 'store/modules/content/global/actions';
import { selectHeaderPromoTab } from 'store/modules/content/global/selectors';
import { isObjectEmpty } from 'utils/objects';

import styles from './HeaderPromoTab.module.scss';

/**
 * Fetches content from the global "Promo Header Tab" page in ButterCMS.
 *
 * API Slug: `promo-header-tab`
 *
 * To update the content, update the title and URL fields. The active checkbox will show/hide the tab.
 */
const HeaderPromoTab = ({ tab, dispatch }) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchGlobalHeaderTab());
      } catch (error) {
        console.error(error);
      }
    };

    if (isObjectEmpty(tab)) {
      fetchData();
    }
  }, [tab, dispatch]);

  if (
    isObjectEmpty(tab) ||
    !tab.active ||
    tab.title.length === 0 ||
    tab.url.length === 0
  ) {
    return null;
  }

  const handleClick = () => {
    const tracker = tab.title.split(' ').join('_').toLowerCase();
    const clickTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.HEADER())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
      .targetPageType({ target_page_type: tracker })
      .payload({
        section: tracker,
      });

    analytics?.track(new Click(_merge({}, clickContext, clickTracker.json())));
  };

  return (
    <a href={tab.url} className={styles['tab-item']} onClick={handleClick}>
      {tab.title}
    </a>
  );
};

const mapStateToProps = (state) => ({
  tab: selectHeaderPromoTab(state),
});

HeaderPromoTab.propTypes = {
  tab: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(HeaderPromoTab);
