import React from 'react';

export default ({ width = 24, height = 24, fill = '#ffffff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.52839C6.28806 3.03753 5.79715 2.20748 5.72381 1.24992C5.71328 1.11232 5.61035 1 5.48214 1H2.92857C2.41574 1 2 1.44772 2 2V22C2 22.5523 2.41574 23 2.92857 23H5.48214C5.61035 23 5.71328 22.8877 5.72381 22.7501C5.79715 21.7925 6.28806 20.9625 7 20.4716V15H5.25C4.99358 15 4.78571 14.7761 4.78571 14.5V13.5C4.78571 13.2239 4.99358 13 5.25 13H7V11H5.25C4.99358 11 4.78571 10.7761 4.78571 10.5V9.5C4.78571 9.22386 4.99358 9 5.25 9H7V3.52839Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5179 1C18.3896 1 18.2867 1.11232 18.2762 1.24992C18.1583 2.78984 16.9604 4 15.5 4C14.0396 4 12.8417 2.78984 12.7238 1.24992C12.7133 1.11232 12.6104 1 12.4821 1H9.92857C9.41574 1 9 1.44772 9 2V22C9 22.5523 9.41574 23 9.92857 23H12.4821C12.6104 23 12.7133 22.8877 12.7238 22.7501C12.8417 21.2102 14.0396 20 15.5 20C16.9604 20 18.1583 21.2102 18.2762 22.7501C18.2867 22.8877 18.3896 23 18.5179 23H21.0714C21.5843 23 22 22.5523 22 22V2C22 1.44772 21.5843 1 21.0714 1H18.5179ZM11.7857 9.5C11.7857 9.22386 11.9936 9 12.25 9H18.75C19.0064 9 19.2143 9.22386 19.2143 9.5V10.5C19.2143 10.7761 19.0064 11 18.75 11H12.25C11.9936 11 11.7857 10.7761 11.7857 10.5V9.5ZM12.25 13C11.9936 13 11.7857 13.2239 11.7857 13.5V14.5C11.7857 14.7761 11.9936 15 12.25 15H18.75C19.0064 15 19.2143 14.7761 19.2143 14.5V13.5C19.2143 13.2239 19.0064 13 18.75 13H12.25Z"
      fill={fill}
    />
  </svg>
);
