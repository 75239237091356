import React from 'react';

const HomeFillIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Home Icon</title>
      <defs>
        <path id="a" d="M0 23.988h23.996V0H0z" />
      </defs>
      <g fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M23.91 11.188c-.18.48-.65.8-1.16.8h-1v10.75c0 .33-.13.65-.37.88-.23.24-.55.37-.88.37h-4.25c-.33 0-.65-.13-.88-.37-.24-.23-.37-.55-.37-.88v-10.75H9v10.75c0 .33-.13.65-.37.88-.23.24-.55.37-.88.37H3.5c-.33 0-.65-.13-.88-.37-.24-.23-.37-.55-.37-.88v-10.75h-1c-.51 0-.98-.32-1.17-.8-.18-.48-.05-1.03.33-1.37L10.48.578c.83-.77 2.21-.77 3.04 0l10.07 9.24c.38.34.51.89.32 1.37"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
};

HomeFillIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#969696',
};

export default HomeFillIcon;
