import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';

import {
  Click,
  PerformerClickTracker,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import SimpleHeroSection from 'components/SimpleHeroSection/SimpleHeroSection';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import eventsNearMeImg from 'pages/MainMetro/milwaukee_city_skyline.png';
import { fetchPICByMetroCategoryGroup } from 'store/modules/data/PerformersInContextV2/actions';
import { selectPerformersInContext } from 'store/modules/data/PerformersInContextV2/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';

import styles from './SportsByMetro.module.scss';

const CATEGORY_GRP = 'sport';

const mapStateToProps = (state, props) => ({
  performersInContext: selectPerformersInContext(state),
  currentMetro:
    selectUserMetro(state) ||
    selectClosestMetro(state, props.appContext.state.ipGeoLocation),
});
const mapDispatchToProps = { fetchPICByMetroCategoryGroup };

@TrackPageView(() => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.SPORTSBYMETRO(),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.SPORTSBYMETRO(),
}))
class SportsByMetro extends Component {
  static propTypes = {
    currentMetro: PropTypes.object,
    performersInContext: PropTypes.array,
    fetchPICByMetroCategoryGroup: PropTypes.func.isRequired,
    appContext: PropTypes.shape({
      state: PropTypes.object.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { performersInContext } = props;

    this.state = {
      loading: false,
      showPaginationButton: performersInContext?.length > 14,
    };
  }

  onShowAllClick = () => {
    const { currentMetro } = this.props;
    this.setState({ loading: true });
    this.props
      .fetchPICByMetroCategoryGroup(currentMetro.id, CATEGORY_GRP, 1000)
      .then(() => {
        this.setState({ showPaginationButton: false, loading: false });
      });
  };

  renderMeta() {
    const desc = `See upcoming sports events near you and score cheap last-minute sports tickets for your favorite
     teams & leagues. Best Price Guarantee! 100% secure checkout.`;
    return (
      <div>
        <HeadTitle title="Cheap Sports Tickets" />
        <HeadDescription description={desc} />
      </div>
    );
  }

  renderHeroSection() {
    const { currentMetro } = this.props;
    const eventsNearMeConfig = {
      name: 'Events Near Me',
      url: '/events-near-me',
    };

    return (
      <SimpleHeroSection
        title={`Sports in ${currentMetro.name}`}
        breadcrumbProps={{
          breadcrumbs: [HOMEPAGE_BREADCRUMB_CONFIG, eventsNearMeConfig],
        }}
        backgroundImage={eventsNearMeImg}
      />
    );
  }

  renderAllEventsSection() {
    const { performersInContext } = this.props;
    if (!performersInContext?.length) {
      return null;
    }

    const { loading, showPaginationButton } = this.state;

    return (
      <>
        <SectionHeader title="All Events" />
        <div className={styles.performers}>
          {performersInContext.map((pic) => {
            return (
              <div key={pic.id()} className={styles['performer-card']}>
                <CollectionCard
                  source={pic}
                  clickTracker={new PerformerClickTracker(pic.performer)}
                  toListingPage
                  jsonLD={dataToJsonLD(pic)}
                />
              </div>
            );
          })}
        </div>
        {showPaginationButton && (
          <div className={styles['pagination-button-container']}>
            <PaginationButton
              label="Show All"
              loading={loading}
              onClick={this.onShowAllClick}
            />
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader search showCategories showAccount showHamburger />
        }
        footer={<GTFooter />}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        <div className={styles['performers-container']}>
          {this.renderAllEventsSection()}
        </div>
      </ContainerTemplate>
    );
  }
}

export default withDataLoader(
  withAppContext(connect(mapStateToProps, mapDispatchToProps)(SportsByMetro)),
  {
    promise: async ({ store: { dispatch }, params: { metroId } }) =>
      await Promise.all([
        dispatch(fetchMetros()),
        dispatch(updateUserPreference({ lastVisitedMetro: metroId })),
        dispatch(fetchPICByMetroCategoryGroup(metroId, CATEGORY_GRP)),
      ]),
  }
);
