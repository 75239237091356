import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import {
  BTN_TYPES,
  Click,
  ClickTracker,
  DeepLinkClickTracker,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import {
  generateBreadcrumbSchema,
  getMetroPerformersBreadcrumbConfig,
} from 'components/Breadcrumbs/breadcrumb.helpers';
import DropdownButton from 'components/Buttons/DropdownButton';
import SimpleButton, {
  TYPES as BUTTON_TYPES,
} from 'components/Buttons/SimpleButton';
import HomepageCollection from 'components/Collection/Collection.tsx';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_CAMPAIGNS } from 'components/DeepLink/DeepLink.constants';
import GametimeGuarantee from 'components/GametimeGuarantee/GametimeGuarantee';
import GTGrid from 'components/GTGrid/GTGrid';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import HeroContainer from 'components/HeroContainer/HeroContainer';
import { downloadAppHeroData } from 'components/HeroContainer/HeroContainer.constants';
import JsonLD from 'components/JsonLD/JsonLD';
import MetroContainer from 'components/MetroPills/MetroContainer';
import MetroSelectorContainer from 'components/MetroSelector/MetroSelectorContainer';
import SearchHero from 'components/Search/SearchHero/SearchHero';
import { HEADER_TYPES } from 'components/SectionHeader/constants';
import MetroSelector from 'components/SelectorModals/MetroSelector/MetroSelector';
import { selectIsMarketShareCollectionV2Experiment } from 'experiments';
import { selectIsGTPicksDiscoveryIntegrationEnabled } from 'featureFlags';
import { redirect } from 'helpers/RedirectHelper';
import ChevronIcon from 'icons/ChevronIcon';
import { Collection } from 'models';
import { getCategoryGroupConfigById } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { getMetroPerformersPageTitle } from 'modules/pageTitles';
import {
  COLLECTION_HEADINGS,
  COLLECTION_VIEWS,
} from 'pages/Collection/constants';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import NotFound from 'pages/NotFound/NotFound';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { showMobileHeroSearchBox } from 'store/modules/app/app.ui';
import { CATEGORY_GROUP_IDS } from 'store/modules/categories/category.helpers';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import {
  metroPageCollectionsSelector,
  selectHeroCarouselEvents,
} from 'store/modules/data/Collections/selectors';
import {
  fetchAllPerformers,
  fetchPerformersByCategoryGroup,
} from 'store/modules/data/Performers/actions';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import { selectMetro } from 'store/modules/resources/resource.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { extendDefaultBranchState, generateBranchLink } from 'utils/branchLink';

import styles from './MetroPerformers.module.scss';

@TrackPageView(
  ({ params: { metroId, categoryGroupId }, isInitialUserPurchase }) => {
    return {
      [TRACK.PAGE_TYPE]: View.PAGE_TYPES.METRO(metroId, categoryGroupId),
      payload: {
        [PAYLOAD.PROMO_ELIGIBLE]: isInitialUserPurchase,
      },
    };
  }
)
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.METRO(),
}))
@withAnalyticsContext
class MetroPerformers extends Component {
  static propTypes = {
    collections: PropTypes.arrayOf(PropTypes.instanceOf(Collection)).isRequired,
    branchData: PropTypes.object.isRequired,
    currentMetro: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    fetchAllPerformers: PropTypes.func.isRequired,
    fetchPerformersByCategoryGroup: PropTypes.func.isRequired,
    categoryGroupConfig: PropTypes.object,
    heroOptions: PropTypes.array,
    showMobileHeroSearchBox: PropTypes.func,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
        ipGeoLocation: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
    isGTPicksDiscoveryIntegrationEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      branchHref: '#', // this needs to be truthy so that DeepLink doesn't call the branch api and generate a url 3 times
      openModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.guaranteeRef = React.createRef();
    this.gametimeShieldTracker = new ClickTracker().interaction(
      Click.INTERACTIONS.GT_SHIELD_BUTTON()
    );
    this.handleHeroSearchboxFocus = this.handleHeroSearchboxFocus.bind(this);
  }

  componentDidMount() {
    const { collections } = this.props;
    if (!collections?.length) {
      this.redirected = true;
      this.props.redirect('/');
      return;
    }

    const branchCallback = (error, branchHref) => {
      if (error) {
        console.error(error);
        return;
      }
      this.setState({ branchHref });
    };

    generateBranchLink(this.props.branchData, branchCallback.bind(this));
  }

  componentDidUpdate(prevProps) {
    const prevMetroId = prevProps.currentMetro?.id;
    const currentMetroId = this.props.currentMetro?.id;
    // update all performers when metro's changed
    // this will mainly affect Music and Show dropdown in the header
    if (prevMetroId !== currentMetroId) {
      this.props.fetchAllPerformers(this.props.currentMetro);
      this.props.fetchPerformersByCategoryGroup(currentMetroId);
    }
  }

  handleGTShieldClick = () => {
    this.guaranteeRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    const { clickContext } = this.props;

    this.props.analyticsContext.track(
      new Click(_merge({}, clickContext, this.gametimeShieldTracker.json()))
    );
  };

  handleHeroSearchboxFocus() {
    this.props.showMobileHeroSearchBox();
  }

  toggleModal() {
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  }

  renderMeta() {
    const { categoryGroupConfig, currentMetro } = this.props;
    const pageTitle = getMetroPerformersPageTitle({
      currentMetro,
      categoryGroupConfig,
    });
    const metroName = currentMetro ? ` in ${currentMetro.name}` : '';
    const categoryName = categoryGroupConfig
      ? ` ${categoryGroupConfig.titleEventType}`
      : '';
    const breadcrumbs = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      getMetroPerformersBreadcrumbConfig(currentMetro),
    ];

    const pageDescription = `What's happening this week${metroName}? Get the cheapest last-minute${categoryName} tickets for events${metroName}. Best Price Guarantee! 100% Authentic Tickets.`;

    return (
      <div>
        <HeadTitle title={pageTitle} />
        <HeadDescription description={pageDescription} />
        <HeadCanonicalPath path={getMetroPerformersPathByMetro(currentMetro)} />
        <JsonLD json={generateBreadcrumbSchema(breadcrumbs)} />
      </div>
    );
  }

  renderCollections() {
    const {
      collections,
      currentMetro,
      heroOptions,
      isGTPicksDiscoveryIntegrationEnabled,
    } = this.props;
    const { name: metroName } = currentMetro;

    if (!collections?.length) {
      return null;
    }

    return (
      <>
        {collections.map((collection, index) => {
          let collectionTitle = collection.title;
          if (collectionTitle && collection.isPopular()) {
            collectionTitle = isGTPicksDiscoveryIntegrationEnabled
              ? COLLECTION_HEADINGS.GT_PICKS
              : `${COLLECTION_HEADINGS.TICKETS_FOR_EVENTS} in ${metroName}`;
          }

          const isGTPicksCollection =
            collection.isPopular() && isGTPicksDiscoveryIntegrationEnabled;

          return (
            <div key={collection.id}>
              <HomepageCollection
                collection={collection}
                collectionTitle={collectionTitle}
                currentMetro={currentMetro}
                position={collection.getUISectionIndex()}
                lazyLoad={index !== 0}
                heroCarouselEvents={heroOptions}
                {...(collection.isPopular() && {
                  headerType: HEADER_TYPES.TITLE,
                })}
                isGTPicksCollection={isGTPicksCollection}
              />
            </div>
          );
        })}
      </>
    );
  }

  renderHeroSection() {
    const {
      collections,
      currentMetro,
      heroOptions,
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    const collectionsIsEmpty = !collections?.length;
    const heroOptionsIsEmpty = !heroOptions?.length;

    if (collectionsIsEmpty) {
      return (
        <div className={styles['no-events']}>
          <p>No events found in </p>
          <div className={styles['metro-selector-button-holder']}>
            <DropdownButton
              text={currentMetro.name}
              type={BUTTON_TYPES.GREEN_SOLID}
              onClick={this.toggleModal}
              icon={
                <ChevronIcon
                  width="12"
                  height="12"
                  direction="right"
                  color={colors.white}
                />
              }
              clickTracker={new ClickTracker().interaction(
                Click.INTERACTIONS.CHANGE_LOCATION()
              )}
            />
          </div>
          <MetroSelectorContainer noEvents>
            <MetroSelector
              onHide={this.toggleModal}
              show={this.state.openModal}
              redirectToMetro
            />
          </MetroSelectorContainer>
        </div>
      );
    }

    return (
      <MetroContainer
        isMobile={isMobile}
        currentMetro={currentMetro}
        heroOptionsIsEmpty={heroOptionsIsEmpty}
      >
        <SearchHero
          currentMetro={currentMetro}
          isMobile={isMobile}
          handleHeroSearchboxFocus={this.handleHeroSearchboxFocus}
        />
      </MetroContainer>
    );
  }

  renderGetAppButton(section) {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    return (
      <DeepLink
        href={this.state.branchHref}
        preventLinkFetch
        campaign={DEEPLINK_CAMPAIGNS.METRO_PAGE}
        clickTracker={new DeepLinkClickTracker(isMobile).interaction(
          Click.INTERACTIONS.BUTTON(),
          {
            [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
            [PAYLOAD.SECTION]: section,
          }
        )}
        fireFacebookEvent
      >
        <SimpleButton text="download" type={BUTTON_TYPES.GREEN_SOLID} />
      </DeepLink>
    );
  }

  render() {
    const { currentMetro } = this.props;

    if (!currentMetro) {
      return <NotFound />;
    }

    if (this.redirected) {
      return null;
    }

    return (
      <GTContainer
        canShowGoogleAdbanner
        className={styles.container}
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
          showGTShield: true,
          handleGTShieldClick: this.handleGTShieldClick,
          isMetroPage: true,
        }}
        bannerProps={{
          campaign: DEEPLINK_CAMPAIGNS.METRO_PAGE,
        }}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        <GTGrid>{this.renderCollections()}</GTGrid>
        <GametimeGuarantee guaranteeRef={this.guaranteeRef} />
        <HeroContainer data={downloadAppHeroData}>
          <div>{this.renderGetAppButton('get-app-tile')}</div>
        </HeroContainer>
      </GTContainer>
    );
  }
}

const mapStateToProps = (state, { params: { metroId, categoryGroupId } }) => {
  const supportedCategoryGroupId = categoryGroupId
    ? CATEGORY_GROUP_IDS[categoryGroupId]
    : '';

  const currentMetro = selectMetro(state, metroId);

  const isMarketShareCollectionV2Experiment =
    selectIsMarketShareCollectionV2Experiment(state);

  const collectionDiscoverView = isMarketShareCollectionV2Experiment
    ? COLLECTION_VIEWS.WEB_DISCOVER_MARKETSHARE
    : COLLECTION_VIEWS.WEB_DISCOVER;

  const metroFilterKeys = {
    metro: metroId,
    categoryGroupId: supportedCategoryGroupId,
    view: collectionDiscoverView,
  };

  const { collections } = metroPageCollectionsSelector(state, metroFilterKeys);

  const categoryGroupConfig = supportedCategoryGroupId
    ? getCategoryGroupConfigById(supportedCategoryGroupId)
    : null;

  return {
    collections,
    currentMetro,
    heroOptions: selectHeroCarouselEvents(state, metroFilterKeys),
    branchData: extendDefaultBranchState(state, DEEPLINK_CAMPAIGNS.METRO_PAGE),
    categoryGroupConfig,
    isGTPicksDiscoveryIntegrationEnabled:
      selectIsGTPicksDiscoveryIntegrationEnabled(state),
  };
};
const mapDispatchToProps = {
  redirect,
  fetchAllPerformers,
  fetchPerformersByCategoryGroup,
  showMobileHeroSearchBox,
};

export default withDataLoader(
  connect(mapStateToProps, mapDispatchToProps)(withAppContext(MetroPerformers)),
  {
    key: 'metroPerformers',
    promise: async ({ store: { dispatch, getState }, params: { metroId } }) => {
      await dispatch(fetchMetros()).then(() => {
        const state = getState();

        const currentMetro = selectMetro(state, metroId);
        if (!currentMetro) {
          return {};
        }

        const currentLocation = currentLocationSelector(state);
        if (currentLocation !== metroId) {
          dispatch(updateCurrentLocation(metroId));
        }

        const isMarketShareCollectionV2Experiment =
          selectIsMarketShareCollectionV2Experiment(state);

        const collectionDiscoverView = isMarketShareCollectionV2Experiment
          ? COLLECTION_VIEWS.WEB_DISCOVER_MARKETSHARE
          : COLLECTION_VIEWS.WEB_DISCOVER;

        const promises = [
          dispatch(
            fetchCollections({
              metro: metroId,
              with_results: true,
              view: collectionDiscoverView,
            })
          ),
          dispatch(fetchPerformersByCategoryGroup(currentMetro.id)),
          dispatch(updateUserPreference({ lastVisitedMetro: metroId })),
        ];

        return Promise.all(promises);
      });
    },
  }
);
