// Abort Error - general
const ABORT_ERROR_REGEX = /Fetch is aborted/i;

// Abort Error - user aborted
const ABORT_ERROR_USER_REGEX = /The user aborted a request/i;

// Network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.
const TERMINATED_REQUEST_ERROR_REGEX = /Request has been terminated/i;

// Two timeout patterns found on sentry, the second is from Google ReCaptcha
const TIMEOUT_EXCEEDED_ERROR_REGEX =
  /request timed out after \d+? milliseconds/i;

const UNHANDLED_TIMEOUT_REJECTION_REGEX =
  /promise rejection captured with value: timeout/i;

// Occurs when attempting to access an iframe via autofill
const IFRAME_ACCESS_ERROR_REGEX = /Blocked a frame with origin/i;

// Error: { message: 'Not Found', status: 404}, usually from ButterCMS
const NOT_FOUND_ERROR_REGEX = /not found/i;

// (type=unhandledrejection) captured as promise rejection. Sentry is planning to remove this
const UNHANDLED_PROMISE_REJECTION_REGEX =
  /Event `CustomEvent` \(type=unhandledrejection\) captured as promise rejection/i;

/** ITEMS ARE IN ORDER OF FREQUENCY (4/23) TO REDUCE ITERATION */
export const ignoreErrors: RegExp[] = [
  ABORT_ERROR_REGEX,
  ABORT_ERROR_USER_REGEX,
  TERMINATED_REQUEST_ERROR_REGEX,
  TIMEOUT_EXCEEDED_ERROR_REGEX,
  UNHANDLED_TIMEOUT_REJECTION_REGEX,
  IFRAME_ACCESS_ERROR_REGEX,
  NOT_FOUND_ERROR_REGEX,
  UNHANDLED_PROMISE_REJECTION_REGEX,
];
