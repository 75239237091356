import React from 'react';
import PropTypes from 'prop-types';

import { Click, HomeClickTracker } from 'analytics';
import NoTicketsImage from 'assets/NoTickets.png';
import { TYPES as BUTTON_TYPES } from 'components/Buttons/SimpleButton';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';

import styles from './NoEvents.module.scss';

const NoEvents = ({ eventType }) => {
  return (
    <section className={styles['no-events']}>
      <div className={styles['no-events-content']}>
        <img
          className={styles['no-events-img']}
          src={NoTicketsImage}
          alt="No Tickets"
        />
        <h3 className={styles['no-events-title']}>
          {`You have no ${eventType} events.`}
        </h3>
        <SimpleLinkButton
          className={styles['discover-button']}
          type={BUTTON_TYPES.GREEN_LIGHT_SOLID}
          text="Discover Events"
          to="/"
          clickTracker={new HomeClickTracker().interaction(
            Click.INTERACTIONS.BUTTON()
          )}
        />
      </div>
    </section>
  );
};

NoEvents.propTypes = {
  eventType: PropTypes.string,
};

NoEvents.defaultProps = {
  eventType: 'upcoming',
};

export default NoEvents;
