export const globalContentSelector = (state) => state?.content?.global;

export const globalBannerContentSelector = (state) => {
  const globalContent = globalContentSelector(state);
  const globalBanner = globalContent?.['global-banner'];

  // Banner can be deactivated from ButterCMS dashboard
  if (!globalBanner || !globalBanner.active) return null;

  return globalBanner.global_banner_content;
};

export const selectHeaderPromoTab = (state) => {
  const globalContent = globalContentSelector(state);

  return globalContent?.headerPromoTab ?? null;
};
