import React from 'react';
import classNames from 'classnames';
import SponsorDealBadge from 'ui/SponsorDealBadge';

import FeaturedListingsTag from 'components/FeaturedListingsTag/FeaturedListingsTag';
import Image from 'components/Image/Image';
import MLBBadge from 'components/MLBBadge/MLBBadge';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { Deal, Disclosure, Listing } from 'models';

import PriceMarkdownTag from '../ListingCard/PriceMarkdownTag';

import styles from './ListingFocusedCard.module.scss';

export interface ListingFocusedCardProps {
  listing: Listing;
  allDeals: Record<string, Deal | undefined>;
  allDisclosures: Record<string, Disclosure>;
  isAllInPrice: boolean;
  isMLBEvent: boolean;
  isMobileVariant?: boolean;
  showListingSource?: boolean;
  isExclusivesV1?: boolean;
  bordered?: boolean;
}

const ListingFocusedCard = ({
  listing,
  allDeals,
  allDisclosures,
  isAllInPrice,
  isMLBEvent,
  isMobileVariant = false,
  showListingSource = false,
  isExclusivesV1 = false,
  bordered = false,
}: ListingFocusedCardProps) => {
  const deal = allDeals[listing.dealType];

  const ticketPrice = isAllInPrice
    ? listing.getTotalPrice()
    : listing.getPrice();

  const isWidePriceLabel =
    listing.isDiscounted() && ticketPrice.toString().length > 3;

  const showSponsorDealBadge =
    listing.isSponsoredDeal || (isExclusivesV1 && listing.isExclusivesDeal);

  const showFeaturedDealTag = listing.isFeaturedDeal && !isExclusivesV1;

  return (
    <div
      className={classNames(styles['card-container'], {
        [styles['card-container-mobile']]: isMobileVariant,
        [styles['exclusives-v1']]: isExclusivesV1,
        [styles[`deal-${listing.dealType}`]]: listing.dealType,
        [styles['bordered']]: bordered,
      })}
    >
      <Image
        className={styles.image}
        {...listing.getImageOptions()}
        lazyLoad={false}
      />

      <div className={styles['content-column-left']}>
        {showSponsorDealBadge && (
          <div className={styles['sponsor-deal-badge']}>
            <SponsorDealBadge
              isExclusivesV1={isExclusivesV1}
              variant={listing.dealType}
            />
          </div>
        )}
        {listing.hasZoneTicketDisclosure && <ZoneTag />}
        {isMLBEvent && <MLBBadge />}
        <h3 className={styles['section-name']}>{listing.sectionGroup}</h3>
        <div className={styles['seat-details-container']}>
          <span
            className={classNames(styles['seat-details'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            {listing.section}, Row {listing.row}
          </span>

          <Disclosures
            disclosures={listing.disclosures}
            allDisclosures={allDisclosures}
            onlyIcon
          />
        </div>
        {showListingSource && (
          <span
            className={classNames(styles['listing-source'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            Ticket Source: {listing.source}
          </span>
        )}
      </div>

      <div className={styles['content-column-right']}>
        {showFeaturedDealTag && deal && (
          <div className={styles['badge-item']}>
            <FeaturedListingsTag
              title={deal.title}
              color={deal.color}
              icon={deal.iconURL}
            />
          </div>
        )}

        {!showFeaturedDealTag && listing.isDiscounted() && (
          <div className={styles['badge-item']}>
            <PriceMarkdownTag listing={listing} showDiscountIcon={false} />
          </div>
        )}

        {isAllInPrice && (
          <span className={styles['all-in-pricing']}>All-In</span>
        )}

        <div className={styles['price-info']}>
          {listing.isDiscounted() && (
            <TicketPrice
              className={styles['price-before-discount']}
              isPriceCrossedOut
              price={ticketPrice + listing.getSavingsAmount()}
            />
          )}
          <TicketPrice price={ticketPrice} className={styles['final-price']} />
        </div>
      </div>

      <span className={styles['card-gradient']}></span>
    </div>
  );
};

export default ListingFocusedCard;
