import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import WarningCircleFillIcon from 'icons/WarningCircleFillIcon';
import colors from 'styles/colors.constants';

import styles from './BraintreeHostedFieldGroup.module.scss';

export function BraintreeHostedFieldGroup({
  label,
  hint,
  fieldId,
  isFocused,
  error,
  startIcon,
  endIcon,
}) {
  return (
    <div className={styles['field-group']}>
      <div className={styles['label-row']}>
        <label className={styles.label} htmlFor={fieldId}>
          {label}
        </label>
        {hint && <span className={styles.hint}>{hint}</span>}
      </div>
      <div
        className={classNames(styles['field-row'], {
          [styles.focused]: isFocused,
          [styles.invalid]: !!error,
        })}
      >
        {startIcon && <span className={styles['start-icon']}>{startIcon}</span>}
        <div className={styles.field} id={fieldId} />
        {endIcon && <span className={styles['end-icon']}>{endIcon}</span>}
      </div>
      {error && (
        <div className={styles['error-row']}>
          <WarningCircleFillIcon fill={colors.softRed} width="16" height="16" />
          <span className={styles.error} aria-live="polite">
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
BraintreeHostedFieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.node,
  fieldId: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  error: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};
