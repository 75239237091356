import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HeadRobots from 'components/Head/Robots';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { querySelector } from 'store/modules/location';

import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';

import styles from './ResetPassword.module.scss';

const ResetPassword = ({ query = { user_id: '', temp_token: '' } }) => {
  return (
    <ContainerTemplate header={<MinimalHeader />}>
      <HeadTitle title="Reset Password" />
      <HeadRobots noindex noarchive nofollow />
      <div className={styles['reset-password']}>
        <div className={styles['form-container']}>
          <h1 className={styles['form-title']}>
            Choose A New
            <br />
            Password
          </h1>
          <ResetPasswordForm
            userId={query.user_id}
            tempToken={query.temp_token}
          />
        </div>
      </div>
    </ContainerTemplate>
  );
};

const mapStateToProps = (state) => ({
  query: querySelector(state),
});

ResetPassword.propTypes = {
  query: PropTypes.shape({
    user_id: PropTypes.string,
    temp_token: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(ResetPassword);
