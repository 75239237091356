import React from 'react';
import { connect } from 'react-redux';

import PreloadedImage from 'components/Head/PreloadedImage';
import SearchBox from 'components/Search/SearchBox/SearchBox';
import MetroPicker from 'components/SelectorModals/MetroPicker/MetroPicker';
import { selectIsGTPicksDiscoveryIntegrationEnabled } from 'featureFlags';
import {
  HERO_IMAGE_BOTTOM_URL,
  SEARCH_HERO_BACKGROUND_URL,
} from 'pages/constants';
import { Metro } from 'types';

import styles from './SearchHero.module.scss';

interface SearchHeroProps {
  currentMetro: Metro;
  isMobile: boolean;
  handleHeroSearchboxFocus?: () => void;
  isGTPicksDiscoveryIntegrationEnabled: boolean;
}

const SearchHero = ({
  currentMetro,
  isMobile,
  handleHeroSearchboxFocus,
  isGTPicksDiscoveryIntegrationEnabled,
}: SearchHeroProps) => {
  const searchHeroImageUrl = isGTPicksDiscoveryIntegrationEnabled
    ? HERO_IMAGE_BOTTOM_URL
    : SEARCH_HERO_BACKGROUND_URL;

  return (
    <>
      <PreloadedImage src={searchHeroImageUrl} />
      <div
        style={{
          backgroundImage: `url(${searchHeroImageUrl})`,
        }}
        className={styles['search-hero-container']}
      >
        <div className={styles['search-hero-header']}>
          <span className={styles['search-hero-title']}>
            Find your next great experience.
          </span>
          <span className={styles['search-hero-subtitle']}>
            Sports · Music · Shows
          </span>
        </div>
        <div className={styles['search-hero-box']}>
          <SearchBox
            onFocus={isMobile ? handleHeroSearchboxFocus : undefined}
            isSearchHero
            isHomepage
          />
        </div>
        {isMobile && (
          <MetroPicker
            currentMetro={currentMetro}
            isGTPicksDiscoveryIntegrationEnabled={
              isGTPicksDiscoveryIntegrationEnabled
            }
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: unknown) => ({
  isGTPicksDiscoveryIntegrationEnabled:
    selectIsGTPicksDiscoveryIntegrationEnabled(state),
});

export default connect(mapStateToProps)(SearchHero);
