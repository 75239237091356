import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

import { COLLECTION_SLUGS, COLLECTION_VIEWS } from 'pages/Collection/constants';

import FullEvent from './FullEvent';
import Performer from './Performer';

// TODO: This is gross. This should just be a field on the API response the same way 'slug' is.
const COLLECTION_VIEW_PATH_MAPPING = {
  [COLLECTION_VIEWS.MUSIC_WEB]: COLLECTION_VIEWS.MUSIC,
  [COLLECTION_VIEWS.THEATER_WEB]: COLLECTION_VIEWS.THEATER,
};
const getPathCollectionView = (view) =>
  COLLECTION_VIEW_PATH_MAPPING[view] || view;

export default class Collection {
  constructor(collection = {}, view = '') {
    this.displayTypes = collection.display_types;
    this.emoji = collection.emoji;
    this.id = collection.id;
    this.imageUrl = collection.image_url;
    this.resource = collection.resource;
    this.subtitle = collection.subtitle;
    this.title = collection.title;
    this.slug = collection.slug;
    this.view = view;
    this.eventsList = [];
    this.uniqueEventsList = [];
    this.performers = [];
    this.groupedEvents = [];

    if (_get(collection, 'results.events')) {
      this.currentPage = collection.results.page;
      this.morePages = collection.results.more;
      this.eventsList = collection.results.events.map((el, index) => {
        const event = new FullEvent(el);
        event.setUISectionIndex(index);
        return event;
      });
      this.uniqueEventsList = this._uniqueEventsList(this.eventsList);
    } else if (_get(collection, 'results.performers')) {
      this.currentPage = collection.results.page;
      this.morePages = collection.results.more;
      this.performers = collection.results.performers.map(
        (performer) => new Performer(performer)
      );
    } else if (_get(collection, 'results.grouped_events')) {
      this.currentPage = collection.results.page;
      this.morePages = collection.results.more;
      this.groupedEvents = collection.results.grouped_events.map(
        (event_group) => event_group.map((event) => new FullEvent(event))
      );
    }
  }

  // What section/tile this model will be rendered in
  setUISectionIndex(sectionIndex) {
    this.sectionIndex = sectionIndex;
  }

  // What section/tile this model will be rendered in
  getUISectionIndex() {
    return this.sectionIndex == null ? -1 : this.sectionIndex;
  }

  // Filters this collection's events by categoryGroupId
  setEventsByCategory(categoryGroupId) {
    this.eventsList = this.eventsList.filter(
      (fe) => fe.performersList[0].categoryGroup === categoryGroupId
    );
    this.uniqueEventsList = this._uniqueEventsList(this.eventsList);
    return this;
  }

  getPrimaryPerformer() {
    return this.eventsList ? this.eventsList[0].getPrimaryPerformer() : null;
  }

  getPrimaryPerformerPath() {
    const primaryPerformer = this.getPrimaryPerformer();

    return primaryPerformer ? primaryPerformer.getPath() : '/';
  }

  eventsCount() {
    return this.eventsList.length;
  }

  uniqueEventsCount() {
    return this.uniqueEventsList.length;
  }

  eventsInCategoryCount(category) {
    return (
      !category ||
      this.eventsList.filter(
        (fe) => fe.performersList[0].categoryGroup === category
      ).length
    );
  }

  pathByMetro(metroId) {
    const lTitle = this.title.toLowerCase();
    const hasEventsInTitle = lTitle.includes('event');
    const viewSuffix = this.view ? `/${getPathCollectionView(this.view)}` : '';

    return `/${lTitle.replace(/[^\w\s\d]/gi, '').replace(/ /g, '-')}${
      hasEventsInTitle ? '' : '-events'
    }/${metroId}/collection/${this.slug}${viewSuffix}`;
  }

  _uniqueEventsList(eventsList) {
    return _uniqBy(eventsList, (fullEvent) => fullEvent.getName());
  }

  isPopular = () => {
    return (
      this.slug === COLLECTION_SLUGS.POPULAR ||
      this.slug === COLLECTION_SLUGS.POPULAR_MARKETSHARE
    );
  };
}
