import _get from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';

import { EVENTS as METRO_SELECTOR_EVENTS } from 'components/SelectorModals/MetroSelector/MetroSelector.tracking.constants';
import { isObjectEmpty } from 'utils/objects';

/**
 * To create a tracking configuration, simply follow these steps
 *  - Add your action to ACTIONS object
 *  - add your prop/state configuration (ie what props and state you want to track)
 *    to the ACTION_PROPS configuration object with the correct action value.
 *  - use it! ex:
 *      getTrackingProps(T_ACTIONS.SUBMIT_PURCHASE_LISTING, this.props, this.state)
 */
export const ACTIONS = {
  PURCHASE_STARTED: 'purchase_started',
  CHANGE_SEAT_COUNT: 'change_seat_count',
  SHOW_PAYMENT_SELECTOR: 'show_payment_selector',
  SHOW_ADD_CARD_SCREEN: 'show_add_card_screen',
  UPDATE_DEFAULT_CARD: 'update_default_card',
  ADD_NEW_CARD_SUCCESS: 'add_new_card_success',
  DELETE_CARD: 'delete_card',
  OPEN_EDIT_CARD_MODAL: 'open_edit_card_modal',
  ADD_NEW_CARD_ERROR: 'add_new_card_error',
  SUBMIT_PURCHASE_EMAIL: 'submit_purchase_email',
  SUBMIT_PURCHASE_PHONE: 'submit_purchase_phone',
  SENT_MAGIC_LINK: 'sent_magic_link',
  SUBMIT_PURCHASE_CREDIT: 'submit_purchase_credit',
  APPLE_PAY_ERROR: 'apple_pay_error',
  SUBMIT_TMM_TICKET: 'submit_tmm_ticket',
  SUBMIT_TEXT_TRANSFER_TICKET: 'submit_text_transfer_ticket',
  PURCHASE_CONFIRM: 'purchase_confirm',
  PURCHASE_SECURE: 'purchase_secure',
  PURCHASE_POLLING_DONE: 'purchase_polling_done',
  PURCHASE_POLLING_TIMED_OUT: 'purchase_polling_timed_out',
  PURCHASE_VERIFY_ADDRESS: 'purchase_verify_address',
  PURCHASE_VERIFY_DELIVERY_ADDRESS: 'purchase_verify_delivery_address',
  SUBMIT_PURCHASE_ZIP: 'submit_purchase_zip',
  CLICK_INSTALL_APP: 'click_install_app',
  CLICK_BROWSE_UPSELL: 'click_browse_upsell',
  TAB_CLICKED: 'tab_clicked',
  VIEWED_PERFORMER: 'viewed_performer',
  SIGN_UP: 'sign_up',
  NO_INVENTORY_EVENT: 'no_inventory_event',
  OPEN_AFFIRM_MODAL: 'open_affirm_modal',
};

const ACTIONS_PROPS = {
  purchase_started: {
    props: {
      seat_count: 'seatCount',
      purchase_fees: 'extendedPurchase.fees',
      purchase_total_price: 'extendedPurchase.totalPrice',
    },
  },
  change_seat_count: {
    props: {
      seat_count: 'seatCount',
    },
  },
  show_payment_selector: {},
  show_add_card_screen: {},
  update_default_card: {},
  add_new_card_success: {},
  add_new_card_error: {},
  submit_purchase_email: {},
  sent_magic_link: {},
  submit_purchase_credit: {},
  submit_tmm_ticket: {},
  submit_text_transfer_ticket: {},
  purchase_confirm: {},
  purchase_secure: {
    props: {
      transaction_id: 'transactionId',
    },
  },
  [ACTIONS.PURCHASE_POLLING_DONE]: {
    props: {
      purchasePollingDone: 'purchasePollingDone',
      purchasePollingResultExplanation: 'purchasePollingResultExplanation',
    },
  },
  [ACTIONS.PURCHASE_POLLING_TIMED_OUT]: {},
  submit_purchase_phone: {},
  purchase_verify_address: {},
  purchase_verify_delivery_address: {},
  submit_purchase_zip: {},
  click_install_app: {}, // no need to track device since we have that in super props
  click_browse_upsell: {},
  tab_clicked: {
    props: {
      tab: 'text',
    },
  },
  viewed_performer: {
    props: {
      name: 'name',
      team_id: 'team_id',
    },
  },
  [METRO_SELECTOR_EVENTS.TAPPED_CURRENT_LOCATION_BUTTON]: {},
  [METRO_SELECTOR_EVENTS.SELECT_METRO]: {},
  [METRO_SELECTOR_EVENTS.RETURN_TO_METRO]: {},
  sign_up: {
    state: {
      loginError: 'loginError',
    },
  },
  no_inventory_event: {
    props: {
      performer: 'performer.team_id',
      event: 'event.id',
    },
  },
};

/**
 * mpActions={getTrackingProps(SUBMIT_PURCHASE_LISTING)}
 * @param action
 * @param props
 * @param state
 * @param extras
 * @returns {{action: string}}
 */
export const getTrackingProps = (action, props = {}, state = {}, extras) => {
  // Find the action in actionProps
  const fullAction = ACTIONS_PROPS[action];

  if (!fullAction) {
    return console.warn(
      'getTrackingProps',
      `Could not find tracking description for key ${action}.
      Did you forget to add it to ACTION_PROPS?`
    );
  }

  if (
    (!isObjectEmpty(fullAction.props) && isObjectEmpty(props) && !extras) ||
    (!isObjectEmpty(fullAction.state) && isObjectEmpty(state) && !extras)
  ) {
    console.warn(
      'getTrackingProps',
      'It looks like you forgot to pass props or state to this function'
    );
  }

  // Create a simple tracking event with action.
  const event = { action };

  // If the action has tracking props, include them in event
  const actionProps = fullAction.props || {};
  Object.keys(actionProps).forEach((trackingKey) => {
    // Try to find the desired prop key in the component's props
    const propValue = _get(props, actionProps[trackingKey]);

    if (propValue !== undefined) {
      event[trackingKey] = propValue;
    } else {
      console.warn(
        'getTrackingProps',
        `Could not find tracking prop ${trackingKey}
        in component props`
      );
    }
  });

  const actionState = fullAction.state || {};
  Object.keys(actionState).forEach((stateKey) => {
    // Try to find the desired state key in the component's state
    const stateValue = _get(state, actionState[stateKey]);

    if (stateValue !== undefined) {
      event[stateKey] = stateValue;
    } else {
      console.warn(
        'getTrackingProps',
        `Could not find tracking state ${stateKey}
        in component state`
      );
    }
  });

  // If extra arguments
  if (extras && _isPlainObject(extras)) {
    Object.assign(event, extras);
  }

  return event;
};
