import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { PerformerClickTracker } from 'analytics';
import GTFullWidthList from 'components/GTFullWidthList/GTFullWidthList';
import Link from 'components/Link/Link';
import {
  CATEGORIES,
  CATEGORY_URLS,
  getCategoryPath,
} from 'store/modules/categories/category.helpers';
import {
  getLocalPerformers,
  makeSelectFilteredHeaderPerformersByCategory,
} from 'store/modules/data/Performers/selectors';

import styles from './CategoriesDropdown.module.scss';

const _CategoryPerformersList = (props) => {
  const { performers, limit, category, onClick, localPerformers } = props;

  const performersLimited = performers.slice(0, limit);
  const listItems = performersLimited.map((performer) => ({
    id: performer.id,
    text: performer.name,
    link: performer.getPath(),
    clickTracker: new PerformerClickTracker(performer),
    isLocal:
      localPerformers && !!localPerformers.find((e) => e.id === performer.id),
  }));
  let longCategory = _get(CATEGORY_URLS[category], 'title', `${category}`);

  // Shows Tickets -> Theater Tickets
  if (category === CATEGORIES.THEATER) {
    longCategory = 'Theater';
  }

  // Music Tickets -> Concert Tickets
  if (category === CATEGORIES.MUSIC) {
    longCategory = 'Concert';
  }

  const seeAllText = `SEE ALL ${longCategory.toUpperCase()} TICKETS`;

  return (
    <>
      <GTFullWidthList
        key={category}
        items={listItems}
        vertical
        onClick={onClick}
        listClassName="categoryPerformerList"
      />
      <Link
        to={getCategoryPath(category)}
        className={styles['see-all']}
        onClick={onClick}
        testId="header-dropdown-see-all"
      >
        <span className={styles['see-all-link']}>{seeAllText}</span>
      </Link>
    </>
  );
};

_CategoryPerformersList.propTypes = {
  performers: PropTypes.array.isRequired,
  localPerformers: PropTypes.array,
  category: PropTypes.string,
  limit: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

const _styled = _CategoryPerformersList;

function mapStateToProps(state, props) {
  const filterHeaderPerformersByCategory =
    makeSelectFilteredHeaderPerformersByCategory();

  return {
    performers: filterHeaderPerformersByCategory(state, props),
    localPerformers: getLocalPerformers(state, props),
  };
}

const CategoryPerformersList = connect(mapStateToProps)(_styled);

export default CategoryPerformersList;
