import React from 'react';

import { GTLogoIcon } from 'icons';
import colors from 'styles/colors.constants';
import { isLastCall, isToday } from 'utils/datetime';

export default class EventTag {
  TAGS = {
    LAST_CALL: {
      name: 'LAST CALL',
      colorCode: 'last-call',
      inlineColor: colors.gametimeGreenLight,
    },
    TODAY: {
      name: 'TODAY',
      colorCode: 'today',
      inlineColor: colors.todayTag,
    },
    TOP_PICK: {
      name: 'TOP PICK',
      colorCode: 'top-pick',
      inlineColor: colors.white,
    },
    DEALS_AVAILABLE: {
      name: 'DEALS AVAILABLE',
      colorCode: 'deals-available',
      inlineColor: colors.gametimeGreenLight,
      icon: <GTLogoIcon fill={colors.black} />,
    },
  };

  constructor(datetimeUtc, datetimeLocal, timezone) {
    this.datetimeUtc = datetimeUtc;
    this.datetimeLocal = datetimeLocal;
    this.timezone = timezone;
  }

  /*
  1. If event is in the next N hours, it's a LAST_CALL tag
  2. If event is today, it's a TODAY tag
  3. tbc....
  */
  props(hours = 3, isTopPick = false, isGTPicksCollection = false) {
    if (isGTPicksCollection) {
      return this.TAGS.DEALS_AVAILABLE;
    }

    if (isLastCall(this.datetimeUtc, this.timezone, hours)) {
      return this.TAGS.LAST_CALL;
    }
    if (isToday(this.datetimeUtc, this.timezone)) {
      return this.TAGS.TODAY;
    }
    if (isTopPick) {
      return this.TAGS.TOP_PICK;
    }
    return null;
  }
}
