import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { device, useMediaQuery } from 'hooks/useMediaQuery';

import { SEAT_MAP_IMAGE_WIDTH } from '../../SeatMap.constants';
import { useTransformContext } from '../../SeatMapInteraction';

import styles from '../SeatMap.module.scss';

const SeatMapImage = React.forwardRef(
  ({ scale = 1, onLoad, mapUrl, onError, noZoomOnLoad }, ref) => {
    const [loading, setLoading] = useState(true);
    const [imgSize, setImgSize] = useState(-1);
    const isMobile = useMediaQuery(device.down.md);
    const maxScale = Math.ceil(scale);
    const minMapSize = SEAT_MAP_IMAGE_WIDTH / 4;
    const imageLoadTimeRef = useRef(0);
    let srcUrl = `${mapUrl}?width=${imgSize}`;
    const { zoomIn } = useTransformContext();

    useEffect(() => {
      const mapSize = () => {
        if (isMobile || maxScale !== 1) {
          return setImgSize(maxScale * minMapSize);
        }
        setImgSize(maxScale * minMapSize * 2);
      };
      mapSize();
    }, [isMobile, loading, maxScale, minMapSize]);

    useEffect(() => {
      // start the timer when the component mounts
      imageLoadTimeRef.current = performance.now();

      // cleanup when the component unmounts
      return () => {
        imageLoadTimeRef.current = 0;
      };
    }, []);

    const handleLoad = () => {
      // calculate the time difference in milliseconds
      const imgLoadTime = Math.round(
        performance.now() - imageLoadTimeRef.current
      );

      setLoading(false);
      onLoad(imgLoadTime);

      if (!noZoomOnLoad) {
        setTimeout(() => zoomIn(0.4), 300);
      }
    };

    const handleError = () => {
      onError(true);
    };

    if (!mapUrl && loading) return null;

    if (imgSize >= 3200) {
      srcUrl = `${srcUrl}&disable=upscale`;
    }

    return (
      <img
        ref={ref}
        alt="Seat Map"
        draggable={false}
        className={styles.seatMapImage}
        onLoad={loading ? handleLoad : undefined}
        onError={handleError}
        src={srcUrl}
      />
    );
  }
);

SeatMapImage.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  scale: PropTypes.any,
  noZoomOnLoad: PropTypes.bool.isRequired,
};

export default SeatMapImage;
