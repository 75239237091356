import React from 'react';
import { getYear, isToday, isTomorrow, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { FullEvent } from 'models';
import {
  DAY_DATE_YEAR_FORMAT,
  getDefaultFormat,
  getTBDFormat,
} from 'utils/datetime';

const propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  type: PropTypes.oneOf(['DEFAULT', 'DOTTED', 'DOTTED_HUMAN']),
  className: PropTypes.string,
  Component: PropTypes.string,
};

const defaultProps = {
  type: 'DEFAULT',
  Component: 'span',
};

function getHumanReadableDate(datetimeLocal, defaultDate) {
  if (isToday(parseISO(datetimeLocal))) {
    return 'Today';
  }
  if (isTomorrow(parseISO(datetimeLocal))) {
    return 'Tomorrow';
  }

  return defaultDate;
}

const EventDateTime = ({ fullEvent, type, className, Component }) => {
  const {
    event: { tbd, datetimeLocal },
  } = fullEvent;

  const date =
    getYear(parseISO(datetimeLocal)) === getYear(new Date())
      ? fullEvent.getDate()
      : fullEvent.getDate(DAY_DATE_YEAR_FORMAT);

  const time = fullEvent.getTime();
  const dateHuman = getHumanReadableDate(datetimeLocal, date);

  switch (type) {
    case 'DOTTED':
      return <Component className={className}>{`${date} ⋅ ${time}`}</Component>;
    case 'DOTTED_HUMAN':
      return (
        <Component className={className}>{`${dateHuman} ⋅ ${time}`}</Component>
      );
    case 'DATE_ONLY':
      return <Component className={className}>{date}</Component>;
    case 'DEFAULT':
    default:
      const dateFormat = tbd ? getTBDFormat : getDefaultFormat;
      const defaultFormatted = dateFormat(fullEvent.event.datetimeLocal);
      return <Component className={className}>{defaultFormatted}</Component>;
  }
};

EventDateTime.propTypes = propTypes;
EventDateTime.defaultProps = defaultProps;

export default EventDateTime;
