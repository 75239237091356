import React from 'react';

const GiftIcon = ({ width, height, fill = 'url(#paint0_linear)' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C2 7.44772 2.44772 7 3 7H21C21.5523 7 22 7.44772 22 8V11H2V8Z"
        fill={fill}
      />
      <path
        d="M14.5 8C15.8807 8 17 6.88071 17 5.5C17 4.11929 15.8807 3 14.5 3C13.1193 3 12 4.11929 12 5.5"
        stroke={fill}
        strokeWidth="2"
      />
      <path
        d="M9.5 8C8.11929 8 7 6.88071 7 5.5C7 4.11929 8.11929 3 9.5 3C10.8807 3 12 4.11929 12 5.5V7.5"
        stroke={fill}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C3.44772 7 3 7.44772 3 8V11H11H13H21V8C21 7.44772 20.5523 7 20 7H4ZM21 13H13V22H20C20.5523 22 21 21.5523 21 21V13ZM11 22V13H3V21C3 21.5523 3.44772 22 4 22H11Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="4"
          y1="4"
          x2="4"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65FFB5" />
          <stop offset="1" stopColor="#00D5E6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

GiftIcon.defaultProps = {
  width: '24',
  height: '24',
};

export default GiftIcon;
