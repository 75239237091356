import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { DEFAULT_SEAT_COUNT } from 'store/modules/resources/constants';

export const seatCountSelector = (state) =>
  parseInt(state.userPreference.seatCount, 10);

export const defaultSeatCountSelector = (state, props) => {
  const selectedSeatCount =
    seatCountSelector(state) || _get(props, 'listing.defaultSeatCount');
  const listingTicketQuantites = _get(props, 'listing.ticketQuantities', []);

  if (listingTicketQuantites.includes(selectedSeatCount)) {
    return selectedSeatCount;
  } else if (listingTicketQuantites.includes(DEFAULT_SEAT_COUNT)) {
    return DEFAULT_SEAT_COUNT;
  } else {
    return listingTicketQuantites[0];
  }
};

const prefeeSeatPriceSelector = (state, props) => props.listing.getPrice();
const seatPriceSelector = (state, props) => props.listing.getTotalPrice();
const seatFeeSelector = (state, props) =>
  props.listing.getTotalPrice() -
  props.listing.getPrice() -
  props.listing.getSalesTax();
const discountSelector = (state, props) => props.listing.discount;
const salesTaxSelector = (state, props) => props.listing.getSalesTax();

export const extendedPurchaseSelector = createSelector(
  [
    seatCountSelector,
    prefeeSeatPriceSelector,
    seatPriceSelector,
    seatFeeSelector,
    discountSelector,
    salesTaxSelector,
  ],
  (seatCount, prefeeSeatPrice, seatPrice, seatFee, discount, salesTax) => ({
    totalPrefeePrice: seatCount * prefeeSeatPrice,
    fees: seatCount * seatFee,
    totalPrice: seatCount * seatPrice,
    prefeeSeatPrice,
    seatFee,
    discount,
    salesTax,
  })
);

export const extendedDefaultSeatCountPurchaseSelector = createSelector(
  [
    defaultSeatCountSelector,
    prefeeSeatPriceSelector,
    seatPriceSelector,
    seatFeeSelector,
    discountSelector,
    salesTaxSelector,
  ],
  (seatCount, prefeeSeatPrice, seatPrice, seatFee, discount, salesTax) => ({
    totalPrefeePrice: seatCount * prefeeSeatPrice,
    fees: seatCount * seatFee,
    totalPrice: seatCount * seatPrice,
    prefeeSeatPrice,
    seatFee,
    discount,
    salesTax,
  })
);
