import React from 'react';
import PropTypes from 'prop-types';

import { getDeliveryDisplayProps } from 'components/DeliveryFormat/delivery.format.constants';
import DeliveryIcon from 'components/DeliveryFormat/DeliveryIcon/DeliveryIcon';
import colors from 'styles/colors.constants';

import styles from './DeliveryBadge.module.scss';

const propTypes = {
  deliveryFormat: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const DeliveryBadge = (props) => {
  const { deliveryFormat, isMobile } = props;
  const deliveryDisplayProps = getDeliveryDisplayProps(deliveryFormat);

  return (
    <div className={styles['ticket-type']}>
      <DeliveryIcon
        deliveryFormat={deliveryFormat}
        iconProps={{ fill: colors.white }}
      />
      <span className={styles.text}>
        {deliveryDisplayProps.title.join(isMobile ? '\n' : ' ')}
      </span>
    </div>
  );
};

DeliveryBadge.propTypes = propTypes;

export default DeliveryBadge;
