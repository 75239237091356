import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';

export const BTN_TYPES = {
  GET_APP: 'get_app',
  LIST_VIEW: 'list_view',
  VIEW_METRO: 'view_metro',
} as const;

export const PAYLOAD = {
  ABSOLUTE_INDEX: 'absolute_index',
  ACTION: 'action',
  API_SEQUENCE: 'api_sequence',
  BRANCH_URL: 'branch_url',
  DEAL_TYPE: 'deal_type',
  EVENT_TAGS: 'event_tags',
  FEATURED_TYPE: 'featured_type',
  IS_PROMO: 'is_promo',
  IS_SPONSORED: 'is_sponsored',
  ITEM_INDEX: 'item_index',
  MODAL: 'modal',
  MLB_MARKETING_OPT_IN_ELIGIBLE: 'mlb_marketing_opt_in_eligible',
  MLB_MARKETING_OPT_IN: 'mlb_marketing_opt_in',
  PAGE_TYPE: 'page_type',
  PROMO_ELIGIBLE: 'promo_eligible',
  QUERY_ID: 'query_id',
  QUANTITY: 'quantity',
  POST_FEE_PRICE: 'post_fee_price',
  REASON: 'reason',
  RESULT_POSITION: 'result_position',
  SEARCH_INDEX: 'search_index',
  SEARCH_SESSION_ID: 'search_session_id',
  SEARCH_TERM: 'search_term',
  SECTION: 'section',
  SECTION_INDEX: 'section_index',
  SECTION_TITLE: 'section_title',
  SEQUENCE: 'sequence',
  SORT: 'sort',
  SUBSCRIPTION: 'subscription',
  TYPE: 'type',
  UUID: 'uuid',
  ZONE_DEAL_AVAILABLE: 'zone_deal_available',
  FLASH_DEAL_AVAILABLE: 'flash_deal_available',
  FEATURED_DEAL_AVAILABLE: 'featured_deal_available',
  ZONE_DEAL_UNLOCKED: 'zone_deal_unlocked',
  FULLEVENT: 'fullEvent',
  LISTING: 'listing',
  DEAL: 'deal',
  MODE: 'mode',
  LISTINGS_IDS: 'listings_ids',
  INTERACTION: 'interaction',
  ZOOM_LEVEL: 'zoom_level',
  SEARCH_TEST_ID: 'search_test_id',
  SEARCH_VARIANT_ID: 'search_variant_id',
} as const;

export const LOCKED_DEAL = {
  LIST: 'list',
  CAROUSEL: 'carousel',
} as const;

export const INTERACTION = {
  SWIPE: 'swipe',
  CLICK: 'click',
  SEAT_MAP_PIN: 'seat_map_pin',
} as const;

export const TRACK = {
  DEV: 'dev',
  INTERACTION: 'interaction',
  PAGE_TYPE: 'pageType',
  PAYLOAD: 'payload',
  SOURCE_PAGE_TYPE: 'sourcePageType',
  TARGET_PAGE_TYPE: 'targetPageType',
} as const;

export const SOURCE_PAGE_TYPE_NAMES = {
  ACCOUNT: 'account',
  ACCOUNT_VERIFICATION: 'account_verification',
  ADD_CREDIT_CARD_IMAGE: 'add_credit_card_image',
  ADD_CREDIT_CARD_MANUAL: 'add_credit_card_manual',
  ADD_EMAIL: 'add_email',
  ADD_PHONE_NUMBER: 'add_phone_number',
  ALL_IN_FILTER: 'all_in_filter',
  BLOG: 'blog',
  BLOG_POST: 'blog_post',
  CATEGORY: 'category',
  CHANGE_PAYMENT_METHOD: 'change_payment_method',
  CHECKOUT: 'checkout',
  COLLECTION: 'collection',
  CVV_VERIFICATION: 'cvv_verification',
  EMAIL: 'email',
  EVENT: 'event',
  HOMEPAGE: 'homepage',
  INSTALL: 'install',
  LISTING: 'listing',
  LOGIN: 'login',
  MAINMETROPAGE: 'main_metro_seo',
  METRO: 'metro',
  MY_ACCOUNT: 'account',
  MY_TICKETS: 'my_tickets',
  NONE: 'none',
  NOT_FOUND: 'not_found',
  OMNIBAR_FILTER: 'ticket_filter',
  OMNIBAR_QUANTITY: 'ticket_quantity',
  OMNIBAR_SORT: 'ticket_sort',
  ORDER_CONFIRMATION: 'order_confirmation',
  PERFORMER: 'performer',
  PROFILE: 'profile',
  REDEEM_CODE: 'redeem_code',
  SCHEDULE: 'schedule',
  SEARCH: 'search',
  SELLING: 'selling',
  SMS_VERIFICATION: 'sms_verification',
  SPORTSBYMETRO: 'metro_category_seo',
  STATIC_PAGE: 'static_page',
  SUPER_BOWL: 'superbowl',
  TICKET: 'ticket',
  TICKET_SHARE: 'ticket_share',
  TRANSFER_TICKETS: 'transfer_tickets',
  UNKNOWN: 'unknown',
  VERIFY_BILLING_ADDRESS: 'verify_billing_address',
  VERIFY_SHIPPING_ADDRESS: 'verify_shipping_address',
  VERIFY_ZIPCODE: 'verify_zipcode',
  VENUE: 'venue',
} as const;

export const TARGET_PAGE_TYPE_NAMES = {
  ACCOUNT: 'account',
  ADD_CREDIT_CARD_IMAGE: 'add_credit_card_image',
  ADD_CREDIT_CARD_MANUAL: 'add_credit_card_manual',
  AFFIRM_PAYMENT_METHOD: 'affirm_payment_method',
  ALL_IN_FILTER: 'all_in_filter',
  APPLE_PAY_PAYMENT_METHOD: 'apple_pay_payment_method',
  BETMGM: 'betmgm',
  BLOG_POST: 'blog_post',
  CATEGORY: 'category',
  CHANGE_PAYMENT_METHOD: 'change_payment_method',
  CHECKOUT: 'checkout',
  COLLECTION: 'collection',
  CVV_VERIFICATION: 'cvv_verification',
  EMAIL: 'email',
  EVENT: 'event',
  HOMEPAGE: 'homepage',
  INSTALL: 'install',
  LISTING: 'listing',
  MAGIC_LINK: 'magic_link',
  METRO: 'metro',
  MLB_ALL_STAR_WEEK: 'mlb_all_star_week',
  MLB_OPENING_DAY: 'mlb_opening_day',
  MY_TICKETS: 'my_tickets',
  NCAA_TOURNAMENT: 'ncaa_tournament',
  NBA_ALL_STAR_WEEKEND: 'nba_all_star_weekend',
  NBA_FINALS: 'nba_finals',
  NONE: 'none',
  OMNIBAR_FILTER: 'ticket_filter',
  OMNIBAR_QUANTITY: 'ticket_quantity',
  OMNIBAR_SORT: 'ticket_sort',
  ORDER_CONFIRMATION: 'order_confirmation',
  PAYPAL_PAYMENT_METHOD: 'paypal_payment_method',
  PERFORMER: 'performer',
  PRICE_BUCKET: 'price_bucket',
  PROFILE: 'profile',
  REDEEM_CODE: 'redeem_code',
  SCHEDULE: 'schedule',
  SEARCH: 'search',
  SELLING: 'selling',
  SMS_VERIFICATION: 'sms_verification',
  STATIC_PAGE: 'static_page',
  SUPER_BOWL: 'superbowl',
  TICKET: 'ticket',
  TICKET_SHARE: 'ticket_share',
  TRANSFER_TICKETS: 'transfer_tickets',
  UNKNOWN: 'unknown',
  VENUE: 'venue',
} as const;

export const PAYMENT_METHOD_NAMES = {
  [PURCHASE_TYPE.CREDIT_CARD_ON_FILE]: 'credit_card_on_file',
  [PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY]: 'manual_entry',
  [PURCHASE_TYPE.APPLE_PAY]: 'apple_pay',
  [PURCHASE_TYPE.AFFIRM_PAY]: 'affirm_pay',
  [PURCHASE_TYPE.GOOGLE_PAY]: 'pay_with_google',
  [PURCHASE_TYPE.PAYPAL_PAY]: 'pay_with_paypal',
} as const;
