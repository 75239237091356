export const PENDING_SEAT_VALUES = ['', 'TBD', 'TBA', '*'];

export const GENERAL_ADMISSIONS_SUBSTRINGS = [
  'general',
  'standing',
  'lawn',
  'floor',
];

export const GENERAL_ADMISSIONS_EXACT_MATCHES = ['ga', 'sro'];
