import { useCallback, useEffect, useMemo, useState } from 'react';
import { create as braintreeDataCollectorCreate } from 'braintree-web/data-collector';
import { create as braintreeHostedFieldsCreate } from 'braintree-web/hosted-fields';

import config from 'config/config';
import { saveUserBraintreeData } from 'store/modules/userPurchases/actions';

function getFieldErrorMessage(fieldName, field) {
  let error = null;

  switch (fieldName) {
    case 'number': {
      if (field.isEmpty) {
        error = 'Credit card number is required';
      } else if (!field.isValid) {
        error = 'Please check if you typed the correct card number';
      }
      break;
    }
    case 'expirationDate': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid card expiry date';
      }
      break;
    }
    case 'cvv': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid 3-4 digit security code';
      }
      break;
    }
    default: {
      break;
    }
  }

  return error;
}

export function useBraintreeHostedFields(dispatch) {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldFocus, setFieldFocus] = useState({});
  const setFieldError = useCallback(
    (fieldName, errorMessage) =>
      setFieldErrors((fieldErrorsState) => ({
        ...fieldErrorsState,
        [fieldName]: errorMessage,
      })),
    []
  );

  /**
   * Creates a Braintree Hosted Fields instance and sets the value in state.
   *
   * @param {Object} options — BraintreeWeb.create options, omitting
   *   authorization.
   *   see: https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#.create
   * @returns {Promise} Promise resolving to the hostedFieldsInstance, useful
   *   for adding additional event handlers or configurations.
   *   see: https://braintree.github.io/braintree-web/current/HostedFields.html
   */
  const createHostedFields = useCallback(
    (options) =>
      braintreeHostedFieldsCreate({
        authorization: config.BRAINTREE_CLIENT_TOKEN,
        ...options,
      })
        .then((hostedFieldsInstance) => {
          function validateOnBlur(event) {
            const fieldName = event.emittedBy;
            const field = event.fields[fieldName];
            const errorMessage = getFieldErrorMessage(fieldName, field);

            setFieldError(fieldName, errorMessage);
          }

          function clearErrorOnFieldValid(event) {
            const fieldName = event.emittedBy;
            const field = event.fields[fieldName];

            if (field.isValid) {
              setFieldError(fieldName, null);
            }
          }

          hostedFieldsInstance.on('focus', (event) => {
            setFieldFocus((fields) => ({
              ...fields,
              [event.emittedBy]: true,
            }));
          });
          hostedFieldsInstance.on('blur', (event) => {
            validateOnBlur(event);
            setFieldFocus((fields) => ({
              ...fields,
              [event.emittedBy]: false,
            }));
          });
          hostedFieldsInstance.on('validityChange', clearErrorOnFieldValid);

          setHostedFieldsInstance(hostedFieldsInstance);

          return hostedFieldsInstance;
        })
        .catch((error) => {
          console.error('Error instantiating Braintree Hosted Fields', error);
        }),
    [setFieldError]
  );

  useEffect(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    return () => {
      hostedFieldsInstance.teardown();
    };
  }, [hostedFieldsInstance]);

  const hostedFields = useMemo(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    return {
      getState: hostedFieldsInstance.getState,

      /**
       * Validates fields found in Braintree Hosted Fields instance state,
       * returning true if all fields are valid or false if any field
       * contains an error.
       */
      validate() {
        const fieldsState = hostedFieldsInstance.getState().fields;
        const fieldErrors = Object.entries(fieldsState).reduce(
          (_fieldErrors, [fieldName, field]) => {
            const errorMessage = getFieldErrorMessage(fieldName, field);
            if (errorMessage) {
              _fieldErrors[fieldName] = errorMessage;
            }
            return _fieldErrors;
          },
          {}
        );

        setFieldErrors(fieldErrors);

        return Object.keys(fieldErrors).length === 0;
      },

      /**
       * Tokenize a payment method with hosted field instance data and
       * return the response. Also has the side effect of dispatching an
       * action to save Braintree user device data. This async function
       * should be called after using validateFields.
       */
      async tokenize() {
        const [payload] = await Promise.all([
          hostedFieldsInstance.tokenize(),
          braintreeDataCollectorCreate({
            authorization: config.BRAINTREE_CLIENT_TOKEN,
          })
            .then((dataCollector) => {
              dispatch(saveUserBraintreeData(dataCollector.deviceData));
            })
            .catch(console.error),
        ]);

        return payload;
      },

      /**
       * Set an attribute on a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {string} options.value — the value to set for the attribute.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is set. Includes an error if one occurred. If the
       *   attribute is set successfully, no data is returned.
       */
      setAttribute:
        hostedFieldsInstance.setAttribute.bind(hostedFieldsInstance),

      /**
       * Remove an attribute from a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is removed. Includes an error if one occurred. If the
       *   attribute is removed successfully, no data is returned.
       */
      removeAttribute:
        hostedFieldsInstance.removeAttribute.bind(hostedFieldsInstance),
    };
  }, [hostedFieldsInstance, dispatch]);

  return {
    createHostedFields,
    hostedFields,
    fieldFocus,
    fieldErrors,
    setFieldError,
  };
}
