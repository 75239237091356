import React from 'react';

import styles from './MetroSelector.module.scss';

interface MetroContainerProps {
  noEvents: boolean;
  children: React.ReactNode;
}

const MetroSelectorContainer = ({
  children,
  noEvents = false,
}: MetroContainerProps) => {
  return (
    <div className={styles['metro-selector']}>
      <div className={styles.metro}>
        {!noEvents && (
          <span className={styles['metro-events-in-text']}>Events In</span>
        )}
        <div
          data-cy="metro-text-container"
          className={styles['metro-text-container']}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MetroSelectorContainer;
