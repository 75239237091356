import React from 'react';

export default ({ width = 24, height = 24, fill = '#ffffff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    data-testid="discount-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.707078 8.29289C0.316554 8.68342 0.316553 9.31658 0.707077 9.70711L6.29286 15.2929C6.68339 15.6834 7.31655 15.6834 7.70708 15.2929L14.7071 8.29289C14.8946 8.10536 15 7.851 15 7.58579V2C15 1.44772 14.5523 1 14 1H8.41418C8.14897 1 7.89462 1.10536 7.70708 1.29289L0.707078 8.29289ZM11.8535 3.35355C11.6583 3.15829 11.3417 3.15829 11.1464 3.35355L10.3535 4.14645C10.1583 4.34171 10.1583 4.65829 10.3535 4.85355L11.1464 5.64645C11.3417 5.84171 11.6583 5.84171 11.8535 5.64645L12.6464 4.85355C12.8417 4.65829 12.8417 4.34171 12.6464 4.14645L11.8535 3.35355Z"
      fill={fill}
    />
  </svg>
);
