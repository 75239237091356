export default {
  //
  white: '#FFFFFF',
  lighterGray: '#FAFAFA',
  gray210: '#D2D2D2',
  gray232: '#E8E8E8',
  gray235: '#EBEBEB',
  gray240: '#eaeaec',
  gray241: '#e6e6e6',
  gray242: '#d3d3d4',
  gray243: '#5f5f62',
  lightGray: '#F0F0F0',
  gray187: '#BBBBBB',
  gray: '#AAAAAA',
  gray149: '#959595',
  gray122: '#7A7A7A',
  darkerGray: '#333333',
  black40: '#282828',
  black35: '#232323',
  black20: '#141414',
  black: '#000000',
  blackTransparent: 'rgba(0, 0, 0, 0.85)',
  blackTransparentLight: 'rgba(0, 0, 0, 0.5)',
  whiteTransparent: 'rgba(255, 255, 255, 0.85)',
  whiteTransparentLight: 'rgba(255, 255, 255, 0.5)',
  infoBlue: '#76BBFC',
  // Updated Grayscale
  black21: '#151517',
  gray900: '#0E0E0F',
  gray800: '#1C1C20',
  gray700: '#27272A',
  gray600: '#38383D',
  gray500: '#5F5F64',
  gray400: '#8B8B90',
  gray300: '#C4C4C8',
  gray200: '#E4E4E6',
  gray100: '#F4F4F6',
  gametimeGray: '#C4C4C6',
  gametimeGreen: '#19CE85',
  gametimeGreenLight: '#65FFB5',
  gametimeGreenDark: '#009C6D',
  homepageGreen: '#19CE85',
  red: '#ff0000',
  softRed: '#EB5757',
  brightPink: '#FF366F',
  brightPurple: '#8C1FFF',
  primaryLink: '#337ab7',
  primaryLinkHover: '#23527c',
  transparent: 'transparent',
  sportColor: '#14C47D',
  concertColor: '#FF366F',
  theaterColor: '#8C1FFF',
  base: '#161616',
  base1: '#262626',
  base2: '#1F2020',
  base3: '#282828',
  base4: '#1C1C20',
  baseLight: '#FFFFFF',
  baseLight1: '#F8F8F8',
  baseLight2: '#EFEFEF',
  baseLight3: '#EEEEEE',
  primary: '#FFFFFF',
  secondary: '#14C47D',
  borderDark: '#555555',
  border: '#333333',
  borderMedium: '#CCCCCC',
  borderLight: '#F0F0F0',
  fontLighter: '#ADADAD',
  fontLighter1: '#CCCCCC',
  fontLight: '#969696',
  fontLightMedium: '#878787',
  fontDark: '#555555',
  darkThemeBase: '#232323',
  darkThemeFontLighter: '#E8E8E8',
  darkThemeFontLight: '#BBBBBB',
  darkThemeFontLight1: '#959595',
  darkThemeFontMedium: '#7A7A7A',
  darkThemeFontDark: '#3B3B3B',
  textShadowLight: '0 1px 2px rgba(0, 0, 0, 0.5)',
  todayTag: '#FF6581',
  softBlue: '#6BAEEC',
  softPurple: '#505cd4',
  softYellow: '#f2c94c',
  teal: '#4AE2D0',
  magenta: '#FF4B76',
  yellowTernary: '#FFED4C',
  yellowTernaryShadow: '#DED04F',
  affirmPurple: '#4A4AF4',
  gradientBlue: '#00d5e6',
  greatViewPurple: '#505CD4',
  turquoise: '#2CD9FF',

  exclusiveIconSuper: '#000000',
  exclusiveIconFlash: '#49E49A',
  exclusiveIconBest: '#0C896B',
  exclusiveIconCheapest: '#FF4B76',
  exclusiveIconZone: '#AF93FF',
};
