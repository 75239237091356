import React from 'react';

const ClockIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.58182 7.58182 4 12 4C16.4182 4 20 7.58182 20 12C20 16.4182 16.4182 20 12 20C7.58182 20 4 16.4182 4 12ZM2 12C2 6.47726 6.47725 2 12 2C17.5228 2 22 6.47726 22 12C22 17.5227 17.5228 22 12 22C6.47725 22 2 17.5227 2 12ZM11.5 7C11.2239 7 11 7.22386 11 7.5V12.5662L16.0568 15.6002C16.2935 15.7423 16.6007 15.6655 16.7428 15.4287L17.2572 14.5713C17.3993 14.3345 17.3225 14.0273 17.0857 13.8853L13 11.4338V7.5C13 7.22386 12.7761 7 12.5 7H11.5Z"
      />
    </svg>
  );
};

ClockIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#969696',
};

export default ClockIcon;
