export const BETMGM_ELIGIBLE_STATES = [
  'AZ',
  'CO',
  'IA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MA',
  'MI',
  'NJ',
  'NC',
  'PA',
  'TN',
  'VA',
] as const;

export const BETMGM_ELIGIBLE_CATEGORIES = [
  'nba',
  'mlb',
  'nhl',
  'atp',
  'soccer',
  'mls',
  'pga',
  'nfl',
  'ufc',
  'boxing',
] as const;

export const ORDER_STEP_STATUS = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  CONFIRMED: 'confirmed',
} as const;
