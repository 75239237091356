import React from 'react';
import PropTypes from 'prop-types';

import GTInfoModal from 'components/GTInfoModal/GTInfoModal';

import styles from './DisclosuresModal.module.scss';

function DisclosuresModal(props) {
  const { onHide, show, disclosure } = props;
  const title = disclosure.title || '';
  const info = disclosure.description || '';
  const icon = disclosure.iconURL;

  return (
    <GTInfoModal
      icon={
        icon ? (
          <img
            className={styles.icon}
            src={icon}
            width={24}
            height={24}
            alt="disclosure icon"
          />
        ) : null
      }
      title={title}
      buttonText="GOT IT"
      show={show}
      onHide={onHide}
    >
      <p className={styles.message}>{info}</p>
    </GTInfoModal>
  );
}

DisclosuresModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool.isRequired,
  disclosure: PropTypes.object.isRequired,
};

export default DisclosuresModal;
