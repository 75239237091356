import React from 'react';
import PropTypes from 'prop-types';

import {
  Click,
  FullEventClickTracker,
  PAYLOAD,
  useAnalyticsContext,
} from 'analytics';
import CheckmarkIndicator from 'components/CheckmarkIndicator/CheckmarkIndicator';
import GTAnimationModal from 'components/GTAnimationModal/GTAnimationModal';
import ArrowIcon from 'icons/ArrowIcon';
import {
  SORT_IDS,
  SORT_OPTIONS,
} from 'store/modules/userPreference/user.preference.selectors';
import colors from 'styles/colors.constants';

import ScheduleList from './ScheduleList/ScheduleList';

import styles from './OmnibarOptions.module.scss';

const OmnibarOptions = ({
  schedule,
  fullEvent,
  seatCount,
  sortId,
  handleSeatsChange,
  handleSortChange,
  availableSeatCounts = [],
  onClose,
  displayKey,
  showOmnibarOptions = false,
  showSortArrows = true,
}) => {
  const analytics = useAnalyticsContext();

  const renderSchedule = () => {
    return (
      <div className={styles['panel-body']}>
        <div className={styles['panel-body-overflow']}>
          <ScheduleList
            schedule={schedule}
            eventId={fullEvent.id}
            onClose={onClose}
          />
        </div>
      </div>
    );
  };

  const renderSeatSelector = () => {
    const handleChange = (seatSelection) => {
      const clickTracker = new FullEventClickTracker(fullEvent)
        .interaction(Click.INTERACTIONS.OMNIBAR(true), {
          [PAYLOAD.QUANTITY]: seatSelection,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey));
      analytics.track(new Click(clickTracker.json()));
      return handleSeatsChange(seatSelection);
    };

    return (
      <div className={styles['panel-body']}>
        <div className={styles['panel-body-overflow']}>
          {availableSeatCounts.map((seat) => {
            const isCurrentSelection = seat === parseInt(seatCount, 10);
            const seatLabel = `${seat}${seat === 1 ? ' Ticket' : ' Tickets'}`;
            return (
              <div
                onClick={handleChange.bind(null, seat)}
                onKeyDown={handleChange.bind(null, seat)}
                id={seat}
                key={seat}
                className={styles['panel-body-item']}
              >
                <span className={styles['item-text']}>{seatLabel}</span>
                <CheckmarkIndicator
                  isSelected={isCurrentSelection}
                  unselectedColor={colors.gray500}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFilterSelector = () => {
    const handleChange = (id) => {
      const clickTracker = new FullEventClickTracker(fullEvent)
        .interaction(Click.INTERACTIONS.OMNIBAR(true), {
          [PAYLOAD.SORT]: id,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey));
      analytics.track(new Click(clickTracker.json()));
      return handleSortChange(id);
    };

    return (
      <div className={styles['panel-body']}>
        <div className={styles['panel-body-overflow']}>
          {SORT_OPTIONS.map((id) => {
            const isCurrentSelection = id === sortId;
            return (
              <div
                onClick={handleChange.bind(null, id)}
                onKeyDown={handleChange.bind(null, id)}
                id={id}
                key={id}
                className={styles['panel-body-item']}
              >
                <span className={styles['item-text']}>{id}</span>
                <CheckmarkIndicator
                  isSelected={isCurrentSelection}
                  unselectedColor={colors.gray500}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPriceSortOptionWithArrow = (currentSortId) => {
    if (currentSortId === SORT_IDS.PRICEH) {
      return (
        <>
          price
          <ArrowIcon style={{ transform: 'rotate(90deg)' }} width="30" />
          (high to low)
        </>
      );
    }
    return (
      <>
        price
        <ArrowIcon style={{ transform: 'rotate(270deg)' }} width="30" />
        (low to high)
      </>
    );
  };

  const renderSortSelector = () => {
    const handleChange = (id) => {
      const clickTracker = new FullEventClickTracker(fullEvent)
        .interaction(Click.INTERACTIONS.OMNIBAR(true), {
          [PAYLOAD.SORT]: id,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey));
      analytics.track(new Click(clickTracker.json()));
      return handleSortChange(id);
    };

    return (
      <div className={styles['panel-body']}>
        <div className={styles['panel-body-overflow']}>
          {SORT_OPTIONS.map((id) => {
            const isCurrentSelection = id === sortId;
            let itemText = id;
            if (
              showSortArrows &&
              (id === SORT_IDS.PRICEH || id === SORT_IDS.PRICEL)
            ) {
              itemText = renderPriceSortOptionWithArrow(id);
            }

            return (
              <div
                onClick={handleChange.bind(null, id)}
                onKeyDown={handleChange.bind(null, id)}
                id={id}
                key={id}
                className={styles['panel-body-item']}
              >
                <span className={styles['item-text']}>{itemText}</span>
                <CheckmarkIndicator
                  isSelected={isCurrentSelection}
                  unselectedColor={colors.gray500}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    switch (displayKey) {
      case 'seatCount':
        return renderSeatSelector();
      case 'filterId':
        return renderFilterSelector();
      case 'sortId':
        return renderSortSelector();
      case 'schedule':
        return renderSchedule();
      default:
        return null;
    }
  };

  const getHeaderTitle = () => {
    switch (displayKey) {
      case 'seatCount':
        return 'Ticket Quantity';
      case 'filterId':
        return 'Filter';
      case 'sortId':
        return 'Sort Tickets By';
      case 'schedule':
        return 'Schedule';
      default:
        return '';
    }
  };
  const handleOmnibarCancel = () => {
    return onClose(true);
  };

  return (
    <GTAnimationModal
      className="omni-modal"
      show={showOmnibarOptions}
      headerTitle={getHeaderTitle()}
      onHide={handleOmnibarCancel}
    >
      {renderBody()}
    </GTAnimationModal>
  );
};

OmnibarOptions.propTypes = {
  schedule: PropTypes.array.isRequired,
  fullEvent: PropTypes.object.isRequired,
  seatCount: PropTypes.number.isRequired,
  sortId: PropTypes.string.isRequired,
  handleSeatsChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  availableSeatCounts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  displayKey: PropTypes.string,
  showOmnibarOptions: PropTypes.bool.isRequired,
  showSortArrows: PropTypes.bool,
};

export default OmnibarOptions;
