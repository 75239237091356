// Note: this is the (server) sdk
import Apptimize from '@apptimize/apptimize-server-sdk';

import config from 'config/config';
import { ENV } from 'config/environments';
import { isObjectEmpty } from 'utils/objects';

import { experiments } from './experiments';

/**
 * By default, development environments will be placed in the forced variants
 * as defined below in `getExperimentsList`. To bucket according to Apptimize's
 * configuration in the development environment, set the
 * below variable to false.
 */
const isDevelopment = config.env === ENV.DEVELOPMENT;

/*
  NAME_OF_EXPERIMENT: {
     name: Must match Dynamic variable name from apptimize
     default_value: (string) default variant
     variant:  (string) Must match variant's name in Apptimize.
   }

   When calling Apptimize.getString()
   Apptimize return the a/b test string.
   Variant name, allocation and target must be set in Apptimize.
*/

/* Apptimize experiments */
export const getExperimentsList = (userId = 'GTid', isbotTraffic = false) => {
  let experimentsList = {};
  const {
    INSURANCE_WEB_PILOT,
    GT_TICKET_COVERAGE,
    PERCENTAGE_VS_DOLLARS,
    FLASH_ZONE_HARMONY_WEB,
    INCLUDES_FEES_COPY,
    GALLERY_VIEW_V2,
    PERFORMER_INLINE_PRICING_MWEB,
    NEW_MLB_INFO_COLLECTION,
    WEB_EXCLUSIVES_V1,
    MLB_MATCHUP_LOGOS,
    LOCALIZE,
    NO_NFL_AUTOZOOM_DESKTOP,
    MARKET_SHARE_COLLECTION_V2,
  } = experiments;

  // Force a variant during development
  if (isDevelopment) {
    return {
      INSURANCE_WEB_PILOT: INSURANCE_WEB_PILOT.variant,
      GT_TICKET_COVERAGE: GT_TICKET_COVERAGE.variant1,
      PERCENTAGE_VS_DOLLARS: PERCENTAGE_VS_DOLLARS.variant,
      FLASH_ZONE_HARMONY_WEB: FLASH_ZONE_HARMONY_WEB.default_variant,
      // INCLUDES_FEES_COPY set as control, otherwise Cypress test for
      // ListingCard will fail
      INCLUDES_FEES_COPY: INCLUDES_FEES_COPY.default_variant,
      GALLERY_VIEW_V2: GALLERY_VIEW_V2.variant,
      PERFORMER_INLINE_PRICING_MWEB: PERFORMER_INLINE_PRICING_MWEB.variant,
      NEW_MLB_INFO_COLLECTION: NEW_MLB_INFO_COLLECTION.variant,
      // the WEB_EXCLUSIVES_V1 variant also requires zListings40 and zListings46
      // to be set and we have no way to set default zListings in development
      // yet, which would require ensuring they are set to the correct value for
      // the A/B test variant
      WEB_EXCLUSIVES_V1: WEB_EXCLUSIVES_V1.default_variant,
      MLB_MATCHUP_LOGOS: MLB_MATCHUP_LOGOS.variant,
      // L10n poc
      LOCALIZE: LOCALIZE.variant,
      NO_NFL_AUTOZOOM_DESKTOP: NO_NFL_AUTOZOOM_DESKTOP.variant,
      MARKET_SHARE_COLLECTION_V2: MARKET_SHARE_COLLECTION_V2.variant,
    };
  }

  // Apptimize
  if (!isObjectEmpty(experiments)) {
    experimentsList = Object.keys(experiments).reduce(
      (accumulator, experiment) => {
        const { name, default_variant } = experiments[experiment];
        if (isbotTraffic) {
          accumulator[experiment] = default_variant;
        } else {
          accumulator[experiment] = Apptimize.getString(
            name,
            default_variant,
            userId
          ).toUpperCase();
        }

        return accumulator;
      },
      {}
    );
  }

  return experimentsList;
};

/* Apptimize zListings */
export const getZListings = (userId = 'GTid', isbotTraffic = false) => {
  // If it's bot traffic, just return empty object
  if (isbotTraffic) return {};

  // If a value is available, returns as { zListings01: 'value' }, etc.
  const zListingsMap = {};
  // Create an array from 1 - 50 to map to zListings keys
  const zListingsIndeces = Array.from(Array(50), (_, index) => ++index);

  // Check each zListing01..50 to see if Apptimize has a value for it.
  zListingsIndeces.forEach((key) => {
    // If key is single digits, it should be padded with a leading zero
    if (key < 10) key = `0${key}`;

    const currentKey = `zListings${key}`;
    const apptimizeValue = Apptimize.getString(currentKey, '', userId);

    // If Apptimize has a value for it, add it to the returned object
    if (apptimizeValue !== '') zListingsMap[currentKey] = apptimizeValue;
  });

  return zListingsMap;
};

/* Apptimize zSearch */
export const getZSearch = (userId = 'GTid', isbotTraffic = false) => {
  if (isbotTraffic) return {};

  const zSearchMap = {};
  const zSearchIndeces = Array.from(Array(50), (_, index) => ++index);

  zSearchIndeces.forEach((key) => {
    if (key < 10) key = `0${key}`;

    const currentKey = `zSearch${key}`;
    const apptimizeValue = Apptimize.getString(currentKey, '', userId);

    if (apptimizeValue !== '') zSearchMap[currentKey] = apptimizeValue;
  });

  return zSearchMap;
};
