// NOTE: Team names are case-sensitive, and must exactly match those entered on Lever.
export const ALL_TEAMS = [
  {
    name: 'Creative',
    description:
      "Gametime's creative team is responsible for making the content that often times makes the first impression for a potential user. Through unique storytelling both visually and written, we create and implement branding, communication, and advertisements across various platforms—including Facebook, Instagram, Snapchat, X, broadcast, OOH, YouTube, and other digital video mediums.\n\nWe work closely with the in-house growth team and data team to track performance of our creative production, and iterate and scale our deliverables. As part of the creative team at Gametime, you'll have the opportunity to try new things, fail, learn, and try again. Our creative positions are very hands-on allowing team members to be part of a full production funnel from ideation through final delivery, minus the red-tape often found at larger organizations or agencies.",
  },
  {
    name: 'Data',
    description:
      'Data is at the core of all our decision-making at Gametime. Our team works at the intersection of big data, high-performance distributed systems, and machine learning to provide insights and analysis that fuel our business strategy.\n\nOur work demands creativity, strong analytical chops, and the willingness to learn every day. We take on exciting large-scale projects like building machine learning models that can accurately predict optimal ticket prices and create a personalized experience that connects with our users in a compelling way. We sweat the small stuff, with an eye on the big picture. Come join us!',
  },
  {
    name: 'Engineering',
    description:
      "Wondering what it takes to succeed on the engineering team at Gametime? We look for people teeming with creativity and engineering smarts, who are willing to learn as they go and are excited to take on greenfield opportunities across the industry.\n\nOur engineering teams use real-time systems, applying data science in curation and pricing to create an award-winning user experience. Most importantly, we're a team without ego. We aim to foster a collaborative, fun environment where everyone's ideas are welcomed.",
  },
  {
    name: 'Finance',
    description:
      "We're a data-centric company, and our finance team plays a large role in driving business outcomes. At Gametime, it's about much more than just knowing the numbers. We're looking for talented individuals who are curious, strive for the best, and believe in our philosophy of \"One Team, One Dream.\" A passion for live events doesn't hurt either!\n\nWorking on this team, you'll have the opportunity to dive deep into interesting problems and partner cross-functionally to turn them into opportunities and results. Our goal as a finance team is to be a trusted advisor and to support the overall business objectives. We help other teams make the best possible decisions to drive the long-term value of our customers, suppliers, employees, and stakeholders. We’re changing an industry and looking for the best talent to help us do that.",
  },
  {
    name: 'Growth',
    description:
      "Our growth team is responsible for introducing millions of users to Gametime's award-winning product and keeping them engaged with our app. We drive installs across many platforms - including Facebook, Instagram, Snapchat, X, DRTV, and Google - and use strategic email campaigns and push notifications to influence retention.\n\nOur work is supported by an in-house creative team that produces high-performance content, while analytics and engineering resources allow us to build tools that improve our scale and return. On the growth team at Gametime you'll have the opportunity to try new things, fail, learn, and try again.",
  },
  {
    name: 'Operations',
    description:
      "Gametime's operations teams work together seamlessly to ensure the best possible ticket-buying experience for our fans. Our remote Fan Happiness team provides prompt and friendly customer support for our users. The Ticket Operations team partners directly with inventory providers to secure and deliver the best tickets on the secondary market. And our Venue team creates detailed event maps and panoramic seat view photos - so users can feel confident in their decision to buy on Gametime.\n\nTogether, our operations teams are rooted in attention to detail, strong relationships, collaboration, and expert knowledge of the ticketing industry.",
  },
  {
    name: 'Product & Design',
    description:
      "Our Product & Design teams are responsible for researching, designing, and building new features that will delight Gametime users and drive the business forward. We've had a mobile-first approach from the beginning, which means every design and product decision we make has to work on a small screen, be fast and easy, and (of course) look great on mobile.\n\nOur team is small and dynamic, and we're passionate about our space. We're dedicated to providing a better experience within the ticketing industry, and we come to work every day empowered to build a better marketplace. From early-stage brainstorming to product launch plans, we collaborate as a team and work cross-functionally with Marketing, Fan Happiness, Data Science, and Engineering.",
  },
  {
    name: 'Total Talent',
    description:
      "Our Total Talent team is made up of diverse specialists across human resources and recruiting. We're on a mission is to ensure that the full employment lifecycle - beginning with the initial phone screen - is an engaging and memorable experience. And our core values guide us in developing a strong and unified culture.\n\nAlthough elements of the employee lifecycle include fun and games, we take our role very seriously. We consistently monitor market data to ensure that we're maintaining a competitive edge and continuing to make Gametime an amazing place to work. We seek out tech tools to run a highly efficient and effective department, while building programs to contribute to personal, professional and company growth.",
  },
];

// NOTE: Location names are case-sensitive, and must exactly match those entered on Lever.
export const ALL_LOCATIONS = ['San Francisco, CA', 'Portland, OR', 'Remote'];

export const GAMETIME_VALUES = [
  {
    name: 'Above & Beyond',
    description:
      "We don't settle. You'll find us going the extra mile for our users, our partners, and each other.",
  },
  {
    name: 'One Team, One Dream',
    description:
      "We're in it together. We celebrate wins and learn from our failures as a team.",
  },
  {
    name: 'Always Be Curious',
    description:
      'We challenge the status quo every day and expect that you will, too.',
  },
];

export const BENEFITS_AND_PERKS = [
  {
    name: 'Health Benefits',
    description:
      'Medical, dental, and vision coverage for you and your dependents',
    src: 'https://assets.gametime.co/static_pages/careers/icon-health-benefits.svg',
  },
  {
    name: 'Equity',
    description: "An ownership stake in the company you'll be helping to build",
    src: 'https://assets.gametime.co/static_pages/careers/icon-equity.svg',
  },
  {
    name: 'Flexible PTO',
    description: "'Take what you need' vacation policy (we actually mean it!)",
    src: 'https://assets.gametime.co/static_pages/careers/icon-flexible-pto.svg',
  },
  {
    name: 'Remote First',
    description: 'Fully remote company',
    src: 'https://assets.gametime.co/static_pages/careers/icon-remote-first.svg',
  },
  {
    name: 'Remote Work Stipend',
    description:
      'Monthly stipend to offset the cost of internet and any home office updates',
    src: 'https://assets.gametime.co/static_pages/careers/icon-remote-work-stipend.svg',
  },
  {
    name: 'Paid Parental Leave',
    description:
      'Peace of mind for parents to dedicate their time to the care of their new child',
    src: 'https://assets.gametime.co/static_pages/careers/icon-paid-parental-leave.svg',
  },
  {
    name: 'Gametime Credit',
    description:
      'Monthly Gametime credit to experience the product for yourself',
    src: 'https://assets.gametime.co/static_pages/careers/icon-gametime-credit.svg',
  },
  {
    name: 'Team Celebrations',
    description: 'Company meetups and events in different cities across the US',
    src: 'https://assets.gametime.co/static_pages/careers/icon-team-celebrations.svg',
  },
];

export const EMPLOYEE_REVIEWS = [
  {
    name: 'Tammy',
    subline: 'Growth',
    src: 'https://assets.gametime.co/static_pages/careers/employee-review-tammy.png',
    copy: 'Gametime offers tons of opportunity for career growth. I love that I get to wear many hats and can explore and learn about different parts of the business.',
  },
  {
    name: 'Bigo',
    subline: 'Engineering',
    src: 'https://assets.gametime.co/static_pages/careers/employee-review-bigo.png',
    copy: "At Gametime I get to work with brilliant people who have fun building software together. We're a small team, and everyone is proud to be building this business together.",
  },
  {
    name: 'Monika',
    subline: 'Ticket Ops',
    src: 'https://assets.gametime.co/static_pages/careers/employee-review-monika.png',
    copy: "I love the energy at Gametime - my day-to-day is always changing and I'm surrounded by incredible, hard-working people!",
  },
];

export const NAVIGATION_SECTIONS = {
  ourCompany: 'our-company',
  benefitsAndPerks: 'benefits-and-perks',
  openPositions: 'open-positions',
};
