import React, { useState } from 'react';

import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import { ShieldIcon } from 'icons';
import GTGuaranteeFillIcon from 'icons/GTGuaranteeFillIcon';

import { COVERAGE, MODAL_DATA } from './GTCoverage.data';

import styles from './GTCoverage.module.scss';

type CoverageVariant = keyof typeof COVERAGE;

interface Props {
  variant: CoverageVariant;
  onClick: () => void;
}

const GTCoverage = ({ variant, onClick }: Props) => {
  const { title, message, cta, shieldColor } = COVERAGE[variant];
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick();
    setModalOpen(true);
  };

  return (
    <>
      <section className={styles.container}>
        <div>
          <ShieldIcon fill={shieldColor} data-testid="shield-icon" />
        </div>

        <div className={styles.content}>
          <h3 className={styles['title']}>{title}</h3>
          <p className={styles.message}>
            {message}
            {cta && (
              <a
                href="#"
                role="button"
                aria-label="learn more about ticket coverage"
                onClick={handleClick}
                className={styles.cta}
              >
                {cta}
              </a>
            )}
          </p>
        </div>
      </section>

      <GTInfoModal
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
        buttonText="GOT IT"
        buttonClassName={styles['modal-button']}
        styleName={styles['modal']}
      >
        <div className={styles['modal-icon']}>
          <GTGuaranteeFillIcon width={40} height={40} />
        </div>

        {MODAL_DATA.map((item) => (
          <div key={item.id} className={styles['modal-container']}>
            <h2 className={styles['modal-content-title']}>{item.title}</h2>
            <p className={styles['modal-content']}>{item.content}</p>
          </div>
        ))}
      </GTInfoModal>
    </>
  );
};

export default GTCoverage;
