import React from 'react';
import classNames from 'classnames';

import {
  AlarmClockIcon,
  FireIcon,
  FlashIcon,
  SparklesIcon,
  StarLargeIcon,
  StarMediumIcon,
  StarSmallIcon,
} from 'icons';
import { LotDeal } from 'types';

import styles from './SponsorDealBadge.module.scss';

type SponsorDealVariant = Extract<
  LotDeal['deal'],
  'super' | 'flash' | 'best' | 'cheapest' | 'zone'
>;

interface SponsorDealBadgeProps {
  variant: SponsorDealVariant;
  isExclusivesV1?: boolean;
}

interface VariantProps {
  text: string;
  Icon: typeof FlashIcon;
}

const exclusivesV1Variants: Record<SponsorDealVariant, VariantProps> = {
  super: {
    text: 'Super Deal',
    Icon: StarLargeIcon,
  },
  flash: {
    // "flash" --> "amazing"
    text: 'Amazing Deal',
    Icon: StarMediumIcon,
  },
  best: {
    // "best" --> "great"
    text: 'Great Deal',
    Icon: StarSmallIcon,
  },
  cheapest: {
    text: 'Cheapest',
    Icon: FireIcon,
  },
  zone: {
    text: 'Zone Deal',
    Icon: SparklesIcon,
  },
};

const controlVariants: Record<string, VariantProps> = {
  flash: {
    Icon: FlashIcon,
    text: 'Flash Deal',
  },
  zone: {
    Icon: AlarmClockIcon,
    text: 'Zone Deal',
  },
};

const SponsorDealBadge = ({
  variant,
  isExclusivesV1 = false,
}: SponsorDealBadgeProps) => {
  const variants = isExclusivesV1 ? exclusivesV1Variants : controlVariants;

  if (!(variant in variants)) {
    return null;
  }

  const variantProps = variants[variant];

  const iconSize = isExclusivesV1 ? '16' : '14';

  return (
    <div
      className={classNames(
        styles['sponsor-deal-badge'],
        styles[`deal-${variant}`],
        {
          [styles['exclusives-v1']]: isExclusivesV1,
        }
      )}
    >
      <variantProps.Icon width={iconSize} height={iconSize} />
      {variantProps.text}
    </div>
  );
};

export default SponsorDealBadge;
