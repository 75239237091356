import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useOnClickOutside from 'hooks/useOnClickOutside';

const ClickOutsideContainer = ({ children, onClick, className = '' }) => {
  const ref = useRef();
  useOnClickOutside(ref, onClick);
  return (
    <div ref={ref} className={classNames(className)} data-cy="search-box">
      {children}
    </div>
  );
};

ClickOutsideContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ClickOutsideContainer;
