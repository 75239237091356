import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PerformerClickTracker } from 'analytics';
import MiniCard from 'components/Search/SearchResults/MiniCard/MiniCard';
import { getCategoryName } from 'store/modules/categories/category.helpers';

import styles from './PerformerList.module.scss';

class PerformerList extends Component {
  render() {
    const { performers } = this.props;
    return (
      <div className={styles.list}>
        {performers.map((performer) => (
          <div key={performer.slug} className={styles['card-container']}>
            <MiniCard
              key={performer.slug}
              imageProps={performer.getImageOptions()}
              title={performer.name}
              subtitle={<span>{getCategoryName(performer.category)}</span>}
              to={performer.getPath()}
              clickTracker={new PerformerClickTracker(performer)}
            />
          </div>
        ))}
      </div>
    );
  }
}

PerformerList.propTypes = {
  performers: PropTypes.array.isRequired,
};
PerformerList.defaultProps = {};

export default PerformerList;
