import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Chip } from 'ui/Chip';
import { FilterChips } from 'ui/FilterChips';
import HorizontalScroll from 'ui/HorizontalScroll/HorizontalScroll';
import { Menu } from 'ui/Menu';

import EventDateTime from 'components/EventDateTime/EventDateTime';
import { selectIsIncludesFeesCopyExperiment } from 'experiments';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import CalendarIcon from 'icons/CalendarIcon';
import PersonOutlineIcon from 'icons/PersonOutlineIcon';
import { FullEvent } from 'models';
import colors from 'styles/colors.constants';
import { MOBILE_VIEW } from 'types/event';

import SortLabel from './SortLabel/SortLabel';

import styles from './OmnibarControls.module.scss';

const priceBuckets = [
  { id: 1, label: '$' },
  { id: 2, label: '$$' },
  { id: 3, label: '$$$' },
  { id: 4, label: '$$$$' },
];

const OmnibarControls = ({
  fullEvent,
  onClick,
  noInventory,
  seatCount,
  view,
  sortId,
  priceQuartile,
  isAllInPriceActive,
  isIncludesFeesCopyExperiment,
}) => {
  const isMobile = useMediaQuery(device.down.md);
  const isMapViewOnMobile = view === MOBILE_VIEW.MAP && isMobile;
  const disabledSort = noInventory || isMapViewOnMobile;
  const componentSize = isMobile ? 'md' : 'lg';
  const { gray300 } = colors;

  return (
    <section className={styles['omnibar-controls']}>
      <div className={styles['omnibar-controls-top-row']}>
        <Menu
          size={componentSize}
          onClick={() => onClick('schedule')}
          label={<EventDateTime fullEvent={fullEvent} type="DOTTED_HUMAN" />}
          icon={<CalendarIcon fill={gray300} width={16} height={16} />}
        />
        <Menu
          disabled={noInventory}
          size={componentSize}
          onClick={() => onClick('seatCount')}
          label={noInventory ? 'Qty' : seatCount}
          icon={<PersonOutlineIcon fill={gray300} width={16} height={16} />}
        />
      </div>

      <div className={styles['omnibar-controls-bottom-row']}>
        <HorizontalScroll
          isMobile={isMobile}
          className={styles['omnibar-controls-bottom-container']}
          groupedArrows
        >
          <HorizontalScroll.Card
            itemId="CardId001"
            className={styles['omnibar-control-item']}
          >
            <Chip
              isMenu
              label={<SortLabel sortId={sortId} />}
              disabled={disabledSort}
              onClick={() => onClick('sortId')}
            />
          </HorizontalScroll.Card>
          <HorizontalScroll.Card
            itemId="CardId002"
            className={styles['omnibar-control-item']}
          >
            <FilterChips
              testId="re-curation"
              list={priceBuckets}
              activeId={priceQuartile}
              onClick={(priceBucket) => onClick('price_bucket', priceBucket)}
            />
          </HorizontalScroll.Card>
          <HorizontalScroll.Card
            itemId="CardId003"
            className={styles['omnibar-control-item']}
          >
            <Chip
              testId="all-in-pricing"
              label={
                isIncludesFeesCopyExperiment ? 'Include Fees' : 'All-In Pricing'
              }
              disabled={noInventory}
              color={isAllInPriceActive ? 'secondary' : 'primary'}
              onClick={() =>
                onClick('allInPricing', {
                  isAllInPriceActive: !isAllInPriceActive,
                })
              }
            />
          </HorizontalScroll.Card>
        </HorizontalScroll>
      </div>
    </section>
  );
};

OmnibarControls.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  seatCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  noInventory: PropTypes.bool,
  isAllInPriceActive: PropTypes.bool,
  sortId: PropTypes.string,
  priceQuartile: PropTypes.number,
  isIncludesFeesCopyExperiment: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isIncludesFeesCopyExperiment: selectIsIncludesFeesCopyExperiment(state),
  };
}

export default connect(mapStateToProps)(OmnibarControls);
