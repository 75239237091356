import { create as applePayCreate } from 'braintree-web/apple-pay';
import { create as braintreeClientCreate } from 'braintree-web/client';

import config from 'config/config';
import { USER_PURCHASE_ACTION_TYPES } from 'store/modules/userPurchases/userPurchases.actionsTypes';

export const applePayInitialState = {
  statusChecked: false,
  available: false,
  isCapable: false,
  applePayInstance: null,
};

export const APPLE_PAY_ERRORS = {
  SESSION_CREATION_FAILED: 'APPLE_PAY_ERROR/SESSION_CREATION_FAILED',
  MERCHANT_VALIDATION_FAILED: 'APPLE_PAY_ERROR/MERCHANT_VALIDATION_FAILED',
  BRAINTREE_TOKENIZE_FAIL: 'APPLE_PAY_ERROR/BRAINTREE_TOKENIZE_FAIL',
};

export default function applePayReducer(
  state = applePayInitialState,
  action = {}
) {
  switch (action.type) {
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_SUCCESS:
      return {
        ...state,
        statusChecked: true,
        ...action.result,
      };
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_FAIL:
      return {
        ...state,
        statusChecked: true,
      };
    default:
      return state;
  }
}

export const initializeApplePay = () => (dispatch) => {
  const brainTree = () =>
    new Promise((resolve, reject) => {
      if (typeof window === 'undefined' || !window.ApplePaySession) {
        reject(new Error('Unable to create Apple Pay session.'));
        return;
      }

      if (!window.ApplePaySession.canMakePayments()) {
        reject(new Error('Apple Pay not supported.'));
        return;
      }

      braintreeClientCreate(
        {
          authorization: config.BRAINTREE_CLIENT_TOKEN,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            console.error('Error creating client:', clientErr);
            reject(clientErr);
            return;
          }

          applePayCreate(
            {
              client: clientInstance,
            },
            (applePayErr, applePayInstance) => {
              if (applePayErr) {
                console.error('Error creating applePayInstance:', applePayErr);
                reject(applePayErr);
                return;
              }

              const promise =
                window.ApplePaySession.canMakePaymentsWithActiveCard(
                  applePayInstance.merchantIdentifier
                );
              promise.then((canMakePaymentsWithActiveCard) => {
                if (canMakePaymentsWithActiveCard) {
                  resolve({
                    available: true,
                    isCapable: true,
                    applePayInstance,
                  });
                  return;
                }

                // User has Apple Pay capability on the device, but not activated (i.e. no saved cards)
                resolve({ isCapable: true });
              });
            }
          );
        }
      );
    });

  return dispatch({
    types: [
      USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY,
      USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_SUCCESS,
      USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_FAIL,
    ],
    promise: brainTree,
  });
};
