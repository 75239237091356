import { createStructuredSelector } from 'reselect';

import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import { featureFlagAssignmentSelector } from 'store/modules/user/user.selectors';

import { featureFlags } from './featureFlags';
import { FeatureFlag } from './types';

const {
  SHOW_TICKET_SOURCE,
  HOW_DID_YOU_HEAR_ABOUT_US_SURVEY,
  BRAZE_INTEGRATION,
  SMS_LOGIN_OPTION,
  MLB_INTEGRATION,
  CALIFORNIA_VENUE_ALL_IN_PRICE,
  MARYLAND_VENUE_ALL_IN_PRICE,
  COLORADO_VENUE_ALL_IN_PRICE,
  GT_PICKS_DISCOVERY_INTEGRATION,
  INSURANCE_PAYMENT_METHOD_APPLEPAY,
  INSURANCE_PAYMENT_METHOD_GOOGLEPAY,
  INSURANCE_PAYMENT_METHOD_AFFIRM,
  INSURANCE_PAYMENT_METHOD_PAYPAL,
  INSURANCE_PAYMENT_METHOD_CREDITCARD,
} = featureFlags;

/**
 * Utilities
 */

function createSelectIsFeatureFlagEnabled(featureFlag: FeatureFlag) {
  return (state: unknown) =>
    featureFlagAssignmentSelector(state, featureFlag.name);
}

/**
 * Feature Flag Selectors
 */
export const selectShowTicketSourceEnabled =
  createSelectIsFeatureFlagEnabled(SHOW_TICKET_SOURCE);

export const selectHowDidYouHearAboutUsSurveyEnabled =
  createSelectIsFeatureFlagEnabled(HOW_DID_YOU_HEAR_ABOUT_US_SURVEY);

export const selectIsBrazeEnabled =
  createSelectIsFeatureFlagEnabled(BRAZE_INTEGRATION);

export const selectIsSMSLoginEnabled =
  createSelectIsFeatureFlagEnabled(SMS_LOGIN_OPTION);

export const selectIsMLBIntegrationEnabled =
  createSelectIsFeatureFlagEnabled(MLB_INTEGRATION);

export const selectIsCaliforniaVenueAllInPriceEnabled =
  createSelectIsFeatureFlagEnabled(CALIFORNIA_VENUE_ALL_IN_PRICE);

export const selectIsMarylandVenueAllInPriceEnabled =
  createSelectIsFeatureFlagEnabled(MARYLAND_VENUE_ALL_IN_PRICE);

export const selectIsColoradoVenueAllInPriceEnabled =
  createSelectIsFeatureFlagEnabled(COLORADO_VENUE_ALL_IN_PRICE);

export const selectIsGTPicksDiscoveryIntegrationEnabled =
  createSelectIsFeatureFlagEnabled(GT_PICKS_DISCOVERY_INTEGRATION);

export const selectEnabledInsurancePaymentMethods = createStructuredSelector({
  [PURCHASE_TYPE.APPLE_PAY]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_APPLEPAY
  ),
  [PURCHASE_TYPE.GOOGLE_PAY]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_GOOGLEPAY
  ),
  [PURCHASE_TYPE.AFFIRM_PAY]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_AFFIRM
  ),
  [PURCHASE_TYPE.PAYPAL_PAY]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_PAYPAL
  ),
  [PURCHASE_TYPE.CREDIT_CARD_ON_FILE]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_CREDITCARD
  ),
  [PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY]: createSelectIsFeatureFlagEnabled(
    INSURANCE_PAYMENT_METHOD_CREDITCARD // same as credit card on file
  ),
});
