import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  dealTypes,
  isListingFlashDeal,
  isListingZoneDeal,
} from 'components/Deals/helpers';
import ZoneDealLockedCell from 'components/Deals/ZoneDealLockedCell';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import { updateMapHarmony } from 'store/modules/data/Listings/actions';
import {
  fetchListingsLoadingSelector,
  isZoneUnlockedSelector,
  listMapHarmonyToggleSelector,
  updatingMapHarmonySelector,
} from 'store/modules/data/Listings/selectors';

import styles from '../ListingCard/ListingCard.module.scss';

const Listings = ({
  fullEvent,
  listings,
  onListingHover,
  allDisclosures,
  allDeals,
  seatCount,
  zoomLevel,
  handleDealUnlock,
  zoneDealIsUnlocked,
  isUpdatingMapHarmony,
  dispatch,
  isLoadingListings,
  isListMapHarmonyToggleIsOn,
  carouselDealType,
  isExclusivesV1,
}) => {
  const timer = useRef(null);
  const isDeviceLargeUp = useMediaQuery(device.up.lg);

  useEffect(() => {
    const handleUpdate = () => {
      if (isUpdatingMapHarmony) {
        timer.current = setTimeout(() => {
          dispatch(updateMapHarmony(false));
        }, 500);
      } else if (isLoadingListings) {
        // handles omnibar re-fetching harmony
        if (isListMapHarmonyToggleIsOn) {
          dispatch(updateMapHarmony());
        }
      }
    };
    if (isDeviceLargeUp) {
      handleUpdate();
    }

    if (!isDeviceLargeUp && isUpdatingMapHarmony) {
      dispatch(updateMapHarmony(false));
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [
    isUpdatingMapHarmony,
    dispatch,
    isLoadingListings,
    isListMapHarmonyToggleIsOn,
    isDeviceLargeUp,
    fullEvent,
  ]);

  if (!listings || !listings.length) {
    return null;
  }

  const listingRows = listings.map((listing, i) => {
    if (!listing.isVisibleInMap && isListMapHarmonyToggleIsOn) {
      return null;
    }

    const isZoneDeal = isListingZoneDeal(listing, seatCount);
    const isFlashDeal = isListingFlashDeal(listing, seatCount);

    /*
        For more tags experiment, we're fetching listings at zoom 0.
        If we have a best deal carousel(BDC), and the current listing
        is a zone deal or flash deal, don't render it.
        TODO(API): This should be handle on the API side.
        */
    const hasBestDealCarousel = carouselDealType === dealTypes.bestDeal;
    if (hasBestDealCarousel && (isZoneDeal || isFlashDeal)) {
      return null;
    }

    const commonProps = {
      listing,
      fullEvent,
      onHover: onListingHover,
      listingIndex: i,
      zoomLevel,
      allDisclosures,
    };

    if (isZoneDeal)
      return (
        <ZoneDealLockedCell
          key={`listing_${listing.id}`}
          {...commonProps}
          handleDealUnlock={handleDealUnlock}
          zoneDealIsUnlocked={zoneDealIsUnlocked}
        />
      );

    return (
      <React.Fragment key={`listing_${listing.id}`}>
        <ListingCard
          {...commonProps}
          allDeals={allDeals}
          seatCount={seatCount}
          isFlashDeal={isFlashDeal}
          isExclusivesV1={isExclusivesV1}
        />
      </React.Fragment>
    );
  });

  return (
    <div className={classNames({ [styles.disabled]: isLoadingListings })}>
      {listingRows}
    </div>
  );
};

Listings.propTypes = {
  onListingHover: PropTypes.func,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  seatCount: PropTypes.number,
  zoomLevel: PropTypes.number,
  handleDealUnlock: PropTypes.func,
  zoneDealIsUnlocked: PropTypes.bool,
  isUpdatingMapHarmony: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isLoadingListings: PropTypes.bool.isRequired,
  isListMapHarmonyToggleIsOn: PropTypes.bool,
  carouselDealType: PropTypes.oneOf(Object.values(dealTypes)),
  isExclusivesV1: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const { eventId } = props;

  return {
    isLoadingListings: fetchListingsLoadingSelector(state),
    isUpdatingMapHarmony: updatingMapHarmonySelector(state),
    isListMapHarmonyToggleIsOn: listMapHarmonyToggleSelector(state),
    zoneDealIsUnlocked: isZoneUnlockedSelector(state, eventId),
  };
};

export default connect(mapStateToProps)(Listings);
