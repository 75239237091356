import { replace } from 'react-router-redux';

import { REDIRECT_PERMANENT_STATUS } from 'server/redirects/constants';
import { setServerRedirectPath } from 'store/modules/app/app';

export const redirect =
  (path, status = REDIRECT_PERMANENT_STATUS, search = '') =>
  (dispatch) => {
    dispatch(setServerRedirectPath(`${path}${search}`, status));
    return dispatch(replace({ pathname: path, search }));
  };
