import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import { isAfter, isEqual, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import {
  Sidebar,
  SIDEBAR_VIEWS,
  SidebarPastEvents,
  SidebarUpcomingEvents,
} from 'components/Sidebar';
import SupportLink from 'components/SupportLink/SupportLink';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { setActiveMyTicketsView } from 'store/modules/app/app';
import { activeMyTicketsViewSelector } from 'store/modules/app/app.selectors';
import { fetchFullEventById } from 'store/modules/data/FullEvents/actions';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { locationSelector } from 'store/modules/location';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { fetchCompleteUserPurchases } from 'store/modules/userPurchases/actions';
import { userPurchasesSelector } from 'store/modules/userPurchases/userPurchases.selectors';

import EventTab from './components/EventTab/EventTab';
import LogOutButton from './components/LogOutButton/LogOutButton';
import PastEvents from './components/PastEvents/PastEvents';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';
import { ACTIVE_RESALE_STATES } from './resale.constants';

import styles from './MyTickets.module.scss';

const hasOwnedTicket = (purchase) =>
  purchase.tickets.some(
    (ticket) => !ACTIVE_RESALE_STATES.includes(ticket.resale_info.status)
  );

@connect(
  (state) => {
    const user = selectUserDetails(state);
    if (!user) {
      return { user };
    }

    const allPurchases = Object.values(
      userPurchasesSelector(state).completePurchases
    );

    const purchases = allPurchases.filter((purchase) =>
      hasOwnedTicket(purchase)
    );
    const fullEventPurchases = [];
    const activeView = activeMyTicketsViewSelector(state) || 'upcoming';

    purchases.reduce((result, purchase) => {
      const fullEvent = selectFullEventById(state, purchase.event_id);
      if (fullEvent) {
        result.push({
          fullEvent,
          purchase,
        });
      }
      return result;
    }, fullEventPurchases);

    return {
      user,
      fullEventPurchases,
      activeView,
    };
  },
  { setActiveMyTicketsView }
)
@withAppContext
@TrackPageView(() => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.MY_TICKETS(),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.MY_TICKETS(),
}))
class MyTickets extends Component {
  static propTypes = {
    user: PropTypes.object,
    fullEventPurchases: PropTypes.arrayOf(PropTypes.object),
    activeView: PropTypes.string,
    setActiveMyTicketsView: PropTypes.func.isRequired,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSetActiveView = this.handleSetActiveView.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { user: nextUser } = nextProps;
    return nextUser !== null;
  }

  renderPageTitle() {
    const {
      user: { email },
    } = this.props;

    return (
      <div className={styles['my-tickets-title-section']}>
        <h1 className={styles['my-tickets-title']}>My Tickets</h1>
        <h2 className={styles['my-tickets-subtitle']}>{email}</h2>
      </div>
    );
  }

  /**
   * Sets the state to determine what component to display to the user. This function
   * is passed to the navigation components (EventTab and Sidebar) for them to use
   * on click of their buttons.
   *
   * @param {String} view the view that should be rendered in getActiveView()
   */
  handleSetActiveView(view) {
    this.props.setActiveMyTicketsView(view);
  }

  /**
   * Takes the event purchases that have been passed down as a prop and organizes
   * them into an object that has a key for upcoming events and past events.
   */
  getFormattedPurchaseEvents() {
    const { fullEventPurchases } = this.props;
    const upcomingEvents = [];
    const pastEvents = [];

    fullEventPurchases.forEach((event) => {
      const isEventCancelled = event.purchase.steps[0]?.status === 'cancelled';
      if (event.fullEvent.isPastEvent() || isEventCancelled) {
        pastEvents.push(event);
      } else {
        upcomingEvents.push(event);
      }
    });

    return {
      upcomingEvents: this.sortUpcomingEvents(upcomingEvents),
      pastEvents: this.sortPastEvents(pastEvents),
    };
  }

  /**
   * For all upcoming events, sort them from closest date to farthest date.
   */
  sortUpcomingEvents(upcomingEvents) {
    return upcomingEvents.sort((fullEventPurchase1, fullEventPurchase2) => {
      const purchaseDate1 = parseISO(
        fullEventPurchase1.fullEvent.event.datetimeUtc
      );
      const purchaseDate2 = parseISO(
        fullEventPurchase2.fullEvent.event.datetimeUtc
      );

      if (isEqual(purchaseDate1, purchaseDate2)) {
        return fullEventPurchase1.purchase.id > fullEventPurchase2.purchase.id
          ? 1
          : -1;
      }

      return isAfter(purchaseDate1, purchaseDate2) ? 1 : -1;
    });
  }

  /**
   * For all past events, sort them from the most recently past to furthest in the past.
   */
  sortPastEvents(pastEvents) {
    return pastEvents.sort((fullEventPurchase1, fullEventPurchase2) => {
      const purchaseDate1 = parseISO(
        fullEventPurchase1.fullEvent.event.datetimeUtc
      );
      const purchaseDate2 = parseISO(
        fullEventPurchase2.fullEvent.event.datetimeUtc
      );

      if (isEqual(purchaseDate1, purchaseDate2)) {
        return fullEventPurchase1.purchase.id > fullEventPurchase2.purchase.id
          ? 1
          : -1;
      }

      return isAfter(purchaseDate2, purchaseDate1) ? 1 : -1;
    });
  }

  /**
   * The state has a property of activeView that is toggled from the sidebar on desktop
   * and the navigation tabs on mobile. When the state changes and we re-render the
   * view for the user, this function is called to see what view should be rendered.
   * This function can easily be extended for every screen that is added to the sidebar
   * and navigation and should be kept in sync with both navigation components.
   */
  getActiveComponent() {
    const eventTickets = this.getFormattedPurchaseEvents();

    switch (this.props.activeView) {
      case SIDEBAR_VIEWS.UPCOMING:
        return <UpcomingEvents tickets={eventTickets.upcomingEvents} />;
      case SIDEBAR_VIEWS.PAST:
        return <PastEvents tickets={eventTickets.pastEvents} />;
      default:
        return <UpcomingEvents tickets={eventTickets.upcomingEvents} />;
    }
  }

  render() {
    const { activeView = SIDEBAR_VIEWS.UPCOMING } = this.props;
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    return (
      <ContainerTemplate
        header={
          <MinimalHeader search showCategories showHamburger showAccount />
        }
        footer={<GTFooter />}
        className={styles['page-container']}
      >
        <HeadTitle title="My Tickets" />
        <div className={styles['my-tickets-page']}>
          {this.renderPageTitle()}
          <div className={styles['tab-navigation-container']}>
            <EventTab
              setActiveView={this.handleSetActiveView}
              eventType={SIDEBAR_VIEWS.UPCOMING}
              isActive={activeView === SIDEBAR_VIEWS.UPCOMING}
            />
            <EventTab
              setActiveView={this.handleSetActiveView}
              eventType={SIDEBAR_VIEWS.PAST}
              isActive={activeView === SIDEBAR_VIEWS.PAST}
            />
          </div>
          <hr className={styles['my-tickets-divider']} />
          <div className={styles['page-content']}>
            {!isMobile ? (
              <div className={styles['sidebar-container']}>
                <Sidebar>
                  <SidebarUpcomingEvents
                    onSetActiveView={this.handleSetActiveView}
                    isActive={activeView === SIDEBAR_VIEWS.UPCOMING}
                  />
                  <SidebarPastEvents
                    onSetActiveView={this.handleSetActiveView}
                    isActive={activeView === SIDEBAR_VIEWS.PAST}
                  />
                  <SupportLink />
                </Sidebar>
              </div>
            ) : null}
            {this.getActiveComponent()}
          </div>
          <hr
            className={classNames(
              styles['my-tickets-divider'],
              styles['my-tickets-divider-mobile']
            )}
          />
          {isMobile && <SupportLink />}
          <div className={styles['log-out-container']}>
            <LogOutButton />
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

export default withDataLoader(MyTickets, {
  promise: async ({ store: { dispatch, getState }, asyncRedirect }) =>
    await new Promise((resolve, reject) => {
      const user = selectUserDetails(getState());
      if (!user) {
        // update for changes to logout method, now on logout this component will re-render
        // and if the last action was logout, it will redirect home, but if the user intentionally
        // visits the url: /my-account, it will redirect to /login
        const { pathname } = locationSelector(getState());
        const path = pathname === '/' ? '/' : '/login';
        asyncRedirect(path);
        reject(new Error('No user details found'));
      } else {
        Promise.all([
          dispatch(fetchMetros()),
          dispatch(
            fetchCompleteUserPurchases({
              user_id: user.id,
              session_token: user.session_token,
            })
          ),
        ])
          .then(() => {
            const allPurchases = Object.values(
              userPurchasesSelector(getState()).completePurchases
            );
            const purchases = allPurchases.filter((purchase) =>
              hasOwnedTicket(purchase)
            );

            if (purchases.length) {
              const fullEventIds = purchases.map(
                (purchase) => purchase.event_id
              );
              return dispatch(fetchFullEventById(fullEventIds.join(',')))
                .then(resolve)
                .catch(reject);
            }
            resolve();
          })
          .catch(reject);
      }
    }),
});
