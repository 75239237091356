import { PURCHASE_PATH } from 'pages/Purchase/constants';
import { REDUCERS } from 'store/modules/reducer.constants';

const initialState = {
  history: [],
};

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

const eventPathRegex = /.*\/events\/\w+(|\?.*)$/;
const performerPathRegex = /^.*\/performers\/\w+(|\?.*)$/;
const venuePathRegex = /^.*\/venues\/\w+(|\?.*)$/;
const checkoutPathRegex = /\/checkout/;

export const isListingPage = (pathname = '') => pathname.includes('listings');
export const isBuyRoute = (pathname = '') => pathname.includes('buy');
export const isOrderRoute = (pathname = '') => pathname.includes('order');
export const isEventPage = (pathname) => eventPathRegex.test(pathname);
export const isVenuePage = (pathname) => venuePathRegex.test(pathname);
export const isSearchPage = (pathname = '') => pathname.includes('/search');
export const isCollectionPage = (pathname = '') =>
  pathname.includes('/collection');
export const isCheckoutPage = (pathname) => checkoutPathRegex.test(pathname);
export const isNewSportsMetro = (pathname = '') => pathname.includes('sport/');
export const isPurchaseRoute = (pathname = '') =>
  pathname.includes(PURCHASE_PATH);
export const isPerformerPage = (pathname) => performerPathRegex.test(pathname);
export const isHomepage = (pathname) => pathname === '/';
export const isMetroPerformersPage = (pathname = '') =>
  pathname.includes('metros');
export const isMusicShowsPage = (pathname = '') => {
  return pathname.includes('/c/concert') || pathname.includes('/c/theater');
};
export const isGTPicksPage = (pathname = '') => pathname.includes('/picks');

export const historySelector = (state) => state[REDUCERS.HISTORY].history;
export const lastRouteSelector = (state) => {
  const history = historySelector(state);
  return history[history.length - 2];
};

/*
  Find the last route that is not event or listing route. (if any)
*/
export const lastNonEventListingRouteSelector = (state) => {
  const history = historySelector(state);
  let route = '/'; // default homepage

  for (let i = history.length - 1; i >= 0; i--) {
    route = history[i];
    if (!isListingPage(route) && !isEventPage(route)) {
      return route;
    }
  }

  return route;
};

export default function reducer(state = initialState, { type, payload } = {}) {
  if (type === LOCATION_CHANGE && __CLIENT__) {
    const { search = '', pathname } = payload;

    return {
      history: [...state.history, `${pathname}${search}`],
    };
  }

  return state;
}

export const shouldShowListingDetailsOverlay = (historyList) => {
  const currRoute = historyList[historyList.length - 1];

  if (isCheckoutPage(currRoute) || isPurchaseRoute(currRoute)) {
    return false;
  }

  if (historyList.length > 1) {
    const prevRoute = historyList[historyList.length - 2];
    const samePage = isListingPage(currRoute) && isListingPage(prevRoute);

    if (isCheckoutPage(prevRoute) && isListingPage(currRoute)) {
      // checkout to listing details
      return true;
    }

    if (samePage && !isCheckoutPage(currRoute)) {
      return true;
    }

    if (isEventPage(prevRoute) && isListingPage(currRoute)) {
      // event to listing details
      return true;
    }

    if (
      isEventPage(currRoute) &&
      isListingPage(prevRoute) &&
      !isBuyRoute(prevRoute)
    ) {
      // listing details to event
      return true;
    }
  }

  return false;
};

// Check if the last route in the state is an event page
export const selectIsPrevRouteEvent = (state) => {
  const lastRoute = lastRouteSelector(state);
  if (!lastRoute || lastRoute === '') return false;
  return isEventPage(lastRoute);
};

export const isEventPagePreListings = (pathname = '') => {
  return isEventPage(pathname) && !isListingPage(pathname);
};

export const isListingDetailsPage = (pathname = '') => {
  return (
    isListingPage(pathname) &&
    !isCheckoutPage(pathname) &&
    !isPurchaseRoute(pathname)
  );
};
