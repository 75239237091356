import { getCollections } from 'services/collection/collection.service';

export const ACTIONS = {
  GET_COLLECTIONS_REQUEST: 'GET_COLLECTIONS_REQUEST',
  GET_COLLECTIONS_REQUEST_SUCCESS: 'GET_COLLECTIONS_REQUEST_SUCCESS',
  GET_COLLECTIONS_REQUEST_FAILURE: 'GET_COLLECTIONS_REQUEST_FAILURE',
};

export const fetchCollections = (params) => async (dispatch) => {
  if (!params.metro) {
    return Promise.resolve();
  }

  const paramAdditions = {};
  if (params.slug || params.view) {
    paramAdditions.with_results = true;
  }
  if (params.slug && !params.view) {
    paramAdditions.results_per_page =
      'results_per_page' in params ? params.results_per_page : 1000;
    paramAdditions.results_page =
      'results_page' in params ? params.results_page : 1;
  }

  const newParams = {
    ...params,
    ...paramAdditions,
  };

  return dispatch({
    types: [
      ACTIONS.GET_COLLECTIONS_REQUEST,
      ACTIONS.GET_COLLECTIONS_REQUEST_SUCCESS,
      ACTIONS.GET_COLLECTIONS_REQUEST_FAILURE,
    ],
    params: newParams,
    promise: (httpClient) => getCollections(newParams, httpClient),
  });
};
