import React from 'react';
import PropTypes from 'prop-types';

import { NAVIGATION_SECTIONS } from '../../careers.constants';

import styles from './CareersHero.module.scss';

const CareersHero = ({ onClick }) => {
  return (
    <div className={styles['careers-hero-container']}>
      <span className={styles.headline} data-cy="career-hero-title">
        Work At Gametime
      </span>
      <span className={styles.subheadline}>Join our fully remote team!</span>
      <div
        className={styles['gametime-btn']}
        onClick={() => onClick(NAVIGATION_SECTIONS.openPositions)}
      >
        <span className={styles['gametime-btn-copy']} data-cy="hiring-button">
          We're Hiring
        </span>
        <span className={styles['gametime-btn-arrow']}></span>
      </div>
    </div>
  );
};

CareersHero.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CareersHero;
