import React from 'react';
import Tooltip from 'rc-tooltip';

import { Deal, Disclosure, Listing } from 'models';
import ListingFocusedCard from 'pages/Event/components/ListingFocusedCard';

import styles from './GalleryViewTooltip.module.scss';

interface GalleryViewTooltipProps {
  children: React.ReactNode;
  listing: Listing;
  isAllInPrice: boolean;
  disableTooltip?: boolean;
  allDeals: Record<string, Deal>;
  allDisclosures: Record<string, Disclosure>;
  isMLBEvent: boolean;
  onHover: () => void;
  isExclusivesV1?: boolean;
}

const GalleryViewTooltip = ({
  children,
  listing,
  isAllInPrice,
  disableTooltip = true,
  allDeals,
  allDisclosures,
  isMLBEvent,
  onHover,
  isExclusivesV1,
}: GalleryViewTooltipProps) => {
  if (disableTooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      zIndex={10000}
      showArrow={false}
      placement="topLeft"
      overlayClassName="gallery-view-tooltip"
      trigger={['hover']}
      mouseLeaveDelay={0}
      overlayStyle={{
        padding: '4px 0px',
        opacity: 1,
      }}
      overlayInnerStyle={{ padding: 0 }}
      align={{ overflow: { adjustX: true, adjustY: true } }}
      mouseEnterDelay={0.2}
      afterVisibleChange={(visible) => visible && onHover()}
      overlay={
        <div className={styles['tooltip-container']}>
          <ListingFocusedCard
            bordered
            listing={listing}
            allDeals={allDeals}
            allDisclosures={allDisclosures}
            isAllInPrice={isAllInPrice}
            isMLBEvent={isMLBEvent}
            isExclusivesV1={isExclusivesV1}
          />
        </div>
      }
    >
      <div className={styles['marker-container']}>{children}</div>
    </Tooltip>
  );
};

export default GalleryViewTooltip;
