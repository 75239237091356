import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { MetrosResponse } from 'types';

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const getMetros = (): Promise<MetrosResponse | null> => {
  return httpClient.request({
    path: '/v1/metros',
  });
};
