import React from 'react';

import { GAMETIME_VALUES } from '../../careers.constants';

import styles from './OurCompany.module.scss';

const OurCompany = React.forwardRef((_props, ref) => {
  return (
    <>
      <div className={styles['our-company-container']} ref={ref}>
        <div className={styles['our-company']}>
          <span className={styles.title}>We live for the last minute.</span>
          <p>
            Our team is on a mission to connect people through incredible shared
            experiences. We build technology that gets people out into the real
            world to enjoy their favorite events at a moment's notice. Come join
            us!
          </p>
          <div className={styles['line-break']}></div>
          <h2 className={styles['gametime-h2']}>Gametime Values</h2>
          <div className={styles['company-values-container']}>
            {GAMETIME_VALUES.map((value) => {
              return (
                <div className={styles['company-value']} key={value.name}>
                  <h3 className={styles['gametime-h3']}>{value.name}</h3>
                  <p>{value.description}</p>
                </div>
              );
            })}
          </div>
          <div className={styles['company-video-container']}>
            <iframe
              className={styles['company-video']}
              title="vimeo-player"
              src="https://player.vimeo.com/video/786659510?h=9db38b652c"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className={styles['company-image-gallery']}>
        <img
          className={styles['company-image-lg']}
          alt="Our Company"
          src="https://assets.gametime.co/static_pages/careers/our-culture-full-large.png"
        />
        <img
          className={styles['company-image-sm']}
          alt="Our Company"
          src="https://assets.gametime.co/static_pages/careers/our-culture-full-small.png"
        />
      </div>
    </>
  );
});

export default OurCompany;
