import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import Banner from 'ui/Banner/Banner';

import GoogleAdBanner from 'components/GoogleAdBanner/GoogleAdBanner';
import Link from 'components/Link/Link';
import ModalManager from 'components/Modals/ModalManager';
import Notifications from 'components/Notifications/Notifications';
import { cookiesConfig } from 'config/common.config';
import { selectIsGTPicksDiscoveryIntegrationEnabled } from 'featureFlags';
import {
  isBuyRoute,
  isCheckoutPage,
  isEventPage,
  isGTPicksPage,
  isListingPage,
  isOrderRoute,
} from 'store/modules/history/history';
import {
  isFromAdwords,
  locationSelector,
  querySelector,
} from 'store/modules/location';
import { isRestrictedMetro } from 'store/modules/user/userLocation.selectors';
import { selectIsInitialUserPurchase } from 'store/modules/userPurchases/userPurchases.selectors';
import { getUTMSearchParams } from 'utils/url';

import { ACTIVE_SEM_PROMOS, SEM_PROMOS } from './constants';

import styles from './ContainerTemplate.module.scss';

class ContainerTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsAvailable: false,
      hidePromoBanner: true,
      hideGTPicksBanner: true,
    };

    this.onSearchResultsAvailabilityChange =
      this.onSearchResultsAvailabilityChange.bind(this);
    this.handleHidePromoBanner = this.handleHidePromoBanner.bind(this);
    this.handleHideGTPicksBanner = this.handleHideGTPicksBanner.bind(this);
  }

  componentDidMount() {
    this.checkBannerVisibility();
    this.checkGTPicksBannerVisibility();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isInitialUserPurchase !== this.props.isInitialUserPurchase) {
      this.checkBannerVisibility();
    }
  }

  checkBannerVisibility() {
    const { isInitialUserPurchase, query } = this.props;
    // set cookie when user arrives from sem ad
    const utmParams = new getUTMSearchParams(query);
    const { name, attributes } = cookiesConfig.SEM_PROMO_TRAFFIC;

    if (ACTIVE_SEM_PROMOS.includes(utmParams.utm_campaign)) {
      const { maxAge, ...cookieAttributes } = attributes;
      if (Number.isFinite(maxAge)) {
        const expiration = new Date(Date.now() + maxAge);
        cookieAttributes.expires = expiration;
      }

      Cookies.set(name, utmParams.utm_campaign, cookieAttributes);
    }

    const semPromoCookieValue = Cookies.get(name);
    const utmBannerName = `${semPromoCookieValue}_BANNER_DISMISSED`;
    const bannerDismissedCookie = Cookies.get(utmBannerName);

    const hidePromoBanner =
      !semPromoCookieValue ||
      !isInitialUserPurchase ||
      bannerDismissedCookie === 'true';

    this.setState({ hidePromoBanner });
  }

  checkGTPicksBannerVisibility() {
    const gtPicksBannerDismissedCookie = Cookies.get(
      cookiesConfig.GT_PICKS_BANNER.name
    );

    const hideGTPicksBanner = gtPicksBannerDismissedCookie === 'true';

    this.setState({ hideGTPicksBanner });
  }

  onSearchResultsAvailabilityChange(available) {
    if (this.state.searchResultsAvailable !== available) {
      this.setState({ searchResultsAvailable: available });
    }
  }

  handleHidePromoBanner() {
    const semPromoCookieValue = Cookies.get(
      cookiesConfig.SEM_PROMO_TRAFFIC.name
    );
    const utmBannerName = `${semPromoCookieValue}_BANNER_DISMISSED`;
    const { attributes } = cookiesConfig.BANNER_DISMISSED;
    const { maxAge, ...cookieAttributes } = attributes;
    if (Number.isFinite(maxAge)) {
      const expiration = new Date(Date.now() + maxAge);
      cookieAttributes.expires = expiration;
    }
    Cookies.set(utmBannerName, 'true', cookieAttributes);
    this.setState({ hidePromoBanner: true });
  }

  handleHideGTPicksBanner() {
    const { attributes } = cookiesConfig.BANNER_DISMISSED;
    const { maxAge, ...cookieAttributes } = attributes;
    if (Number.isFinite(maxAge)) {
      const expiration = new Date(Date.now() + maxAge);
      cookieAttributes.expires = expiration;
    }
    Cookies.set(cookiesConfig.GT_PICKS_BANNER.name, 'true', cookieAttributes);
    this.setState({ hideGTPicksBanner: true });
  }

  render() {
    const {
      helmetProps,
      className,
      header,
      footer,
      children,
      location: {
        query: { header: showHeader, footer: showFooter },
        pathname,
      },
      containerProps = {},
      canShowGoogleAdbanner = false,
      shouldShowGoogleAdbanner,
      isGTPicksDiscoveryIntegrationEnabled,
    } = this.props;
    const { searchResultsAvailable, hidePromoBanner, hideGTPicksBanner } =
      this.state;
    const headerProps = {
      onSearchResultsAvailabilityChange: this.onSearchResultsAvailabilityChange,
    };

    let bodyAttributes = {};
    const showGoogleAdBanner =
      canShowGoogleAdbanner && shouldShowGoogleAdbanner;

    if (searchResultsAvailable) {
      bodyAttributes = { 'data-search-bar-active': true };
    }

    const isShowBannerPath =
      !isBuyRoute(pathname) &&
      !isCheckoutPage(pathname) &&
      !isEventPage(pathname) &&
      !isListingPage(pathname) &&
      !isOrderRoute(pathname);

    const showFirstPurchasePromo = isShowBannerPath && !hidePromoBanner;

    const semPromoCookieValue = Cookies.get(
      cookiesConfig.SEM_PROMO_TRAFFIC.name
    );

    const bannerMessage = SEM_PROMOS[semPromoCookieValue]?.bannerHeadline;

    const showGTPicksBanner =
      isGTPicksDiscoveryIntegrationEnabled &&
      !showFirstPurchasePromo &&
      isShowBannerPath &&
      !isGTPicksPage(pathname) &&
      !hideGTPicksBanner;

    return (
      <div
        {...containerProps}
        className={classNames(className, styles.container)}
      >
        <Helmet {...helmetProps} bodyAttributes={{ ...bodyAttributes }} />
        <Notifications />
        <ModalManager />
        {showFirstPurchasePromo && (
          <Banner
            message={bannerMessage}
            onClose={this.handleHidePromoBanner}
          />
        )}
        {showGTPicksBanner && (
          <Banner
            message={
              <>
                🚀 Gametime Picks has just launched.{' '}
                <Link
                  className={styles['banner-link']}
                  href="/picks"
                  target="_blank"
                >
                  Learn More!
                </Link>
              </>
            }
            onClose={this.handleHideGTPicksBanner}
          />
        )}
        {showGoogleAdBanner && <GoogleAdBanner />}
        {showHeader !== 'false' &&
          header &&
          React.cloneElement(header, headerProps)}
        <div className="app-main-content__wrapper">
          <main className={styles.appContent}>{children}</main>
          {showFooter !== 'false' && footer}
        </div>
      </div>
    );
  }
}

ContainerTemplate.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
  isInitialUserPurchase: PropTypes.bool.isRequired,
  className: PropTypes.string,
  helmetProps: PropTypes.object,
  containerProps: PropTypes.object,
  location: PropTypes.object,
  canShowGoogleAdbanner: PropTypes.bool,
  query: PropTypes.object,
  shouldShowGoogleAdbanner: PropTypes.bool.isRequired,
  userIsLoggedIn: PropTypes.bool,
  isGTPicksDiscoveryIntegrationEnabled: PropTypes.bool,
};

export default connect((state) => ({
  location: locationSelector(state),
  shouldShowGoogleAdbanner: isFromAdwords(state) || isRestrictedMetro(state),
  isInitialUserPurchase: selectIsInitialUserPurchase(state),
  query: querySelector(state),
  isGTPicksDiscoveryIntegrationEnabled:
    selectIsGTPicksDiscoveryIntegrationEnabled(state),
}))(ContainerTemplate);
