import { ValueOf } from 'type-fest';

import { DELIVERY_FORMATS } from 'store/datatypes/DELIVERY_FORMATS';

type DeliveryFormats = ValueOf<typeof DELIVERY_FORMATS>;

type ReplacementIdentifier =
  | '<transfer_email>'
  | '<transfer_type>'
  | '<in_hand_date>'
  | '<user_email>';

export const TRANSFER_EMAIL_REPLACEMENT: ReplacementIdentifier = `<transfer_email>`;
export const TRANSFER_TYPE_REPLACEMENT: ReplacementIdentifier = `<transfer_type>`;
export const IN_HAND_DATE_REPLACEMENT: ReplacementIdentifier = `<in_hand_date>`;
export const USER_EMAIL_REPLACEMENT: ReplacementIdentifier = `<user_email>`;

const DEFAULT_GET_APP_TITLE = 'Stay in the know.' as const;
const DEFAULT_GET_APP_TEXT =
  'See pricing easily for events near you, get updates, and buy with two taps.' as const;

export const DELIVERY_DISPLAY_DATA = {
  [DELIVERY_FORMATS.LOCAL_PICKUP_ONLY]: {
    title: ['Local Pickup', 'Required'],
    text: 'This event requires in-person ticket pick-up. You will receive a separate email with instructions on where to pick up your tickets.',
    confirmationCardTitle: 'Local Pickup Required',
    orderDetailTitle: 'Local Pickup Required',
    getAppTitle: DEFAULT_GET_APP_TITLE,
    getAppText: DEFAULT_GET_APP_TEXT,
  },

  [DELIVERY_FORMATS.APP_REQUIRED]: {
    title: ['Mobile', 'Transfer'],
    text: "Per the venue's policy, these tickets will be transferred to you outside of the Gametime app via Ticketmaster or the team's official site.",
    confirmationCardTitle: 'Gametime App Required',
    orderDetailTitle: 'Gametime App Required',
    getAppTitle: 'Access your tickets offline.',
    getAppText:
      'Download and share your mobile tickets for quick scanning at the venue.',
  },

  [DELIVERY_FORMATS.DELIVERY_THIRD_PARTY]: {
    title: ['Mobile', 'Transfer'],
    text: `Per the venue's policy, these tickets will be transferred to you outside of the Gametime app via Ticketmaster or the team's official site;`,
    preDeliveryText: `Due to restrictions set by the venue, you'll receive your tickets via ${TRANSFER_TYPE_REPLACEMENT} (outside of the Gametime app).\n
         Check your email at ${TRANSFER_EMAIL_REPLACEMENT} for instructions and a link to claim your tickets. You must accept the ticket transfer to scan in at the venue.`,
    postDeliveryText: `1. Look for an email at ${TRANSFER_EMAIL_REPLACEMENT} with a link to claim your tickets via ${TRANSFER_TYPE_REPLACEMENT}.
         2. Click the link in the transfer email to accept your tickets.
         3. Log in or create an account on ${TRANSFER_TYPE_REPLACEMENT}.
         4. Follow the prompts to accept your mobile tickets.`,
    acceptanceDeliveryText:
      'Your tickets have been delivered! Tap the link below to access your mobile tickets.',
    confirmationCardTitle: 'Mobile Transfer Ticket',
    orderDetailTitle: 'Mobile Transfer Ticket',
  },

  [DELIVERY_FORMATS.TEXT_MESSAGE]: {
    title: ['Mobile', 'Transfer'],
    text: `Per the venue's policy, these tickets will be transferred to you outside of the Gametime app via text message;`,
    preDeliveryText: `Due to restrictions set by the venue, you'll receive your tickets via text message (outside of the Gametime app).\n
         Check your text messages for instructions and a link to claim your tickets. You must accept the ticket transfer to scan in at the venue.\n
         They will arrive by ${IN_HAND_DATE_REPLACEMENT}\n\n`,
    postDeliveryText: `1. Look for a text message with a link to claim your tickets.
         2. Click the link in the text message to accept your tickets.
         3. Follow the prompts to accept your mobile tickets.`,
    acceptanceDeliveryText:
      "There's just one more step to claim your tickets! Check your text messages and follow the prompts to accept your mobile tickets.",
    confirmationCardTitle: 'Mobile Transfer Ticket',
    orderDetailTitle: 'Mobile Transfer Ticket',
  },

  [DELIVERY_FORMATS.MLB_BALLPARK]: {
    title: ['Mobile', 'Transfer'],
    text: `Per the venue's policy, these tickets will be transferred to you outside of the Gametime app via Ticketmaster or the team's official site;`,
    preDeliveryText: `Due to restrictions set by the venue, you'll receive your tickets via ${TRANSFER_TYPE_REPLACEMENT} (outside of the Gametime app).\n
          You will need to have MLB Ballpark installed on your phone and verify that ${TRANSFER_EMAIL_REPLACEMENT} is associated with your account. We'll send an email and text when your tickets have been delivered.\n
          They will arrive by ${IN_HAND_DATE_REPLACEMENT}\n`,
    postDeliveryText: `Due to restrictions set by the venue, your tickets have been delivered to you in the MLB Ballpark app. To access:\n
        1. Install or open the MLB Ballpark app.\n
        2. Log in or create an account. Verify that ${TRANSFER_EMAIL_REPLACEMENT} is listed in your profile.\n
        3. Tap on your tickets and enjoy the game!`,
    postDeliveryTitle: 'Your tickets are ready',
    confirmationCardTitle: 'Mobile Transfer Ticket',
    orderDetailTitle: 'Mobile Transfer Ticket',
    acceptanceDeliveryText:
      'Your tickets have been delivered! Tap the link below to access your mobile tickets.',
  },

  [DELIVERY_FORMATS.PACIOLAN]: {
    title: ['Mobile', 'Transfer'],
    text: `Per the venue's policy, these tickets will be transferred to you outside of the Gametime app via the team or venue's official site;`,
    preDeliveryText: `Due to restrictions set by the venue, you'll receive your tickets via the team or venue (outside of the Gametime app).\n
      You'll get an email at ${TRANSFER_EMAIL_REPLACEMENT} with instructions and a link to claim your tickets. This email will arrive by ${IN_HAND_DATE_REPLACEMENT}.\n
      You must accept the ticket transfer to scan in at the venue.\n`,
    postDeliveryText: `1. Look for an email at ${TRANSFER_EMAIL_REPLACEMENT} with a link to claim your tickets.
       2. Click the link in the transfer email to accept your tickets.
       3. Log in or create an account with the team or venue.
       4. Follow the prompts to accept your mobile tickets.`,
    acceptanceDeliveryText:
      'Your tickets have been delivered! Tap the link below to access your mobile tickets.',
    confirmationCardTitle: 'Mobile Transfer Ticket',
    orderDetailTitle: 'Mobile Transfer Ticket',
  },

  [DELIVERY_FORMATS.PRINT_ONLY]: {
    title: ['Printing', 'Required'],
    text: "This event requires printed tickets for entry. We'll send you an email when your tickets are available to print.",
    confirmationCardTitle: 'Printing Required',
    orderDetailTitle: 'Printing Required',
    getAppTitle: 'Print from your phone.',
    getAppText:
      'Share tickets with friends and print easily from the Gametime app.',
  },

  [DELIVERY_FORMATS.DELIVERY_ONLY]: {
    title: ['Mail', 'Delivery'],
    text: "This event requires your tickets to be shipped to you directly. Your order is all set, and you'll receive your tickets in the mail before the event.",
    confirmationCardTitle: 'Shipping Required',
    orderDetailTitle: 'Shipping Required',
    getAppTitle: DEFAULT_GET_APP_TITLE,
    getAppText: DEFAULT_GET_APP_TEXT,
  },

  [DELIVERY_FORMATS.MOBILE]: {
    title: ['Mobile', 'Ticket'],
    text: '',
    confirmationCardTitle: 'Mobile Delivery',
    orderDetailTitle: 'Mobile Delivery',
    getAppTitle: 'Access your tickets offline.',
    getAppText:
      'Download and share your mobile tickets for quick scanning at the venue.',
  },

  [DELIVERY_FORMATS.MLB_ORDER]: {
    text: 'To access your tickets, you’ll need to complete a few more steps:\n',
    instructions: [
      {
        text: 'Download the Gametime app and log in to your account using ',
        dataKey: 'user',
      },
      { text: 'Navigate to your event in the Tickets tab' },
      {
        text: 'Follow the instructions to link or finish creating your MLB account, using ',
        dataKey: 'externalAccount',
      },
      { text: 'View your tickets and enjoy the game!' },
    ],
    confirmationCardTitle: 'Almost Finished',
    orderDetailTitle: 'Almost Finished',
    getAppTitle: 'Access your tickets offline.',
    getAppText:
      'Download and share your mobile tickets for quick scanning at the venue.',
  },
} as const;

type DisplayType = DeliveryFormats | undefined;

export const getDeliveryDisplayProps = (type: DisplayType) => {
  if (!type) {
    return DELIVERY_DISPLAY_DATA[DELIVERY_FORMATS.MOBILE];
  }

  return DELIVERY_DISPLAY_DATA[type];
};
