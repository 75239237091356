import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './GTGrid.module.scss';

const GTGrid = ({ children, evenSpacing = false }) => {
  const styling = evenSpacing
    ? classNames(styles['gt-grid'], styles['even-spacing'])
    : classNames(styles['gt-grid']);

  return <div className={styling}>{children}</div>;
};

GTGrid.propTypes = {
  children: PropTypes.node,
  evenSpacing: PropTypes.bool,
};

GTGrid.defaultProps = {
  evenSpacing: false,
};

export default GTGrid;
