import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import {
  ACTIONS as T_ACTIONS,
  getTrackingProps,
} from 'components/Trackable/TrackingHelper';
import UserNamePhoneForm from 'components/UserNamePhoneForm/UserNamePhoneForm';
import { Listing } from 'models';
import { PURCHASE_CTA_TEXTS } from 'pages/Listing/constants';

@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.TRANSFER_TICKETS(),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
@TrackPageView(({ transactionId, listing }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.TRANSFER_TICKETS(transactionId),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
export default class PurchaseUserTextMessageCollection extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    listing: PropTypes.instanceOf(Listing).isRequired, // Used in tracking
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(form) {
    await this.props.onSubmit({
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      phone: form.phone.trim(),
    });
  }

  render() {
    const clickTracker = new ClickTracker().interaction(
      Click.INTERACTIONS.CONTINUE_BUTTON()
    );

    return (
      <div>
        <InfoHeader headerContent="Securing Your Tickets" isDarkTheme bold />
        <ThemedCard
          title="Transfer Required"
          desc="This event requires your tickets to be transferred to you via text message."
        >
          <UserNamePhoneForm
            handleSubmit={this.handleSubmit}
            ctaText={PURCHASE_CTA_TEXTS.DEFAULT}
            mpActions={getTrackingProps(T_ACTIONS.SUBMIT_TEXT_TRANSFER_TICKET)}
            clickTracker={clickTracker}
          />
        </ThemedCard>
      </div>
    );
  }
}
