/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { PAYLOAD, TRACK, TrackPageView } from 'analytics';
import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import EmailFillIcon from 'icons/EmailFillIcon';
import { Listing } from 'models';
import colors from 'styles/colors.constants';

const UserEmailFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .trim()
    .required('Please enter a valid email address'),
});

const EmailForm = ({
  ctaText = 'CONTINUE WITH EMAIL',
  isSubmitting,
  handleEmailLogin,
  autoFocus = false,
}) => {
  Mailcheck.defaultDomains.push('gametime.co');

  const [emailSuggestion, setEmailSuggestion] = useState('');

  /**
   * When the user submits the form, Mailcheck will run to see if the
   * email address has a spelling error in it. If Mailcheck determines
   * that there's a spelling error, it will show in the info section
   * under the input field and prevent a submission. If the user submits
   * while there's an email suggestion shown on screen, they acknowledge
   * that what they typed is accurate.
   */
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      if (emailSuggestion) {
        await handleEmailLogin(values.email);
      } else {
        await Mailcheck.run({
          email: values.email.trim(),
          suggested: (suggestion) => {
            setEmailSuggestion(suggestion.full);
          },
          empty: async () => {
            await handleEmailLogin(values.email);
          },
        });
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserEmailFormValidationSchema}
    >
      {(props) => {
        const { touched, errors, handleChange, handleBlur, handleSubmit } =
          props;

        return (
          <Form onSubmit={handleSubmit}>
            <TextInput
              id="user-email"
              name="email"
              label="Email Address"
              placeholder="Email Address"
              autoComplete="email"
              autoFocus={autoFocus}
              startIcon={<EmailFillIcon fill={colors.gray200} />}
              onChange={(e) => {
                setEmailSuggestion('');
                handleChange(e);
              }}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.email && touched.email,
                message: errors.email,
              }}
              info={{
                hasInfo: emailSuggestion,
                infoMessage: `Did you mean ${emailSuggestion}?`,
              }}
            ></TextInput>
            <DarkThemedFormButtonLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              showBackground
            />
          </Form>
        );
      }}
    </Formik>
  );
};
EmailForm.propTypes = {
  ctaText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handleEmailLogin: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  listing: PropTypes.instanceOf(Listing), // Used for tracking
};

const getEventState = ({ pageTypeCallback, listing }) => {
  const pageType = pageTypeCallback?.();
  const listingTrackingData = listing?.listingTrackingData || {};

  return {
    [TRACK.PAGE_TYPE]: pageType,
    active: !!pageType,
    payload: {
      [PAYLOAD.DEAL_TYPE]: listingTrackingData.dealType,
      [PAYLOAD.FEATURED_TYPE]: listingTrackingData.featuredType,
      [PAYLOAD.IS_PROMO]: listingTrackingData.isPromo,
      [PAYLOAD.IS_SPONSORED]: listingTrackingData.isSponsored,
    },
  };
};

export default TrackPageView(getEventState)(EmailForm);
