import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore as _createStore } from 'redux';

import createMiddleware from './middleware/clientMiddleware';
import cmsMiddleware from './middleware/cmsMiddleware';
import reducer from './modules/reducer';

export default function createStore(history, httpClient, cms, data) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = [
    createMiddleware(httpClient),
    cmsMiddleware(cms),
    reduxRouterMiddleware,
  ];

  let finalCreateStore;
  if (__DEVELOPMENT__ && __CLIENT__ && __DEVTOOLS__) {
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    finalCreateStore = composeEnhancers(applyMiddleware(...middleware))(
      _createStore
    );
  } else {
    finalCreateStore = compose(applyMiddleware(...middleware))(_createStore);
  }

  const store = finalCreateStore(reducer, data);

  if (__DEVELOPMENT__ && import.meta.webpackHot) {
    import.meta.webpackHot.accept('./modules/reducer', () => {
      store.replaceReducer(reducer);
    });
  }

  return store;
}
